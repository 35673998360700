import React from "react";
import Card from "components/Card/Card.jsx";
import { Table, Button } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";

import api from "../../services/api.jsx";
import LeadsFilter from "./leads-filter.jsx";

export function leads() {
  const [leads, setLeads] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(15);
  const [totalItems, setTotalItems] = React.useState(0);
  const [filters, setFilters] = React.useState("");

  React.useEffect(() => {
    loadLeads(`/get_leads?${filters}paginate=1&page=${page}`);
  }, [filters, page]);

  function loadLeads(url) {
    trackPromise(api.get(url))
      .then((response) => {
        const formattedLeads = response.data.data.map((lead) => {
          return {
            ...lead,
            user_phone: formatPhone(lead.user_phone),
          };
        });
        setLeads(formattedLeads);
        setCurrentPage(response.data.current_page);
        setItemsPerPage(response.data.per_page);
        setTotalItems(response.data.total);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function deleteLead(leadId) {
    trackPromise(api.delete(`/application_payment/${leadId}`))
      .then((response) => {
        loadLeads(`/get_leads?${filters}paginate=1&page=${page}`);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function formatPhone(phone) {
    return phone
      .replace(/[^0-9]/g, "")
      .replace(/(\d{2})(\d{1})/, "($1) $2")
      .replace(/\((\d{2})\)(\d{4,5})/, "($1) $2")
      .replace(/\((\d{2})\)\s(\d{4,5})(\d{4})$/, "($1) $2 - $3");
  }

  return (
    <div className="content leads-page">
      <Card
        title="Leads"
        category="Gerenciamento de leads"
        ctTableFullWidth
        ctTableResponsive
        content={
          <>
            <LeadsFilter
              callback={setFilters}
              reset={() => {
                setPage(1);
                setFilters("");
              }}
            />
            <Table striped hover>
              <thead>
                <tr>
                  <th>
                    Nome do Lead /<br></br> Nome do Candidato
                  </th>
                  <th>Contatos</th>
                  <th>Data de criação</th>
                  <th>Região da aplicação</th>
                  <th>Tipo de app</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {leads.map((lead) => (
                  <tr key={lead.id}>
                    <td>
                      {lead.user_full_name}
                      <br></br>
                      {lead.candidate ? lead.candidate : "-"}
                    </td>
                    <td>
                      {lead.user_mail} <br></br>
                      {lead.user_phone}
                    </td>
                    <td>
                      {new Date(lead.created_at).toLocaleDateString()} -{" "}
                      {new Date(lead.created_at).toLocaleTimeString()}
                    </td>
                    <td>
                      {lead.city} - {lead.state}
                    </td>
                    <td className="text-capitalize">{lead.application_type}</td>
                    <td>
                      {" "}
                      <Button
                        bsStyle="danger"
                        simple
                        type="button"
                        bsSize="xs"
                        onClick={() => {
                          Swal.fire({
                            title: "Deseja remover esse lead ?",
                            confirmButtonText: "Remover",
                            cancelButtonText: `Manter`,
                            showCancelButton: true
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteLead(lead.id);
                            }
                          });
                        }}
                      >
                        <i className="fa fa-trash" />
                        Remover lead
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        }
      />
      <div className="text-center">
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalItems}
          pageRangeDisplayed={5}
          onChange={(page) => setPage(page)}
        />
      </div>
    </div>
  );
}
