import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import React, { useState } from "react";
import {
  Alert, Col, Modal, Row
} from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import api from "services/api.jsx";

const Update = ({ onClose = () => { }, eletivePositions, politicalParties, candidato }) => {

  const [show, setShow] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const [elective_positions] = useState(eletivePositions);
  const [political_parties] = useState(politicalParties);

  const [name, setName] = useState(candidato.name);
  const [number, setNumber] = useState(candidato.number);
  const [email, setEmail] = useState(candidato.user.email ?? "");
  const [elective_position_id, setElectivePositionId] = useState(
    Object.entries(eletivePositions).filter((
      [key, value]) => {
        return value === candidato.elective_position; 
      })[0][0]
  );
  const [political_party_id, setPoliticalPartyId] = useState(candidato.political_party.id);
  const [operational_area_type, setOperationalAreaType] = useState(candidato.operational_area.regionalization_id ? 'counties' : 'federal_units');
  const [operational_area_id, setOperationalAreaId] = useState(candidato.operational_area.id);
  const [is_active, setIsActive] = useState(candidato.is_active?1:0);
  const [erro, setErro] = useState('');
  const [slug, setSlug] = useState(candidato.slug);

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  const handleInputChange = (newValue) => {
    setOperationalAreaId(newValue.id);
  };

  const animatedComponents = makeAnimated();

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      if (inputValue) {
        let query;
        if (operational_area_type === "counties") query = `/candidates/operational_areas/counties?q=${inputValue}`;
        if (operational_area_type === "federal_units") query = `/candidates/operational_areas/federal_units`;

        api.get(query)
          .then(response => {
            if (response.data.data) {

              let result = response.data.data.map(elm => {
                return {
                  id: elm.id,
                  value: elm.id,
                  label: elm.name
                };
              });

              const filters = result.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
              );
              resolve(filters);
            }
            resolve([]);
          })
          .catch(err => {
            resolve([]);
          });
      }
    });

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('name', name);
    if (candidato.number !== number) formData.append('number', number);
    formData.append('elective_position_id', elective_position_id);
    formData.append('political_party_id', political_party_id);
    formData.append('operational_area_type', operational_area_type);
    formData.append('operational_area_id', operational_area_id);
    formData.append('is_active', is_active);
    formData.append('slug', slug);
    if (email) formData.append('email', email);

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    trackPromise(api.post(`/${localStorage.getItem('idApp')}/candidates/${candidato.id}`, formData, config))
      .then(response => {
        onClose();
      })
      .catch((error) => {
        console.log(error.response.data.errors);
        if (error.response.data.errors) {
          if (error.response.data.errors.name && error.response.data.errors.number) {
            setErro(error.response.data.errors.name[0] + ' ' + error.response.data.errors.number[0]);
          } else if (error.response.data.errors.name) {
            setErro(error.response.data.errors.name[0]);
          } else if (error.response.data.errors.number) {
            setErro(error.response.data.errors.number[0]);
          }
        } else{
          setErro(error.response.data.message);
        }
        
        setIsAlertVisible(true);
      });

  };

  const setNameSlug = (text) => {
    setName(text);
    setSlug(text
      .toString() // Cast to string (optional)
      .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string (optional)
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\_/g, "-") // Replace _ with -
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/\-$/g, "")); // Remove trailing -
  }

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edição de Candidato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitHandler} encType="multipart/form-data">

            {isAlertVisible && <Alert bsStyle="danger">
              <button type="button" aria-hidden="true" className="close">×</button>
              <span><b> Atenção - </b> {erro}</span>
            </Alert>}
            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              properties={[
                {
                  label: "Nome",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Nome completo",
                  required: true,
                  defaultValue: name,
                  name: 'name',
                  onChange: event => setNameSlug(event.target.value)
                },
                {
                  label: "Slug",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Slug do candidato",
                  required: true,
                  value: slug,
                  defaultValue: slug,
                  onChange: event => setSlug(event.target.value)
                },
              ]}
            />
            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              properties={[
                {
                  label: "Email",
                  type: "email",
                  bsClass: "form-control",
                  placeholder: "Número",
                  required: false,
                  defaultValue: email,
                  name: 'email',
                  onChange: event => setEmail(event.target.value)
                },
                {
                  label: "Número",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Número",
                  required: true,
                  defaultValue: number,
                  name: 'number',
                  onChange: event => setNumber(event.target.value)
                },
              ]}
            />
            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="">Partido Político</label>
                  <select
                    name="political_party_id"
                    defaultValue={political_party_id}
                    onChange={event => setPoliticalPartyId(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">-</option>
                    {political_parties.map(constant => (
                      <option key={constant.id} value={constant.id}>{constant.abbreviation}</option>
                    ))}
                  </select>

                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="">Cargo Eletivo</label>
                  <select
                    name="elective_position_id"
                    defaultValue={elective_position_id}
                    onChange={event => setElectivePositionId(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">-</option>
                    {Object.entries(elective_positions).map(([key, value]) => (
                      <option key={key} value={key}>{value}</option>
                    ))}
                  </select>

                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="">Tipo de area Operacional</label>
                  <select
                    name="operational_area_type"
                    defaultValue={operational_area_type}
                    onChange={event => setOperationalAreaType(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">-</option>
                    <option value="counties">Cidade</option>
                    <option value="federal_units">Unidade Federativa</option>
                  </select>

                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="">{operational_area_type === 'counties' ? 'Munícipio' : 'UF'}</label>
                  <AsyncSelect
                    placeholder={candidato.operational_area.name ? candidato.operational_area.name : `Digite o nome do(a) ${operational_area_type === 'counties' ? 'munícipio' : 'estado'}`}
                    isDisabled={operational_area_type === ''}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    onChange={handleInputChange}
                    cacheOptions
                    loadOptions={promiseOptions}
                  />
                </div>
              </Col>
            </Row>

            <div className="form-group">
              <label className="">Situação</label>
              <select
                name="isActive"
                defaultValue={is_active}
                onChange={event => setIsActive(event.target.value)}
                className="form-control"
                required
              >
                <option value={1}>Ativo</option>
                <option value={0}>Inativo</option>
              </select>
            </div>

            <Button bsStyle="danger" fill onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button bsStyle="info" pullRight fill type="submit">
              Salvar Alterações
            </Button>
            <div className="clearfix" />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Update;
