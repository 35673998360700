import Button from "components/CustomButton/CustomButton.jsx";
import React, { useState } from "react";
import {
  Alert, Col, ControlLabel, FormControl, FormGroup, Modal, Row
} from "react-bootstrap";
import api from "services/api.jsx";

const RecuseForm = ({ onClose = () => { }, item }) => {

  const appId = localStorage.getItem('idApp');
  const [show, setShow] = useState(true);
  const [justification, setJustification] = useState(null);

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    api
    .put(`${appId}/leadership_request/${item.id}/reprove`, {review_message: justification})
    .then(res => {
      console.log('res', res);
      if (res.status === 204) {
        handleClose();
      }
    });
  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Recusar liderança</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            <Row>
              {item && (
                <Col md={12} style={{marginBottom: "16px"}}>
                  <strong>Identificador:</strong> {item.user.name}
                </Col>
              )}
            </Row>
            <FormGroup>
              <ControlLabel>Justificativa</ControlLabel>
              <FormControl
                componentClass="textarea"
                placeholder="Informe a justificativa"
                name="review_message"
                onChange={event => setJustification(event.target.value)}
              />
            </FormGroup>
            <Button bsStyle="danger" fill onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button bsStyle="info" pullRight fill type="submit">
              Salvar Alterações
            </Button>
            <div className="clearfix" /> 
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RecuseForm;
