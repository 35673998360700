import React from "react";
import { useFormContext } from "react-hook-form";
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";

export function SelectField({ value, onChange, field }) {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[field.id]?.message
    ? String(errors[field.id]?.message)
    : undefined;

  const options = field.options?.split(",");

  return (
    <FormGroup>
      <ControlLabel>{field.name}</ControlLabel>
      <FormControl
        componentClass="select"
        value={value}
        hint={field.hint}
        onChange={(event) => onChange(event.target.value)}
        isInvalid={!!error}
      >
        <option value="" disabled>
          Selecione
        </option>
        {options?.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </FormControl>
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </FormGroup>
  );
}
