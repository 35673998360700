import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";

import api from "services/api";
import FormInputs from "components/FormInputs/FormInputs";
import Button from "components/CustomButton/CustomButton.jsx";

export default function InvoiceModal({ setModalClose, invoice, showModal }) {
  const [file, setFile] = useState();
  const [title, setTitle] = useState(null);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleClose = (invoiceSended) => {
    setModalClose(invoiceSended);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    const formData = new FormData();

    formData.append("title", title);

    if (file) {
      formData.append("invoice", file);
    }

    try {
      await trackPromise(
        api.post(
          `/applications_payment/${invoice.id}/invoice`,
          formData,
          config
        )
      );

      handleClose(true);
    } catch (error) {
      console.error(error);
      setIsAlertVisible(true);
    }
  };

  return (
    <Modal
      size="small"
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Enviar nota fiscal para {invoice?.user_full_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={submitHandler} encType="multipart/form-data">
          {isAlertVisible && (
            <div className="alert-error">Erro ao enviar a nota fiscal!</div>
          )}

          <FormInputs
            ncols={["col-md-12"]}
            properties={[
              {
                label: "Título",
                type: "text",
                bsClass: "form-control",
                placeholder: "Informe um título para a emissão da nota",
                required: true,
                defaultValue: title,
                onChange: (event) => setTitle(event.target.value),
              },
            ]}
          />

          <Row>
            <Col md={12}>
              <div className="form-group">
                <label className="">Nota fiscal</label>
                <input
                  type="file"
                  accept=".pdf"
                  className="form-control"
                  name="invoice"
                  required
                  onChange={(event) => setFile(event.target.files[0])}
                />
              </div>
            </Col>
          </Row>

          <hr />

          <Button bsStyle="danger" fill onClick={() => handleClose()}>
            Cancelar
          </Button>
          <Button bsStyle="info" pullRight fill type="submit">
            Enviar
          </Button>
          <div className="clearfix" />
        </form>
      </Modal.Body>
    </Modal>
  );
}
