import api from "services/api";

export function useRemoveGroup({ appId }) {
  async function removeGroup(id) {
    try {
      await api.delete(`${appId}/group_information/${id}`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  return { removeGroup };
}
