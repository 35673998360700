import React, { Component } from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { Button } from "react-bootstrap";
import { Icon } from "leaflet";
import api from "../../services/api.jsx";

import HeatmapLayer from "react-leaflet-heatmap-layer";

const voter = new Icon({
  iconUrl: "/location.svg",
  iconSize: [30, 30],
});

export class Maps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMark: null,
      position: [-5.832372, -35.205449],
      zoom: 11,
      activeLayerPoints: 0,
    };
    this.centerMap();
    console.log(props.markes);
  }

  async centerMap() {
    // get information of the city app
    let response = await api.get(
      "/applications/" + localStorage.getItem("idApp")
    );
    if (!response.data.data.county_id) {
      //Centraliza mapa no brasil, sem cidade especificada
      this.setState({ position: [-8.910475, -54.3025137] });
      this.setState({ zoom: 4 });
    } else {
      //Busca os bairros
      let neighborhoods = await api.get(
        `/counties/${response.data.data.county_id}/neighborhoods`
      );
      if (neighborhoods.data.data && neighborhoods.data.data.length > 0) {
        // Tem bairro
        let centroBairro = neighborhoods.data.data.filter(function (elm) {
          return elm.name === `Centro`;
        });
        if (centroBairro && centroBairro[0])
          this.setState({
            position: [centroBairro[0].latitude, centroBairro[0].longitude],
          });
        else
          this.setState({
            position: [
              neighborhoods.data.data[0].latitude,
              neighborhoods.data.data[0].longitude,
            ],
          });
        this.setState({ zoom: 10 });
      }
    }
  }
  setActiveMark(mark) {
    this.setState({ activeMark: mark });
  }

  alteraMapa() {
    this.setState({
      activeLayerPoints: this.state.activeLayerPoints === 1 ? 0 : 1,
    });
  }

  render() {
    return (
      <>
        <Button
          variant="success"
          style={{ marginBottom: "16px" }}
          onClick={() => {
            this.alteraMapa();
          }}
        >
          Trocar para mapa de{" "}
          {this.state.activeLayerPoints === 1 ? "Calor" : "Pontos"}
        </Button>
        <br></br>
        <Map center={this.state.position} zoom={this.state.zoom}>
          {this.props.markes && this.state.activeLayerPoints === 0 && (
            <HeatmapLayer
              // fitBoundsOnLoad
              // fitBoundsOnUpdate
              points={this.props.markes}
              longitudeExtractor={(m) => m.neighborhood.geometry.long}
              latitudeExtractor={(m) => m.neighborhood.geometry.lat}
              intensityExtractor={(m) => m.totalVoters * 170}
            />
          )}
          {/* customizado para o mapa de intenções de votos */}
          {this.props.markes &&
            this.state.activeLayerPoints === 1 &&
            this.props.markes.map((mark) => (
              <Marker
                key={mark.id}
                position={[
                  mark.neighborhood.geometry.lat,
                  mark.neighborhood.geometry.long,
                ]}
                onClick={() => {
                  this.setActiveMark(mark);
                }}
                icon={voter}
              />
            ))}
          {/* customizado para o maps de pesquisa */}
          {this.props.result &&
            this.props.result.map((mark) => (
              <Marker
                key={mark.id}
                position={[
                  mark.voter.geolocation.lat,
                  mark.voter.geolocation.lng,
                ]}
                onClick={() => {
                  //this.setActiveMark(mark)
                }}
                icon={voter}
              />
            ))}

          {this.state.activeMark && (
            <Popup
              position={[
                this.state.activeMark.neighborhood.geometry.lat,
                this.state.activeMark.neighborhood.geometry.long,
              ]}
              onClose={() => {
                this.setState({ activeMark: null });
              }}
            >
              <div>
                <p>
                  Total de eleitores:{" "}
                  <strong>{this.state.activeMark.totalVoters} </strong>{" "}
                </p>
                {this.state.activeMark.totalVoters > 0 ? (
                  <span>
                    <ul>
                      {this.state.activeMark.voters &&
                        this.props.application_type === "many" &&
                        this.state.activeMark.voters.map((candidates) => (
                          <li>
                            {" "}
                            {candidates.candidate_name} recebeu{" "}
                            <strong>{candidates.votes_count}</strong>{" "}
                            intenção(ões) de voto(s){" "}
                          </li>
                        ))}
                    </ul>
                  </span>
                ) : null}
                <p>
                  {this.state.activeMark.neighborhood.name},{" "}
                  {this.state.activeMark.neighborhood.county} -{" "}
                  {this.state.activeMark.neighborhood.federal_unit}
                </p>
              </div>
            </Popup>
          )}

          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </Map>
      </>
    );
  }
}

export default Maps;
