import { useMemo, useState } from "react";
import { useAtom, useSetAtom } from "jotai";
import { ArrowRight, User } from "@phosphor-icons/react";

import { FormDataAtom } from "components/Onboarding/atoms/FormDataAtom";
import { CurrentStepAtom } from "components/Onboarding/atoms/CurrentStepAtom";

import "./styles.css";

export function BannerImageStep() {
  const [formData, setFormData] = useAtom(FormDataAtom);
  const setCurrentStep = useSetAtom(CurrentStepAtom);

  const [bannerImage, setBannerImage] = useState(formData.banner ?? null);

  function handleNextStep() {
    setFormData((prev) => ({
      ...prev,
      banner: bannerImage,
    }));

    setCurrentStep((prev) => prev + 1);
  }

  function handleSelectedImage(file) {
    if (!file) {
      return;
    }

    setBannerImage(file);
  }

  const imagePath = useMemo(
    () => (bannerImage ? URL.createObjectURL(bannerImage) : ""),
    [bannerImage]
  );

  return (
    <div className="content banner-step">
      <div className="heading">
        <strong>Escolha a foto de capa do perfil</strong>
        <span>
          Você poderá alterar essa imagem após essa primeira configuração
        </span>
      </div>

      <label htmlFor="banner">
        <strong>Clique para adicionar a imagem</strong>

        <div>
          <div className="area-image">
            {imagePath ? <img src={imagePath} alt="" /> : null}

            {!imagePath ? (
              <>
                <strong>Foto de capa do perfil</strong>
                <span>Tamanho recomendado: 1024x1024 px</span>
              </>
            ) : null}
          </div>

          <div className="icon">
            <User size={40} color="#828282" />
          </div>
        </div>
      </label>

      <input
        id="banner"
        name="banner"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => handleSelectedImage(e.target.files[0])}
      />

      <button disabled={!bannerImage} onClick={handleNextStep}>
        Avançar
        <ArrowRight size={20} weight="bold" />
      </button>
    </div>
  );
}
