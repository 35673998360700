import { useEffect, useState } from "react";

import api from "services/api";

export function useTags({ appId, groupId }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getTags() {
      try {
        const response = await api.get(`${appId}/group/${groupId}/tags`);

        const tags = response.data.map((item) => ({
          id: item.id,
          title: item.title,
        }));

        setData(tags);
      } catch (error) {
        console.error(error);
      }
    }

    if (groupId) {
      getTags();
    }
  }, [groupId]);

  return { tags: data };
}
