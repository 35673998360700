import "bootstrap/dist/css/bootstrap.min.css";
import LoadingModal from "components/Loading/LoadingModal.jsx";
import AdminLayout from "layouts/Admin.jsx";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import isAuthenticated from "services/auth.jsx";
import "./assets/css/animate.min.css";
import "./assets/css/demo.css";
import "./assets/css/loading.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./components/Onboarding/styles.css";
import LoginTemplate from "./views/login/index.jsx";
import PasswordReset from "./views/login/passwod-reset.jsx";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);

ReactDOM.render(
  <BrowserRouter>
    <Switch>

      <PrivateRoute path="/admin" component={props => <AdminLayout {...props} />} />

      <Route exact path="/login" component={() => <LoginTemplate />} />

      <PrivateRoute exact path="/password-reset" component={() => <PasswordReset />} />

      <Redirect from="/" to="/login" />
    </Switch>
    <LoadingModal />
  </BrowserRouter>,
  document.getElementById("root")
);
