import React, { useState, useEffect } from "react";
import {
	Dropdown,
	MenuItem,
	Table,
  Grid,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal
} from "react-bootstrap";
import Pagination from 'react-js-pagination';
import { CustomToggle } from 'components/CustomToggle/index.jsx';
import Button from "components/CustomButton/CustomButton.jsx";
import "../styles.css";

import api from "services/api.jsx";
import Card from "components/Card/Card";
import { Link } from "react-router-dom";
import { FiChevronLeft, FiInfo } from "react-icons/fi";

import { datetimeFormatter } from "services/formatters";
import RecuseForm from "./recuseForm";

function Loading(){
  return (
    <div style={{"padding": "30px"}}>
      <p> Carregando conteúdo... </p>
    </div>
  )
}

export default () => {

    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(true)
    const appId = localStorage.getItem('idApp');
    const [openModalRecuse, setOpenModalRecuse] = useState(false);
    const [itemRecuse, setItemRecuse] = useState(null);
    const [textField, setTextField] = useState('');
    const [status, setStatus] = useState('');
    const [openModalAprove, setOpenModalAprove] = useState(false);
    const [idAprove, setIdAprove] = useState(null);
    const [configPagination, setConfigPagination] = useState({
      activePage: null,
      itemsCountPerPage: null,
      totalItemsCount: 1,
      pageRangeDisplayed: 5,
    });

    useEffect(() => {
      getLeadershipRequest();
    }, []);

    function getLeadershipRequest(page = 1, filter) {

      filter = filter ? filter : `&textField=${textField}&status=${status}`;

      api
      .get(`${appId}/leadership_request?page=${page}${filter ? filter : ''}`)
      .then(res => {
        setContent(res.data.data);
        setConfigPagination({
						activePage: res.data.current_page,
						itemsCountPerPage: res.data.per_page,
						totalItemsCount: res.data.total,
						pageRangeDisplayed: 5,
				});
      }).finally(() => {
        setLoading(false);
      });
    }

    function aprove() {
      api
      .put(`${appId}/leadership_request/${idAprove}/aprove`)
      .then((res) => {
        getLeadershipRequest(configPagination.activePage);
        handleCloseAprove();
      });
    };

    function recuse(item) {
      setItemRecuse(item);
      setOpenModalRecuse(true);
    };

    function changeFilter(event) {
      
      let filter = '';

      if (event.target.name === 'textField' && event.target.value) {
        filter = `&textField=${event.target.value}&status=${status}`;
        setTextField(event.target.value);
      }
      if (event.target.name === 'status') {
        filter = `&textField=${textField}&status=${event.target.value}`;
        setStatus(event.target.value);
      }
      getLeadershipRequest(1, filter);
    };

    function handleCloseAprove() {
      setOpenModalAprove(false);
      setIdAprove(null);
    };

    function handlePageChange(pageNumber) {
      getLeadershipRequest(pageNumber);
    };

    return (
      <>
          { loading && <Loading /> }

          { (!loading && content) &&
            <>
              <div className="content">
                <Grid fluid>
                  <Row>
                    <Col md={12}>
                      <Card
                        title="Filtros"
                        // ctTableFullWidth
                        ctTableResponsive
                        content={
                          <>
                            <Row>
                              <Col md={5}>
                                <FormGroup>
                                  <ControlLabel>Nome / Email / ID</ControlLabel>
                                  <FormControl
                                    type="text"
                                    name="textField"
                                    placeholder="Informe o nome, email ou ID"
                                    onChange={async (event) => {
                                      changeFilter(event);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <ControlLabel>Status</ControlLabel>
                                  <FormControl
                                    componentClass="select"
                                    name="status"
                                    placeholder="Selecione o status"
                                    onChange={async (event) => {
                                      changeFilter(event);
                                    }}
                                  >
                                    <option value="">Todos</option>
                                    <option value="revisao">Revisao</option>
                                    <option value="aprovado">Aprovado</option>
                                    <option value="recusado">Recusado</option>
                                  </FormControl>
                                </FormGroup>
                              </Col>
                              <div className="buttons">
                                <Link to={`/admin/leaders`}>
                                  <button
                                    className="btn-leadership-request"
                                  >
                                    <FiChevronLeft />
                                    Voltar
                                  </button>
                                </Link>
                              </div>
                            </Row>
                            {content.length === 0 && (
                              <Row>
                                <Col md={12}>
                                  <div className="not-found">
                                    <FiInfo size={20} />
                                    <span>Sem dados encontrados</span>
                                  </div>
                                </Col>
                              </Row>
                            )}
                            {content.length > 0 && (
                              <Table striped hover>
                                <thead>
                                  <tr>
                                    <th>Identificador</th>
                                    <th className="text-center">Status</th>
                                    <th className="text-center">Data solicitação</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  
                                  {content.map((item) => (
                                    <tr key={item.id} style={{ cursor: 'pointer' }}>
                                      <td>
                                        <div className="profile">
                                          <strong>
                                            {item.user.name}
                                            {
                                              <span className="badge badge-custom">
                                                ID #{item.user.id}
                                              </span>
                                            }
                                          </strong>
                                          <span className="email">{item.user.email}</span>
                                        </div>
                                      </td>
                                      <td className="text-center">
                                        {item.review_message && item.status === 'recusado' && (
                                          <OverlayTrigger placement="right" overlay={
                                            <Tooltip id="tooltip">
                                              {item.review_message}
                                            </Tooltip>
                                          }>
                                            <span className="badge badge-danger">
                                              {item.status}
                                            </span>
                                          </OverlayTrigger>
                                        )}
                                        {!item.review_message && (
                                          <span className={`badge ${item.status === 'revisao' ?
                                          'badge-primary' : item.status === 'aprovado' ?
                                          'badge-success' : 'badge-danger'}`}
                                          >
                                            {item.status}
                                          </span>
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {datetimeFormatter(item.created_at)}
                                      </td>
                                      <td className="text-center">
                                        {item.status === 'revisao' ?
                                          <Dropdown
                                            id="dropdown-custom-1"
                                            bsSize="small"
                                            pullRight
                                          >
                                            <CustomToggle bsRole="toggle" />
                                            <Dropdown.Menu>
                                              <MenuItem
                                                className="text-center"
                                                onClick={() => {
                                                  // aprove(item.id);
                                                  // modalAprove(item.id);
                                                  setOpenModalAprove(true);
                                                  setIdAprove(item.id);
                                                }}
                                              >
                                                <span className="text-success">
                                                  <i className="fa fa-check" />
                                                  Aprovar
                                                </span>
                                              </MenuItem>
                                              <MenuItem
                                                className="text-center"
                                                onClick={() => {
                                                  recuse(item);
                                                }}
                                              >
                                                <span className="text-danger">
                                                  <i className="fa fa-times" />
                                                  Recusar
                                                </span>
                                              </MenuItem>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        : null}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            )}
                            <div style={{ textAlign: 'center' }}>
                              <Pagination
                                activePage={configPagination.activePage}
                                itemsCountPerPage={configPagination.itemsCountPerPage}
                                totalItemsCount={configPagination.totalItemsCount}
                                pageRangeDisplayed={
                                  configPagination.pageRangeDisplayed
                                }
                                onChange={handlePageChange.bind(this)}
                              />
                            </div>
                          </>
                        }
                      ></Card>
                    </Col>
                  </Row>
                </Grid>
              </div>
              {openModalAprove && (
                <Modal
                  show={openModalAprove}
                  onHide={handleCloseAprove}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Deseja aprovar liderança?</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col md={12} style={{marginBottom: "16px"}}>
                        Ao clicar em <strong>"Aprovar"</strong>, o eleitor passará a ser lider.
                      </Col>
                    </Row>
                    <Button bsStyle="danger" fill onClick={() => handleCloseAprove()}>
                      Cancelar
                    </Button>
                    <Button bsStyle="success" pullRight fill onClick={() => aprove()}>
                      Aprovar
                    </Button>
                    <div className="clearfix" /> 
                  </Modal.Body>
                </Modal>
              )}
              {openModalRecuse && 
                <RecuseForm
                  item={itemRecuse}
                  onClose={() => {
                    setItemRecuse(null);
                    setOpenModalRecuse(false);
                    getLeadershipRequest(configPagination.activePage);
                  }}
                />
              }
            </>
          }
      </>
    );
}
