
import { useEffect, useState } from "react";

import api from "services/api";

export function useCandidates({ appId }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getCandidates() {
      try {
        const response = await api.get(`${appId}/candidates/get_by_roles`);
    
        const candidates = response.data.data.map((item) => ({
          value: String(item.id),
          label: item.name,
        }));
    
        setData(candidates);
    
        return candidates;
      } catch (error) {
        console.error(error);
      }
    }

    getCandidates();
  }, []);

  return { candidates: data };
}
