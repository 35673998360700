import React from "react";
import "./styles.css";

export function EmptyAnswer() {
	return (
		<div className="fields-empty-state">
			<strong>Não há respostas cadastradas</strong>

			<p>
				Parece que não há respostas no momento. Por favor,
				verifique novamente mais tarde.
			</p>
		</div>
	);
}
