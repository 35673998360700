import React from "react";
import defaultAvatar from "assets/img/default-avatar.png";
import "./style.css";

const CandidateTag = ({ candidate }) => {
  return (
    <>
      <img
        src={candidate.link_profile_image || defaultAvatar}
        alt="foto"
        className="mini-img"
      />
      &nbsp;
      <span>{candidate.name}</span>
    </>
  );
};

export default CandidateTag;