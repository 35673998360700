import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import api from "services/api.jsx";

import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
import logoMobbyCandidato from "assets/img/logo-candidato-light.png";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      typeRole: localStorage.getItem("typeRole"),
      typeApplication: localStorage.getItem("typeApplication"),
      groupedRoutes: {},
      appData: null,
    };
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  async componentDidMount() {
    this.updateDimensions();

    window.addEventListener("resize", this.updateDimensions.bind(this));

    if (!!localStorage.getItem("idApp")) {
      try {
        const response = await api.get(
          "/applications/" + localStorage.getItem("idApp")
        );

        this.setState({ appData: response.data.data });

        this.updateRoutes()
      } catch (error) {
        console.error("Erro ao chamar a API", error);
      }
    }

    else {
      this.updateRoutes(true)

    }
  }

  updateRoutes(isAdmin = false) {
    const { routes } = this.props;
    const { typeRole, typeApplication, appData } = this.state;

    // Agrupando rotas por grupo
    const groupedRoutes = routes.reduce((acc, route) => {
      const isVisible = isAdmin ? true : (route.group === "Gamificação de liderança" ||
        route.group === "Página de Participações" ||
        route.name === "Lideranças") ? appData.enable_leadership : true

      if (
        isVisible &&
        route.role.includes(typeRole) &&
        route.visible &&
        (typeRole === "coordinator" ||
          typeRole === "admin" ||
          route.application_type.includes(typeApplication))
      ) {
        if (!appData?.enable_leadership
          &&
          route.group === "Gamificação de liderança" ||
          route.group === "Página de Participações" ||
          route.name === "Lideranças"
        ) {
          route.visible = false;
        }

        const group = route.group || "ungrouped";
        acc[group] = acc[group] || [];
        acc[group].push(route);
      }

      return acc;
    }, {});

    this.setState({ groupedRoutes })
  }

  render() {
    const { width, groupedRoutes } = this.state;

    return (
      <div id="sidebar" className="sidebar" data-color="black">
        <div className="logo">
          <div className="simple-text logo-mini">
            <div className="logo-img">
              <img src={logoMobbyCandidato} alt="logo_image" />
            </div>
          </div>
        </div>
        <div className="sidebar-wrapper">
          {width <= 991 && <AdminNavbarLinks />}
          {Object.keys(groupedRoutes).map((group, groupIndex) => (
            <ul
              key={groupIndex}
              className="nav"
              style={{
                marginTop: "0px",
              }}
            >
              {group != "ungrouped" ? (
                <li className="nav-item">
                  <p
                    className="nav-label"
                    style={{
                      margin: "18px 0px 5px 8px",
                      lineHeight: "normal",
                    }}
                  >
                    {group}
                  </p>
                </li>
              ) : null}
              {groupedRoutes[group].map((route, key) => (
                <li
                  className={
                    route.upgrade
                      ? "active active-pro"
                      : this.activeRoute(route.layout + route.path)
                  }
                  key={key}
                >
                  <NavLink
                    to={route.layout + route.path}
                    className="nav-link"
                    activeClassName="active"
                    style={{
                      margin: "4px 8px",
                      padding: "8px 4px",
                      fontSize: "14px",
                      display: "flex",
                    }}
                    key={key}
                  >
                    <span
                      className={route.icon}
                      style={{
                        marginRight: "8px",
                        fontSize: "20px",
                        width: "20px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    />
                    <span>{route.name}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
    );
  }
}

export default Sidebar;
