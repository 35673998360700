import React from "react";
import { Modal } from "react-bootstrap";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function ImagesModal({ setModalClose, showModal, images }) {
  const handleClose = () => {
    setModalClose();
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Imagens</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="image-gallery">
          {images.map((image) => (
            <Zoom key={image.id}>
              <img
                src={image.image}
                alt={`Task ${image.task_id} - Voter ${image.voter_id}`}
                className="styled-image"
              />
            </Zoom>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}
