import DangerAlert from "components/DangerAlert/DangerAlert";
import React from "react";
import {
  Col,
  Row,
  Modal,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import { trackPromise } from "react-promise-tracker";
import api from "services/api";
import { participationStatus } from "./index";

const Update = ({ show, close, reload, category }) => {
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [error, setError] = React.useState("");

  function submitHandler(event) {
    event.preventDefault();
    const appId = localStorage.getItem("idApp");
    const config = { headers: { "Content-Type": "application/json" } };
    const data = {
      name: event.target.name.value,
      initial_state: event.target.initialState.value,
      initial_message: event.target.initialMessage.value,
    };

    trackPromise(
      api.put(`/${appId}/participation_category/${category.id}`, data, config)
    )
      .then(() => {
        close();
        reload();
      })
      .catch((err) => setError(err.message));
  }

  return (
    <Modal
      size="sm"
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Edição de categoria de participação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          {isAlertVisible && (
            <DangerAlert error={error} close={() => setIsAlertVisible(false)} />
          )}
          <Row>
            <Col md={8}>
              <FormGroup>
                <ControlLabel>Nome</ControlLabel>
                <FormControl
                  type="text"
                  placeholder="Nome da categoria"
                  name="name"
                  defaultValue={category && category.name}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Status inicial</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="Status inicial da categoria"
                  name="initialState"
                  defaultValue={category && category.initial_state}
                  required
                >
                  {Array.from(participationStatus.entries()).map(
                    (status, index) => (
                      <option key={index} value={status[0]}>
                        {status[1]}
                      </option>
                    )
                  )}
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <ControlLabel>Mensagem inicial</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  defaultValue={category && category.initial_message}
                  name="initialMessage"
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Button bsStyle="danger" fill onClick={close}>
            Cancelar
          </Button>
          <Button bsStyle="info" pullRight fill type="submit">
            Atualizar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Update;
