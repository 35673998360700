import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";

import api from "services/api";
import { useDebounce } from "hooks/useDebounce";

export function useInfos({ appId, search, groupId, candidateId }) {
  const [data, setData] = useState([]);
  const [shouldRefetch, refetch] = useState(false);

  const searchDeffered = useDebounce(search, 700);

  useEffect(() => {
    async function getInfos() {
      const response = await trackPromise(
        api.get(`${appId}/infos/home`, {
          params: {
            ...(search ? { search: searchDeffered } : {}),
            ...(groupId ? { group_id: groupId } : {}),
            ...(candidateId ? { candidate: candidateId } : {}),
            ...({visibility: false})
          },
        })
      );

      const data = response.data;

      const infos = data.map((item) => {
        let imageData = null;

        const image =
          item.type === "Group Information"
            ? item.orderdeable.image
            : item.orderdeable.cover;

        if (image) {
          imageData = {
            type: isLink(image) ? "image" : "icon",
            link: image,
          };
        }

        return {
          id: item.orderdeable.id,
          orderId: item.id,
          type: item.type,
          title: item.orderdeable.title,
          image: imageData,
          visibility: item.orderdeable.visibility,
          tags: item.orderdeable.assigned_tags.map((item) => ({
            tagId: item.id,
            title: item.title,
          })),
        };
      });

      setData(infos);
    }

    getInfos();
  }, [searchDeffered, groupId, candidateId, shouldRefetch]);

  function isLink(value) {
    return value.startsWith("http");
  }

  return { infos: data, refetch: () => refetch((prevState) => !prevState), updateInfos: setData };
}
