import React from "react";
import { Modal } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

export function ModalConfirmationDelete({ isOpen, onClose, name }) {
  return (
    <Modal size="sm" show={isOpen} onHide={() => onClose()} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Excluir campo do perfil</Modal.Title>
      </Modal.Header>

      <Modal.Body>{`Deseja remover o campo "${decodeURIComponent(name)}"?`}</Modal.Body>

      <Modal.Footer>
        <Button bsStyle="danger" fill onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button bsStyle="info" fill onClick={() => onClose(true)}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
