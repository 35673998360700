import React, { Fragment, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { Modal, Form } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";
import { useAssignedTags } from "views/infos/hooks/useAssignedTags";
import { useQueryParams } from "hooks/useQueryParams";
import api from "services/api";

import "./styles.css";

export function AssociateTags({ isOpen, onClose, tags }) {
  const appId = localStorage.getItem("idApp");

  const queryParams = useQueryParams();

  const { assignedTags } = useAssignedTags({
    appId,
    id: queryParams.get("item_id"),
    type: queryParams.get("item_type"),
  });

  const { control, handleSubmit, reset, register } = useForm({
    defaultValues: {
      tags: [],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "tags",
  });

  useEffect(() => {
    if (tags.length && isOpen) {
      reset({
        tags: tags.map((item) => ({
          tagId: item.id,
          title: item.title,
          checked: false,
        })),
      });
    }
  }, [tags, isOpen]);

  useEffect(() => {
    if (assignedTags) {
      reset({
        tags: fields.map((item) => ({
          ...item,
          checked: assignedTags.some((tag) => tag.id === item.tagId),
        })),
      });
    }
  }, [assignedTags]);

  async function handleAssociateTags({ tags }) {
    try {
      const tagsId = tags
        .filter((item) => item.checked)
        .map((item) => item.tagId);

      await trackPromise(
        api.post(`${appId}/tags/assigned/${queryParams.get("item_id")}`, {
          type: queryParams.get("item_type"),
          tags: tagsId,
        })
      );

      handleOnClose(true);
    } catch (error) {
      console.error(error);
    }
  }

  function handleOnClose(success) {
    reset({
      tags: [],
    });

    onClose(success);
  }

  const itemName = queryParams.get("item_name");

  return (
    <Modal show={isOpen} onHide={() => handleOnClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{`Associar tags em ${itemName}`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          id="form-associate-tags"
          className="form-associate-tags"
          onSubmit={handleSubmit(handleAssociateTags)}
        >
          {fields.map((item, index) => (
            <div key={item.id}>
              <input
                id={item.tagId}
                type="checkbox"
                className="checkbox-tag"
                {...register(`tags.${index}.checked`)}
              />

              <label htmlFor={item.tagId}>{item.title}</label>
            </div>
          ))}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button fill onClick={() => handleOnClose()}>
          Cancelar
        </Button>

        <Button
          bsStyle="success"
          pullRight
          fill
          form="form-associate-tags"
          type="submit"
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
