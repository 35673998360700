import { Link } from "react-router-dom";
import { Panel } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";

import "./styles.css";
import api from "services/api";
import Card from "components/Card/Card";
import logo from "assets/img/mobby-candidato-logo.png";
import CustomCheckbox from "./components/checkbox/custom-checkbox";
import CircularProgressBar from "./components/progress-bar/progress-bar";

export const StartGuide = () => {
  const [redeCor, setRedeCor] = useState(false);
  const [candidato, setCandidato] = useState(false);
  const [biografia, setBiografia] = useState(false);
  const [coordenador, setCoordenador] = useState(false);
  const [redeCorCandidato, setRedeCorCandidato] = useState(false);

  const appId = localStorage.getItem("idApp");

  useEffect(() => {
    loadStartGuide();
  }, [appId]);

  const loadStartGuide = async () => {
    try {
      const response = await trackPromise(api.get(`${appId}/start_guide`));

      setRedeCor(response.data.rede_cor);
      setBiografia(response.data.biografia);
      setCoordenador(response.data.coordenador);
      setCandidato(response.data.existe_candidato);
      setRedeCorCandidato(response.data.rede_cor_candidatos);
    } catch (error) {
      console.error(error);
    }
  };

  const segments = [
    { show: redeCor, percentage: 33.33, color: "#6039ce" },
    { show: coordenador, percentage: 33.33, color: "#6039ce" },
    { show: biografia, percentage: 33.33, color: "#6039ce" },
  ];

  return (
    <div className="content content-start-guide">
      <div className="header-guide">
        <img src={logo} alt="logotipo do mobby candidato" width="35%"></img>
        <h3>Bem-Vindo ao Mobby Candidato</h3>
        <span>
          Nós ajudamos gestores políticos a se aproximarem dos seus eleitores e
          suas lideranças
        </span>
      </div>
      <Card
        title="Checklist das Configurações Iniciais"
        category="Siga o passo a passo para iniciar com o sistema"
        ctTableFullWidth
        ctTableResponsive
        content={
          <>
            <div>
              <Panel eventKey="1">
                <Panel.Toggle>
                  <div className="accordion-header">
                    <CustomCheckbox
                      readOnly={true}
                      isChecked={coordenador}
                      color="#26ae37"
                    />

                    <div>
                      <Panel.Title>1. Adicione novos coordenadores</Panel.Title>
                      <span className="accordion-span">
                        Acesse a área de coordenadores e crie os usuários para o
                        time de campanha
                      </span>
                    </div>
                  </div>
                </Panel.Toggle>
                <Panel.Collapse>
                  <Panel.Body className="accordion-body">
                    <div className="img-wrapper">
                      <img className="cover-img" src="https://grt8-devs-assets.s3.sa-east-1.amazonaws.com/FOTO+01+-+Coordenadores.png" />
                    </div>

                    <div className="body-card">
                      <p style={{ color: "#663dc9", fontWeight: "700", fontSize: "16px" }}>Como cadastrar um novo coordenador?</p>

                      <span>
                        Os coordenadores têm a capacidade de visualizar todas as
                        áreas do sistema do Mobby Candidato, isso inclui ajustes e
                        configurações tanto da aplicação quanto do sistema Mobby
                        Candidato. As pessoas que estão em cargos de gestão na
                        campanha devem ser coordenadores no sistema do Mobby
                        Candidato. Por exemplo, pessoas de Marketing,
                        coordenadores de campanha e entre outros.
                      </span>

                      <ol type="1">
                        <li>
                          No menu lateral esquerdo, selecione "Coordenadores";
                        </li>
                        <li>
                          Na área de coordenadores do sistema do Mobby Candidato,
                          clique em 'Novo Cadastro';
                        </li>
                        <li>
                          Preencha as informações de cadastro do novo coordenador;
                        </li>
                        <li>Clique em 'cadastrar';</li>
                        <li>
                          Solicite que o coordenador acesse o email com as
                          instruções de acesso.
                        </li>
                      </ol>

                      <div className="btn-wrapper">
                        <Link to="/admin/organizers_filltered">
                          <button className="btn-action">Cadastrar agora</button>
                        </Link>
                      </div>
                    </div>
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>

              <Panel eventKey="2">
                <Panel.Toggle>
                  <div className="accordion-header">
                    <CustomCheckbox
                      readOnly={true}
                      isChecked={biografia}
                      color="#26ae37"
                    />

                    <div>
                      <Panel.Title>
                        2. Configure a 'Página do Candidato'
                      </Panel.Title>

                      <span className="accordion-span">
                        Configure as propostas, biografias, informações, redes
                        sociais e imagens.
                      </span>
                    </div>
                  </div>
                </Panel.Toggle>
                <Panel.Collapse>
                  <Panel.Body className="accordion-body">
                    <div className="img-wrapper">
                      <img className="cover-img" src="https://grt8-devs-assets.s3.sa-east-1.amazonaws.com/Guia_Biografia_Pagina+do+Candiadto.png" />
                    </div>

                    <div className="body-card">
                      <p style={{ color: "#663dc9", fontWeight: "700", fontSize: "16px" }}>
                        Como configurar a biografia da página do candidato?
                      </p>

                      <span>
                        O santinho virtual, também chamado de página do candidato,
                        é dividido entre Propostas, Biografias, Informações e Redes Sociais.
                        Primeiro, configure a biografia do candidato, seguindo o passo a passo:
                      </span>

                      <ol type="1">
                        <li>
                          No menu lateral esquerdo, selecione Biografia do
                          Módulo "Página do Candidato"
                        </li>
                        <li>
                          Selecione um(a) candidato(a) e clique no botão superior esquerdo 'Nova História';
                        </li>
                        <li>
                          Adicione uma imagem e o texto para contar a história do candidato(a);
                        </li>
                        <li>
                          Continue configurando as áreas de Propostas, Informações e Redes Sociais
                          e Redes Sociais e Imagens da Página do Candidato.
                        </li>
                      </ol>

                      <div className="btn-wrapper">
                        <Link to="/admin/histories">
                          <button className="btn-action">Começar a configurar</button>
                        </Link>
                      </div>
                    </div>
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>

              <Panel eventKey="3">
                <Panel.Toggle>
                  <div className="accordion-header">
                    <CustomCheckbox
                      readOnly={true}
                      isChecked={redeCor}
                      color="#26ae37"
                    />

                    <div>
                      <Panel.Title>3. Configure a Aplicação</Panel.Title>

                      <span className="accordion-span">
                        Configure as cores, imagens e outras informações da
                        aplicação.
                      </span>
                    </div>
                  </div>
                </Panel.Toggle>
                <Panel.Collapse>
                  <Panel.Body className="accordion-body">
                    <div div className="img-wrapper">
                      <img className="cover-img" src="https://grt8-devs-assets.s3.sa-east-1.amazonaws.com/FOTO+03+-+Configurar+Aplicac%CC%A7a%CC%83o.png" />
                    </div>

                    <div className="body-card">
                      <p style={{ color: "#663dc9", fontWeight: "700", fontSize: "16px" }}>Como configurar a aplicação?</p>

                      <span>
                        Configure a aplicação conforme a identidade visual do
                        candidato(a). Para isso, siga o passo a passo:
                      </span>

                      <ol type="1">
                        <li>
                          No menu lateral esquerdo, selecione "Configurar
                          Aplicação";
                        </li>
                        <li>
                          Inicie selecionando o Candidato, o contexto da campanha
                          (municipal, estadual ou federal) e a cidade sede da
                          campanha;
                        </li>
                        <li>
                          Selecione as cores conforme a identidade visual da
                          campanha;
                        </li>
                        <li>
                          Escolha as imagens de acordo com a descrição de cada
                          campo;
                        </li>
                        <li>
                          Caso queira, configure quantas moldura para selfies;
                        </li>
                        <li>
                          Informe as redes sociais do candidato majoritário;
                        </li>
                        <li>
                          Clique no botão 'Salvar Alterações', no canto inferior
                          esquerdo, para confirmar as mudanças feitas.
                        </li>
                      </ol>

                      <span>
                        Caso queira visualizar as alterações, selecione o botão
                        'Acessar aplicação' no canto superior esquerdo da
                        página.
                      </span>

                      <div className="btn-wrapper">
                        <Link to="/admin/mobile">
                          <button className="btn-action">Configure aqui</button>
                        </Link>
                      </div>
                    </div>
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>

              <Panel eventKey="4">
                <Panel.Toggle>
                  <div className="accordion-header">
                    <CustomCheckbox
                      readOnly={true}
                      isChecked={true}
                      color="#26ae37"
                    />

                    <div>
                      <Panel.Title>
                        4. Saiba como configurar outras áreas
                      </Panel.Title>

                      <span className="accordion-span">
                        Acesse nossa base de conhecimento
                      </span>
                    </div>
                  </div>
                </Panel.Toggle>
                <Panel.Collapse>
                  <Panel.Body className="accordion-body">
                    <div className="img-wrapper">
                      <img className="cover-img" src="https://grt8-devs-assets.s3.sa-east-1.amazonaws.com/Captura+de+tela+2024-08-22+1032141.png" />
                    </div>

                    <div className="body-card">
                      <p style={{ color: "#663dc9", fontWeight: "700", fontSize: "16px"}}>Como configurar outras áreas?</p>

                      <span>
                        Acesse nossa Base de Conhecimento do Mobby Candidato e
                        aprenda a configurar as outras áreas do nosso sistema.
                      </span>

                      <div className="btn-wrapper">
                        <a href="https://basedeconhecimento.mobby.app.br/" target="_blank" rel="noopener noreferrer">
                          <button className="btn-action">Acessar Base de Conhecimento</button>
                        </a>
                      </div>
                    </div>
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
            </div>
            <div className="progress-bar">
              <CircularProgressBar segments={segments} />
            </div>
          </>
        }
      />
    </div>
  );
};
