import Button from 'components/CustomButton/CustomButton';
import React from 'react';
import { Modal } from 'react-bootstrap';

const Confirm = (props) => {
  return (
    <Modal
      show={props.show}
      backdrop='static'
    >
      <Modal.Header>
        <Modal.Title>Deseja {props.text}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button
          bsStyle='danger'
          type='button'
          onClick={props.close}
          fill
        >
          &nbsp;Cancelar&nbsp;
        </Button>
        <Button
          bsStyle='info'
          type='button'
          onClick={() => {
            props.callback();
            props.close();
          }}
          fill
          pullRight
        >
          &nbsp;Confimar&nbsp;
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default Confirm;;