import Button from "components/CustomButton/CustomButton.jsx";
import React, { useEffect, useState } from "react";
import { Alert, ControlLabel, FormGroup, Modal, Row } from "react-bootstrap";
import 'react-quill/dist/quill.snow.css';
import api from "services/api.jsx";

const UnlinkAdvisorCandidate = ({ onClose = () => { }, idAdvisor, advisor, idApp }) => {
    const [show, setShow] = useState(true);
    const [idCandidate, setIdCandidate] = useState();
    const [candidates, setCandidates] = useState([]);
    const [erro, setErro] = useState();
    const [success, setSuccess] = useState();
    const [isAlertVisible, setIsAlertVisible] = useState(false);

    const handleClose = () => {
        setShow(false)
         onClose()
        };


    useEffect(() => {
        try {
            api.get(`/advisors/${idAdvisor}`).then(
                result => {
                    setCandidates(result.data.data.candidates.map((el) => ({
                        id: el.candidate.id, name: el.candidate.name
                    })));
                }
            );
        } catch (error) {
            console.log(error);
            setErro("Erro ao achar candidatos");
            setIsAlertVisible(true)
        }

    }, [idApp, idAdvisor,isAlertVisible]);

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            await api.post(`/advisors/unlink_candidate`,{
                advisor_id: idAdvisor,
                candidate_id: parseInt(idCandidate)
            })    
            setSuccess("Candidato desassociado");
            setIsAlertVisible(true)
            handleClose()
        } catch (error) {
            console.log(error);
            setErro("Erro ao desassociar");
            setIsAlertVisible(true)
        }
    };

    return (
        <>
            <Modal
                size="sm"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Desassociar Candidato ao Assessor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submitHandler} encType="multipart/form-data">
                    {isAlertVisible && 
                            <Alert bsStyle={erro?"danger":"success"}>
                                <button type="button" aria-hidden="true" className="close">×</button>
                                {erro?
                                (<span><b> Atenção - </b> {erro}</span>):
                                (<span><b> Sucesso - </b> {success}</span>)
                                }
                            </Alert>
                    }
                    <Row>
                    <div className="col-md-6">
                        <FormGroup >
                            <ControlLabel htmlFor='associar'>Desassociar Candidato</ControlLabel>
                            <div style={{display:"flex", alignItems:"center"}}>
                            <select
                                aria-label="Selecionar Associado"
                                name="associar"
                                onChange={event => setIdCandidate(event.target.value)}
                                className="form-control"
                            >
                                <option >Selecionar</option>
                                {candidates.map((el)=>
                                    <option key={el.id} value={el.id}>{el.name}</option>
                                )}
                            </select>
                            </div>
                        </FormGroup>
                    </div>
                    </Row>
                        <div style={{marginTop:"1rem"}}>
                            <Button bsStyle="danger" fill onClick={() => onClose()}>
                                Cancelar
                            </Button>
                            <Button bsStyle="info" pullRight fill type="submit" >
                                Salvar Alterações
                            </Button>
                        </div>
                        <div className="clearfix" />
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default UnlinkAdvisorCandidate