import Card from "components/Card/Card.jsx";
import Confirm from "components/Confirm/Confirm.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import React from "react";
import { Col, Grid, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Pagination from "react-js-pagination";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";
import { style } from "variables/Variables.jsx";
import api from "../../services/api.jsx";
import UserFilter from "./users-filter.jsx";

const roles = new Map([
  ["admin", "Administrador"],
  ["voter", "Eleitor"],
  ["candidate", "Candidato"],
  ["coordinator", "Coordenador"],
  ["advisor", "Conselheiro"],
  ["leader", "Líder"],
]);

const Users = () => {
  const [confirm, setConfirm] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [page, setPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState();
  const [itemsPerPage, setItemsPerPage] = React.useState(15);
  const [totalItems, setTotalItems] = React.useState(0);
  const [filters, setFilters] = React.useState("");
  const notificationSystem = React.useRef(null);

  React.useEffect(() => {
    loadUsers(`/users?${filters}paginate=1&page=${page}`);
  }, [filters, page]);

  function resetPasswordCandidate(id) {
    trackPromise(api.put(`/users/${id}/reset_password`))
      .then((response) => {
        loadUsers();
        handleNotificationClick("Senha resetada com sucesso!", "success");
      })
      .catch((error) => {
        console.log(error.message);
        if (error.response.data.errors.user) {
          handleNotificationClick(error.response.data.errors.user[0], "error");
        }
      });
  };

  function loadUsers(url) {
    trackPromise(api.get(url))
      .then(response => {
        setUsers(response.data.data);
        setCurrentPage(response.data.meta.current_page);
        setItemsPerPage(response.data.meta.per_page);
        setTotalItems(response.data.meta.total);
      })
      .catch(e => {
        handleNotificationClick(e.response.data.message, "error");
      });
  }

  function handleNotificationClick(message, color) {
    notificationSystem.current.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: <div>{message}</div>,
      level: color,
      position: "tr",
      autoDismiss: 5,
      dismissible: true,
    });
  };

  const resetePassword = <Tooltip id="password_tooltip">Resetar Senha</Tooltip>;


  return (
    <>
      <div className="content">
        <NotificationSystem ref={notificationSystem} style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Usuários"
                category="Gerenciamento de usuários(administradores, organizadores, eleitores e candidatos)"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <>
                    <UserFilter
                      callback={setFilters}
                      reset={() => {
                        setPage(1);
                        setFilters("");
                      }}
                    />
                    <Table striped hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nome</th>
                          <th>Email</th>
                          <th>Tipo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user) => (
                          <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{roles.get(user.role)}</td>
                            <td>
                              <OverlayTrigger placement="top" overlay={resetePassword}>
                                <Button
                                  bsStyle="warning"
                                  simple
                                  type="button"
                                  bsSize="xs"
                                  onClick={() => {
                                    setConfirm(true);
                                    setUser(user);
                                  }}
                                >
                                  <i className="fa fa-unlock-alt"></i>
                                  Resetar senha
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                }
              />
            </Col>
          </Row>
        </Grid>
        <div className="text-center">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalItems}
            pageRangeDisplayed={5}
            onChange={(page) => setPage(page)}
          />
        </div>
      </div>
      <Confirm
        show={confirm}
        close={() => {
          setConfirm(false);
          setUser({});
        }}
        callback={() => resetPasswordCandidate(user.id)}
        text={`redefinir senha do usuário ${user.name}`}
      />
    </>
  );
};

export default Users;
