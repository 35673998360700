import React from "react";
import style from "../style.module.css";
import { datetimeFormatter } from "services/formatters";

const MessagesList = ({ participation }) => {
  const userId = participation.id_user;

  return (
    <div className={style.msgList}>
      {participation && participation.messages.map((msg) => (
        <div
          key={msg.id}
          className={msg.id_user === userId ? style.oddLine : ""}
        >
          <div
            className={`${style.msgContent} ${
              msg.id_user !== userId ? style.primaryMessage : ""
            }`}
          >
            <section>{msg.content}</section>

            <div className={style.infosMessage}>
              {msg.id_user !== userId && msg.user && (
                <>
                  <small>{msg.user.name}</small>
                  {" - "}
                </>
              )}
              <small>{datetimeFormatter(msg.created_at)}</small>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MessagesList;
