// icones material ui
import Select from "react-select";
import { Card } from "components/Card/Card.jsx";
import Confirm from "components/Confirm/Confirm.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { TutorialVideoModal } from "components/TutorialModal/TutorialModal.jsx";
import Loading from "components/Loading/Loading.jsx";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";
import { style } from "variables/Variables.jsx";
import api from "../../services/api.jsx";
import Selfies from "components/Selfies/Selfies.jsx";

class ProfileCandidate extends Component {
  selectRef = React.createRef();
  notificationSystem = React.createRef();

  state = {
    // _notificationSystem: null,
    app: localStorage.getItem("idApp"),
    user: null,
    appType: "",
    role: localStorage.getItem("typeRole"),
    candidate: [],
    candidates: [],
    profile_image: null,
    selfie_template: null,
    header_image: null,
    Instagram: "",
    Facebook: "",
    YouTube: "",
    Twitter: "",
    Tiktok: "",
    Kwai: "",
    userNameInstagram: "",
    userNameFacebook: "",
    userNameYouTube: "",
    userNameTwitter: "",
    userNameTiktok: "",
    userNameKwai: "",
    preview: "home",
    preview_selfie_template: null,
    preview_header_image: null,
    preview_profile_image: null,
    preview_primary_color: "",
    preview_secondary_color: "",
    preview_text_color: "",
    loading: false,
    primary_color: "",
    banner: "",
    banner_action: null,
    callback: undefined,
    text: {},
    confirm: false,
    isComunicationModalVisible: false
  };

  setSocialNetworks(socialNetworks) {
    if (socialNetworks.length) {
      socialNetworks.map((elem) => {
        if (elem.name === "Twitter")
          this.setState({
            Twitter: elem.link,
            userNameTwitter: elem.user_name ? elem.user_name : "",
          });
        if (elem.name === "Facebook")
          this.setState({
            Facebook: elem.link,
            userNameFacebook: elem.user_name ? elem.user_name : ""
          });
        if (elem.name === "Instagram")
          this.setState({
            Instagram: elem.link,
            userNameInstagram: elem.user_name ? elem.user_name : ""
          });
        if (elem.name === "YouTube")
          this.setState({
            YouTube: elem.link,
            userNameYouTube: elem.user_name ? elem.user_name : ""
          });
        if (elem.name === "Tiktok")
          this.setState({
            Tiktok: elem.link,
            userNameTiktok: elem.user_name ? elem.user_name : ""
          });
        if (elem.name === "Kwai")
          this.setState({
            Kwai: elem.link,
            userNameKwai: elem.user_name ? elem.user_name : ""
          });
      });
    } else {
      this.setState({
        Twitter: "",
        Facebook: "",
        Instagram: "",
        YouTube: "",
        Tiktok: "",
        Kwai: "",
        userNameTwitter: "",
        userNameFacebook: "",
        userNameInstagram: "",
        userNameYouTube: "",
        userNameTiktok: "",
        userNameKwai: "",
      });
    }

  }

  componentWillMount() {
    const idApp = localStorage.getItem("idApp");
    const coordinator_id = JSON.parse(
      localStorage.getItem("user")
    ).coordinator_id;

    let currentApp = null;
    let currentCandidate_id = null;

    if (coordinator_id?.length) {
      currentApp = coordinator_id.find(
        (item) => item.application.id === Number(idApp)
      );

      currentCandidate_id = currentApp.political_representative_id;
    }

    let candidate_id = currentCandidate_id || this.state.user;

    this.setState({
      user: candidate_id,
    });
  }

  async componentDidMount() {
    await this.getAppType();
    await this.loadApplication();
    this.loadCandidates();
  }

  async getAppType() {
    await api.get(`applications/${this.state.app}`).then((response) => {
      this.setState({
        appType: response.data.data.type_application,
      });
    });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeHandlerFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });

    if (e.target.files[0]) {
      this.setState({
        ["preview_" + e.target.name]: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  loadCandidates = () => {
    trackPromise(api.get(`/${this.state.app}/candidates?onlyActives=1`)).then((res) => {
      this.setState({
        candidates: res.data.data.map((item) => ({
          value: item.id,
          label: `${item.name} - ${item.number}`,
        })),
      });
      if (res.data.data.length == 1) this.loadCandidate(res.data.data[0].id);
    });
  };

  loadCandidate = async (candidate_id = null) => {
    try {
      if (!this.state.user && !candidate_id) {
        return
      }

      const response = await trackPromise(
        api.get(`/${this.state.app}/candidates/${this.state.user ? this.state.user : candidate_id}`)
      );

      let socialNetworks = response.data.data.social_networks_resumed;

      this.setSocialNetworks(socialNetworks);

      this.setState({ candidate: response.data.data });

      this.setState({
        primary_color: response.data.data.primary_color || "",
      });

      this.setState({
        preview_header_image: response.data.data.link_header_image || "",
      });

      this.setState({
        preview_profile_image: response.data.data.link_profile_image || "",
      });

      this.setState({
        preview_selfie_template: response.data.data.link_selfie_template || "",
      });

      this.setState({
        banner_action: response.data.data.banner_action || "",
      });
    } catch (err) {
      console.error(err);
    }
  };
  setColors(colors) {
    if (colors["primary-color"])
      this.setState({ preview_primary_color: colors["primary-color"] });
    if (colors["secondary-color"])
      this.setState({ preview_secondary_color: colors["secondary-color"] });
    if (colors["text-color"])
      this.setState({ preview_text_color: colors["text-color"] });
  }
  loadApplication = async () => {
    try {
      let response = await trackPromise(
        api.get("/applications/" + this.state.app)
      );
      // pegando as colos
      let colors = response.data.data.colors;
      // setando as cores no states de cada variavel
      this.setColors(colors);
      //setando os dados do usuario
    } catch (e) {
      console.log(e);
      this.handleNotificationClick(e.response.data.message, "error");
    }
  };
  submitHandler = async (e) => {
    e.preventDefault();

    const {
      Facebook,
      Instagram,
      Twitter,
      YouTube,
      Tiktok,
      Kwai,
      userNameFacebook,
      userNameInstagram,
      userNameTwitter,
      userNameYouTube,
      userNameTiktok,
      userNameKwai,
      primary_color,
      profile_image,
      header_image,
      selfie_template,
      banner,
      banner_action,
      candidate
    } = this.state;

    const formData = new FormData();
    formData.append("_method", "PUT");

    if (Facebook || Facebook === "") {
      formData.append("social_networks[0][name]", "Facebook");
      formData.append("social_networks[0][link]", Facebook);
    }
    if (userNameFacebook || userNameFacebook === "") {
      formData.append("social_networks[0][user_name]", userNameFacebook);
    }

    if (Twitter || Twitter === "") {
      formData.append("social_networks[1][name]", "Twitter");
      formData.append("social_networks[1][link]", Twitter);
    }
    if (userNameTwitter || userNameTwitter === "") {
      formData.append("social_networks[1][user_name]", userNameTwitter);
    }

    if (YouTube || YouTube === "") {
      formData.append("social_networks[2][name]", "YouTube");
      formData.append("social_networks[2][link]", YouTube);
    }
    if (userNameYouTube || userNameYouTube === "") {
      formData.append("social_networks[2][user_name]", userNameYouTube);
    }

    if (Instagram || Instagram === "") {
      formData.append("social_networks[3][name]", "Instagram");
      formData.append("social_networks[3][link]", Instagram);
    }
    if (userNameInstagram || userNameInstagram === "") {
      formData.append("social_networks[3][user_name]", userNameInstagram);
    }


    if (Tiktok || Tiktok === "") {
      formData.append("social_networks[4][name]", "Tiktok");
      formData.append("social_networks[4][link]", Tiktok);
    }
    if (userNameTiktok || userNameTiktok === "") {
      formData.append("social_networks[4][user_name]", userNameTiktok);
    }

    if (Kwai || Kwai === "") {
      formData.append("social_networks[5][name]", "Kwai");
      formData.append("social_networks[5][link]", Kwai);
    }
    if (userNameKwai || userNameKwai === "") {
      formData.append("social_networks[5][user_name]", userNameKwai);
    }

    if (primary_color)
      formData.append("primary_color", primary_color);

    if (profile_image) {
      formData.append("profile_image", profile_image, profile_image.name);
    } else if (candidate.link_profile_image && profile_image === "") {
      formData.append("profile_image", "");
    }

    if (header_image) {
      formData.append("header_image", header_image, header_image.name);
    } else if (candidate.link_header_image && header_image === "") {
      formData.append("header_image", "");
    }

    if (banner) {
      formData.append("banner", banner);
    } else if (candidate.banner && banner === "") {
      formData.append("banner", "");
    }

    formData.append("banner_action", banner_action ? banner_action : "");

    if (selfie_template)
      formData.append("selfie_template", selfie_template, selfie_template.name);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    this.setState({ loading: true });

    trackPromise(
      api.post(
        `/${this.state.app}/candidates/${this.state.user}`,
        formData,
        config
      )
    )
      .then(async (response) => {
        this.setState({
          loading: false,
          candidate: [],
          Twitter: "",
          YouTube: "",
          Facebook: "",
          Instagram: "",
          Tiktok: "",
          Kwai: "",
        });

        this.handleNotificationClick(response.data.message, "success");

        await this.loadCandidate();
      })
      .catch((error) => {
        console.error(error);

        this.setState({ loading: false });

        this.handleNotificationClick(
          "Error ao aplicar as alterações!",
          "error"
        );
      });
  };

  handleNotificationClick = (message, color) => {
    const notification = this.notificationSystem.current;

    notification.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: <div>{message}</div>,
      level: color,
      position: "tr",
      autoDismiss: 5,
      dismissible: true,
    });
  };

  selectCandidate = (selectedCandidate) => {
    this.setState(
      {
        user: selectedCandidate,
        selfie_template: null,
        Instagram: "",
        Facebook: "",
        YouTube: "",
        Twitter: "",
        Tiktok: "",
        Kwai: "",
        userNameInstagram: "",
        userNameFacebook: "",
        userNameYouTube: "",
        userNameTwitter: "",
        userNameTiktok: "",
        userNameKwai: "",
        banner: null,
        banner_action: "",
      },
      async () => {
        if (document.querySelector("#profile_image")) {
          document.querySelector("#profile_image").value = null;
        }

        if (document.querySelector("#selfie_template")) {
          document.querySelector("#selfie_template").value = null;
        }

        if (document.querySelector("#header_image")) {
          document.querySelector("#header_image").value = null;
        }

        if (document.querySelector("#banner")) {
          document.querySelector("#banner").value = null;
        }

        this.setState({ profile_image: null });
        this.setState({ selfie_template: null });
        this.setState({ header_image: null });
        this.setState({ banner: null });

        await this.loadCandidate(() => { });
      }
    );
  };

  openComunicationModal = () => {
    this.setState({ isComunicationModalVisible: true });
  };

  render() {
    const {
      candidate,
      candidates,
      Twitter,
      YouTube,
      Facebook,
      Instagram,
      Tiktok,
      Kwai,
      userNameTwitter,
      userNameYouTube,
      userNameFacebook,
      userNameInstagram,
      userNameTiktok,
      userNameKwai,
      primary_color,
      profile_image,
      header_image,
      banner,
      banner_action,
      loading,
      isComunicationModalVisible,
    } = this.state;

    return (
      <div className="content">
        <Loading loading={loading} message="Salvando alterações..." />
        <NotificationSystem ref={this.notificationSystem} style={style} />
        <Grid fluid>
          <Row>
            {(this.state.role === "coordinator" ||
              this.state.role === "advisor") &&
              this.state.appType === "many" && (
                <Card
                  title="Candidatos"
                  content={
                    <Row>
                      <Col md={6}>
                        <Select
                          ref={this.selectRef}
                          name="candidates"
                          options={candidates}
                          placeholder="Selecione um candidato"
                          noOptionsMessage={() => "Dados não encontrados"}
                          isSearchable
                          onChange={(selectedOption) => {
                            if (selectedOption) {
                              this.selectCandidate(selectedOption.value);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  }
                />
              )}
            {candidate.length !== 0 && (
              <Card
                title="Meu Perfil"
                extraTitleContent={
                  <i
                    className="fa fa-question-circle"
                    style={{ fontSize: "18px", cursor: "pointer" }}
                    title="Assista ao vídeo tutorial dessa área"
                    onClick={this.openComunicationModal}
                  />
                }

                content={
                  <form
                    onSubmit={this.submitHandler}
                    encType="multipart/form-data"
                  >
                    <fieldset>
                      <legend>Redes sociais</legend>
                      <FormInputs
                        ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                        properties={[
                          {
                            label: "Facebook",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "link",
                            value: Facebook,
                            minLength: 10,
                            name: "Facebook",
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Usuário Facebook",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Informe o usuário",
                            value: userNameFacebook,
                            name: "userNameFacebook",
                            minLength: 2,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Instagram",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "link",
                            minLength: 10,
                            value: Instagram,
                            name: "Instagram",
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Usuário Instagram",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Informe o usuário",
                            value: userNameInstagram,
                            name: "userNameInstagram",
                            minLength: 2,
                            onChange: this.changeHandler,
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                        properties={[
                          {
                            label: "YouTube",
                            type: "text",
                            minLength: 10,
                            bsClass: "form-control",
                            placeholder: "link",
                            name: "YouTube",
                            value: YouTube,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Usuário YouTube",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Informe o usuário",
                            value: userNameYouTube,
                            name: "userNameYouTube",
                            minLength: 2,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Twitter",
                            type: "text",
                            minLength: 10,
                            bsClass: "form-control",
                            placeholder: "link",
                            name: "Twitter",
                            value: Twitter,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Usuário Twitter",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Informe o usuário",
                            value: userNameTwitter,
                            name: "userNameTwitter",
                            minLength: 2,
                            onChange: this.changeHandler,
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                        properties={[
                          {
                            label: "Tiktok",
                            type: "text",
                            minLength: 10,
                            bsClass: "form-control",
                            placeholder: "link",
                            name: "Tiktok",
                            value: Tiktok,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Usuário Tiktok",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Informe o usuário",
                            value: userNameTiktok,
                            name: "userNameTiktok",
                            minLength: 2,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Kwai",
                            type: "text",
                            minLength: 10,
                            bsClass: "form-control",
                            placeholder: "link",
                            name: "Kwai",
                            value: Kwai,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Usuário Kwai",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Informe o usuário",
                            value: userNameKwai,
                            name: "userNameKwai",
                            minLength: 2,
                            onChange: this.changeHandler,
                          },
                        ]}
                      />
                    </fieldset>

                    <fieldset>
                      <legend>Configuração de imagem</legend>
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={9}>
                              <FormInputs
                                ncols={["col-md-12"]}
                                properties={[
                                  {
                                    label: "Imagem de Perfil",
                                    type: "file",
                                    accept: "image/jpeg, image/jpg, image/png",
                                    bsClass: "form-control",
                                    name: "profile_image",
                                    id: "profile_image",
                                    onChange: this.changeHandlerFile,
                                  },
                                ]}
                              />
                            </Col>

                            {candidate.link_profile_image && profile_image !== "" ? (
                              <Col md={3} className="justify-content-md-center">
                                <Button
                                  bsStyle="danger"
                                  bsSize="small"
                                  simple
                                  onClick={() => this.setState({
                                    callback: () => this.setState({ profile_image: "" }),
                                    confirm: true,
                                    text: "remover imagem de perfil",
                                  })}
                                >
                                  <span>REMOVER</span>
                                </Button>
                              </Col>
                            ) : null}
                          </Row>

                          {candidate.link_profile_image && profile_image !== "" && (
                            <label className="labelInfo">
                              Imagem atual:{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={candidate.link_profile_image}
                              >
                                Acesse aqui
                              </a>
                            </label>
                          )}
                          <label className="info-muted">
                            Envie imagem com as dimensões sugeridas: Largura:{" "}
                            <strong>1024 pixels</strong> e Altura:{" "}
                            <strong>1024 pixels</strong>
                          </label>
                        </Col>

                        <Col md={4}>
                          <Row>
                            <Col md={9}>
                              <FormInputs
                                ncols={["col-md-12"]}
                                properties={[
                                  {
                                    label: "Imagem da página principal",
                                    type: "file",
                                    accept: "image/jpeg, image/jpg, image/png",
                                    bsClass: "form-control",
                                    name: "header_image",
                                    id: "header_image",
                                    onChange: this.changeHandlerFile,
                                  },
                                ]}
                              />
                            </Col>

                            {candidate.link_header_image && header_image !== "" ? (
                              <Col md={3} className="justify-content-md-center">
                                <Button
                                  bsStyle="danger"
                                  bsSize="small"
                                  simple
                                  onClick={() => this.setState({
                                    callback: () => this.setState({ header_image: "" }),
                                    confirm: true,
                                    text: "remover imagem da página principal",
                                  })}
                                >
                                  <span>REMOVER</span>
                                </Button>
                              </Col>
                            ) : null}
                          </Row>

                          {candidate.link_header_image && header_image !== "" && (
                            <label className="labelInfo">
                              Imagem atual:{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={candidate.link_header_image}
                              >
                                Acesse aqui
                              </a>
                            </label>
                          )}

                          <label className="info-muted">
                            Envie imagem com as dimensões sugeridas: Largura:{" "}
                            <strong>1000 pixels</strong> e Altura:{" "}
                            <strong>300 pixels</strong>
                          </label>
                        </Col>

                        <Col md={4}>
                          <Row>
                            <Col md={9}>
                              <FormInputs
                                ncols={["col-md-12"]}
                                properties={[
                                  {
                                    label: "Banner",
                                    type: "file",
                                    accept: "image/jpeg, image/jpg, image/png",
                                    bsClass: "form-control",
                                    name: "banner",
                                    id: "banner",
                                    onChange: this.changeHandlerFile,
                                  },
                                ]}
                              />
                            </Col>

                            {candidate.banner && banner !== "" ? (
                              <Col md={3} className="justify-content-md-center">
                                <Button
                                  bsStyle="danger"
                                  bsSize="small"
                                  simple
                                  onClick={() => this.setState({
                                    callback: () => this.setState({ banner: "", banner_action: "" }),
                                    text: 'remover imagem do banner',
                                    confirm: true
                                  })}
                                >
                                  <span>REMOVER</span>
                                </Button>
                              </Col>
                            ) : null}
                          </Row>

                          {candidate.banner && banner !== '' && (
                            <label className="labelInfo">
                              Banner atual:{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={candidate.banner}
                              >
                                Acesse aqui
                              </a>
                            </label>
                          )}

                          <label className="info-muted">
                            Envie imagem com as dimensões sugeridas: Largura:{" "}
                            <strong>1000 pixels</strong> e Altura:{" "}
                            <strong>300 pixels</strong>
                          </label>

                          <FormInputs
                            ncols={["col-md-12"]}
                            properties={[
                              {
                                label: "URL de acesso do Banner",
                                type: "text",
                                bsClass: "form-control",
                                name: "banner_action",
                                id: "banner_action",
                                value: banner_action,
                                onChange: this.changeHandler,
                                minLength: 2,
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Selfies
                            app_id={this.state.app}
                            data_id={candidate.id}
                            type="candidate"
                          ></Selfies>
                        </Col>
                      </Row>
                    </fieldset>

                    <fieldset>
                      <legend>Cores</legend>
                      <FormInputs
                        ncols={["col-md-3"]}
                        properties={[
                          {
                            label: "Cor primária",
                            type: "color",
                            bsClass: "form-control",
                            name: "primary_color",
                            id: "primary_color",
                            value: primary_color,
                            onChange: this.changeHandler,
                          },
                        ]}
                      />
                    </fieldset>

                    <Row>
                      <Col md={12}>
                        <Button bsStyle="success" fill type="submit">
                          Atualizar Perfil
                        </Button>
                      </Col>
                    </Row>
                  </form>
                }
              />
            )}
          </Row>
        </Grid>
        <TutorialVideoModal
          isOpen={isComunicationModalVisible}
          onClose={() => this.setState({ isComunicationModalVisible: false })}
          title={"Informações e redes sociais"}
          linkTitle={"Informações e redes sociais"}
          link={"https://www.youtube.com/embed/xy_c5wy33C4?si=s7TL1-S_zHUIOsBM"}
        />
        <Confirm
          show={this.state.confirm}
          close={() => this.setState({
            confirm: false,
            callback: undefined,
            text: ''
          })}
          callback={this.state.callback}
          text={this.state.text}
        />
      </div>
    );
  }
}

export default ProfileCandidate;
