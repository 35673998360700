import React from "react";

import { useFormContext } from "react-hook-form";
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import { Calendar } from "@phosphor-icons/react";

export function DateField({ value, onChange, field }) {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[field.id]?.message
    ? String(errors[field.id]?.message)
    : undefined;


  return (
    <FormGroup>
      <ControlLabel>{field.name}</ControlLabel>
      <FormControl
        value={String(value)}
        onChange={(event) => onChange(event.target.value)}
        label={field.name}
        icon={<Calendar />}
        maxLength={10}
        inputMode="numeric"
        type="date"
        placeholder={field.name}
        hint={field.hint}
        error={error}
      />
    </FormGroup>
  );
}
