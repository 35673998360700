import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { Chart } from "react-google-charts";
import Select from "react-select";
import { trackPromise } from "react-promise-tracker";

import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton";
import Maps from "components/Leaflet/Leaflet.jsx";
import { style } from "variables/Variables.jsx";

import NotificationSystem from "react-notification-system";
import api from "services/api.jsx";

import iconStar from "assets/img/icon-star-yw.svg";

class VotingIntentions extends Component {
  ratings = [
    { value: 1, label: "1 estrela" },
    { value: 2, label: "2 estrelas" },
    { value: 3, label: "3 estrelas" },
    { value: 4, label: "4 estrelas" },
    { value: 5, label: "5 estrelas" },
  ];

  options = {
    title: "Masculino x Feminino",
    chartArea: { width: "50%"},
    isStacked: true,
    vAxis: {
      title: "Idade",
    }
  };

  stateRef = React.createRef();
  cityRef = React.createRef();
  neighborhoodRef = React.createRef();
  ratingRef = React.createRef();
  dateStartRef = React.createRef();
  dateEndRef = React.createRef();
  candidateRef = React.createRef();
  leaderRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      data: {},
      markes: [],
      user: JSON.parse(localStorage.getItem("user")).candidate_id,
      appType: localStorage.getItem("typeApplication"),
      totalVoters: 0,
      idApplication: localStorage.getItem("idApp"),
      dataBarChart: [],
      dataPieChart: [],
      dataStarsChart: 0,
      voting_intentions_by_neighborhood: [],
      candidate: "",
      role: JSON.parse(localStorage.getItem("user")).role,
      notaTotal: 0,
      eleitoresTotal: 0,
      candidateFilter: -1,
      federalUnits: [],
      citys: [],
      neighborhoods: [],
      leaders: [],
      candidates: [],
      filters: {},
      isReset: false,
      genders: [],
      maxGenders: 100,
    };
  }

  async componentDidMount() {
    if (this.state.role === "candidate") {
      if (this.state.appType === "many") {
        this.setState({
          candidate: JSON.parse(localStorage.getItem("user")).candidate_id
            ? JSON.parse(localStorage.getItem("user")).candidate_id
            : null,
        });
      }
    }

    await this.loadData();
  }

  async loadData() {
    const promises = [
      this.getFederalUnitys(),
      this.getCandidates(),
      this.getLeaders(),
      this.loadDataAndFormat(),
      this.voterPerGenderAge(),
    ];
    await Promise.all(promises);
  }

  async loadDataAndFormat() {
    const filtersFormtted = Object.values(this.state.filters)
      .filter((item) => item !== "")
      .join("&");

    const response = await trackPromise(
      api.get(
        `${this.state.idApplication}/voting_intentions_candidate_by_neighborhood/search?${filtersFormtted}`
      )
    );

    // Variaveis que serão passadas ao state
    let dataBarChart = [["Candidato", "Total Eleitores"]];
    let dataPieChart = [["Candidato", "Média"]];
    let dataStarsChart = [["Estrelas", "Quantidade"]];
    let dadosMapaFormatados = [];
    let totalEleitores = 0;
    let notaSomadaEleitores = 0;

    // Variaveis de controle intermediarias
    let intVotPorCand = {};
    let notasPorValor = {};
    let bairrosTotais = {};
    let dadosMapa = {};

    // Percorre o array recebido com um segundo loop pelo field "neighborhoods"
    // eslint-disable-next-line
    response.data.data.map((candidate_intentions) => {
      if (candidate_intentions.neighborhood.map) {
        // eslint-disable-next-line
        candidate_intentions.neighborhood.map((intention) => {
          intVotPorCand[candidate_intentions["name"]]
            ? (intVotPorCand[candidate_intentions["name"]] += intention.count)
            : (intVotPorCand[candidate_intentions["name"]] = intention.count);
          notasPorValor[intention["value"]]
            ? (notasPorValor[intention["value"]] += intention.count)
            : (notasPorValor[intention["value"]] = intention.count);
          bairrosTotais[intention["name"]] = {
            id: intention["id"],
            county: intention["county"],
            federal_unit: intention["federal_unit"],
          };

          //Formatação de markers pro mapa
          if (!dadosMapa[`${intention["name"]}-${intention["county"]}`])
            dadosMapa[`${intention["name"]}-${intention["county"]}`] = {
              neighborhood: {
                id: intention.id,
                name: intention.name,
                county: intention.county,
                federal_unit: intention.federal_unit,
                geometry: {
                  lat: intention.lat || 0,
                  long: intention.long || 0,
                },
              },
              voters: [
                {
                  candidate_name: candidate_intentions["name"],
                  votes_count: intention.count,
                },
              ],
              totalVoters: intention.count,
              id: intention.id,
            };
          else {
            let adicionar_candidato = true;
            dadosMapa[`${intention["name"]}-${intention["county"]}`]["voters"].forEach((candidato) => {
              if (candidato.candidate_name === candidate_intentions["name"]) {
                adicionar_candidato = false;
                candidato.votes_count += intention.count;
              }
            });
            if (adicionar_candidato) {
              dadosMapa[`${intention["name"]}-${intention["county"]}`]["voters"].push({
                candidate_name: candidate_intentions["name"],
                votes_count: intention.count,
              });
            }
            dadosMapa[`${intention["name"]}-${intention["county"]}`]["totalVoters"] += intention.count;
          }
        });
      }
    });

    // Graficos de intenção de voto
    Object.keys(intVotPorCand).forEach(function (key) {
      dataBarChart.push([key, intVotPorCand[key]]);
      dataPieChart.push([key, intVotPorCand[key]]);
    });

    // Graficos de Estrelas (Avaliação)
    Object.keys(notasPorValor).forEach(function (key) {
      if (Number(key) === 0) {
        dataStarsChart.push(["Não votou", notasPorValor[key]])
      } else {
        dataStarsChart.push([key, notasPorValor[key]]);
        notaSomadaEleitores += notasPorValor[key] * key;
        totalEleitores += notasPorValor[key];
      }
    });

    // Dados dos bairros (Avaliação) removendo chave...
    Object.keys(dadosMapa).forEach(function (key) {
      dadosMapaFormatados.push(dadosMapa[key]);
    });

    // Repassando dados ao state
    this.setState({
      dataBarChart: dataBarChart,
      dataPieChart: dataPieChart,
      dataStarsChart: dataStarsChart,
      markes: dadosMapaFormatados,
      notaTotal: notaSomadaEleitores,
      eleitoresTotal: totalEleitores,
    });
  }

  async getCandidates() {
    return trackPromise(
      api.get(`/${this.state.idApplication}/candidates/get_by_roles`)
    )
      .then((res) => {
        this.setState({
          candidates: res.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      })
      .catch((err) => console.error(err));
  }

  async getFederalUnitys() {
    return trackPromise(api.get(`/federal_units`))
      .then((res) => {
        this.setState({
          federalUnits: res.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      })
      .catch((err) => console.error(err));
  }

  getCitys(stateId) {
    if (stateId === "") {
      return this.setState({
        citys: [],
      });
    }

    trackPromise(api.get(`counties?id=${stateId}`))
      .then((res) => {
        this.setState({
          citys: res.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      })
      .catch((err) => console.error(err));
  }

  getNeighborhoods(cityId) {
    if (cityId === "") {
      return this.setState({
        neighborhoods: [],
      });
    }

    trackPromise(api.get(`counties/${cityId}/neighborhoods`))
      .then((res) => {
        this.setState({
          neighborhoods: res.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      })
      .catch((err) => console.error(err));
  }

  getLeaders() {
    trackPromise(
      api.get(
        `${this.state.idApplication}/leaders`
      )
    )
      .then((res) => {
        this.setState({
          leaders: res.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      })
      .catch((err) => console.error(err));
  }

  getLeadersByCandidate(candidate) {
    if (candidate === "") {
      return this.setState({
        leaders: [],
      });
    }

    trackPromise(
      api.get(
        `${this.state.idApplication}/leaders/get_leaders_by_candidate?candidate_id=${candidate}`
      )
    )
      .then((res) => {
        this.setState({
          leaders: res.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
      })
      .catch((err) => console.error(err));
  }

  async voterPerGenderAge() {
    return trackPromise(
      api.get(`/${this.state.idApplication}/voters_gender_age`)
    )
      .then((res) => {

        const genders = res.data.ranges.map((item, index) => {
          return [item, -res.data.males[index], res.data.females[index]];
        }).reverse();


        let maxValue = 0;

        genders.forEach(item => {
          maxValue = Math.max(maxValue, Math.max(Math.abs(item[1]), item[2]));
        })

        genders.unshift(["Idade", "Masculino", "Feminino"]);
        this.setState({
          genders: genders,
          maxGenders : maxValue
        });
      })
      .catch((err) => console.error(err));
  }

  handleChangeState(state) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          state: state ? `federal_unit_id=${state}` : "",
        },
      },
      async () => {
        if (state !== "" && !this.state.isReset) {
          await this.loadDataAndFormat();
        } else {
          this.cityRef.current.select.clearValue();
          this.setState({
            citys: [],
          });
        }
      }
    );

    if (state !== "") {
      this.getCitys(state);
    }
  }

  handleChangeCity(city) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          city: city ? `county_id=${city}` : "",
        },
      },
      async () => {
        if (city !== "" && !this.state.isReset) {
          await this.loadDataAndFormat();
        } else {
          this.neighborhoodRef.current.select.clearValue();
          this.setState({
            neighborhoods: [],
          });
        }
      }
    );

    if (city !== "") {
      this.getNeighborhoods(city);
    }
  }

  handleChangeNeighborhood(neighborhood) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          neighborhood: neighborhood ? `neighborhood=${neighborhood}` : "",
        },
      },
      async () => {
        if (!this.state.isReset) {
          await this.loadDataAndFormat();
        }
      }
    );
  }

  handleChangeRating(rating) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          rating: rating ? `rating=${rating}` : "",
        },
      },
      async () => {
        if (!this.state.isReset) {
          await this.loadDataAndFormat();
        }
      }
    );
  }

  handleChangeCandidate(candidate) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          candidate: candidate ? `candidate_id=${candidate}` : "",
        },
      },
      async () => {
        if (!this.state.isReset) {
          await this.loadDataAndFormat();
        } 
      }
    );

    if (candidate !== "") {
      this.getLeadersByCandidate(candidate);
    } else {
      this.getLeaders();
    }
  }

  handleChangeLeader(leader) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          leader: leader ? `leader_id=${leader}` : "",
        },
      },
      async () => {
        if (!this.state.isReset) {
          await this.loadDataAndFormat();
        }
      }
    );
  }

  handleChangeDateStart(dateStart) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          dateStart: dateStart ? `firstdate=${dateStart}` : "",
        },
      },
      async () => {
        if (!this.state.isReset) {
          await this.loadDataAndFormat();
        }
      }
    );
  }

  handleChangeDateEnd(dateEnd) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          dateEnd: dateEnd ? `lastdate=${dateEnd}` : "",
        },
      },
      async () => {
        if (!this.state.isReset) {
          await this.loadDataAndFormat();
        }
      }
    );
  }

  handleReset() {
    this.setState(
      {
        filters: {},
        isReset: true,
      },
      async () => {
        this.stateRef.current.select.clearValue();
        this.ratingRef.current.select.clearValue();
        this.dateStartRef.current.value = "";
        this.dateEndRef.current.value = "";
        this.candidateRef.current.select.clearValue();

        await this.loadDataAndFormat();

        this.setState({
          isReset: false,
        });
      }
    );
  }
formatTicks(chartWrapper) {
  const { maxGenders } = this.state;
  const interval = Math.ceil((maxGenders ? maxGenders : 100 * 1.1) < 10 ? (maxGenders ? maxGenders : 100 * 1.1)  : 10);
  const ticks = [];
  for (let i = 0; i <= interval; i += interval) {
    ticks.push({ v: i, f: Math.ceil(i).toString() });
    ticks.push({ v: -i, f: Math.ceil(i).toString() });
  }
  chartWrapper.setOption('hAxis.ticks', ticks);
  chartWrapper.draw();
}

  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: <div>{message}</div>,
      level: color,
      position: "tr",
      autoDismiss: 5,
      dismissible: true,
    });
  };

  render() {
    const {
      dataBarChart,
      dataPieChart,
      markes,
      dataStarsChart,
      candidate,
      role,
      notaTotal,
      eleitoresTotal,
      appType,
      federalUnits,
      citys,
      neighborhoods,
      candidates,
      leaders,
    } = this.state;

    return (
      <div className="content">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Button
                title="Resetar filtros"
                bsStyle="default"
                fixMargin
                pullRight
                style={{
                  marginRight: "1.5rem",
                  marginTop: "1.5rem"
                }}
                onClick={() => this.handleReset()}
              >
                Resetar filtros
              </Button>
              <Card
                id="filters"
                title="Filtros"
                content={
                  <>
                    <Row
                      style={{
                        marginTop: "16px",
                        marginBottom: "16px",
                      }}
                    >
                      <Col md={4}>
                        <label>Estado</label>
                        <Select
                          ref={this.stateRef}
                          name="state"
                          options={federalUnits}
                          placeholder="Digite a busca"
                          noOptionsMessage={() => "Dados não encontrados"}
                          isSearchable
                          isClearable
                          onChange={(option, triggeredAction) => {
                            if (triggeredAction.action === "clear")
                              return this.handleChangeState("");
                            if (option) this.handleChangeState(option.value);
                          }}
                        />
                      </Col>
                      <Col md={4}>
                        <label>Cidade</label>
                        <Select
                          ref={this.cityRef}
                          name="city"
                          options={citys}
                          placeholder="Digite a busca"
                          noOptionsMessage={() => "Dados não encontrados"}
                          isSearchable
                          isClearable
                          isDisabled={citys.length === 0}
                          onChange={(option, triggeredAction) => {
                            if (triggeredAction.action === "clear")
                              return this.handleChangeCity("");
                            if (option) this.handleChangeCity(option.value);
                          }}
                        />
                      </Col>
                      <Col md={4}>
                        <label>Bairro</label>
                        <Select
                          ref={this.neighborhoodRef}
                          name="neighborhood"
                          options={neighborhoods}
                          placeholder="Digite a busca"
                          noOptionsMessage={() => "Dados não encontrados"}
                          isSearchable
                          isClearable
                          isDisabled={neighborhoods.length === 0}
                          onChange={(option, triggeredAction) => {
                            if (triggeredAction.action === "clear")
                              return this.handleChangeNeighborhood("");
                            if (option)
                              this.handleChangeNeighborhood(option.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginBottom: "16px",
                      }}
                    >
                      <Col md={4}>
                        <label>Nota</label>
                        <Select
                          ref={this.ratingRef}
                          name="rating"
                          options={this.ratings}
                          placeholder="Selecione a nota"
                          noOptionsMessage={() => "Dados não encontrados"}
                          isSearchable
                          isClearable
                          onChange={(option, triggeredAction) => {
                            if (triggeredAction.action === "clear")
                              return this.handleChangeRating("");
                            if (option) this.handleChangeRating(option.value);
                          }}
                        />
                      </Col>

                      <Col md={4}>
                        <label>Data início</label>
                        <input
                          ref={this.dateStartRef}
                          type="date"
                          style={{
                            appearance: "none",
                            width: "100%",
                            color: "hsl(0,0%,20%)",
                            border: "1px solid hsl(0,0%,80%)",
                            borderRadius: "4px",
                            background: "hsl(0,0%,100%)",
                            padding: "7px 8px",
                            display: "inline-block !important",
                            visibility: "visible !important",
                          }}
                          onChange={(e) => {
                            if (!e.target.value)
                              return this.handleChangeDateStart("");
                            this.handleChangeDateStart(e.target.value);
                          }}
                        />
                      </Col>

                      <Col md={4}>
                        <label>Data fim</label>
                        <input
                          ref={this.dateEndRef}
                          type="date"
                          style={{
                            appearance: "none",
                            width: "100%",
                            color: "hsl(0,0%,20%)",
                            border: "1px solid hsl(0,0%,80%)",
                            borderRadius: "4px",
                            background: "hsl(0,0%,100%)",
                            padding: "7px 8px",
                            display: "inline-block !important",
                            visibility: "visible !important",
                          }}
                          onChange={(e) => {
                            if (!e.target.value)
                              return this.handleChangeDateEnd("");
                            this.handleChangeDateEnd(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>

                    <Row
                      style={{
                        marginBottom: "16px",
                      }}
                    >
                      {(role === "coordinator" || role === "advisor") &&
                        this.state.appType === "many" && (
                          <Col md={6}>
                            <label>Selecione o candidato</label>
                            <Select
                              ref={this.candidateRef}
                              name="candidate"
                              options={candidates}
                              placeholder="Busque o candidato"
                              noOptionsMessage={() => "Dados não encontrados"}
                              isSearchable
                              isClearable
                              onChange={(option, triggeredAction) => {
                                if (triggeredAction.action === "clear")
                                  return this.handleChangeCandidate("");
                                if (option)
                                  this.handleChangeCandidate(option.value);
                              }}
                            />
                          </Col>
                        )}

                      {(role === "coordinator" ||
                        role === "advisor" ||
                        role === "candidate") && (
                        <Col md={6}>
                          <label>Selecione o líder</label>
                          <Select
                            ref={this.leaderRef}
                            name="leader"
                            options={leaders}
                            placeholder="Busque o líder"
                            noOptionsMessage={() => "Dados não encontrados"}
                            isSearchable
                            isClearable
                            onChange={(option, triggeredAction) => {
                              if (triggeredAction.action === "clear")
                                return this.handleChangeLeader("");
                              if (option) this.handleChangeLeader(option.value);
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                }
              />
            </Col>
          </Row>
          {candidate === "-" ? null : (
            <>
              <Row>
                <Col xl={2} md={6}>
                  <Card
                    id="chartHours"
                    title="Quantidade de eleitores:"
                    content={
                      <>
                        <strong style={{ fontSize: 190 + `%` }}>
                          {eleitoresTotal ? eleitoresTotal : `Não computado`}
                        </strong>
                        <br></br>{" "}
                        <small>Total de eleitores da aplicação</small>
                      </>
                    }
                  ></Card>
                </Col>
                <Col xl={2} md={6}>
                  <Card
                    id="chartHours"
                    title="Média de votos:"
                    content={
                      <>
                        <strong
                          style={{
                            fontSize: 190 + `%`,
                            verticalAlign: `middle`,
                          }}
                        >
                          {notaTotal
                            ? (notaTotal / eleitoresTotal).toFixed(2)
                            : `Não computado`}
                          &nbsp;
                          <img src={iconStar} style={{ width: 25 }} alt="" />
                        </strong>
                        <br></br>{" "}
                        <small>
                          Eleitores que não atribuíram nota não são computados
                        </small>
                      </>
                    }
                  ></Card>
                </Col>
              </Row>
              <Row>
                {!this.state.candidate && this.state.appType === "many" ? (
                  <Col xl={4} md={6}>
                    <Card
                      id="chartHours"
                      title="Avaliação do candidato majoritário"
                      content={
                        <>
                          <div>
                            {dataStarsChart.length > 1 ? (
                              <Chart
                                chartType="ColumnChart"
                                loader={<div>Carregando dados..</div>}
                                data={dataStarsChart}
                                options={{
                                  // title: 'Intenções de votos dos eleitores',
                                  chartArea: { width: "50%" },
                                  isStacked: false,
                                  hAxis: {
                                    title: "Estrelas",
                                    minValue: 0,
                                  },
                                  vAxis: {
                                    title: "Intenções de voto",
                                  },
                                }}
                                // For tests
                                rootProps={{ "data-testid": "1" }}
                              />
                            ) : (
                              <p>Sem dados disponíveis!</p>
                            )}
                          </div>
                        </>
                      }
                    />
                  </Col>
                ) : (
                  <Col xl={4} md={12}>
                    <Card
                      id="chartHours"
                      title="Avaliação do candidato majoritário"
                      content={
                        <>
                          <div>
                            {dataStarsChart.length > 1 ? (
                              <Chart
                                chartType="ColumnChart"
                                loader={<div>Carregando dados..</div>}
                                data={dataStarsChart}
                                options={{
                                  // title: 'Intenções de votos dos eleitores',
                                  chartArea: { width: "50%" },
                                  isStacked: false,
                                  hAxis: {
                                    title: "Estrelas",
                                    minValue: 0,
                                  },
                                  vAxis: {
                                    title: "Intenções de voto",
                                  },
                                }}
                                // For tests
                                rootProps={{ "data-testid": "1" }}
                              />
                            ) : (
                              <p>Sem dados disponíveis!</p>
                            )}
                          </div>
                        </>
                      }
                    />
                  </Col>
                )}
                {this.state.appType !== "one" && !this.state.candidate && (
                  <Col xl={4} md={6}>
                    <Card
                      id="chartHours"
                      title="Proporção do eleitorado"
                      content={
                        <>
                          {dataPieChart.length > 1 ? (
                            <Chart
                              // width={'500px'}
                              // height={'300px'}
                              chartType="PieChart"
                              loader={<div>Carregando dados..</div>}
                              data={dataPieChart}
                              options={{
                                // title: 'Média das intenções de votos',
                                // Just add this option
                                is3D: false,
                              }}
                              rootProps={{ "data-testid": "2" }}
                            />
                          ) : (
                            <p>Sem dados disponíveis!</p>
                          )}
                        </>
                      }
                    />
                  </Col>
                )}

                {this.state.appType !== "one" && !this.state.candidate && (
                  <Col xl={4} md={12}>
                    <Card
                      id="chartHours"
                      title="Intenções de votos dos eleitores"
                      category=""
                      content={
                        <>
                          <div>
                            {dataBarChart.length > 1 ? (
                              <Chart
                                // width={'500px'}
                                height={"300px"}
                                chartType="BarChart"
                                loader={<div>Carregando dados..</div>}
                                data={dataBarChart}
                                options={{
                                  // title: 'Intenções de votos dos eleitores',
                                  chartArea: { width: "60%" },
                                  isStacked: false,
                                  hAxis: {
                                    title: "Total das intenções de votos",
                                    minValue: 0,
                                  },
                                  vAxis: {
                                    title: "Candidatos",
                                  },
                                }}
                                // For tests
                                rootProps={{ "data-testid": "1" }}
                              />
                            ) : (
                              <p>Sem dados disponíveis!</p>
                            )}
                          </div>
                        </>
                      }
                    />
                  </Col>
                )}
                {this.state.genders && this.state.maxGenders ? (
                  <Col xl={4} md={12}>
                    <Card
                      id="charGenders"
                      title="Gênero e idade de eleitores"
                      content={
                        <>
                          <Chart
                            chartType="BarChart"
                            width="100%"
                            height="600px"
                            data={this.state.genders}
                            options={this.options}
                            chartEvents={[
                              {
                                eventName: "ready",
                                callback: ({ chartWrapper }) => this.formatTicks(chartWrapper),
                              },
                            ]}
                          />
                        </>
                      }
                    />
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col ms={12}>
                  <Card
                    statsIcon="fa fa-info"
                    id="chartHours"
                    title="Eleitores por Bairro"
                    category=""
                    stats="Os bairros que não aparecem no mapa é devido a não possuírem eleitores registrados."
                    content={
                      <>
                        {markes.length ? (
                          <Maps markes={markes} application_type={appType} />
                        ) : (
                          <p>Sem dados disponíveis!</p>
                        )}
                      </>
                    }
                  />
                </Col>
              </Row>
            </>
          )}
        </Grid>
      </div>
    );
  }
}

export default VotingIntentions;
