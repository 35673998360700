import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import React, { useEffect, useState } from "react";
import {
  Col, ControlLabel, FormGroup, Modal,
  Row
} from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import api from "services/api.jsx";


//import AlertCustom from "components/CustomAlert/Alert.jsx";

const Update = ({ onClose = () => { }, history, idCandidato }) => {
  const [show, setShow] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [content, setContent] = useState(history.content);
  const [image_header, setImageHeader] = useState(null);

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  //const handleShow = () => setShow(true);

  useEffect(() => { });

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('content', content);
    if (image_header) {
      formData.append('image_header', image_header, image_header.name);
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    trackPromise(api.post(
      `/${localStorage.getItem('idApp')}/candidates/${idCandidato}/histories`,
      formData,
      config
    ))
      .then(response => {
        onClose();
      })
      .catch((error) => {
        console.log(error.message);
        setIsAlertVisible(true);
      });

  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edição de História</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            {isAlertVisible && <div className="alert alert-danger">Erro na atualização da história!</div>}

            <FormInputs
              ncols={["col-md-12"]}
              properties={[
                {
                  label: "Imagem",
                  type: "file",
                  bsClass: "form-control",
                  defaultValue: image_header,
                  name: 'image_header',
                  onChange: event => setImageHeader(event.target.files[0])
                }
              ]}
            />
            <Row>
              <Col md={12}>
                <FormGroup controlId="formControlsTextarea">
                  <ControlLabel>Conteúdo</ControlLabel>
                  <CKEditor
                    editor={ClassicEditor}
                    data={content}
                    onInit={editor => { }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setContent(data);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button bsStyle="danger" fill onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button bsStyle="info" pullRight fill type="submit">
              Salvar Alterações
            </Button>
            <div className="clearfix" />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Update;
