import React from "react";
import { useFormContext } from "react-hook-form";
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";

export function CpfField({ value, onChange, field }) {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[field.id]?.message
    ? String(errors[field.id]?.message)
    : undefined;

  const formatCpf = (cpf) => {
    return cpf
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  }

  return (
    <FormGroup>
      <ControlLabel>{field.name}</ControlLabel>
      <FormControl
        value={String(value)}
        onChange={(event) => onChange(formatCpf(event.target.value))}
        maxLength={14}
        inputMode="numeric"
        type="text"
        placeholder={field.name}
        hint={field.hint}
        isInvalid={!!error}
      />
      {error && <div className="error">{error}</div>}
    </FormGroup>
  );
}
