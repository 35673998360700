import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSetAtom } from "jotai";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";

import Loading from "components/Loading/Loading.jsx";
import { style } from "variables/Variables.jsx";
import api from "services/api.jsx";
import { OnboardingOpenAtom } from "components/Onboarding/atoms/OnboardingOpenAtom";
import iconMobbyCandidato from "assets/img/logooficial.svg";

import "./styles.css";

const LoginTemplate = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validateEmail] = useState(false);
  const [applications] = useState([]);
  const [applicationId, setApplicationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [coordinatorId, setCoordinatorId] = useState([]);
  const [onboarding, setOnboarding] = useState(false);

  const setOnboardingOpen = useSetAtom(OnboardingOpenAtom);

  const notificationSystem = useRef(null);
  const navigate = useHistory();

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "application_id") {
      const app = coordinatorId.find(
        (elm) => elm.application.id === Number(value)
      );

      if (app) {
        localStorage.setItem("nameApplication", app.application.name);

        localStorage.setItem("typeApplication", app.application.type);

        setOnboarding(!!app.application.onboarding);

        if (!app.application.type) {
          trackPromise(api.get(`/applications/${app.application.id}`))
            .then((res) =>
              localStorage.setItem(
                "typeApplication",
                res.data.data.type_application
              )
            )
            .catch((err) => console.error(err));
        } else {
          localStorage.setItem("typeApplication", app.application.type);
        }
      }
      setApplicationId(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const getRoute = (role) => {
    switch (role) {
      case "admin":
        return "/admin/dashboard";
      case "candidate":
        return "/admin/profile/candidate";
      default:
        return "/admin/dashboard";
    }
  };

  const submitHandlerApplication = async (e) => {
    e.preventDefault();
    setLoading(true);
    localStorage.setItem("idApp", applicationId);

    const { rede_cor, coordenador, existe_candidato, rede_cor_candidatos } =
      await loadStartGuide(applicationId);

    if (coordenador && existe_candidato && rede_cor && rede_cor_candidatos) {
      navigate.push("/admin/mobile");
    } else {
      navigate.push("/admin/start_guide");
    }

    if (onboarding) {
      localStorage.setItem("onboarding", onboarding)
    }

    setOnboardingOpen(onboarding);
  };

  const loadStartGuide = async (appId) => {
    try {
      const response = await trackPromise(api.get(`${appId}/start_guide`));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);

    trackPromise(api.post("/auth/login", { email, password }))
      .then(async (response) => {
        const { access_token, user } = response.data.data;
        const { application_id } = user;
        if (
          user.candidates &&
          user.candidates.length === 0 &&
          user.role === "advisor"
        ) {
          throw Object.assign(
            new Error("Você não possui nenhum candidato atrelado!"),
            { code: 1 }
          );
        }

        if (application_id) localStorage.setItem("idApp", application_id);
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("logado", true);
        localStorage.setItem("typeRole", user.role);
        localStorage.setItem("user", JSON.stringify(user));
        setCoordinatorId(user.coordinator_id || adjustArray(user.candidates));
        setLoading(false);

        if (user.role === "candidate") {
          await trackPromise(api.get(`/applications/${application_id}`))
            .then((res) => {
              localStorage.setItem(
                "typeApplication",
                res.data.data.type_application
              );
            })
            .catch((err) => console.error(err));
        }

        if (!user.change_password) {
          handleNotificationClick("Login validado com sucesso!", "success");
          if (user.role === "coordinator" || user.role === "advisor") {
            setIsLogin(false);
          } else {
            navigate.push(getRoute(user.role));
          }
        } else {
          handleNotificationClick("Você tem que alterar sua senha", "error");
          navigate.push("/password-reset");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === 1) {
          handleNotificationClick(error.message, "error");
        } else {
          handleNotificationClick("Email ou senha incorretos!", "error");
        }
      });
  };

  const adjustArray = (candidates) => {
    if (!candidates) return undefined;
    const formattedCandidates = candidates.map((el, index) => ({
      id: index,
      application: { id: el.application.id, name: el.application.name },
    }));
    return [
      ...new Map(
        formattedCandidates.map((el) => [el.application.id, el])
      ).values(),
    ];
  };

  const handleNotificationClick = (message, color) => {
    notificationSystem.current.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: <div>{message}</div>,
      level: color,
      position: "tr",
      autoDismiss: 5,
      dismissible: true,
    });
  };

  return (
    <div className="container-login">
      <Loading loading={loading} message="Validando dados..." />
      <NotificationSystem ref={notificationSystem} style={style} />
      <div className="content-login first-content-login">
        <div className="first-column-login">
          <h2 className="title title-primary-login ">
            Olá, seja bem vindo ao Mobby Candidato!
          </h2>
          <p className="description description-primary-login ">
            Plataforma desenvolvida pela Mobby.
          </p>
          <p className="description description-primary-login ">
            &copy; 2024 - Todos os Direitos Reservados
            <br />
          </p>
        </div>
        {isLogin ? (
          <div className="second-column-login">
            <img src={iconMobbyCandidato} alt="mobby candidato" width="250" />
            <h2 className="title title-second-login "></h2>
            <p className="description description-second-login ">
              Realize o login informando seus dados de acesso
            </p>
            <form className="form-login" method="POST" onSubmit={submitHandler}>
              <label className="label-input-login">
                <i className="fa fa-envelope icon-modify-login"></i>
                <input
                  type="email"
                  className="login"
                  placeholder="email@grt8.com"
                  required
                  autoFocus
                  name="email"
                  onChange={changeHandler}
                />
              </label>
              {applications.length > 1 && (
                <label className="label-input-login">
                  <i className="fa fa-user icon-modify-login"></i>
                  <select
                    name="application_id"
                    value={application_id || ""}
                    onChange={changeHandler}
                    required
                  >
                    <option value="">Selecione sua aplicação</option>
                    {applications.map((app) => (
                      <option key={app.id} value={app.id}>
                        {app.name}
                      </option>
                    ))}
                  </select>
                </label>
              )}
              <label className="label-input-login">
                <i className="fa fa-lock icon-modify-login"></i>
                <input
                  type="password"
                  placeholder="*********"
                  name="password"
                  className="login"
                  onChange={changeHandler}
                  disabled={validateEmail}
                  minLength="8"
                  required
                />
              </label>
              <button className="btn-login btn-second-login" type="submit">
                Entrar
              </button>
            </form>
          </div>
        ) : (
          <div className="second-column-login">
            <img src={iconMobbyCandidato} alt="mobby candidato" width="250" />
            <h2 className="title title-second-login "></h2>
            <p className="description description-second-login ">
              Selecione sua aplicação
            </p>
            <form
              className="form-login"
              method="POST"
              onSubmit={submitHandlerApplication}
            >
              <label className="label-input-login">
                <i className="fa fa-user icon-modify-login"></i>
                <select name="application_id" onChange={changeHandler} required>
                  <option value="">Selecione sua aplicação</option>
                  {coordinatorId.map((app) => (
                    <option key={app.id} value={app.application.id}>
                      {app.application.name}
                    </option>
                  ))}
                </select>
              </label>
              <button className="btn-login btn-second-login" type="submit">
                Avançar
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginTemplate;
