
import React, { Component } from "react";
import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";

import api from "services/api.jsx";

class AdminNavbarLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      nameApp: localStorage.getItem('nameApplication')
    };
  }

  setApplication(id) {
    if (id === "logout") {
      this.deslogar();
    } else if(id==="user"){
      return;
    }else {
      if (this.state.user.coordinator_id.length > 1) {
        let app = this.state.user.coordinator_id.filter(elm => { return elm.application.id === Number(id) })[0];
        localStorage.setItem("nameApplication", app.application.name);
        if(!app.application.type){
          trackPromise(api.get(`/applications/${app.application.id}`))
            .then((res) => localStorage.setItem("typeApplication", res.data.data.type_application))
            .catch(err => console.err(err));
        } else {
          localStorage.setItem("typeApplication", app.application.type);
        }
        localStorage.setItem('idApp', id);
        window.location.reload();
      }
    }

  }

  deslogar = () => {
    api.get(`/auth/logout`)
      .then(response => {
        localStorage.clear();
        window.location.href = '/login';
      })
      .catch((error) => {
        localStorage.clear();
        window.location.href = '/login';
        console.log(error.message)
      });
  }

  render() {
    const { user, nameApp } = this.state;
    return (
      <div>
        <Nav>
          <NavItem href="#">
            {/* <i className="fa fa-dashboard" /> */}
            <p className="hidden-lg hidden-md">Dashboard</p>
          </NavItem>
        </Nav>

        <Nav
          onSelect={(selectedKey) => this.setApplication(selectedKey)}
          pullRight>
          {(user.role === "coordinator") &&
            <NavDropdown eventKey={2} title={nameApp} id="basic-nav-dropdown-right">
              {user.coordinator_id.length > 1 &&
                user.coordinator_id.map(app => (
                  <MenuItem key={app.id} eventKey={app.application.id}>{app.application.name}</MenuItem>
                ))
              }

            </NavDropdown>
          }

          <NavItem eventKey={'user'}>
            <i className="fa fa-user" />
            {user.name}
          </NavItem>
          <NavItem eventKey={'logout'}>
            <i className="fa fa-sign-out" /> Sair
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;
