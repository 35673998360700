import React from "react";
import { Modal, Col, Row } from "react-bootstrap";
import { Map, Marker, TileLayer, Circle } from "react-leaflet";

export default function MapModal({ setModalClose, showModal, latitude, longitude, radius }) {
  const handleClose = () => {
    setModalClose();
  };

  return (
    <Modal
      size="small"
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Localização da tarefa</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {latitude && longitude ? (
          <Row >
            <Col md={12}>
              <Map style={{ marginLeft: "15px", marginTop: "15px", marginBottom: "15px" }} center={[latitude, longitude]} zoom={17}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={[latitude, longitude]} />
                <Circle center={[latitude, longitude]} fillColor="blue" radius={radius} />
              </Map>
            </Col>
          </Row>
        ) : (
          <p>Sem localização definida</p>
        )}
      </Modal.Body>
    </Modal>
  );
}
