import DangerAlert from "components/DangerAlert/DangerAlert";
import React from "react";
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Radio,
  Row,
} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import { trackPromise } from "react-promise-tracker";
import api from "services/api";

const Update = ({ show, close, reload, area }) => {
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [error, setError] = React.useState("");
  const [candidates, setCandidates] = React.useState([]);
  const appId = localStorage.getItem('idApp');
  const appType = localStorage.getItem('typeApplication');

  React.useEffect(() => {
    trackPromise(api.get(`/${appId}/candidates/get_by_roles`))
      .then((res) => setCandidates(res.data.data))
      .catch(err => console.err(err));
  }, [appId]);

  function submitHandler(event) {
    event.preventDefault();

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    const data = new FormData();
    data.append('name', event.target.name.value);
    data.append('description', event.target.description.value);

    data.append('location_request', +(event.target.location.value));
    data.append('file_request', +(event.target.file.value));
    data.append('extra_field_name', event.target.extraField.value);
    data.append('enable', +(event.target.enable.value));

    if (event.target.candidate && appType === "many") {
      data.append('candidate_id', event.target.candidate.value);
    }

    if (!!event.target.icon.files.length) {
      data.append('icon_link', event.target.icon.files[0]);
    }

    trackPromise(api.post(`/${appId}/participation_area/${area.id}`, data, config))
      .then(() => {
        close();
        reload();
      })
      .catch(err => {
        setError(err.message);
        setIsAlertVisible(true);
      });

  };

  return (
    <Modal size="sm" show={show} onHide={close} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Edição de área de interesse</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          {isAlertVisible && <DangerAlert error={error} close={() => setIsAlertVisible(false)} />}
          <Row>
            <Col md={12}>
              <FormGroup>
                <ControlLabel>Nome</ControlLabel>
                <FormControl
                  type="text"
                  placeholder="Nome da área"
                  name="name"
                  defaultValue={area && area.name}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={7}>
              <FormGroup>
                <ControlLabel>Nome do campo do perfil</ControlLabel>
                <FormControl
                  type="text"
                  placeholder="Nome do campo perfil"
                  name="extraField"
                  defaultValue={area && area.extra_field_name}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={5} style={{ display: "flex", alignItems: "center" }}>
              {
                area.icon_link &&
                <img
                  src={area.icon_link}
                  alt="foto"
                  className="mini-img"
                />
              }
              <FormGroup>
                <ControlLabel>Ícone</ControlLabel>
                <FormControl
                  type="file"
                  accept="image/*"
                  placeholder="Ícone"
                  name="icon"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <ControlLabel>Requerir localização?</ControlLabel>
              <FormGroup >
                <Radio name="location" value={1} defaultChecked={area && area.location_request} required inline>
                  Sim
                </Radio>
                &nbsp;
                <Radio name="location" value={0} defaultChecked={area && !area.location_request} required inline>
                  Não
                </Radio>
              </FormGroup>
            </Col>
            <Col md={4}>
              <ControlLabel>Requerir arquivo?</ControlLabel>
              <FormGroup >
                <Radio name="file" value={1} defaultChecked={area && area.file_request} required inline>
                  Sim
                </Radio>
                &nbsp;
                <Radio name="file" value={0} defaultChecked={area && !area.file_request} required inline>
                  Não
                </Radio>
              </FormGroup>
            </Col>
            <Col md={4}>
              <ControlLabel>Habilitar?</ControlLabel>
              <FormGroup>
                <Radio name="enable" value={1} defaultChecked={area && area.enable} required inline>
                  Sim
                </Radio>
                &nbsp;
                <Radio name="enable" value={0} defaultChecked={area && !area.enable} required inline>
                  Não
                </Radio>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ControlLabel>Descrição</ControlLabel>
              <FormGroup>
                <FormControl
                  type="text"
                  placeholder="Descrição"
                  name="description"
                  rows={3}
                  defaultValue={area && area.description}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          {
            appType === "many" &&
              <Row>
                <Col md={12}>
                  <ControlLabel>Candidato</ControlLabel>
                  <FormGroup>
                    <FormControl
                      componentClass="select"
                      placeholder="Selecionar candidato"
                      name="candidate"
                      defaultValue={area.candidate_id ? area.candidate_id : 0}
                      required
                    >
                      <option value="0">Sem candidato definido</option>
                      {
                        candidates.map(candidate => 
                          <option key={candidate.id} value={candidate.id} selected={area.candidate_id === candidate.id}>{candidate.name}</option>
                        )
                      }
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
          }   
          <hr />
          <Button bsStyle="danger" fill onClick={close}>
            Cancelar
          </Button>
          <Button bsStyle="info" pullRight fill type="submit">
            Atualizar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Update;
