import React, { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import "./styles.css";
import { trackPromise } from "react-promise-tracker";
import api from "services/api.jsx";

const Selfies = ({app_id, data_id, type = "candidate" | "applications"}) => {

  const inputRef = useRef();
  const [selfies, setSelfies] = useState([]);
  const [idSelected, setIdSelected] = useState(null);
  const [show, setShow] = useState(false);
  const url = type === "candidate" ?
    `/${app_id}/${type}/${data_id}/selfie` :
    `/${type}/${app_id}/selfie`;
  
  useEffect(() => {
    getSelfies();
  }, [data_id]);

  async function getSelfies() {
    const response = await trackPromise(api.get(url));
    setSelfies(response.data);
  }

  function submitSelfie(image) {

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    const formData = new FormData();

    formData.append("image", image, image.name);

    trackPromise(api.post(url, formData, config))
      .then(async (response) => {
        await getSelfies();
        inputRef.current.value = "";
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function deleteSelfie() {
    setShow(false);
    trackPromise(api.delete(`${url}/${idSelected}`))
      .then(async (response) => {
        await getSelfies();
        setIdSelected(null);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleClose() {
    setShow(false);
    setIdSelected(null)
  };

  return (
    <>
      <div className="content-selfs">
        <div className="title-selfs">
          Molduras das selfies
        </div>
        <div className="list-selfs">
          {selfies.map(item =>
            <div
              key={item.id}
              className="card-image"
            >
              <img
                src={item.url}
                className="image-selfie"
              />
              <button
                className="button-card-image"
                type="button"
                onClick={() => {
                  setShow(true);
                  setIdSelected(item.id);
                }}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          )}
          <div className="input-selfie">
            <label className="label-file" htmlFor="file_id">
              <i className="fa fa-plus icon-more"></i>
            </label>
            <input
              type="file"
              accept="image/*"
              id="file_id"
              className="input-file"
              ref={inputRef}
              onChange={event => submitSelfie(event.target.files[0])}
            />
          </div>
        </div>
        <label>A moldura deve ser no formato .png com transparencia e dimensões de 1200x1200px.</label>
      </div>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Excluir selfie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
          <Col md={12}>
            Deseja excluir selfie?
          </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="danger"
            fill
            onClick={() => setShow(false)}
          >
            Cancelar
          </Button>
          <Button
            bsStyle="info"
            fill
            onClick={() => deleteSelfie()}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Selfies;;