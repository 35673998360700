import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Modal
} from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import api from "services/api.jsx";


const Update = ({ onClose = () => { }, county_id, neighborhood }) => {
  const [show, setShow] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [erro, setErro] = useState('');
  const [name, setName] = useState(neighborhood.name);
  const [latitude, setLatitude] = useState(neighborhood.latitude);
  const [longitude, setLongitude] = useState(neighborhood.longitude);
  // eslint-disable-next-line
  const [countyid, setCouintyid] = useState(county_id);

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  useEffect(() => { }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    let formData = {
      _method: "PUT",
      latitude,
      longitude
    };
    if (neighborhood.name !== name) {
      formData.name = name;
    }

    const config = {
      headers: {
        'content-type': 'application/json'
      }
    };

    trackPromise(api.post(
      `/counties/${countyid}/neighborhoods/${neighborhood.id}`,
      formData,
      config
    ))
      .then(response => {
        onClose();
      })
      .catch((error) => {
        console.log(error.response.data.errors);
        if (error.response.data.errors.name) {
          setErro(error.response.data.errors.name[0]);
        } else if (error.response.data.errors.latitude) {
          setErro(error.response.data.errors.latitude[0]);
        } else if (error.response.data.errors.logintude) {
          setErro(error.response.data.errors.logintude[0]);
        }
        setIsAlertVisible(true);
      });

  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edição de Bairro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            {isAlertVisible && <Alert bsStyle="danger">
              <button type="button" aria-hidden="true" className="close">×</button>
              <span><b> Atenção - </b> {erro}</span>
            </Alert>}
            <FormInputs
              ncols={["col-md-4", "col-md-4", "col-md-4"]}
              properties={[
                {
                  label: "Nome *",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Nome do Bairro",
                  required: true,
                  defaultValue: name,
                  disabled: countyid ? false : true,
                  onChange: event => setName(event.target.value)
                },
                {
                  label: "Latitude",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Latitude",
                  defaultValue: latitude,
                  disabled: countyid ? false : true,
                  onChange: event => setLatitude(event.target.value)
                },
                {
                  label: "Longitude",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Longitude",
                  defaultValue: longitude,
                  disabled: countyid ? false : true,
                  onChange: event => setLongitude(event.target.value)
                },
              ]}
            />

            <hr />

            <Button bsStyle="danger" fill onClick={() => onClose()}>Cancelar</Button>
            <Button bsStyle="info" pullRight fill type="submit">Salvar</Button>

            <div className="clearfix" />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Update;
