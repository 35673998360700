import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import React, { useEffect, useState } from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import api from "services/api.jsx";

const StoreFiltered = ({ onClose = () => {}, applications }) => {
  const [show, setShow] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [erro, setErro] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [change_password, setChangePassword] = useState("");
  const [password, setPassword] = useState("");

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  useEffect(() => {}, []);

  const submitHandler = (e) => {
    e.preventDefault();

    const data = {
      name,
      application_id: localStorage.getItem("idApp"),
      email,
      // password,
      // change_password,
      // password_confirmation: password,
    };
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    trackPromise(api.post(`/coordinators`, data, config))
      .then((response) => {
        onClose();
      })
      .catch((error) => {
        console.log(error.response.data.errors);
        if (error.response.data.errors.name) {
          setErro(error.response.data.errors.name[0]);
        } else if (error.response.data.errors.email) {
          setErro(error.response.data.errors.email[0]);
        }
        setIsAlertVisible(true);
      });
  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de Coordenador</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitHandler}>
            {isAlertVisible && (
              <Alert bsStyle="danger">
                <button type="button" aria-hidden="true" className="close">
                  ×
                </button>
                <span>
                  <b> Atenção - </b> {erro}
                </span>
              </Alert>
            )}
            <FormInputs
              ncols={["col-md-8", "col-md-4"]}
              properties={[
                {
                  label: "Nome",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Nome",
                  name: "name",
                  required: true,
                  defaultValue: name,
                  onChange: (event) => setName(event.target.value),
                },
                {
                  label: "E-mail",
                  type: "email",
                  bsClass: "form-control",
                  placeholder: "seu@email.com",
                  name: "email",
                  required: true,
                  defaultValue: email,
                  onChange: (event) => setEmail(event.target.value),
                },
              ]}
            />

            {/* <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="">
                    Forçar alteração de senha no primero acesso
                  </label>
                  <select
                    name="change_password"
                    onChange={(event) => setChangePassword(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">-</option>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="password">Senha</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    minLength="8"
                    maxLength="32"
                    value={password}
                    placeholder="******"
                    onChange={(event) => setPassword(event.target.value)}
                    required
                  />
                  <span className="muted">
                    Sua senha deverá conter entre 8 e 32 caracteres e não poderá
                    conter espaços.{" "}
                  </span>
                </div>
              </Col>
            </Row> */}
            <hr />

            <Button bsStyle="danger" fill onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button bsStyle="info" pullRight fill type="submit">
              Cadastrar
            </Button>

            <div className="clearfix" />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StoreFiltered;
