import React, { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import { ArrowRight } from "@phosphor-icons/react";

import AdminNavbarLinks from "./AdminNavbarLinks.jsx";
import api from "services/api.jsx";

import "./styles.css";

export default function Header() {
  const [sidebarExists, setSidebarExists] = useState(false);
  const [url, setUrl] = useState("");


  useEffect(() => {
    const idApp = localStorage.getItem("idApp");

    if (!idApp) {
      return
    }

    api
      .get(`/applications/${idApp}`)
      .then((response) =>
        setUrl(
          response.data.data.custom_domain
            ? `https://${response.data.data.custom_domain}`
            : `https://${response.data.data.slug}_${response.data.data.checker_key}.app.mobbycandidato.com`
        )
      );
  }, []);

  function mobileSidebarToggle(e) {
    e.preventDefault();

    if (sidebarExists === false) {
      setSidebarExists(true);
    }

    document.documentElement.classList.toggle("nav-open");
    const node = document.createElement("div");
    node.id = "bodyClick";

    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };

    document.body.appendChild(node);
  }

  return (
    <Navbar fluid>
      <Navbar.Header>
        <Navbar.Brand>
          <a
            href={url}
            className="link-for-app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Acessar aplicação</span>
            <ArrowRight size={18} />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle onClick={mobileSidebarToggle} />
      </Navbar.Header>

      <Navbar.Collapse>
        <AdminNavbarLinks />
      </Navbar.Collapse>
    </Navbar>
  );
}
