import React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  Col,
  Row,
  Modal,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Checkbox,
} from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";

import Button from "components/CustomButton/CustomButton.jsx";
import { handleSendNotification } from "services/notification";
import api from "services/api";

export function CreateFieldModal({ isOpen, onClose }) {
  const appId = localStorage.getItem("idApp");

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      hint: "",
      options: "",
      type: "text",
      required: true,
    },
  });

  function submitHandler(data) {
    const { name, hint, type, options, required } = data;

    const body = {
      name,
      required,
      type,
      ...(hint ? { hint } : {}),
      ...(options ? { options } : {}),
    };

    trackPromise(api.post(`/${appId}/voters/fields`, body))
      .then(() => {
        handleSendNotification({
          type: "success",
          message: "Campo criado com sucesso!",
        });

        handleOnClose(true);
      })
      .catch((err) => {
        console.error(err);

        handleSendNotification({
          type: "error",
          message: "Erro ao criar campo, tente novamente!",
        });
      });
  }

  const type = useWatch({
    control,
    name: "type",
  });

  function handleOnClose(success) {
    reset({
      name: "",
      hint: "",
      options: "",
      type: "text",
      required: true,
    });

    onClose(success);
  }

  return (
    <Modal
      show={isOpen}
      onHide={() => handleOnClose()}
      size="sm"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Criar campos do perfil</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit(submitHandler)}>
          <Row>
            <div style={{ marginLeft: "15px", marginBottom: "10px" }}>
              <p>Os campos de <strong>Nome</strong>, <strong>Email</strong>, <strong>Gênero</strong>, <strong>Telefone</strong>, <strong>Bairro</strong> e <strong>Data de nascimento</strong> já existem!</p>
            </div>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>Nome do campo</ControlLabel>
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: "Informe o nome do campo" }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      type="text"
                      placeholder="Nome do campo"
                      name="name"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <ControlLabel>Descrição do campo</ControlLabel>
                <Controller
                  control={control}
                  name="hint"
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      type="text"
                      placeholder="Descrição do campo"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <ControlLabel>Tipo do campo</ControlLabel>
                <Controller
                  control={control}
                  name="type"
                  rules={{ required: "Informe o tipo do campo" }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      componentClass="select"
                      value={value}
                      onChange={onChange}
                    >
                      <option value="text">Texto</option>
                      <option value="text_long">Texto longo</option>
                      <option value="cpf">CPF</option>
                      <option value="date">Data</option>
                      <option value="number">Número</option>
                      <option value="select">Opções</option>
                    </FormControl>
                  )}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <ControlLabel>Informe as opções</ControlLabel>

                <Controller
                  control={control}
                  name="options"
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      type="text"
                      placeholder="Escreva as opções separadas por vírgula"
                      disabled={type !== "select"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <Controller
                  control={control}
                  name="required"
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      name="required"
                      inline
                      checked={value}
                      onChange={(event) => onChange(event.target.checked)}
                    >
                      O campo é obrigatório
                    </Checkbox>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>

          <hr />

          <Button
            bsStyle="danger"
            type="button"
            fill
            onClick={() => handleOnClose()}
          >
            Cancelar
          </Button>
          <Button bsStyle="info" pullRight fill type="submit">
            Cadastrar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
