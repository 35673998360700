import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import NotificationSystem from "react-notification-system";

import { HeaderInfos } from "./components/Header/index";
import { FiltersInfos } from "./components/Filters/index";
import { ListInfos } from "./components/ListInfos/index";
import { EmptyState } from "./components/EmptyState/index";

import { CreateGroup } from "./forms/CreateGroup";
import { UpdateGroup } from "./forms/UpdateGroup";
import { CreateInfo } from "./forms/CreateInfo";
import { UpdateInfo } from "./forms/UpdateInfo";
import { AssociateTags } from "./forms/AssociateTags";

import { useInfos } from "./hooks/useInfos";
import { useTags } from "./hooks/useTags";
import { useQueryParams } from "hooks/useQueryParams";

import { style } from "variables/Variables.jsx";
import "./styles.css";

export function Infos() {
  const appId = localStorage.getItem("idApp");

  const notificiationSystemRef = useRef(null);

  const history = useHistory();
  const queryParams = useQueryParams();

  const { infos, updateInfos, refetch } = useInfos({
    appId,
    groupId: queryParams.get("group_id"),
    search: queryParams.get("search"),
    candidateId: queryParams.get("candidate_id"),
  });

  const { tags } = useTags({
    appId,
    groupId: queryParams.get("group_id"),
  });

  function handleCloseModalCreateGroup(success = false) {
    if (success) {
      handleNotificationClick("Grupo criado com sucesso!", "success");
      refetch();
    }

    history.goBack();
  }

  function handleCloseModalUpdateGroup(success = false) {
    if (success) {
      handleNotificationClick("Grupo editado com sucesso!", "success");
      refetch();
    }

    history.goBack();
  }

  function handleCloseModalCreateInfo(success = false) {
    if (success) {
      handleNotificationClick("Informação criada com sucesso!", "success");
      refetch();
    }

    history.goBack();
  }

  function handleCloseModalUpdateInfo(success = false) {
    if (success) {
      handleNotificationClick("Informação editada com sucesso!", "success");
      refetch();
    }

    history.goBack();
  }

  function handleCloseModalAssociateTags(success = false) {
    if (success) {
      handleNotificationClick("Tags atualizadas com sucesso!", "success");
      refetch();
    }

    history.goBack();
  }

  function handleNotificationClick(message, color) {
    notificiationSystemRef.current?.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: <div>{message}</div>,
      level: color,
      position: "tr",
      dismissible: true,
    });
  }

  return (
    <div className="container-infos">
      <div className="content-infos">
        <HeaderInfos />

        <FiltersInfos />

        <div className="infos">
          {infos.length ? (
            <ListInfos
              infos={infos}
              updateInfos={updateInfos}
              refetchInfos={refetch}
              hasTags={tags?.length}
            />
          ) : (
            <EmptyState />
          )}
        </div>
      </div>

      <CreateGroup
        isOpen={queryParams.get("open_create_group") === "open"}
        onClose={(success) => handleCloseModalCreateGroup(success)}
      />

      <UpdateGroup
        isOpen={queryParams.get("open_update_group") === "open"}
        onClose={(success) => handleCloseModalUpdateGroup(success)}
      />

      <CreateInfo
        isOpen={queryParams.get("open_create_info") === "open"}
        onClose={(success) => handleCloseModalCreateInfo(success)}
      />

      <UpdateInfo
        isOpen={queryParams.get("open_update_info") === "open"}
        onClose={(success) => handleCloseModalUpdateInfo(success)}
      />

      <AssociateTags
        isOpen={queryParams.get("open_associate_tags") === "open"}
        onClose={(success) => handleCloseModalAssociateTags(success)}
        tags={tags}
      />

      <NotificationSystem ref={notificiationSystemRef} style={style} />
    </div>
  );
}
