import React from "react";

import { useFormContext } from "react-hook-form";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";

export function TextAreaField({ value, onChange, field }) {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[field.id]?.message
    ? String(errors[field.id]?.message)
    : undefined;

  return (
    <FormGroup>
      <ControlLabel>{field.name}</ControlLabel>
      <FormControl
        value={String(value)}
        onChange={(event) => onChange(event.target.value)}
        label={field.name}
        placeholder={field.name}
        hint={field.hint}
        error={error}
        componentClass="textarea"
        type="text"
      />
    </FormGroup>
  );
}
