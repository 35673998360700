import React from "react";
import { Modal } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton.jsx';


export default function DeleteVoter({ show, onClose, onDelete }) {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar exclusão</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Você tem certeza que deseja exclusão esse usuário?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="warning"
          fixMargin pullRight fill
          onClick={onClose}>
          Voltar
        </Button>
        <Button
          bsStyle="info"
          fixMargin pullRight fill
          onClick={onDelete}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}