
import React from "react";
import { Modal } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";

export function TutorialVideoModal({ isOpen, onClose, link, linkTitle, title }) {
  return (
    <Modal size="lg" show={isOpen} onHide={() => onClose()} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <iframe
          width="100%"
          height="400"
          src={link}
          title={linkTitle}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen></iframe>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="danger" fill onClick={() => onClose()}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
