import React, { useState, useRef } from "react";
import { trackPromise } from "react-promise-tracker";
import NotificationSystem from "react-notification-system";
import { Table, Dropdown, MenuItem } from "react-bootstrap";

import CandidateTag from "components/CandidateTag/CandidateTag.jsx";
import Button from "components/CustomButton/CustomButton";
import { CustomToggle } from "components/CustomToggle";
import { style } from "variables/Variables.jsx";
import api from "services/api";

import { SetGoal } from "./SetGoal";
import { Notifcation } from "./Notification";
import { RemoveLeader } from "./RemoveLeader";
import { Atuation } from "./Atuation";

const initialValuesModalSetGoal = { show: false, data: null };
const initialValuesModalRemoveLeader = { show: false, data: null };
const initialValuesModalAtuation = { show: false, data: null };
const initialValuesModalNotification = { show: false, id: null };

export function TableLeaders({ leaders, handleSelectLeader, updateLeaders }) {
  const notificationSystem = useRef();

  const appId = localStorage.getItem("idApp");

  const [openModalSetGoal, setOpenModalSetGoal] = useState(
    initialValuesModalSetGoal
  );

  const [openModalNotification, setOpenModalNotification] = useState(
    initialValuesModalNotification
  );

  const [openModalRemoveLeader, setOpenModalRemoveLeader] = useState(
    initialValuesModalRemoveLeader
  );

  const [openModalAtuation, setOpenModalAtuation] = useState(
    initialValuesModalAtuation
  );

  async function loadAtuation({ id, name }) {
    try {
      const response = await trackPromise(
        api.get(`${appId}/leaders/get_leader_voters_by_region?leader_id=${id}`)
      );

      const voters = response.data.data.voters.map((res, index) => ({
        id: index,
        neighborhood: {
          name: res.name,
          county: res.county,
          federal_unit: res.federal_unit,
          geometry: {
            lat: res.lat || 0,
            long: res.long || 0,
          },
        },
        totalVoters: res.count,
      }));

      setOpenModalAtuation({
        show: true,
        data: {
          name,
          voters,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  function handleNotification(message, color) {
    notificationSystem.current.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: <div>{message}</div>,
      level: color,
      position: "tr",
      autoDismiss: 5,
      dismissible: true,
    });
  }

  return (
    <>
      <Table striped hover style={{ marginBottom: 0 }}>
        <thead>
          <tr>
            <th>Identificador</th>
            <th>Candidato</th>
            <th className="text-center">Diretos</th>
            <th className="text-center">Indiretos</th>
            <th className="text-center">Líderes</th>
            <th className="text-center">Líderes indiretos</th>
            <th className="text-center">Rede total</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {leaders.map((item) => (
            <tr
              key={item.id}
              style={{
                cursor: item.leadersDirect ? "pointer" : "default",
              }}
              onClick={() => {
                if (item.leadersDirect) {
                  handleSelectLeader(item);
                }
              }}
            >
              <td>
                <div className="profile">
                  <strong>
                    {item.name}
                    {<span className="badge badge-custom">ID #{item.id}</span>}
                  </strong>
                  <span className="email">{item.email}</span>
                </div>
              </td>

              <td>
                {item.candidate ? (
                  <CandidateTag candidate={item.candidate} />
                ) : (
                  <div>Sem candidato específico</div>
                )}
              </td>

              <td className="text-center">{item.votersDirect}</td>

              <td className="text-center">{item.votersIndirect}</td>

              <td className="text-center">{item.leadersDirect}</td>

              <td className="text-center">{item.leadersIndirect}</td>

              <td className="text-center">
                {item.total}
                {item.goal ? ` / ${item.goal}` : null}
              </td>

              <td className="text-center">
                <Dropdown id="dropdown-custom-1" bsSize="small" pullRight>
                  <CustomToggle bsRole="toggle" />

                  <Dropdown.Menu>
                    <MenuItem
                      disabled={!item.leadersDirect}
                      onClick={() => handleSelectLeader(item)}
                    >
                      <Button
                        bsStyle="primary"
                        style={{ width: "100%" }}
                        simple
                        type="button"
                        bsSize="xs"
                        disabled={!item.leadersDirect}
                      >
                        <i className="fa fa-users" />
                        Visualizar Lideranças
                      </Button>
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        setOpenModalSetGoal({
                          show: true,
                          data: {
                            id: item.id,
                            goal: item.goal,
                          },
                        })
                      }
                    >
                      <Button
                        bsStyle="warning"
                        style={{ width: "100%" }}
                        simple
                        type="button"
                        bsSize="xs"
                      >
                        <i className="fa fa-bullseye"></i>
                        {item.goal ? "Editar meta" : "Adicionar meta"}
                      </Button>
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        loadAtuation({
                          id: item.id,
                          name: item.name,
                        })
                      }
                    >
                      <Button
                        bsStyle="info"
                        style={{ width: "100%" }}
                        simple
                        type="button"
                        bsSize="xs"
                      >
                        <i className="fa fa-map"></i>
                        Atuação
                      </Button>
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        setOpenModalNotification({
                          show: true,
                          id: item.id,
                        })
                      }
                    >
                      <Button
                        bsStyle="info"
                        style={{ width: "100%" }}
                        simple
                        type="button"
                        bsSize="xs"
                      >
                        <i className="fa fa-bell"></i>
                        Notificão
                      </Button>
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        setOpenModalRemoveLeader({
                          show: true,
                          data: {
                            id: item.id,
                            name: item.name,
                          },
                        })
                      }
                    >
                      <Button
                        bsStyle="danger"
                        style={{ width: "100%" }}
                        simple
                        type="button"
                        bsSize="xs"
                      >
                        <i className="fa fa-user"></i>
                        Remover lider partidário
                      </Button>
                    </MenuItem>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {openModalSetGoal.show ? (
        <SetGoal
          show={openModalSetGoal.show}
          id={openModalSetGoal.data.id}
          goal={openModalSetGoal.data.goal}
          updateLeaders={updateLeaders}
          showNotification={handleNotification}
          onClose={() => setOpenModalSetGoal(initialValuesModalSetGoal)}
        />
      ) : null}

      {openModalNotification.show ? (
        <Notifcation
          show={openModalNotification.show}
          voterId={openModalNotification.id}
          onClose={() =>
            setOpenModalNotification(initialValuesModalNotification)
          }
        />
      ) : null}

      {openModalRemoveLeader.show ? (
        <RemoveLeader
          show={openModalRemoveLeader.show}
          id={openModalRemoveLeader.data.id}
          name={openModalRemoveLeader.data.name}
          updateLeaders={updateLeaders}
          showNotification={handleNotification}
          onClose={() =>
            setOpenModalRemoveLeader(initialValuesModalRemoveLeader)
          }
        />
      ) : null}

      {openModalAtuation.show ? (
        <Atuation
          show={openModalAtuation.show}
          voters={openModalAtuation.data.voters}
          name={openModalAtuation.data.name}
          onClose={() => setOpenModalAtuation(initialValuesModalAtuation)}
        />
      ) : null}

      <NotificationSystem ref={notificationSystem} style={style} />
    </>
  );
}
