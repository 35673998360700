import React from "react";
import { Alert } from "react-bootstrap";
import { FiX } from 'react-icons/fi';

const DangerAlert = ({ error, close }) => {
  return (
    <Alert bsStyle="danger" style={{ position: 'relative' }}>
      <div className="closeButton-wrapper">
        <button
          type="button"
          aria-hidden="true"
          className="closeButton"
          onClick={close}
        >
          <FiX color="#b91c1c"/>
        </button>
      </div>
      <span><b> Atenção - </b>&nbsp;{error}</span>
    </Alert>
  );
};

export default DangerAlert;