import { atom } from "jotai";

export const FormDataAtom = atom({
  name: "",
  banner: null,
  profile: null,
  politicalParty: null,
  colorPrimary: "",
  colorBg: "",
  colorText: "",
});
