import React from "react";
import { Modal } from "react-bootstrap";

import Button from 'components/CustomButton/CustomButton.jsx';


export default function DeleteModal({ show, onClose, onConfirm }) {
	return (
		<Modal show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Confirmar exclusão</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Você tem certeza que deseja cancelar esta tarefa?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button
					bsStyle="warning"
					fixMargin pullRight fill
					onClick={onClose}>
					Voltar
				</Button>
				<Button
					bsStyle="info"
					fixMargin pullRight fill
					onClick={onConfirm}>
					Confirmar
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

