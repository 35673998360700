import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";

import api from "services/api";

export function useInfo({ appId, id }) {
  const [data, setData] = useState();

  useEffect(() => {
    async function getInfo() {
      const response = await trackPromise(
        api.get(`${appId}/information/${id}`)
      );

      const data = response.data;

      let images = [];

      if (data.images?.length) {
        images = data.images?.map((item) => ({
          imageId: item.id,
          preview: item.image,
        }));
      }

      const info = {
        title: data.title,
        description: data.sub_title,
        cover: data.cover,
        content: data.content,
        integration: data.link,
        latitude: data.latitude,
        longitude: data.longitude,
        images,
      };

      setData(info);
    }

    if (id) {
      getInfo();
    }
  }, [id]);

  return { info: data };
}
