import Card from "components/Card/Card.jsx";
import Confirm from "components/Confirm/Confirm.jsx";
import Button from 'components/CustomButton/CustomButton.jsx';
import React, { Component } from "react";
import { Col, Grid, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { TutorialVideoModal } from "components/TutorialModal/TutorialModal.jsx";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { style } from "variables/Variables.jsx";
import api from "../../services/api.jsx";
import Store from "./store.jsx";
import Update from "./update.jsx";
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      researches: [],
      isModalVisibleEdit: false,
      isModalVisibleNew: false,
      search: null,
      isComunicationModalVisible: false,
      app: localStorage.getItem('idApp'),
      role: JSON.parse(localStorage.getItem('user')).role,
      text: '',
      callback: undefined,
      confirm: false
    };
  }
  // state de cilco de vida de inicialização do componente
  componentDidMount() {
    this.loadSearchs();

  }
  // edita uma pesquisa
  editSearch(event) {
    this.setState({ search: event });
    this.setState({ isModalVisibleEdit: true });
  }
  //carrega as pesquisas de um app
  loadSearchs = async () => {
    try {
      const response = await trackPromise(api.get(`/${this.state.app}/researches`));
      this.setState({ researches: response.data.data });
    } catch (e) {
      this.handleNotificationClick(e.response.data.message, 'error');
    }
  };

  //deleta uma pesquisa
  deleteSearch = (id) => {
    trackPromise(api.delete(`/${this.state.app}/researches/${id}`))
      .then(response => {
        this.loadSearchs();
        this.handleNotificationClick('Pesquisa excluida com sucesso!', 'success');
      })
      .catch((error) => {
        this.handleNotificationClick('Erro na exclusão da pesquisa! Tente novamente!', 'error');
      });
  };
  // componente de notificação
  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: (
        <div>
          {message}
        </div>
      ),
      level: color,
      position: 'tr',
      autoDismiss: 5,
      dismissible: true
    });
  };
  openComunicationModal = () => {
    this.setState({ isComunicationModalVisible: true });
  };
  // renderzação do componente
  render() {
    const { isModalVisibleEdit, researches, isModalVisibleNew, isComunicationModalVisible } = this.state;

    const remove = (<Tooltip id="remove_tooltip">Excluir</Tooltip>);
    const edit = (<Tooltip id="edit_tooltip">Editar</Tooltip>);

    return (
      <>
        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Button title="cadastrar nova pesquisa" style={{ marginTop: "15px", marginRight: "15px" }} bsStyle="primary" fixMargin pullRight fill onClick={() => this.setState({ isModalVisibleNew: true })}>  <i className="fa fa-plus"></i> Nova Pesquisa</Button>
                <Card
                  title="Pesquisas"
                  category="Pesquisas cadastradas"
                  extraTitleContent={
                    <i
                      className="fa fa-question-circle"
                      style={{ fontSize: "18px", cursor: "pointer" }}
                      title="Assista ao vídeo tutorial dessa área"
                      onClick={this.openComunicationModal}
                    />
                  }
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <>
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>Título</th>
                            <th>Descrição</th>
                            <th>Início</th>
                            <th>Encerramento</th>
                            <th>Situação</th>
                            <th>Resultados</th>
                            <th>Perguntas</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            researches.map(event => (
                              <tr key={event.id}>
                                <td>{event.title}</td>
                                <td>{event.description}</td>
                                <td>{event.begin_period_at}</td>
                                <td>{event.end_period_at}</td>
                                <td>
                                  <span className={`badge badge-${event.is_active ? 'success' : 'danger'}`}>
                                    {event.is_active ? 'Ativa' : 'Inativa'}
                                  </span>
                                </td>
                                <td>
                                  <Link to={`./answers/researche/${event.id}`}>
                                    <Button bsStyle="success" fill ><i className="fa fa-inbox"></i></Button>
                                  </Link>
                                </td>
                                <td>
                                  <Link to={`./researche/${event.id}/questions`}>
                                    <Button bsStyle="warning" fill ><i className="fa fa-question"></i></Button>
                                  </Link>

                                </td>
                                <td>
                                  <OverlayTrigger placement="top" overlay={edit}>
                                    <Button
                                      bsStyle="info"
                                      simple
                                      type="button"
                                      bsSize="xs"
                                      onClick={() => this.editSearch(event)}
                                    >
                                      <i className="fa fa-edit"></i>
                                      Editar
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" overlay={remove}>
                                    <Button
                                      bsStyle="danger"
                                      simple
                                      type="button"
                                      bsSize="xs"
                                      onClick={() => this.setState({
                                        callback: () => this.deleteSearch(event.id),
                                        text: `excluir a pesquisa`,
                                        confirm: true
                                      })}
                                    >
                                      <i className="fa fa-times" />
                                      Excluir
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            ))
                          }

                        </tbody>
                      </Table>

                      {isModalVisibleEdit && <Update onClose={() => { this.setState({ isModalVisibleEdit: false }); this.loadSearchs(); }} search={this.state.search} />}
                      {isModalVisibleNew && <Store onClose={() => { this.setState({ isModalVisibleNew: false }); this.loadSearchs(); }} />}

                    </>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <TutorialVideoModal isOpen={isComunicationModalVisible}
          onClose={() => this.setState({ isComunicationModalVisible: false })}
          link={"https://www.youtube.com/embed/lV-IKFdVjzI?si=huI0YXaVDx973TxL"}
          title={"Notícias, eventos e pesquisas"}
          linkTitle={"Área de comunicação"} />
        <Confirm
          show={this.state.confirm}
          close={() => this.setState({
            confirm: false,
            callback: undefined,
            text: ''
          })}
          callback={this.state.callback}
          text={this.state.text}
        />
      </>
    );
  }
}

export default Search;
