import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import Card from "components/Card/Card.jsx";
import api from "services/api";
import './gallery.css';

export function Gallery() {
  const [images, setImages] = useState([]);
  const appId = localStorage.getItem('idApp');

  useEffect(() => {
    trackPromise(
      api.get(`/${appId}/galery_portal`)
        .then(response => {
          setImages(response.data);
        })
        .catch(error => {
          console.error("Error fetching images:", error);
        })
    );
  }, [appId]);

  return (
    <div className="content">
      <Card
        title="Galeria"
        category="Galeria de imagens"
        ctTableFullWidth
        ctTableResponsive
        content={
          images.length > 0 ? (
            <div className="gallery">
              {images.map(image => (
                <div key={image.id} className="gallery-item">
                  <Zoom>
                    <img src={image.image} alt={image.description} className="gallery-image" />
                  </Zoom>
                  <p className="gallery-description">{image.description}</p>
                  <p className="gallery-user">Enviado por: {image.voter.user.name}</p>
                </div>
              ))}</div>
          ) : (
            <div className="fields-empty-state">
              <strong>Não há Imagens</strong>

              <p>
                Parece que não há imagens disponíveis no momento. Por favor,
                verifique novamente mais tarde.
              </p>
            </div>
          )
        } />
    </div>
  );
}
