import React from "react";
import { trackPromise } from "react-promise-tracker";
import { Modal } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";
import api from "services/api";

export function RemoveLeader({
  show,
  onClose,
  id,
  name,
  updateLeaders,
  showNotification,
}) {
  const appId = localStorage.getItem("idApp");

  async function handleRemoveLeader() {
    trackPromise(
      api.delete(`${appId}/leaders/unassign`, {
        data: {
          user_id: id,
        },
      })
    )
      .then(() => {
        updateLeaders();

        showNotification("Liderança removida com sucesso!", "success");

        onClose();
      })
      .catch((error) => {
        console.error(error);

        showNotification(
          `Erro tente novamente ${error.response.data.message}`,
          "error"
        );
      });
  }

  return (
    <>
      <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Remover a liderança do(a) {name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <span>
            Ao confirmar, o eleitor perderá o seu papel de líder na aplicação.
            Só confirme, caso realmente deseje{" "}
            <strong>remover a liderança</strong> do usuário.
          </span>
        </Modal.Body>

        <Modal.Footer>
          <Button fill onClick={onClose}>
            Cancelar
          </Button>

          <Button
            bsStyle="danger"
            pullRight
            fill
            onClick={() => handleRemoveLeader()}
          >
            Remover
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
