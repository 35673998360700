import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import React, { useEffect, useState } from "react";
import {
  Col, ControlLabel, FormGroup, Modal,
  Row
} from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import ReactQuill from 'react-quill';
import api from "services/api.jsx";



//import AlertCustom from "components/CustomAlert/Alert.jsx";

const Store = ({ onClose = () => { }, history, idCandidato }) => {
  const [show, setShow] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [content, setContent] = useState('');
  const [image_header, setImageHeader] = useState(null);

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  //const handleShow = () => setShow(true);

  useEffect(() => { });

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('content', content);
    if (image_header) {
      formData.append('image_header', image_header, image_header.name);
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    trackPromise(api.post(
      `/${localStorage.getItem('idApp')}/candidates/${idCandidato}/histories`,
      formData,
      config
    ))
      .then(response => {
        onClose();
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message)
          setErrorText(error.response.data.message);
        if (error.response.data.errors && error.response.data.errors.image_header)
          setErrorText(error.response.data.errors.image_header[0]);
        setIsAlertVisible(true);
      });

  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de História</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            {isAlertVisible && <div className="alert alert-danger">Erro no cadastro da história!<br /> ERRROR: {errorText}</div>}

            <FormInputs
              ncols={["col-md-12"]}
              properties={[
                {
                  label: "Imagem",
                  type: "file",
                  bsClass: "form-control",
                  defaultValue: image_header,
                  name: 'image_header',
                  onChange: event => setImageHeader(event.target.files[0])
                }
              ]}
            />
            <Row>
              <Col md={12}>
                <FormGroup controlId="formControlsTextarea">
                  <ControlLabel>Conteúdo</ControlLabel>


                  <ReactQuill theme="snow" value={content} onChange={(event, editor) => {
                    setContent(event);
                  }}
                    modules={{
                      toolbar: [
                        [{ 'header': [1, 2, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                        ['link'],
                        ['clean']
                      ],
                    }}></ReactQuill>
                </FormGroup>
              </Col>
            </Row>
            <Button bsStyle="danger" fill onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button bsStyle="info" pullRight fill type="submit">
              Salvar
            </Button>
            <div className="clearfix" />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Store;