import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Col, ControlLabel,
  FormControl, FormGroup, Modal,
  Row
} from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import api from "services/api.jsx";
import './styles.css';


const Store = ({ onClose = () => { }, idCandidato }) => {

  const [show, setShow] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [place, setPlace] = useState('');
  const [occurs_date, setOccursDt] = useState('');
  const [occurs_time, setOccursTm] = useState('');
  const [image_header, setImageHeader] = useState(null);
  const [slots, setSlots] = useState(null);

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  //const handleShow = () => setShow(true);

  useEffect(() => { });

  const submitHandler = (e) => {
    e.preventDefault();
    const dataTemp = moment(new Date(occurs_date)).add(1, 'days');
    const occurs_dt_temp = moment(dataTemp).format("DD/MM/YYYY");

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('content', content);
    formData.append('place', place);
    formData.append('occurs_at', `${occurs_dt_temp} ${occurs_time.substr(0, 5)}`);
    formData.append('slots', slots ? slots : 0);

    if (image_header) {
      formData.append('image_header', image_header, image_header.name);
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    trackPromise(
      api.post(`/${localStorage.getItem('idApp')}/candidates/${idCandidato}/events`,
        formData,
        config))
      .then(response => {
        onClose();
      })
      .catch((error) => {
        let errorMessage = '';

        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((key) => {
            errorMessage += `${error.response.data.errors[key][0]}\n`;
          });
        }
        setErrorText(errorMessage || 'Houve um erro ao criar o evento!');
        setIsAlertVisible(true);
      });
  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Novo Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            {isAlertVisible && <div className="alert alert-danger"
              dangerouslySetInnerHTML={{ __html: `Erro no cadastro do evento! <br/>ERRO: ${errorText}` }}></div>}
            <FormInputs
              ncols={["col-md-4", "col-md-4", "col-md-4 input-file-custom"]}
              properties={[
                {
                  label: "Título",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Título",
                  required: true,
                  defaultValue: title,
                  name: 'title',
                  onChange: event => setTitle(event.target.value)
                },
                {
                  label: "Local",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Local",
                  required: true,
                  defaultValue: place,
                  name: 'place',
                  onChange: event => setPlace(event.target.value)
                },
                {
                  label: "Imagem",
                  type: "file",
                  defaultValue: image_header,
                  name: 'image_header',
                  required: true,
                  onChange: event => setImageHeader(event.target.files[0])
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-4", "col-md-4", "col-md-4"]}
              properties={[
                {
                  label: "Data",
                  type: "date",
                  bsClass: "form-control",
                  placeholder: "Data",
                  required: true,
                  defaultValue: occurs_date,
                  name: 'occurs_date',
                  onChange: event => setOccursDt(event.target.value)
                },
                {
                  label: "Horário",
                  type: "time",
                  bsClass: "form-control",
                  placeholder: "Horário",
                  required: true,
                  defaultValue: occurs_time,
                  name: 'occurs_time',
                  onChange: event => setOccursTm(event.target.value)
                },
                {
                  label: "Limite de interessados",
                  type: "number",
                  bsClass: "form-control",
                  placeholder: "Limite de interessados",
                  defaultValue: slots,
                  name: 'slots',
                  onChange: event => setSlots(event.target.value)
                }
              ]}
            />
            <Row>
              <Col md={12}>
                <FormGroup controlId="formControlsTextarea">
                  <ControlLabel>Descrição</ControlLabel>
                  <FormControl
                    rows="candidato"
                    componentClass="textarea"
                    bsClass="form-control"
                    placeholder="Descrição do Evento"
                    defaultValue={description}
                    required
                    name="description"
                    onChange={event => setDescription(event.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup controlId="formControlsTextarea">
                  <ControlLabel>Conteúdo</ControlLabel>
                  <CKEditor
                    required={true}
                    editor={ClassicEditor}
                    data={content}
                    onInit={editor => { }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setContent(data);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button bsStyle="danger" fill onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button bsStyle="info" pullRight fill type="submit">
              Salvar
            </Button>
            <div className="clearfix" />
          </form>
        </Modal.Body>
      </Modal >
    </>
  );
};

export default Store;
