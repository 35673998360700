import React from "react";
import "./styles.css";

export function EmptyState() {
  return (
    <div className="fields-empty-state">
      <strong>Não há campos do perfil cadastrados</strong>

      <p>
        Parece que não há campos do perfil disponíveis no momento. Por favor,
        verifique novamente mais tarde ou realize a criação de um novo campo.
      </p>
    </div>
  );
}
