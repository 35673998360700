import { useState } from "react";
import { useAtom, useSetAtom } from "jotai";
import { Listbox } from "@headlessui/react";
import { ArrowRight, CaretDown } from "@phosphor-icons/react";

import { FormDataAtom } from "components/Onboarding/atoms/FormDataAtom";
import { CurrentStepAtom } from "components/Onboarding/atoms/CurrentStepAtom";
import { politicalParties } from "./data/PoliticalParties";

import "./styles.css";

export function PoliticalPartyStep() {
  const [formData, setFormData] = useAtom(FormDataAtom);
  const setCurrentStep = useSetAtom(CurrentStepAtom);

  const [politicalParty, setPoliticalParty] = useState(
    formData.politicalParty ?? null
  );

  function handleNextStep() {
    setFormData((prev) => ({
      ...prev,
      politicalParty,
    }));

    setCurrentStep((prev) => prev + 1);
  }

  return (
    <div className="content political-step">
      <div className="heading">
        <strong>Partido do(a) candidato(a) majoritário(a)</strong>
        <span>
          Selecione abaixo o partido do(a) candidato(a) majoritário(a) da
          campanha
        </span>
      </div>

      <div className="field-select">
        <Listbox value={politicalParty} onChange={setPoliticalParty}>
          <div className="select-container">
            <Listbox.Button className="select-button">
              {politicalParty
                ? `${politicalParty.name} - ${politicalParty.abbreviation}`
                : null}

              {!politicalParty ? <span className="placeholder">Selecione o partido</span> : null}

              <CaretDown size={20} />
            </Listbox.Button>

            <Listbox.Options className="select-options">
              {politicalParties.map((item) => (
                <Listbox.Option
                  key={item.id}
                  value={item}
                  className="select-option"
                >
                  {item.name} - {item.abbreviation}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </div>

      <button disabled={!politicalParty} onClick={handleNextStep}>
        Avançar
        <ArrowRight size={20} weight="bold" />
      </button>
    </div>
  );
}
