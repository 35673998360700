import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CpfField } from "./fields/cpf-field.jsx";
import { DateField } from "./fields/date-field.jsx";
import { TextField } from "./fields/text-field.jsx";
import { TextAreaField } from "./fields/textarea-field.jsx";
import { NumberField } from "./fields/number-field.jsx";
import { SelectField } from "./fields/select-field.jsx";


const fields = {
  cpf: CpfField,
  date: DateField,
  number: NumberField,
  select: SelectField,
  text: TextField,
  text_long: TextAreaField,
}

export default function Field({ field }) {
  const Field = fields[field.type];
  const { control } = useFormContext;

  return (
    <Controller
      control={control}
      name={String(field.id)}
      defaultValue=""
      render={({ field: { value, onChange } }) => (
        <Field value={value ?? ""} onChange={onChange} field={field} />
      )} />
  )
}