import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Loading from "components/Loading/Loading.jsx";
import React, { Component } from "react";
import Confirm from "components/Confirm/Confirm.jsx";
import {
  Col,
  Grid,
  Row,
  FormControl,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";
import { TutorialVideoModal } from "components/TutorialModal/TutorialModal.jsx";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { style } from "variables/Variables.jsx";
import api from "../../services/api.jsx";
import Selfies from "components/Selfies/Selfies.jsx";
import Toggle from "react-toggle";
import "./styles.css";

export default class PreviewMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      user: localStorage.getItem("idApp"),
      candidate: {},
      constantsColors: [],
      primary_color: "",
      secondary_color: "",
      text_color: "",
      constantsSocialNetworks: [],
      Instagram: "",
      Facebook: "",
      YouTube: "",
      Twitter: "",
      Tiktok: "",
      Kwai: "",
      userNameInstagram: "",
      userNameFacebook: "",
      userNameYouTube: "",
      userNameTwitter: "",
      userNameTiktok: "",
      userNameKwai: "",
      titleRequestLeadership: "",
      descriptionRequestLeadership: "",
      main_image: null,
      main_screen: null,
      logo_image: null,
      selfie_template: null,
      preview_main_image: null,
      preview_main_screen: null,
      preview_logo_image: null,
      preview_selfie_template: null,
      loading: false,
      preview: "splash",
      county_id: null,
      county: "Digite o nome da cidade",
      type_region: "",
      candidatesByApplication: [],
      political_representative_id: "",
      callback: undefined,
      text: {},
      confirm: false,
      isModalVisible: false,
      slug: "",
      enableRequestLead: false,
    };
    this.changeHandler = this.changeHandler.bind(this);
  }

  openComunicationModal = () => {
    this.setState({ isModalVisible: true });
  };

  trasnformNameColorInput(nameInput, opt) {
    if (!opt) {
      return nameInput.replace("-", "_");
    } else {
      if (nameInput === "primary-color") return "Cor de botões, títulos e ícones principais";
      if (nameInput === "secondary-color") return "Cor de fundo da aplicação";
      if (nameInput === "text-color") return "cor dos textos";
    }
  }

  async componentDidMount() {
    await this.loadApplication(this.state.user);
    await this.loadConstantsColors();
    await this.loadConstantsSocialNetworks();
    await this.loadCandidatesByApplication(this.state.user);
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeHandlerFile = (e) => {
    /* if (e.target.name === "selfie_template") {
         const imageDimension = MediaDimensions.getImageDimensionsFromFile(e.target.files[0])
         if (imageDimension.width !== 1024 && imageDimension.height !== 1024) {
             this.handleNotificationClick("A moldura deve ser ser no formato .png e com dimensões de 1204x1024.", 'error');
             return false;
         }
     }*/

    this.setState({ [e.target.name]: e.target.files[0] });
    if (e.target.files[0]) {
      this.setState({
        ["preview_" + e.target.name]: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  // converte image em blob
  loadXHR(url) {
    return new Promise(function (resolve, reject) {
      try {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.onerror = function () {
          reject("Network error.");
        };
        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject("Loading error:" + xhr.statusText);
          }
        };
        xhr.send();
      } catch (err) {
        reject(err.message);
      }
    });
  }

  setSocialNetworks(socialNetworks) {
    if (socialNetworks.length) {
      socialNetworks.map((elem) => {
        if (elem.name === "Twitter")
          this.setState({
            Twitter: elem.link,
            userNameTwitter: elem.user_name ? elem.user_name : "",
          });
        if (elem.name === "Facebook")
          this.setState({
            Facebook: elem.link,
            userNameFacebook: elem.user_name ? elem.user_name : "",
          });
        if (elem.name === "Instagram")
          this.setState({
            Instagram: elem.link,
            userNameInstagram: elem.user_name ? elem.user_name : "",
          });
        if (elem.name === "YouTube")
          this.setState({
            YouTube: elem.link,
            userNameYouTube: elem.user_name ? elem.user_name : "",
          });
        if (elem.name === "Tiktok")
          this.setState({
            Tiktok: elem.link,
            userNameTiktok: elem.user_name ? elem.user_name : "",
          });
        if (elem.name === "Kwai")
          this.setState({
            Kwai: elem.link,
            userNameKwai: elem.user_name ? elem.user_name : "",
          });
      });
    } else {
      this.setState({
        Twitter: "",
        Facebook: "",
        Instagram: "",
        YouTube: "",
        Tiktok: "",
        Kwai: "",
        userNameTwitter: "",
        userNameFacebook: "",
        userNameInstagram: "",
        userNameYouTube: "",
        userNameTiktok: "",
        userNameKwai: "",
      });
    }
  }

  setColors(colors) {
    if (colors["primary-color"])
      this.setState({ primary_color: colors["primary-color"] });
    if (colors["secondary-color"])
      this.setState({ secondary_color: colors["secondary-color"] });
    if (colors["text-color"])
      this.setState({ text_color: colors["text-color"] });
  }

  loadApplication = async (idApp) => {
    try {
      let response = await trackPromise(api.get("/applications/" + idApp));
      // pegando as redes sociasi
      let socialNetworks = response.data.data.social_networks_resumed;
      // setando as redes sociais no states de cada variavel
      this.setSocialNetworks(socialNetworks);
      // pegando as colos
      let colors = response.data.data.colors;
      // setando as cores no states de cada variavel
      this.setColors(colors);
      //setando os dados do usuario
      this.setState({ candidate: response.data.data });
      // pegando as imagens do usuario caso exista
      if (response.data.data.link_main_image) {
        this.setState({
          preview_main_image: response.data.data.link_main_image,
        });
      }
      if (response.data.data.link_main_screen) {
        this.setState({
          preview_main_screen: response.data.data.link_main_screen,
        });
      }

      if (response.data.data.link_logo_image) {
        this.setState({
          preview_logo_image: response.data.data.link_logo_image,
        });
      }

      if (response.data.data.link_selfie_template) {
        this.setState({
          preview_selfie_template: response.data.data.link_selfie_template,
        });
      }

      if (response.data.data.county_id) {
        this.setState({ county_id: response.data.data.county_id }, () => {
          this.getCounty();
        });
      }

      if (response.data.data.type_region) {
        this.setState({
          type_region: response.data.data.type_region,
        });
      }

      if (response.data.data.coalition?.political_representative_id) {
        this.setState({
          political_representative_id:
            response.data.data.coalition.political_representative_id,
        });
      }

      this.setState({
        slug: `${response.data.data.slug}_${response.data.data.checker_key}`,
      });

      if (response.data.data.leadership_request_title) {
        this.setState({
          titleRequestLeadership: response.data.data.leadership_request_title,
        });
      }
      if (response.data.data.leadership_request_description) {
        this.setState({
          descriptionRequestLeadership:
            response.data.data.leadership_request_description,
        });
      }
      this.setState({
        enableRequestLead: response.data.data.enable_request_lead,
      });
    } catch (e) {
      console.log(e);
      this.handleNotificationClick(e.response.data.message, "error");
    }
  };

  loadConstantsColors = async () => {
    try {
      const response = await api.get("/colors/constants");
      this.setState({ constantsColors: response.data.data.tags });
    } catch (e) {
      this.handleNotificationClick(e.response.data.message, "error");
    }
  };

  animatedComponents = makeAnimated();

  handleInputChange = (newValue) => {
    this.setState({ county_id: newValue.id });
  };

  promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      if (inputValue) {
        let query;
        query = `/candidates/operational_areas/counties?q=${inputValue
          .toLowerCase()
          .replace(/(?:^|\s)\S/g, function (a) {
            return a.toUpperCase();
          })}`;
        api
          .get(query)
          .then((response) => {
            if (response.data.data) {
              let result = response.data.data.map((elm) => {
                return {
                  id: elm.id,
                  value: elm.id,
                  label: `${elm.name} - ${elm.uf.abbreviation}`,
                };
              });
              const filters = result.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
              );
              resolve(filters);
            }
            resolve([]);
          })
          .catch((err) => {
            resolve([]);
          });
      }
    });

  getCounty = async () => {
    let query = `/candidates/operational_areas/counties_id?q=${this.state.county_id}`;
    api
      .get(query)
      .then((response) => {
        if (response.data.data) {
          let result = response.data.data.map((elm) => {
            return `${elm.name} - ${elm.uf.abbreviation}`;
          });
          this.setState({ county: result[0] });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadConstantsSocialNetworks = async () => {
    try {
      const response = await api.get("/social_networks/constants");
      this.setState({ constantsSocialNetworks: response.data.data.names });
    } catch (e) {
      this.handleNotificationClick(e.response.data.message, "error");
    }
  };

  loadCandidatesByApplication = async (idApp) => {
    try {
      const response = await api.get(`${idApp}/candidates`);
      this.setState({ candidatesByApplication: response.data.data });
    } catch (e) {
      this.handleNotificationClick(e.response.data.message, "error");
    }
  };

  submitHandler = async (e) => {
    e.preventDefault();

    const {
      Facebook,
      Instagram,
      Twitter,
      YouTube,
      Tiktok,
      Kwai,
      userNameTwitter,
      userNameFacebook,
      userNameInstagram,
      userNameYouTube,
      userNameTiktok,
      userNameKwai,
      titleRequestLeadership,
      descriptionRequestLeadership,
      main_image,
      main_screen,
      logo_image,
      selfie_template,
      primary_color,
      secondary_color,
      text_color,
      county_id,
      type_region,
      political_representative_id,
      enableRequestLead,
    } = this.state;

    const formData = new FormData();
    formData.append("_method", "PUT");

    if (primary_color) {
      formData.append("colors[0][code]", primary_color);
      formData.append("colors[0][tag]", "primary-color");
    }
    if (secondary_color) {
      formData.append("colors[1][code]", secondary_color);
      formData.append("colors[1][tag]", "secondary-color");
    }
    if (text_color) {
      formData.append("colors[2][code]", text_color);
      formData.append("colors[2][tag]", "text-color");
    }

    if (Facebook || Facebook === "") {
      formData.append("social_networks[0][name]", "Facebook");
      formData.append("social_networks[0][link]", Facebook);
    }
    if (userNameFacebook || userNameFacebook === "") {
      formData.append("social_networks[0][user_name]", userNameFacebook);
    }

    if (Twitter || Twitter === "") {
      formData.append("social_networks[1][name]", "Twitter");
      formData.append("social_networks[1][link]", Twitter);
    }
    if (userNameTwitter || userNameTwitter === "") {
      formData.append("social_networks[1][user_name]", userNameTwitter);
    }

    if (YouTube || YouTube === "") {
      formData.append("social_networks[2][name]", "YouTube");
      formData.append("social_networks[2][link]", YouTube);
    }
    if (userNameYouTube || userNameYouTube === "") {
      formData.append("social_networks[2][user_name]", userNameYouTube);
    }

    if (Instagram || Instagram === "") {
      formData.append("social_networks[3][name]", "Instagram");
      formData.append("social_networks[3][link]", Instagram);
    }
    if (userNameInstagram || userNameInstagram === "") {
      formData.append("social_networks[3][user_name]", userNameInstagram);
    }

    if (Tiktok || Tiktok === "") {
      formData.append("social_networks[4][name]", "Tiktok");
      formData.append("social_networks[4][link]", Tiktok);
    }
    if (userNameTiktok || userNameTiktok === "") {
      formData.append("social_networks[4][user_name]", userNameTiktok);
    }

    if (Kwai || Kwai === "") {
      formData.append("social_networks[5][name]", "Kwai");
      formData.append("social_networks[5][link]", Kwai);
    }
    if (userNameKwai || userNameKwai === "") {
      formData.append("social_networks[5][user_name]", userNameKwai);
    }

    if (main_image) formData.append("main_image", main_image, main_image.name);
    if (main_image === "") formData.append("main_image", "");

    if (main_screen)
      formData.append("main_screen", main_screen, main_screen.name);
    if (main_screen === "") formData.append("main_screen", "");

    if (logo_image) formData.append("logo_image", logo_image, logo_image.name);
    if (logo_image === "") formData.append("logo_image", "");

    if (selfie_template)
      formData.append("selfie_template", selfie_template, selfie_template.name);

    if (county_id) formData.append("county_id", county_id);

    if (type_region) formData.append("type_region", type_region);

    if (political_representative_id)
      formData.append(
        "political_representative_id",
        political_representative_id
      );

    if (titleRequestLeadership)
      formData.append("leadership_request_title", titleRequestLeadership);

    if (descriptionRequestLeadership)
      formData.append(
        "leadership_request_description",
        descriptionRequestLeadership
      );

    formData.append("enable_request_lead", enableRequestLead);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    this.setState({ loading: true });
    await trackPromise(
      api.post("/applications/" + this.state.user, formData, config)
    )
      .then((response) => {
        this.setState({ loading: false });
        this.handleNotificationClick(response.data.message, "success");
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.handleNotificationClick(
          "Error ao aplicar as alterações!",
          "error"
        );
      });
  };

  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: <div>{message}</div>,
      level: color,
      position: "tr",
      autoDismiss: 5,
      dismissible: true,
    });
  };

  handleChangeToggle = (e) => {
    this.setState({ enableRequestLead: e.target.checked });
  };

  render() {
    const {
      Twitter,
      YouTube,
      Facebook,
      Instagram,
      Tiktok,
      Kwai,
      userNameTwitter,
      userNameFacebook,
      userNameInstagram,
      userNameYouTube,
      userNameTiktok,
      userNameKwai,
      titleRequestLeadership,
      descriptionRequestLeadership,
      constantsColors,
      constantsSocialNetworks,
      secondary_color,
      text_color,
      primary_color,
      main_image,
      logo_image,
      preview_main_image,
      preview_main_screen,
      preview_logo_image,
      main_screen,
      loading,
      county,
      type_region,
      candidatesByApplication,
      political_representative_id,
      slug,
      enableRequestLead,
      isModalVisible
    } = this.state;

    return (
      <div className="content">
        <Loading loading={loading} message="Salvando alterações..." />
        <NotificationSystem ref="notificationSystem" style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Configurações Gerais"
                extraTitleContent={
                  <i
                    className="fa fa-question-circle"
                    style={{ fontSize: "18px", cursor: "pointer" }}
                    title="Assista ao vídeo tutorial dessa área"
                    onClick={this.openComunicationModal}
                  />
                }
                content={
                  <form
                    onSubmit={this.submitHandler}
                    encType="multipart/form-data"
                  >
                    <fieldset>
                      <Row>
                        <Col md={4}>
                          <div className="form-group">
                            <ControlLabel>Candidato majoritário</ControlLabel>
                            <FormGroup>
                              <FormControl
                                componentClass="select"
                                aria-label="Candidato majoritário"
                                name="political_representative_id"
                                value={political_representative_id}
                                onChange={(e) =>
                                  this.setState({
                                    political_representative_id: e.target.value,
                                  })
                                }
                              >
                                <option value="">Selecione</option>
                                {candidatesByApplication.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </FormControl>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="form-group">
                            <ControlLabel>
                              Atualizar contexto regional da aplicação
                            </ControlLabel>
                            <FormGroup>
                              <FormControl
                                componentClass="select"
                                aria-label="Região da aplicação"
                                name="status"
                                value={type_region}
                                onChange={(e) =>
                                  this.setState({
                                    type_region: e.target.value,
                                  })
                                }
                              >
                                <option key="blankChoice" hidden value>
                                  Atualizar região
                                </option>
                                <option value="federal">Federal</option>
                                <option value="state">Estadual</option>
                                <option value="county">Municipal</option>
                              </FormControl>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="form-group">
                            <label>
                              Cidade de centralização dos mapas da aplicação
                            </label>
                            <AsyncSelect
                              placeholder={county}
                              isDisabled={false}
                              closeMenuOnSelect={false}
                              components={this.animatedComponents}
                              onChange={this.handleInputChange}
                              cacheOptions
                              loadOptions={this.promiseOptions}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3}>
                          <div className="toggle">
                            <label>
                              Habilitar solicitação para se tornar lider
                            </label>
                            <Col md={1}>
                              <Toggle
                                checked={enableRequestLead}
                                onChange={this.handleChangeToggle}
                              />
                            </Col>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div className="form-group">
                            <label>
                              Título para a página de ser tornar liderança
                            </label>
                            <input
                              type="text"
                              name="titleRequestLeadership"
                              className="form-control"
                              value={titleRequestLeadership}
                              onChange={this.changeHandler}
                              disabled={!enableRequestLead}
                            />
                          </div>
                        </Col>
                        <Col md={8}>
                          <div className="form-group">
                            <label>
                              Descrição para a página de ser tornar liderança
                            </label>
                            <input
                              type="text"
                              name="descriptionRequestLeadership"
                              className="form-control"
                              value={descriptionRequestLeadership}
                              onChange={this.changeHandler}
                              disabled={!enableRequestLead}
                            />
                          </div>
                        </Col>
                      </Row>
                    </fieldset>
                    <fieldset>
                      <legend>Cores</legend>
                      <Row>
                        {constantsColors.map((constant) => (
                          <Col key={constant} md={4}>
                            <div key={constant} className="form-group">
                              <label>
                                {this.trasnformNameColorInput(constant, true)}
                              </label>
                              <input
                                required
                                minLength="4"
                                type="color"
                                name={this.trasnformNameColorInput(
                                  constant,
                                  false
                                )}
                                defaultValue={
                                  constant === "primary-color"
                                    ? primary_color
                                    : constant === "secondary-color"
                                      ? secondary_color
                                      : constant === "text-color"
                                        ? text_color
                                        : ""
                                }
                                onChange={this.changeHandler}
                                className="form-control"
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </fieldset>
                    <fieldset>
                      <legend>Imagens</legend>
                      <Row>

                        <Col md={4}>
                          <Row>
                            <Col md={9}>
                              <FormInputs
                                ncols={["col-md-12"]}
                                properties={[
                                  {
                                    label: "Logo da aplicação",
                                    type: "file",
                                    bsClass: "form-control",
                                    required: false,
                                    defaultValue: logo_image,
                                    name: "logo_image",
                                    onChange: this.changeHandlerFile,
                                  },
                                ]}
                              />
                            </Col>

                            {preview_logo_image && logo_image !== "" && (
                              <Col md={3} className="justify-content-md-center">
                                <Button
                                  bsStyle="danger"
                                  bsSize="small"
                                  simple
                                  onClick={() =>
                                    this.setState({
                                      callback: () => this.setState({ logo_image: "" }),
                                      confirm: true,
                                      text: "remover logo",
                                    })
                                  }
                                >
                                  <span>REMOVER</span>
                                </Button>
                              </Col>
                            )}
                          </Row>

                          {preview_logo_image && logo_image !== "" && (
                            <label className="labelInfo">
                              Imagem atual:{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={preview_logo_image}
                              >
                                Acesse aqui
                              </a>
                            </label>
                          )}

                          <label className="info-muted">
                            Utilizada quando o app é instalado no celular do usuário<hr></hr>
                            Envie imagem com as dimensões sugeridas: Largura:{" "}
                            <strong>512 pixels</strong> e Altura:{" "}
                            <strong>512 pixels</strong>
                          </label>
                        </Col>

                        <Col md={4}>
                          <Row>
                            <Col md={9}>
                              <FormInputs
                                ncols={["col-md-12"]}
                                properties={[
                                  {
                                    label: "Imagem da Tela inicial",
                                    type: "file",
                                    bsClass: "form-control",
                                    required: false,
                                    defaultValue: main_image,
                                    name: "main_image",
                                    onChange: this.changeHandlerFile,
                                  },
                                ]}
                              />
                            </Col>

                            {preview_main_image && main_image !== "" && (
                              <Col md={3} className="justify-content-md-center">
                                <Button
                                  bsStyle="danger"
                                  bsSize="small"
                                  simple
                                  onClick={() =>
                                    this.setState({
                                      callback: () =>
                                        this.setState({ main_image: "" }),
                                      confirm: true,
                                      text: "remover imagem de perfil",
                                    })
                                  }
                                >
                                  <span>REMOVER</span>
                                </Button>
                              </Col>
                            )}
                          </Row>

                          {preview_main_image && main_image !== "" && (
                            <label className="labelInfo">
                              Imagem atual:{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={preview_main_image}
                              >
                                Acesse aqui
                              </a>
                            </label>
                          )}
                          <label className="info-muted">
                            Utilizada no cabeçalho da tela inicial<hr></hr>
                            Envie imagem com as dimensões sugeridas: Largura:{" "}
                            <strong>900 pixels</strong> e Altura:{" "}
                            <strong>600 pixels</strong> (preferecialmente com
                            fundo transparente)
                          </label>
                        </Col>

                        <Col md={4}>
                          <Row>
                            <Col md={9}>
                              <FormInputs
                                ncols={["col-md-12"]}
                                properties={[
                                  {
                                    label: "Imagem da tela de autenticação",
                                    type: "file",
                                    bsClass: "form-control",
                                    required: false,
                                    defaultValue: main_screen,
                                    name: "main_screen",
                                    onChange: this.changeHandlerFile,
                                  },
                                ]}
                              />
                            </Col>

                            {preview_main_screen && main_screen !== "" && (
                              <Col md={3} className="justify-content-md-center">
                                <Button
                                  bsStyle="danger"
                                  bsSize="small"
                                  simple
                                  onClick={() =>
                                    this.setState({
                                      callback: () =>
                                        this.setState({ main_screen: "" }),
                                      confirm: true,
                                      text: "remover imagem da página de autenticação",
                                    })
                                  }
                                >
                                  <span>REMOVER</span>
                                </Button>
                              </Col>
                            )}
                          </Row>

                          {preview_main_screen && main_screen !== "" && (
                            <label className="labelInfo">
                              Imagem atual:{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={preview_main_screen}
                              >
                                Acesse aqui
                              </a>
                            </label>
                          )}
                          <label className="info-muted">
                            Utilizada no cabeçalho da tela de autenticação<hr></hr>
                            Envie imagem com as dimensões sugeridas: Largura:{" "}
                            <strong>1000 pixels</strong> e Altura:{" "}
                            <strong>300 pixels</strong>
                          </label>
                        </Col>
                      </Row>
                    </fieldset>

                    <Row>
                      <Col md={12}>
                        <Selfies
                          app_id={this.state.user}
                          data_id={1}
                          type="applications"
                        ></Selfies>
                      </Col>
                    </Row>
                    <fieldset>
                      <legend>Redes Sociais</legend>
                      <FormInputs
                        ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                        properties={[
                          {
                            label: "Facebook",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "link",
                            value: Facebook,
                            minLength: 10,
                            name: "Facebook",
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Usuário Facebook",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Informe o usuário",
                            value: userNameFacebook,
                            name: "userNameFacebook",
                            minLength: 2,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Instagram",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "link",
                            minLength: 10,
                            value: Instagram,
                            name: "Instagram",
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Usuário Instagram",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Informe o usuário",
                            value: userNameInstagram,
                            name: "userNameInstagram",
                            minLength: 2,
                            onChange: this.changeHandler,
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                        properties={[
                          {
                            label: "YouTube",
                            type: "text",
                            minLength: 10,
                            bsClass: "form-control",
                            placeholder: "link",
                            name: "YouTube",
                            value: YouTube,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Usuário YouTube",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Informe o usuário",
                            value: userNameYouTube,
                            name: "userNameYouTube",
                            minLength: 2,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Twitter",
                            type: "text",
                            minLength: 10,
                            bsClass: "form-control",
                            placeholder: "link",
                            name: "Twitter",
                            value: Twitter,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Usuário Twitter",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Informe o usuário",
                            value: userNameTwitter,
                            name: "userNameTwitter",
                            minLength: 2,
                            onChange: this.changeHandler,
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                        properties={[
                          {
                            label: "Tiktok",
                            type: "text",
                            minLength: 10,
                            bsClass: "form-control",
                            placeholder: "link",
                            name: "Tiktok",
                            value: Tiktok,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Usuário Tiktok",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Informe o usuário",
                            value: userNameTiktok,
                            name: "userNameTiktok",
                            minLength: 2,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Kwai",
                            type: "text",
                            minLength: 10,
                            bsClass: "form-control",
                            placeholder: "link",
                            name: "Kwai",
                            value: Kwai,
                            onChange: this.changeHandler,
                          },
                          {
                            label: "Usuário Kwai",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Informe o usuário",
                            value: userNameKwai,
                            name: "userNameKwai",
                            minLength: 2,
                            onChange: this.changeHandler,
                          },
                        ]}
                      />
                    </fieldset>

                    <Button bsStyle="success" fill type="submit">
                      Salvar Alterações
                    </Button>

                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>

        <TutorialVideoModal
          isOpen={isModalVisible}
          onClose={() => this.setState({ isModalVisible: false })}
          link={"https://www.youtube.com/embed/NhiVpOE73LE?si=RdH7cd5W1RkieZGz"}
          linkTitle={"Configurações gerais"}
          title={"Configurações gerais"} />


        <Confirm
          show={this.state.confirm}
          close={() =>
            this.setState({
              confirm: false,
              callback: undefined,
              text: "",
            })
          }
          callback={this.state.callback}
          text={this.state.text}
        />
      </div>
    );
  }
}
