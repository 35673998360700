import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";

import api from "services/api";

export function useField({ appId, id }) {
  const [data, setData] = useState();

  useEffect(() => {
    async function getField() {
      const response = await trackPromise(
        api.get(`${appId}/voters/fields/${id}`)
      );

      setData(response.data);
    }

    if (id) {
      getField();
    }
  }, [id]);

  return { field: data };
}
