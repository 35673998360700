import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { trackPromise } from "react-promise-tracker";
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

import api from "../../services/api.jsx";
import Card from "components/Card/Card.jsx";
import Button from 'components/CustomButton/CustomButton.jsx';
import MapModal from "./components/map.jsx";
import DeleteModal from './components/delete.jsx';
import { EmptyTask } from "./components/empty-task.jsx"

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const appId = localStorage.getItem('idApp');
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [selectedTaskLocation, setSelectedTaskLocation] = useState({});
  const history = useHistory();

  useEffect(() => {
    loadTasks(`/${appId}/tasks`);
  }, [appId]);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  function loadTasks(url) {
    trackPromise(
      api.get(url)
        .then((response) => {
          const formattedTasks = response.data.map((task) => {
            return {
              ...task
            };
          });
          setTasks(formattedTasks);
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  const handleNewTask = () => {
    history.push({ pathname: "/admin/create-task" });
  };

  const handleEditTask = (taskId) => {
    history.push({ pathname: `/admin/edit-task/${taskId}` });
  };

  const handleAnswer = (taskId) => {
    history.push({ pathname: `/admin/task-answer/${taskId}` });
  };

  const handleDeleteTask = (taskId) => {
    setSelectedTaskId(taskId);
    setShowDeleteModal(true);
  };

  const confirmDeleteTask = () => {
    if (selectedTaskId) {
      trackPromise(
        api.delete(`/${appId}/tasks/${selectedTaskId}`)
          .then(() => {
            loadTasks(`/${appId}/tasks`);
            Toast.fire({
              icon: "success",
              title: "Tarefa cancelada com sucesso!"
            });
          })
          .catch((e) => {
            Toast.fire({
              icon: "error",
              title: "Erro ao cancelar a tarefa."
            });
            console.log(e);
          })
      );
    }
    setShowDeleteModal(false);
  };

  const handleShowMap = (latitude, longitude, radius) => {
    setSelectedTaskLocation({ latitude, longitude, radius });
    setShowModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <div className="content">
      <Button
        style={{ display: 'flex', gap: "3px", alignItems: "center", marginTop: "15px", marginRight: "15px" }}
        title="Cadastrar Nova Tarefa"
        bsStyle="primary"
        fixMargin pullRight fill
        onClick={handleNewTask}
      >
        <i className="fa fa-user-plus" />
        Nova Tarefa
      </Button>
      <Card
        title="Tarefas"
        category="Gerenciamento de tarefas"
        ctTableFullWidth
        ctTableResponsive
        content={
          tasks.length === 0 ? (
            <EmptyTask />

          ) : (
            <Table striped hover>
              <thead>
                <tr>
                  <th>Título</th>
                  <th>Descrição</th>
                  <th>Pontuação</th>
                  <th>Data limite</th>
                  <th>Fotos necessárias</th>
                  <th>Localização</th>
                  <th>Editar</th>
                  <th>Excluir</th>
                  <th>Resposta</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map((task) => (
                  <tr key={task.id}>
                    <td>{task.title}</td>
                    <td>{task.description}</td>
                    <td>{task.done_points}</td>
                    <td>{dayjs(task.max_date).format('DD/MM/YYYY')}</td>
                    {task.needed_pictures ? (
                      <td>{task.needed_pictures}</td>
                    ) : (
                      <td>Sem fotos</td>
                    )}
                    {task.latitude_checking && task.longitude_checking && task.radius ? (
                      <td>
                        <Button
                          bsStyle="info"
                          simple
                          type="button"
                          bsSize="xs"
                          onClick={() => handleShowMap(task.latitude_checking, task.longitude_checking, task.radius)}
                        >
                          <i className="fa fa-map"></i>
                          Localização
                        </Button>
                      </td>
                    ) : (
                      <td>Sem localização</td>
                    )}
                    <td>
                      <Button
                        bsStyle="info"
                        simple
                        type="button"
                        bsSize="xs"
                        onClick={() => handleEditTask(task.id)}
                      >
                        <i className="fa fa-edit"></i>
                        Editar
                      </Button>
                    </td>
                    <td>
                      <Button
                        bsStyle="danger"
                        simple
                        type="button"
                        bsSize="xs"
                        onClick={() => handleDeleteTask(task.id)}
                      >
                        <i className="fa fa-times" />
                        Excluir
                      </Button>
                    </td>
                    <td>
                      <Button
                        bsStyle="info"
                        simple
                        type="button"
                        bsSize="xs"
                        onClick={() => handleAnswer(task.id)}
                      >
                        Resposta
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )
        }
      />
      <MapModal
        setModalClose={() => setShowModal(false)}
        showModal={showModal}
        latitude={selectedTaskLocation.latitude}
        longitude={selectedTaskLocation.longitude}
        radius={selectedTaskLocation.radius}
      />
      <DeleteModal
        show={showDeleteModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={confirmDeleteTask}
      />
    </div>
  );
}

export default Tasks;
