import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";

import api from "services/api";

export function useFields({ appId }) {
  const [data, setData] = useState([]);
  const [shouldRefetch, refetch] = useState(false);

  useEffect(() => {
    async function getFields() {
      const response = await trackPromise(api.get(`${appId}/voters/fields`));
      setData(response.data);
    }

    getFields();
  }, [appId, shouldRefetch]);

  return {
    fields: data,
    refetch: () => refetch((prevState) => !prevState),
    updateFields: setData,
  };
}
