import React from "react";
import "./styles.css";

export function EmptyGoal() {
	return (
		<div className="fields-empty-state">
			<strong>Não há metas cadastradas</strong>

			<p>
				Parece que não há metas disponíveis no momento. Por favor,
				verifique novamente mais tarde ou realize a criação de uma nova meta.
			</p>
		</div>
	);
}
