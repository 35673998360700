import DangerAlert from "components/DangerAlert/DangerAlert";
import React from "react";
import { Col, Row, Modal, Form, FormGroup, FormControl, ControlLabel, Radio } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import { trackPromise } from "react-promise-tracker";
import api from "services/api";

const Store = ({ show, close, reload }) => {
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [error, setError] = React.useState('');
  const [candidates, setCandidates] = React.useState([]);
  const appId = localStorage.getItem('idApp');
  const appType = localStorage.getItem('typeApplication');

  React.useEffect(() => {
    trackPromise(api.get(`/${appId}/candidates/get_by_roles`))
      .then((res) => setCandidates(res.data.data))
      .catch(err => console.err(err));
  }, [appId]);

  function submitHandler(event) {
    event.preventDefault();
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    const data = new FormData();
    data.append('name', event.target.name.value);
    data.append('description', event.target.description.value);
    data.append('icon_link', event.target.icon.files[0]);
    data.append('location_request', +(event.target.location.value));
    data.append('file_request', +(event.target.file.value));
    data.append('extra_field_name', event.target.extraField.value);
    data.append('enable', +(event.target.enable.value));

    if(appType == "many"){
      if (!!+(event.target.candidate.value)) {
        data.append('candidate_id', event.target.candidate.value);
      }
    } else {
      data.append('candidate_id', candidates[0].id);
    }
    

    trackPromise(api.post(`/${appId}/participation_area`, data, config))
      .then(() => {
        close();
        reload();
      })
      .catch(err => {
        setError(err.message);
        setIsAlertVisible(true);
      });
  }

  return (
    <Modal size="sm" show={show} onHide={close} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Cadastro de área de participação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          {
            isAlertVisible &&
            <DangerAlert error={error} close={() => setIsAlertVisible(false)} />
          }
          <Row>
            <Col md={12}>
              <FormGroup>
                <ControlLabel>Nome</ControlLabel>
                <FormControl type="text" placeholder="Nome da área" name="name" required />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={7}>
              <FormGroup>
                <ControlLabel>Nome do campo do perfil</ControlLabel>
                <FormControl type="text" placeholder="Nome do campo do perfil" name="extraField" required />
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <ControlLabel>Ícone</ControlLabel>
                <FormControl
                  type="file"
                  accept="image/*"
                  placeholder="Ícone"
                  name="icon"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <ControlLabel>Requerir localização?</ControlLabel>
              <FormGroup>
                <Radio name="location" value={1} required inline>Sim</Radio>&nbsp;
                <Radio name="location" value={0} required inline>Não</Radio>
              </FormGroup>
            </Col>
            <Col md={4}>
              <ControlLabel>Requerir arquivo?</ControlLabel>
              <FormGroup>
                <Radio name="file" value={1} required inline>Sim</Radio>&nbsp;
                <Radio name="file" value={0} required inline>Não</Radio>
              </FormGroup>
            </Col>
            <Col md={4}>
              <ControlLabel>Habilitar?</ControlLabel>
              <FormGroup>
                <Radio name="enable" value={1} required inline>Sim</Radio>&nbsp;
                <Radio name="enable" value={0} required inline>Não</Radio>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ControlLabel>Descrição</ControlLabel>
              <FormGroup>
                <FormControl
                  type="text"
                  placeholder="Descrição"
                  name="description"
                  rows={3}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          {
            appType === "many" &&
            <Row>
              <Col md={12}>
                <ControlLabel>Candidato</ControlLabel>
                <FormGroup>
                  <FormControl
                    componentClass="select"
                    placeholder="Selecionar candidato"
                    name="candidate"
                    required
                  >
                    <option value="0">Sem candidato definido</option>
                    {
                      candidates.map(candidate => <option key={candidate.id} value={candidate.id}>{candidate.name}</option>)
                    }
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>
          }
          <hr />
          <Button bsStyle="danger" fill onClick={close}>Cancelar</Button>
          <Button bsStyle="info" pullRight fill type="submit">Cadastrar</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Store;