import React from "react";
import Button from 'components/CustomButton/CustomButton.jsx';
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const RemoveButton = ({ callback }) => {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id="remove">Excluir</Tooltip>}>
      <Button
        bsStyle="danger"
        simple
        type="button"
        bsSize="xs"
        onClick={callback}
      >
        <i className="fa fa-times" />
        Excluir
      </Button>
    </OverlayTrigger>
  );
};

export default RemoveButton;