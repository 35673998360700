import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useForm, Controller, useWatch, useFieldArray } from "react-hook-form";
import ReactQuill from "react-quill";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
  HelpBlock,
} from "react-bootstrap";
import { X } from "@phosphor-icons/react";

import Button from "components/CustomButton/CustomButton.jsx";
import { MapCreate } from "../../components/MapCreate";
import { useInfo } from "./hooks/useInfo";
import { useQueryParams } from "hooks/useQueryParams";

import api from "services/api";

import "./styles.css";

export function UpdateInfo({ isOpen, onClose }) {
  const appId = localStorage.getItem("idApp");

  const queryParams = useQueryParams();

  const [imagesRemoved, setImagesRemoved] = useState([]);

  const { info } = useInfo({
    appId,
    id: queryParams.get("info_edit_id")
      ? Number(queryParams.get("info_edit_id"))
      : undefined,
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      previewCoverUrl: "",
      image: "",
      images: [],
      content: "",
      integration: false,
      linkIntegration: "",
      geolocation: false,
      address: null,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "images",
  });

  useEffect(() => {
    if (info) {
      reset({
        title: info.title,
        description: info.description,
        content: info.content ? info.content : "",
        previewCoverUrl: info.cover ? info.cover : "",
        images: info.images.length ? info.images : [],
        integration: !!info.integration,
        linkIntegration: info.integration ? info.integration : "",
        geolocation: info.latitude && info.longitude,
        address:
          info.latitude && info.longitude
            ? { lat: info.latitude, lng: info.longitude }
            : null,
      });
    }
  }, [info]);

  async function handleUpdateInfo({
    title,
    description,
    cover,
    images,
    content,
    integration,
    linkIntegration,
    geolocation,
    address,
  }) {
    try {
      const formData = new FormData();

      formData.append("_method", "PUT");
      formData.append("title", title);
      formData.append("sub_title", description);

      images
        .filter((item) => !item.imageId)
        .forEach((image) => {
          formData.append("images[]", image.file, "imagem.png");
        });

      imagesRemoved.forEach((image) => {
        formData.append("images_removed[]", image);
      });

      if (queryParams.get("group_id")) {
        formData.append("groups_information_id", queryParams.get("group_id"));
      }

      if (queryParams.get("candidate_id")) {
        formData.append("candidate_id", queryParams.get("candidate_id"));
      }

      if (cover.length && cover[0]) {
        formData.append("cover", cover[0], "imagem.png");
      } else if (previewCoverUrl && !cover.length) {
        formData.append("cover", previewCoverUrl);
      }

      if (!integration) {
        formData.append("content", content);
      }

      if (integration && linkIntegration) {
        formData.append("link", linkIntegration);
      }

      if (geolocation && address) {
        formData.append("latitude", address.lat);
        formData.append("longitude", address.lng);
      }

      await trackPromise(
        api.post(
          `${appId}/information/${queryParams.get("info_edit_id")}`,
          formData
        )
      );

      handleOnClose(true);
    } catch (error) {
      console.error(error);
    }
  }

  function handleOnClose(success) {
    reset({
      title: "",
      description: "",
      previewCoverUrl: "",
      cover: "",
      images: [],
      content: "",
      integration: false,
      linkIntegration: "",
      geolocation: false,
      address: null,
    });

    onClose(success);
  }

  function handleOnChangeCover(files) {
    if (files.length) {
      setValue("previewCoverUrl", URL.createObjectURL(files[0]));
    }
  }

  function handleRemoveCover() {
    setValue("previewCoverUrl", "");
    setValue("cover", "");
  }

  function handleOnChangeHasIntegration(value) {
    if (value) {
      unregister("content");
      unregister("geolocation");
      unregister("images");
    } else {
      unregister("linkIntegration");
    }
  }

  function handleOnChangeHasGeolocation(value) {
    if (!value) {
      unregister("address");
    }
  }

  function handleOnChangeImages(event) {
    if (!event.target.files) {
      return;
    }

    const selectedImages = Array.from(event.target.files);

    selectedImages.forEach((image) => {
      append({
        file: image,
        preview: URL.createObjectURL(image),
      });
    });
  }

  function handleRemoveImage({ id, link, index }) {
    remove(index);

    if (id) {
      setImagesRemoved((prevState) => [...prevState, link]);
    }
  }

  const previewCoverUrl = useWatch({
    control,
    name: "previewCoverUrl",
  });

  const hasIntegration = useWatch({
    control,
    name: "integration",
  });

  const hasGeolocation = useWatch({
    control,
    name: "geolocation",
  });

  return (
    <Modal show={isOpen} onHide={() => handleOnClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Atualizar informação</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          id="form-update-info"
          className="form-update-info"
          onSubmit={handleSubmit(handleUpdateInfo)}
        >
          <div className="row">
            <div className="col-md-6">
              <FormGroup>
                <ControlLabel>Título</ControlLabel>

                <Controller
                  control={control}
                  name="title"
                  rules={{ required: "Informe o título do grupo" }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      type="text"
                      value={value}
                      placeholder="Título do grupo"
                      onChange={onChange}
                    />
                  )}
                />

                {errors.title ? (
                  <HelpBlock>{errors.title.message}</HelpBlock>
                ) : null}
              </FormGroup>
            </div>

            <div className="col-md-6">
              <FormGroup>
                <ControlLabel>Descrição</ControlLabel>

                <Controller
                  control={control}
                  name="description"
                  rules={{ required: "Informe a descrição do grupo" }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl
                      type="text"
                      value={value}
                      placeholder="Descrição do grupo"
                      onChange={onChange}
                    />
                  )}
                />

                {errors.description ? (
                  <HelpBlock>{errors.description.message}</HelpBlock>
                ) : null}
              </FormGroup>
            </div>
          </div>

          <div>
            <ControlLabel>Imagem de capa</ControlLabel>

            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <input
                    type="file"
                    {...register("cover", {
                      onChange: (event) =>
                        handleOnChangeCover(event.target.files),
                      ...(!previewCoverUrl
                        ? { required: "Informe a imagem" }
                        : {}),
                    })}
                  />

                  {errors.cover ? (
                    <HelpBlock>{errors.cover.message}</HelpBlock>
                  ) : null}
                </FormGroup>

                {previewCoverUrl ? (
                  <div className="preview_image">
                    <img src={previewCoverUrl} alt="" />
                    <button type="button" onClick={handleRemoveCover}>
                      <X weight="bold" />
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <label htmlFor="integration" className="container-checkbox">
            <input
              id="integration"
              type="checkbox"
              {...register("integration", {
                onChange: (e) => handleOnChangeHasIntegration(e.target.checked),
              })}
            />
            Informação é do tipo integração?
          </label>

          {hasIntegration ? (
            <FormGroup>
              <ControlLabel>Link da integração</ControlLabel>

              <Controller
                control={control}
                name="linkIntegration"
                rules={{
                  ...(hasIntegration
                    ? { required: "Informe o link da integração" }
                    : {}),
                }}
                render={({ field: { value, onChange } }) => (
                  <FormControl
                    type="text"
                    value={value}
                    placeholder="Link da integração"
                    onChange={(event) => onChange(event.target.value)}
                    disabled={!hasIntegration}
                  />
                )}
              />

              {hasIntegration && errors.linkIntegration ? (
                <HelpBlock>{errors.linkIntegration.message}</HelpBlock>
              ) : null}
            </FormGroup>
          ) : null}

          {!hasIntegration ? (
            <div className="container-images">
              <ControlLabel>Imagens</ControlLabel>

              <div className="content-images">
                <div className="images">
                  {fields.map((item, index) => (
                    <div className="preview-image-info" key={item.preview}>
                      <img src={item.preview} alt="" />

                      <button
                        type="button"
                        onClick={() =>
                          handleRemoveImage({
                            id: item.imageId,
                            link: item.preview,
                            index,
                          })
                        }
                      >
                        <X weight="bold" />
                      </button>
                    </div>
                  ))}

                  <label htmlFor="image[]">
                    <i className="fa fa-plus" />
                  </label>
                </div>

                <input
                  multiple
                  type="file"
                  id="image[]"
                  onChange={handleOnChangeImages}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          ) : null}

          {!hasIntegration ? (
            <FormGroup>
              <ControlLabel>Conteúdo</ControlLabel>

              <Controller
                control={control}
                name="content"
                rules={{
                  ...(!hasIntegration
                    ? { required: "Informe o conteúdo da informação" }
                    : {}),
                }}
                render={({ field: { value, onChange } }) => (
                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={onChange}
                    readOnly={hasIntegration}
                    placeholder="Informe o conteúdo..."
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link", "image"],
                      ],
                    }}
                  />
                )}
              />

              {errors.content ? (
                <HelpBlock>{errors.content.message}</HelpBlock>
              ) : null}
            </FormGroup>
          ) : null}

          {!hasIntegration ? (
            <div>
              <label htmlFor="geolocation" className="container-checkbox">
                <input
                  id="geolocation"
                  type="checkbox"
                  {...register("geolocation", {
                    onChange: (e) =>
                      handleOnChangeHasGeolocation(e.target.checked),
                  })}
                />
                Informação possui geolocalização?
              </label>

              <Controller
                control={control}
                name="address"
                rules={{
                  ...(hasGeolocation
                    ? { required: "Informe a localização" }
                    : {}),
                }}
                render={({ field: { value, onChange } }) => (
                  <div className="map-container">
                    <MapCreate
                      position={value}
                      handleSetPosition={onChange}
                      interactive={hasGeolocation}
                    />

                    {!hasGeolocation ? (
                      <div className="map-disabled">
                        <span>
                          Habilite a opção acima para informar a localização
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}
              />

              {hasGeolocation && errors.address ? (
                <HelpBlock>{errors.address.message}</HelpBlock>
              ) : null}
            </div>
          ) : null}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button fill onClick={() => handleOnClose()}>
          Cancelar
        </Button>

        <Button
          bsStyle="success"
          pullRight
          fill
          form="form-update-info"
          type="submit"
        >
          Atualizar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
