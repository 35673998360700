import React from 'react';
import { Modal } from "react-bootstrap";
import { usePromiseTracker } from 'react-promise-tracker';
import Spinner from 'react-spinkit';

const LoadingModal = () => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    <Modal
      backdrop='static'
      show={promiseInProgress}
      dialogClassName='loading-modal'
      centered='true'
    >
      <Spinner
        name='ball-clip-rotate-multiple'
        fadeIn='none'
        color='blue'
      />
    </Modal>
  );
};



export default LoadingModal;