import React, { Component } from "react";

export class Card extends Component {
  render() {
    return (
      <div className={"card" + (this.props.plain ? " card-plain" : "")}>
        <div className={"header" + (this.props.hCenter ? " text-center" : "")}>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <h5 className="title">{this.props.title}</h5>
            {this.props.extraTitleContent && <div>{this.props.extraTitleContent}</div>}
          </div>
          <p className="category">{this.props.category}</p>
        </div>
        <div
          className={
            "content" +
            (this.props.ctAllIcons ? " all-icons" : "") +
            (this.props.ctTableFullWidth ? " table-full-width" : "") +
            (this.props.ctTableResponsive ? " table-responsive" : "") +
            (this.props.ctTableUpgrade ? " table-upgrade" : "")
          }
        >
          {this.props.content}

          {(this.props.legend || this.props.stats) &&
            <div className="footer">
              {this.props.legend}
              {this.props.stats ?
                <>
                  <hr />
                  <div className="stats">
                    <i className={this.props.statsIcon} /> {this.props.stats}
                  </div>
                </> : null
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

export default Card;
