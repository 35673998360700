import Card from "components/Card/Card.jsx";
//import Pagination from 'react-bootstrap/Pagination';
//import PageItem from 'react-bootstrap/PageItem';
import Button from 'components/CustomButton/CustomButton.jsx';
import React, { Component } from "react";
import { Col, Grid, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Pagination from "react-js-pagination";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import api from "../../services/api.jsx";
import Store from "./create.jsx";
import Update from "./update.jsx";
import LinkAdvisorCandidate from "./linkAdvisorCandidate.jsx";
import UnlinkAdvisorCandidate from "./unlinkAdvisorCandidate.jsx";
import { trackPromise } from "react-promise-tracker";
import Confirm from "components/Confirm/Confirm.jsx";

class Advisors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      callback:undefined,
      confirmText:"",
      confirmCondition:false,
      advisors: [],
      advisor: [],
      idApplication: localStorage.getItem('idApp'),
      configPagination: {
        activePage: null,
        itemsCountPerPage: null,
        totalItemsCount: 1,
        pageRangeDisplayed: 5
      },
      isModalVisibleEdit: false,
      isModalVisibleNew: false,
    };
  }

  componentDidMount() {
    this.loadAdvisors();
  }

  loadAdvisors = async (page = 1) => {

    const idApplication = this.state.idApplication;

    const response = await trackPromise(api.get(`/advisors?by_application=${idApplication}&paginate=1&page=${page}`));

    if (page) {
      this.setState({
        configPagination: {
          activePage: response.data.meta.current_page,
          itemsCountPerPage: response.data.meta.per_page,
          totalItemsCount: response.data.meta.total,
          pageRangeDisplayed: 5
        }
      });
    }
    this.setState({ advisors: response.data.data });
  };

  editAdvisor(value) {
    this.setState({ advisor: value, isModalVisibleEdit: true });
  }
  linkAdvisor(value) {
    this.setState({ advisor: value, isModalVisibleLink: true });
  }
  unlinkAdvisor(value) {
    this.setState({ advisor: value, isModalVisibleUnlink: true });
  }

  deleteAdvisor = (id) => {
      api.delete(`/users/${id}`)
        .then(response => {
          this.loadAdvisors();
          this.handleNotificationClick('Coordenador excluido com sucesso!', 'success');
        })
        .catch((error) => {
          console.log(error.message);
          this.handleNotificationClick('Erro na exclusão do assessor! Tente novamente!', 'error');
        });
  };
  resetPassword = id => {
    api.put(`/users/${id}/reset_password`)
      .then(response => {
        this.loadAdvisors();
        this.handleNotificationClick('Senha resetada com sucesso!', 'success');
      })
      .catch((error) => {
        console.log(error.message);
        if (error.response.data.errors.user) {
          this.handleNotificationClick(error.response.data.errors.user[0], 'error');
        }
      });
  };
  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: (
        <div>
          {message}
        </div>
      ),
      level: color,
      position: 'tr',
      autoDismiss: 5,
      dismissible: true
    });
  };

  render() {
    const { advisors, isModalVisibleNew, configPagination, isModalVisibleEdit, isModalVisibleLink, isModalVisibleUnlink } = this.state;

    const remove = (<Tooltip id="remove_tooltip">Excluir</Tooltip>);
    const resetePassword = (<Tooltip id="password_tooltip">Resetar Senha</Tooltip>);
    const edit = (<Tooltip id="edit_tooltip">Editar/Visualizar</Tooltip>);
    const link = (<Tooltip id="edit_tooltip">Associar Candidato</Tooltip>);
    const unlink = (<Tooltip id="edit_tooltip">Desassociar Candidato</Tooltip>);

    return (
      <>
      <div className="content">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Button title="cadastrar novo assessor" bsStyle="success" fixMargin pullRight fill onClick={() => this.setState({ isModalVisibleNew: true })}>
                <i className="fa fa-plus"></i> Novo cadastro</Button>
              <Card
                title="Coordenadores"
                category="Gerenciamento de assessores"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <>

                    <Table striped hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nome</th>
                          <th>E-mail</th>
                          <th>Candidatos</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          advisors.map(adv => (
                            <tr key={adv.id}>
                              <td>{adv.id}</td>
                              <td>{adv.name}</td>
                              <td>{adv.email}</td>
                              <td>

                                {
                                  adv.candidates.length === 0 ? 'nenhum candidato vinculado' :
                                    <span>{adv.candidates.length === 1 ? `${adv.candidates.length} Candidato` : `${adv.candidates.length} Candidatos`}</span>
                                }

                              </td>
                              <td>
                              <OverlayTrigger placement="top" overlay={link}>
                                  <Button
                                    bsStyle="info"
                                    simple
                                    type="button"
                                    bsSize="xs"
                                    onClick={() => this.linkAdvisor(adv)}
                                  >
                                    <i className="fa fa-user-plus"></i>
                                  </Button>
                              </OverlayTrigger>
                              <OverlayTrigger placement="top" overlay={unlink}>
                                  <Button
                                    bsStyle="danger"
                                    simple
                                    type="button"
                                    bsSize="xs"
                                    onClick={() => this.unlinkAdvisor(adv)}
                                  >
                                    <i className="fa fa-user-times"></i>
                                  </Button>
                              </OverlayTrigger>
                              
                                <OverlayTrigger placement="top" overlay={edit}>
                                  <Button
                                    bsStyle="info"
                                    simple
                                    type="button"
                                    bsSize="xs"
                                    onClick={() => this.editAdvisor(adv)}
                                  >
                                    <i className="fa fa-edit"></i>
                                    Editar/Visualizar
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger placement="top" overlay={resetePassword}>
                                  <Button
                                    bsStyle="warning"
                                    simple
                                    type="button"
                                    bsSize="xs"
                                    onClick={() => this.resetPassword(adv.id)}
                                  >
                                    <i className="fa fa-unlock-alt"></i>
                                    Resetar senha
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger placement="top" overlay={remove}>
                                  <Button
                                    bsStyle="danger"
                                    simple
                                    type="button"
                                    bsSize="xs"
                                    onClick={() => this.setState({
                                      callback:()=> this.deleteAdvisor(adv.id),
                                      confirmText:`excluir ${adv.name}`,
                                      confirmCondition:true
                                    })}
                                  >
                                    <i className="fa fa-times"></i>
                                    Excluir
                                  </Button>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          ))
                        }

                      </tbody>
                    </Table>
                    {isModalVisibleLink &&
                      <LinkAdvisorCandidate
                        onClose={() => {
                          this.setState({ isModalVisibleLink: false });
                          this.loadAdvisors();
                        }}
                        advisor={this.state.advisor}
                        idAdvisor={this.state.advisor.id}
                        idApp={this.state.idApplication}
                      />
                    }
                    {isModalVisibleUnlink &&
                      <UnlinkAdvisorCandidate
                        onClose={() => {
                          this.setState({ isModalVisibleUnlink: false });
                          this.loadAdvisors();
                        }}
                        advisor={this.state.advisor}
                        idAdvisor={this.state.advisor.id}
                        idApp={this.state.idApplication}
                      />
                    }
                    {isModalVisibleEdit &&
                      <Update
                        onClose={() => {
                          this.setState({ isModalVisibleEdit: false });
                          this.loadAdvisors();
                        }}
                        advisor={this.state.advisor}
                        idAdvisor={this.state.advisor.id}
                        idApp={this.state.idApplication}
                      />
                    }
                    {isModalVisibleNew &&
                      <Store
                        onClose={() => { this.setState({ isModalVisibleNew: false }); this.loadAdvisors(); }}
                      />
                    }
                    <div style={{ textAlign: "center" }}>
                      <Pagination
                        activePage={configPagination.activePage}
                        itemsCountPerPage={configPagination.itemsCountPerPage}
                        totalItemsCount={configPagination.totalItemsCount}
                        pageRangeDisplayed={configPagination.pageRangeDisplayed}
                        onChange={(page) => {
                          this.loadAdvisors(page);
                        }}
                      />
                    </div>
                  </>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
      <Confirm  
      callback={this.state.callback} 
      text={this.state.confirmText} 
      show={this.state.confirmCondition}
      close={()=>this.setState({
        callback:undefined,
        confirmText:"",
        confirmCondition:false,
      })}
      />
      </>
    );
  }
}

export default Advisors;