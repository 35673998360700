import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ArrowLeft } from "@phosphor-icons/react";

import { useQueryParams } from "hooks/useQueryParams";

import { TutorialVideoModal } from "components/TutorialModal/TutorialModal";
import "./styles.css";

export function HeaderInfos() {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOnCloseModal = () => {
    setOpenModal(false);
  };


  const queryParams = useQueryParams();

  function handleOpenCreateGroup() {
    queryParams.set("open_create_group", "open");

    history.push({ pathname: "/admin/infos", search: queryParams.toString() });
  }

  function handleOpenCreateInfo() {
    queryParams.set("open_create_info", "open");

    history.push({ pathname: "/admin/infos", search: queryParams.toString() });
  }

  const groupName = queryParams.get("group_name");

  return (
    <header className="infos-header">
      <div style={{ display: "flex", justifyContent: "flex-start", gap: "5px" }}>
        <h3>
          {groupName
            ? `${groupName} | Grupos e informações`
            : "Grupos e informações"}
        </h3>
        <i
          className="fa fa-question-circle"
          style={{ fontSize: "18px", cursor: "pointer" }}
          title="Assista ao vídeo tutorial dessa área"
          onClick={handleOpenModal}
        />
      </div>

      <div className="infos-buttons">
        {groupName ? (
          <button className="info-btn-outline" onClick={() => history.goBack()}>
            <ArrowLeft size={16} />
            Retornar
          </button>
        ) : null}

        <button className="info-btn-action" onClick={handleOpenCreateGroup}>
          Adicionar grupo
        </button>

        <button
          className="info-btn-action"
          onClick={handleOpenCreateInfo}
          disabled={!queryParams.get("group_id")}
        >
          Adicionar informação
        </button>
      </div>

      <TutorialVideoModal
        isOpen={openModal}
        onClose={handleOnCloseModal}
        title={"Informações e redes sociais"}
        linkTitle={"Informações e redes sociais"}
        link={"https://www.youtube.com/embed/xy_c5wy33C4?si=s7TL1-S_zHUIOsBM"}
      />
    </header>
  );
}
