import React, { useState, useEffect } from "react";
import {
    Modal,
} from "react-bootstrap";

import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import api from "services/api.jsx";

//import AlertCustom from "components/CustomAlert/Alert.jsx";

const Store = ({ onClose = () => { }, id_research }) => {

    const [show, setShow] = useState(true);
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    // eslint-disable-next-line
    const [errorText, setErrorText] = useState('');

    const [title, setTitle] = useState();
    const [type, setType] = useState()
    const [description, setDescription] = useState();
    const [constants, setConstants] = useState([]);

    const handleClose = () => {
        onClose();
        setShow(false);
    };

    //const handleShow = () => setShow(true);

    useEffect(() => { loadConstants() }, [])

    const loadConstants = async () => {
        try {
            const response = await api.get(`/questions/constants`);

            setConstants(response.data.data.types);
        } catch (e) {
            console.log('error ao buscar as constantes', e)
        }
    }
    const submitHandler = (e) => {
        e.preventDefault();

        const formData = {
            title,
            description,
            type,
        };

        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        api.post(`/${localStorage.getItem('idApp')}/researches/${id_research}/questions`, formData, config)
            .then(response => {
                onClose();
            })
            .catch((error) => {
                console.log(error)
                setIsAlertVisible(true);

            });

    };

    return (
        <>
            <Modal
                size="sm"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Cadastro de pergunta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submitHandler} encType="multipart/form-data">
                        {isAlertVisible && <div className="alert alert-danger">Error no cadastro da pergunta!<br />ERROR: {errorText}</div>}
                        <FormInputs
                            ncols={["col-md-12"]}
                            properties={[
                                {
                                    label: "Título",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Título",
                                    required: true,
                                    defaultValue: title,
                                    name: 'title',
                                    onChange: event => setTitle(event.target.value)
                                }
                            ]}
                        />
                        <div className="form-group">
                            <label>Descrição</label>
                            <textarea className="form-control" required rows="5" name="description" placeholder="Descrição da pergunta" onChange={event => setDescription(event.target.value)}></textarea>
                        </div>

                        <div className="form-group">
                            <label className="">Tipo da pergunta</label>
                            <select
                                name="isActive"
                                defaultValue={type}
                                onChange={event => setType(event.target.value)}
                                className="form-control"
                                required
                            >
                                <option value="">Selecione</option>
                                {Object.entries(constants).map(([key, value]) => (
                                    <option key={value} value={value}>
                                      {value === "string" && "Pergunta com resposta descritiva"}
                                      {value === "int" && "Pergunta com resposta em valor inteiro ex: (100)"}
                                      {value === "float" && "Pergunta com resposta em valor real ex: (3,25)"}
                                      {value === "checkbox" && "Pergunta com múltipla escolha"}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <Button bsStyle="danger" fill onClick={() => onClose()}>
                            Cancelar
                        </Button>
                        <Button bsStyle="success" pullRight fill type="submit">
                            Salvar
                        </Button>
                        <div className="clearfix" />
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Store;
