import React from "react";
import { Modal } from "react-bootstrap";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function ImagesModal({ setModalClose, showModal, voter }) {
	const handleClose = () => {
		setModalClose();
	};

	return (
		<Modal
			size="lg"
			show={showModal}
			onHide={handleClose}
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header closeButton>
				<Modal.Title>Imagens</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="image-gallery">
					{voter && voter.images.filter(img => img?.step_number === voter.voterGoal?.step_number).map((img, index) => (
						<Zoom key={index}>
							<img
								src={img?.image}
								alt={`Goal ${voter.voter_goal?.step_number} - Image ${index + 1}`}
								className="styled-image"
							/>
						</Zoom>
					))}
				</div>
			</Modal.Body>
		</Modal>
	);
}
