import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { TutorialVideoModal } from "components/TutorialModal/TutorialModal.jsx";
import NotificationSystem from "react-notification-system";
import api from "../../services/api.jsx";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { FiInfo, FiXCircle, FiTrash } from "react-icons/fi";
import { trackPromise } from "react-promise-tracker";
import { style } from "variables/Variables.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import "./styles.css";
import emptyImage from "assets/img/sem_imagem.jpg";
import swal from "sweetalert";

/* 
TODO: 
- Remover valor do campo file ao alterar item de menu.
- Funcão de fechamento da edição.
- Esvaziar preview image ao alterar item menu.

*/
class MenuApplication extends Component {
  sizes = [
    { value: 1, label: "01 / 12" },
    { value: 2, label: "02 / 12" },
    { value: 3, label: "03 / 12" },
    { value: 4, label: "04 / 12" },
    { value: 5, label: "05 / 12" },
    { value: 6, label: "06 / 12" },
    { value: 7, label: "07 / 12" },
    { value: 8, label: "08 / 12" },
    { value: 9, label: "09 / 12" },
    { value: 10, label: "10 / 12" },
    { value: 11, label: "11 / 12" },
    { value: 12, label: "12 / 12" },
  ];
  types = [
    { value: "banner", label: "Banner" },
    { value: "events", label: "Eventos" },
    { value: "news", label: "Noticias" },
    { value: "socialNetworks", label: "Rede Social" },
    { value: "survey", label: "Pesquisas" },
  ];
  openTypes = [
    { value: "1", label: "Interna no aplicativo" },
    { value: "0", label: "Externo no navegador" },
  ];

  constructor(props) {
    super(props);
    this.state = {
      itemList: [],
      itemSelected: null,
      itemSelectedBackUp: null,
      itemSelectedIndex: null,
      previewPicture: null,
      imageInput: null,
      isModalVisible: false,
    };
  }

  openComunicationModal = () => {
    this.setState({ isModalVisible: true });
  };

  componentDidMount() {
    this.loadItensMenu();
  }

  submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    let itemClone = Object.assign({}, this.state.itemSelected);

    if (
      this.state.itemSelected.type == "news" ||
      this.state.itemSelected.type == "events" ||
      this.state.itemSelected.type == "socialNetworks"
    ) {
      itemClone.size = 12;
      itemClone.link = "";
      itemClone.internal_open = "1";
      itemClone.image = "";
    }

    formData.append("size", itemClone.size);
    formData.append("title", itemClone.title);
    formData.append("type", itemClone.type);
    formData.append("link", itemClone.link ? itemClone.link : "");

    formData.append("internal_open", itemClone.internal_open);
    formData.append("visibility", itemClone.visibility);

    if (typeof itemClone.image == "object" && itemClone.image != null)
      formData.append("image", itemClone.image);
    else if (!itemClone.image) formData.append("image", "");

    formData.append("_method", "PUT");

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    trackPromise(
      api.post(
        `/${localStorage.getItem("idApp")}/grids/${this.state.itemSelected.id}`,
        formData,
        config
      )
    )
      .then((response) => {
        this.handleNotificationClick("Salvo com sucesso!", "success");
        const newPos = this.state.itemList.indexOf(this.state.itemSelected);
        if (this.state.itemSelectedIndex != newPos) {
          this.submitChangePosition();
        }
        let newElement = Object.assign({}, this.state.itemSelected);
        newElement.image = response.data.image;
        this.setState({
          itemSelectedBackUp: newElement,
          itemSelectedIndex: newPos,
        });
      })
      .catch(() => {
        this.handleNotificationClick("Erro ao salvar o item da GRID!", "error");
      });
  };

  submitChangePosition = async () => {
    let listPositions = {};
    this.state.itemList.forEach((element, index) => {
      listPositions[element.id] = index + 1;
    });

    trackPromise(
      api.post(`${localStorage.getItem("idApp")}/grids/reorder`, listPositions)
    );
  };

  submitRemoveItem = async () => {
    swal({
      title: "Atenção!",
      text: `Deseja deletar o item menu ${this.state.itemSelected.title}?`,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        trackPromise(
          api.delete(
            `${localStorage.getItem("idApp")}/grids/${this.state.itemSelected.id
            }`
          )
        );
        this.state.itemList.splice(this.state.itemSelectedIndex);
        this.setState({
          itemList: [...this.state.itemList],
          itemSelected: null,
          itemSelectedBackUp: null,
          itemSelectedIndex: null,
          previewPicture: null,
          imageInput: null,
        });
      }
    });
  };

  createNewElement = async (e) => {
    e.preventDefault();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    const formData = new FormData();

    formData.append("title", "Item");
    formData.append("size", 12);
    formData.append("type", "news");
    formData.append("link", "");
    formData.append("internal_open", "1");
    formData.append("visibility", "1");

    const response = await trackPromise(
      api.post(`${localStorage.getItem("idApp")}/grids`, formData, config)
    );
    this.setState({
      itemList: [...this.state.itemList, response.data],
    });
    this.handleItemMenuClick(response.data);
  };

  loadItensMenu = async () => {
    try {
      const response = await trackPromise(
        api.get(`${localStorage.getItem("idApp")}/grids`)
      );

      this.setState({
        itemList: response.data.map((item) => {
          item.internal_open = item.internal_open === true ? "1" : "0";
          item.visibility = item.visibility === true ? "1" : "0";
          return item;
        }),
      });
    } catch (e) {
      this.handleNotificationClick(e.response.data.message, "error");
    }
  };

  reorderItemList(newPosition) {
    const currentIndex = this.state.itemList.indexOf(this.state.itemSelected);

    if (currentIndex !== -1) {
      const elementoRemovido = this.state.itemList.splice(currentIndex, 1)[0];
      let items = [...this.state.itemList];
      items.splice(newPosition, 0, elementoRemovido);
      return items;
    }
  }

  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: <div>{message}</div>,
      level: color,
      position: "tr",
      autoDismiss: 1,
      dismissible: true,
    });
  };

  handleItemMenuClick = (item) => {
    if (this.state.itemSelected && item.id === this.state.itemSelected.id)
      return;

    let itemListOrdered;
    if (this.state.itemSelectedIndex === 0 || this.state.itemSelectedIndex) {
      itemListOrdered = this.reorderItemList(this.state.itemSelectedIndex);
    } else {
      itemListOrdered = this.state.itemList;
    }
    let indexSelected;
    this.setState({
      itemList: [
        ...itemListOrdered.map((listElement, index) => {
          if (listElement.id !== item.id) listElement.selected = false;
          else {
            listElement.selected = true;
            indexSelected = index;
          }

          if (
            this.state.itemSelectedBackUp &&
            listElement.id === this.state.itemSelectedBackUp.id
          ) {
            this.state.itemSelectedBackUp.selected = false;
            return this.state.itemSelectedBackUp;
          }

          return listElement;
        }),
      ],
    });

    this.setState({
      previewPicture: null,
      imageInput: null,
      itemSelectedBackUp: Object.assign({}, item),
      itemSelected: item,
      itemSelectedIndex: indexSelected,
    });
  };

  changeSelectedValue(field, value) {
    this.state.itemSelected[field] = value;
    if (field == "image" && value && !this.state.previewPicture) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.setState({
          itemSelected: this.state.itemSelected,
          previewPicture: fileReader.result,
        });
      };
      fileReader.readAsDataURL(value);
    }
    if (this.state.previewPicture) {
      this.setState({
        imageInput: null,
        previewPicture: null,
      });
    } else {
      if (field == "type" && (value == "news" || value == "events" || value == "socialNetworks" || value == "survey"))
        this.state.itemSelected.size = 12;
      this.setState({
        itemSelected: this.state.itemSelected,
      });
    }
  }

  handleRemoveImage() {
    if (this.state.previewPicture) {
      this.changeSelectedValue("image", this.state.itemSelectedBackUp.image);
      this.setState({
        previewPicture: null,
        imageInput: null,
      });
    } else this.changeSelectedValue("image", null);
  }

  blockComponent = (props) => {
    return (
      <label
        className={`block-element ${props.selected && "block-element-selected"
          }`}
        style={{
          gridColumn: `span ${props.size} / span ${props.size}`,
        }}
        key={props.id}
        onClick={() => this.handleItemMenuClick(props)}
      >
        <div className="overflow-elypsis">{props.title}</div>
      </label>
    );
  };

  render() {
    const { itemList, itemSelected, previewPicture, imageInput, isModalVisible } = this.state;
    return (
      <>
        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Button
                  title="cadastrar nova applicação"
                  style={{ marginTop: "15px", marginRight: "15px" }}
                  bsStyle="success"
                  fixMargin
                  pullRight
                  fill
                  onClick={this.createNewElement}
                >
                  <i className="fa fa-plus"></i> Novo item no GRID
                </Button>
                <Card
                  title="GRID"
                  extraTitleContent={
                    <i
                      className="fa fa-question-circle"
                      style={{ fontSize: "18px", cursor: "pointer" }}
                      title="Assista ao vídeo tutorial dessa área"
                      onClick={this.openComunicationModal}
                    />
                  }
                  category="Gerenciamento de grid da aplicação"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <>
                      <div className="content-main">
                        <div className="iphone-x">
                          <div className="size-control">
                            <div className="main-element">
                              {itemList &&
                                itemList.map((item) =>
                                  this.blockComponent(item)
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="content-form">
                          <div className="selected-box">
                            {!itemSelected && (
                              <>
                                {" "}
                                <FiInfo size={20} />
                                <span>
                                  Selecione ou crie um item de menu para editar!
                                </span>
                              </>
                            )}
                            {itemSelected && (
                              <>
                                <form
                                  onSubmit={this.submitHandler}
                                  encType="multipart/form-data"
                                >
                                  <FormInputs
                                    ncols={["col-md-12"]}
                                    properties={[
                                      {
                                        label: "Titulo",
                                        type: "text",
                                        bsClass: "form-control",
                                        placeholder: "Titulo",
                                        required: true,
                                        value: itemSelected.title,
                                        name: "title",
                                        onChange: (event) => {
                                          this.changeSelectedValue(
                                            "title",
                                            event.target.value
                                          );
                                        },
                                      },
                                    ]}
                                  />
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label>Tipo do item</label>
                                        <select
                                          name="associar"
                                          value={itemSelected.type}
                                          onChange={(selectedOption) => {
                                            this.changeSelectedValue(
                                              "type",
                                              selectedOption.target.value
                                            );
                                          }}
                                          className="form-control"
                                        >
                                          {this.types.map((el) => (
                                            <option
                                              key={el.value}
                                              value={el.value}
                                            >
                                              {el.label}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    {!(
                                      itemSelected.type == "news" ||
                                      itemSelected.type == "events" ||
                                      itemSelected.type == "survey" ||
                                      itemSelected.type == "socialNetworks"
                                    ) && (
                                        <div className="col-md-4">
                                          <div className="form-group">
                                            <label>Largura</label>
                                            <select
                                              name="associar"
                                              value={itemSelected.size}
                                              onChange={(selectedOption) => {
                                                this.changeSelectedValue(
                                                  "size",
                                                  selectedOption.target.value
                                                );
                                              }}
                                              className="form-control"
                                            >
                                              {this.sizes.map((el) => (
                                                <option
                                                  key={el.value}
                                                  value={el.value}
                                                >
                                                  {el.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                      )}
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label>Posicionamento</label>
                                        <select
                                          name="associar"
                                          value={itemList.indexOf(itemSelected)}
                                          onChange={(selectedIndex) => {
                                            this.setState({
                                              itemList: this.reorderItemList(
                                                selectedIndex.target.value
                                              ),
                                            });
                                          }}
                                          className="form-control"
                                        >
                                          {itemList.map((el, index) => (
                                            <option key={index} value={index}>
                                              {index + 1} / {itemList.length}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  {!(
                                    itemSelected.type == "news" ||
                                    itemSelected.type == "survey" ||
                                    itemSelected.type == "socialNetworks" ||
                                    itemSelected.type == "events"
                                  ) && (
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>Link</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              value={itemSelected.link}
                                              onChange={(event) => {
                                                this.changeSelectedValue(
                                                  "link",
                                                  event.target.value
                                                );
                                              }}
                                            ></input>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>Forma de abertura</label>
                                            <br></br>
                                            <select
                                              name="internal_open"
                                              value={itemSelected.internal_open}
                                              onChange={(selectedOption) => {
                                                this.changeSelectedValue(
                                                  "internal_open",
                                                  selectedOption.target.value
                                                );
                                              }}
                                              className="form-control"
                                            >
                                              {this.openTypes.map((el) => (
                                                <option
                                                  key={el.value}
                                                  value={el.value}
                                                >
                                                  {el.label}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  {this.state.itemSelected.type == "banner" && (
                                    <>
                                      {" "}
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>Imagem</label>
                                            <input
                                              label="Imagem"
                                              type="file"
                                              className="form-control"
                                              name="image"
                                              value={imageInput}
                                              onChange={(event) => {
                                                this.changeSelectedValue(
                                                  "image",
                                                  event.target.files[0]
                                                );
                                              }}
                                            ></input>
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <label>Preview</label>
                                            <div className="preview-group">
                                              {(previewPicture ||
                                                itemSelected.image) && (
                                                  <FiTrash
                                                    size={20}
                                                    className="close-button"
                                                    onClick={() =>
                                                      this.handleRemoveImage()
                                                    }
                                                  ></FiTrash>
                                                )}
                                              <img
                                                src={
                                                  previewPicture
                                                    ? previewPicture
                                                    : itemSelected.image
                                                      ? itemSelected.image
                                                      : emptyImage
                                                }
                                                className="img-thumbnail"
                                              ></img>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  <Button
                                    bsStyle="danger"
                                    onClick={() => {
                                      this.submitRemoveItem();
                                    }}
                                  >
                                    Excluir
                                  </Button>
                                  <Button
                                    bsStyle="primary"
                                    pullRight
                                    fill
                                    type="submit"
                                  >
                                    Salvar
                                  </Button>
                                  <div className="clearfix" />
                                </form>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <TutorialVideoModal
          isOpen={isModalVisible}
          onClose={() => this.setState({ isModalVisible: false })}
          link={"https://www.youtube.com/embed/NhiVpOE73LE?si=RdH7cd5W1RkieZGz"}
          linkTitle={"Configurações gerais"}
          title={"Configurações gerais"} />
      </>
    );
  }
}

export default MenuApplication;
