import React from "react";
import { trackPromise } from "react-promise-tracker";
import { Col, Modal, Row } from "react-bootstrap";

import api from "services/api";

export function SetGoal({
  id,
  goal,
  show,
  onClose,
  updateLeaders,
  showNotification,
}) {
  const appId = localStorage.getItem("idApp");

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const data = {
      leader_id: id,
      goal: event.target.goal.value,
    };

    trackPromise(
      api
        .post(`${appId}/leaders/set_leader_goal`, data)
        .then(() => {
          updateLeaders();

          showNotification("Meta do líder atualizada com sucesso!", "success");

          onClose();
        })
        .catch((err) => {
          console.error(err);

          showNotification(`Erro! ${err.response.data.message}`, "error");
        })
    );
  }

  return (
    <>
      <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar meta</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form id="dataForm" onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <label htmlFor="goal"> Meta </label>
                <input
                  type="number"
                  id="goal"
                  placeholder="Meta"
                  className="form-control"
                  defaultValue={goal ? goal : ""}
                />
              </Col>
            </Row>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="submit"
            form="dataForm"
            className="btn-fill btn-card btn btn-primary"
          >
            Enviar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
