import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import api from "services/api.jsx";

export function Notifcation({ onClose = () => {}, voterId }) {
  const [show, setShow] = useState(true);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [link, setLink] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState("");

  const appId = localStorage.getItem("idApp");

  function handleClose() {
    onClose();
    setShow(false);
  }

  function titleChangeHandle(event) {
    setTitle(event.target.value);
  }

  function contentChangeHandle(event) {
    setContent(event.target.value);
  }

  function linkChangeHandle(event) {
    setLink(event.target.value);
  }

  function sendNotification() {
    let data = {};

    if (!title) {
      setError(true);
      return false;
    }

    setLoading(true);

    data.title = title;

    if (content) data.content = content;
    if (link) data.link = link;

    api
      .post(`${appId}/send_notification_to_user/${voterId}`, data)
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  }

  function Loading() {
    return (
      <div
        style={{
          width: "100%",
          height: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{ fontSize: "20px" }}> Enviando mensagem... </p>
      </div>
    );
  }

  function Success() {
    return (
      <div
        style={{
          width: "100%",
          height: "300px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{ width: "100%", textAlign: "center" }}
          className="alert alert-success"
        >
          <b> Mensagem enviada. </b>
        </div>
        <br />
        <button
          onClick={handleClose}
          className="btn-fill btn-card btn btn-primary"
        >
          Sair
        </button>
      </div>
    );
  }

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> Notificação </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading && <Loading />}
          {success && <Success />}
          {!loading && !success && (
            <div>
              <div>
                <label htmlFor="title"> Título (obrigatório) </label>
                <input
                  onChange={titleChangeHandle}
                  id="title"
                  type="text"
                  placeholder="Título"
                  className="form-control"
                />
              </div>

              <br />

              <div>
                <label htmlFor="title"> Conteúdo </label>
                <textarea
                  onChange={contentChangeHandle}
                  id="title"
                  type="text"
                  placeholder="Conteúdo"
                  className="form-control"
                ></textarea>
              </div>

              <br />

              <div>
                <label htmlFor="link"> Link </label>
                <input
                  onChange={linkChangeHandle}
                  id="link"
                  type="text"
                  placeholder="Link"
                  className="form-control"
                />
              </div>

              <br />

              <div>
                <button
                  onClick={sendNotification}
                  className="btn-fill btn-card btn btn-primary"
                >
                  Enviar
                </button>
              </div>

              <br />

              {error && (
                <div className="alert alert-danger">
                  O título da notificação é obrigatório.
                </div>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
