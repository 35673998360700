import { useMemo, useState } from "react";
import { useAtom, useSetAtom } from "jotai";
import { CameraPlus, ArrowRight } from "@phosphor-icons/react";

import { FormDataAtom } from "components/Onboarding/atoms/FormDataAtom";
import { CurrentStepAtom } from "components/Onboarding/atoms/CurrentStepAtom";

import "./styles.css";

export function ProfileImageStep() {
  const [formData, setFormData] = useAtom(FormDataAtom);
  const setCurrentStep = useSetAtom(CurrentStepAtom);

  const [profileImage, setProfileImage] = useState(formData.profile ?? null);

  function handleNextStep() {
    setFormData((prev) => ({
      ...prev,
      profile: profileImage,
    }));

    setCurrentStep((prev) => prev + 1);
  }

  function handleSelectedImage(file) {
    if (!file) {
      return;
    }

    setProfileImage(file);
  }

  const imagePath = useMemo(
    () => (profileImage ? URL.createObjectURL(profileImage) : ""),
    [profileImage]
  );

  return (
    <div className="content profile-step">
      <div className="heading">
        <strong>Escolha a foto do perfil </strong>
        <span>
          Você poderá alterar essa imagem após essa primeira configuração
        </span>
      </div>

      <div className="field-profile-image">
        <label htmlFor="profile">
          {!!imagePath ? <img src={imagePath} alt="" /> : null}
          <CameraPlus size={64} color="#727272" className="icon" />
        </label>

        <input
          id="profile"
          name="profile"
          type="file"
          style={{ display: "none" }}
          onChange={(e) => handleSelectedImage(e.target.files[0])}
        />

        <div>
          <strong>Clique ou arraste a foto aqui</strong>
          <span>Tamanho recomendado: 1024x1024 px</span>
        </div>
      </div>

      <button disabled={!profileImage} onClick={handleNextStep}>
        Avançar
        <ArrowRight size={20} weight="bold" />
      </button>
    </div>
  );
}
