import React, { useEffect, useMemo } from "react";
import Select from "react-select";
import { useHistory } from "react-router-dom";

import { useCandidates } from "views/infos/hooks/useCandidates";
import { useQueryParams } from "hooks/useQueryParams";

import "./styles.css";

export function FiltersInfos() {
  const appId = localStorage.getItem("idApp");

  const history = useHistory();
  const queryParams = useQueryParams();

  const { candidates } = useCandidates({ appId });

  useEffect(() => {
    if (candidates.length === 1) {
      handleOnChangeCandidate(candidates[0].value);
    }
  }, [candidates]);

  function handleOnChangeSearch(value) {
    if (value) {
      queryParams.set("search", value);
    } else {
      queryParams.delete("search");
    }

    history.push({ pathname: "/admin/infos", search: queryParams.toString() });
  }

  function handleOnChangeCandidate(value) {
    history.push({
      pathname: "/admin/infos",
      ...(value ? { search: `candidate_id=${value}` } : {}),
    });
  }

  const candidateSelected = useMemo(() => {
    if (queryParams.get("candidate_id")) {
      return candidates.find(
        (item) => item.value === queryParams.get("candidate_id")
      );
    }
  }, [candidates, queryParams.get("candidate_id")]);

  return (
    <div className="infos-filters">
      <div className="infos-form-input">
        <label>Título</label>
        <input
          className="form-control info-input-title"
          name="voterName"
          placeholder="Buscar grupo ou informação por título"
          value={queryParams.get("search") ? queryParams.get("search") : ""}
          onChange={(event) => handleOnChangeSearch(event.target.value)}
        />
      </div>

      <div className="infos-form-input">
        <label>Selecione o candidato</label>
        <Select
          name="candidate"
          options={candidates}
          placeholder="Selecione o candidato"
          noOptionsMessage={() => "Dados não encontrados"}
          isDisabled={!candidates.length}
          isSearchable
          isClearable
          value={candidateSelected}
          onChange={(option) =>
            handleOnChangeCandidate(option ? option.value : undefined)
          }
        />
      </div>
    </div>
  );
}
