import React from "react";
import "./styles.css";

export function EmptyState() {
  return (
    <div className="infos-empty-state">
      <strong>Não há grupos ou informações cadastradas</strong>

      <p>
        Parece que não há informações disponíveis no momento. Por favor,
        verifique novamente mais tarde ou realize uma ação para gerar as
        informações necessárias.
      </p>
    </div>
  );
}
