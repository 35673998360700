import React, { useState } from 'react';

import './custom-checkbox-styles.css';

const CustomCheckbox = ({ readOnly, isChecked, color }) => {
  return (
    <label className={`custom-checkbox ${isChecked ? 'checked' : ''}`} style={{ borderColor: isChecked ? color : '#ccc' }}>
      <input
        type="checkbox"
        checked={isChecked}
        readOnly={readOnly}
      />
      <span className="checkmark" style={{ backgroundColor: isChecked ? color : 'transparent' }}>
        {isChecked && <i className="fa fa-check" aria-hidden="true"></i>}
      </span>
    </label>
  );
};

export default CustomCheckbox;