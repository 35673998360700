import React from "react";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { Link } from "react-router-dom";
import { Col, Grid, Row, Table } from "react-bootstrap";
import EditButton from "components/EditButton/EditButton";
import RemoveButton from "components/RemoveButton/RemoveButton";
import Store from "./store";
import Update from "./update";
import { trackPromise } from "react-promise-tracker";
import api from "services/api";

export const participationStatus = new Map([
  ["open", "Em aberto"],
  ["attendance", "Em apreciação"],
  ["closed", "Encerrado"],
]);

const Categories = ({ match }) => {
  const appId = localStorage.getItem("idApp");
  const { areaId } = match.params;
  const [categories, setCategories] = React.useState([
    { id: 1, name: "Teste", area: { id: 1 } },
  ]);
  const [showStore, setShowStore] = React.useState(false);
  const [showUpdate, setShowUpdate] = React.useState(false);
  const [categoryUpdate, setCategoryUpdate] = React.useState();

  function getCategories() {
    trackPromise(api.get(`/${appId}/participation_category?area_id=${areaId}`))
      .then((res) => setCategories(res.data.data))
      .catch((err) => console.error(err));
  }

  function deleteCategory(categoryId) {
    trackPromise(api.delete(`/${appId}/participation_category/${categoryId}`))
      .then((res) => getCategories())
      .catch((err) => console.error(err));
  }

  React.useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <div className="content">
        <NotificationSystem style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Link to="/admin/participation/areas">
                <Button
                  title="Voltar para áreas"
                  bsStyle="danger"
                  fixMargin pullRight fill
                  >
                  <i className="fa fa-backward"></i>
                  Voltar
                </Button>
              </Link>
              <Button
                title="cadastrar categoria"
                bsStyle="primary"
                fixMargin
                pullRight
                fill
                onClick={() => setShowStore(true)}
              >
                <i className="pe-7s-albums" />
                &nbsp; Nova categoria
              </Button>
              <Card
                title="Categorias"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <>
                    <Table striped hover>
                      <thead>
                        <tr>
                          {[
                            "Nome",
                            "Área",
                            "Estado Inicial",
                            "Messagem Inicial",
                            "Opções",
                          ].map((prop, index) => (
                            <th key={index}>{prop}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {categories.map((category) => (
                          <tr key={category.id}>
                            <td>{category.name}</td>
                            <td>{category.area.name}</td>
                            <td>
                              {participationStatus.get(category.initial_state)}
                            </td>
                            <td>
                              {category.initial_message ||
                                "Sem mensagem inicial"}
                            </td>
                            <td>
                              <EditButton
                                callback={() => {
                                  setCategoryUpdate(category);
                                  setShowUpdate(true);
                                }}
                              />
                              <RemoveButton
                                callback={() => deleteCategory(category.id)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
      {showStore && (
        <Store
          show={showStore}
          close={() => setShowStore(false)}
          reload={getCategories}
          areaId={areaId}
        />
      )}
      {showUpdate && categoryUpdate && (
        <Update
          show={showUpdate}
          close={() => {
            setShowUpdate(false);
            setCategoryUpdate();
          }}
          reload={getCategories}
          category={categoryUpdate}
        />
      )}
    </>
  );
};

export default Categories;
