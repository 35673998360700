import Pagination from "react-js-pagination";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Col, Grid, OverlayTrigger, Row, Table, Tooltip, Badge } from "react-bootstrap";

import api from "services/api";
import Card from "components/Card/Card";
import InvoiceModal from "./components/invoice";
import DetailInvoices from "./components/detailInvoices";
import Button from 'components/CustomButton/CustomButton.jsx';
import ProfileCandidate from "./components/profileCandidate";

export default function Invoice() {
  const [issue, setIssue] = useState();
  const [usersInvoice, setUsersInvoice] = useState([]);

  const [invoiceCtrl, setInvoiceCtrl] = useState({ isInvoiceOpen: false, invoice: null });
  const [detailsCtrl, setDetailsCtrl] = useState({ isDetailsOpen: false, details: null });
  const [profileCtrl, setProfileCtrl] = useState({ isProfileOpen: false, profile: null });

  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const invoiceMsg = (<Tooltip id="">Clique para enviar a nota fiscal</Tooltip>);
  const detailsMsg = (<Tooltip id="details_invoice">Visualizar notas emitidas</Tooltip>);
  const profileMsg = (<Tooltip id="profile_invoice">Visualizar perfil do candidato</Tooltip>);

  useEffect(() => {
    loadCandidates(issue);
  }, [issue, page]);

  const loadCandidates = async (value) => {
    try {
      const response = await trackPromise(api.get(
        value ? `/applications_payment/to_generate_invoice?emitido=${value}&$paginate=1&page=${page}`
          : `/applications_payment/to_generate_invoice?paginate=1&page=${page}`
      ));

      setTotalItems(response.data.total);
      setUsersInvoice(response.data.data);
      setCurrentPage(response.data.current_page);
      setItemsPerPage(response.data.per_page);
    } catch (error) {
      console.error(error);
    }
  }

  const handleModalInvoice = (invoice) => {
    setInvoiceCtrl({
      isInvoiceOpen: true,
      invoice: invoice,
    });
  };

  const handleCloseInvoice = (invoiceSended) => {
    setInvoiceCtrl({ isInvoiceOpen: false });

    if (invoiceSended) {
      loadCandidates();
    }
  };

  const handleModalDetails = (details) => {
    setDetailsCtrl({
      isDetailsOpen: true,
      details: details,
    });
  };

  const handleCloseDetailsInvoice = () => {
    setDetailsCtrl({ isDetailsOpen: false })
  }

  const handleModalProfile = (profile) => {
    setProfileCtrl({
      isProfileOpen: true,
      profile: profile,
    });
  };

  const handleCloseProfile = () => {
    setProfileCtrl({ isProfileOpen: false });
  }

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Emissão de notas fiscais"
              category="Gerenciamento de notas fiscais"
              ctTableFullWidth
              ctTableResponsive
              content={
                <>
                  <Col md={4}>
                    <div className="form-group">
                      <label className="">Filtro</label>
                      <select
                        name="filtro_emitido"
                        onChange={(e) => setIssue(e.target.value)}
                        className="form-control"
                      >
                        <option value="">Todos</option>
                        <option value="1">Emitidos</option>
                        <option value="0">Não emitidos</option>
                      </select>
                    </div>
                  </Col>

                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Status</th>
                        <th>Data do último envio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersInvoice.map((invoice) => (
                        <tr key={invoice.id}>
                          <td>{invoice.user_full_name}</td>
                          <td><Badge>{invoice.invoice?.length ? "Emitido" : "Não emitido"}</Badge></td>
                          <td>
                            {invoice.invoice?.length
                              ? new Date(invoice.invoice[invoice.invoice.length - 1].created_at)
                                .toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
                              : "Não enviado"}
                          </td>
                          <td className="text-right">
                            <OverlayTrigger placement="top" overlay={invoiceMsg}>
                              <Button
                                simple
                                bsSize="xs"
                                type="button"
                                bsStyle="success"
                                disabled={!!invoice.invoice?.length && (invoice.signature_type !== "monthly" || invoice.signature_type !== "mensal")}
                                onClick={() => handleModalInvoice(invoice)}
                              >
                                <i className="fa fa-money" aria-hidden="true"></i>
                                Emitir nota fiscal
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger placement="top" overlay={detailsMsg}>
                              <Button
                                simple
                                bsSize="xs"
                                type="button"
                                bsStyle="warning"
                                disabled={!invoice.invoice?.length}
                                onClick={() => handleModalDetails(invoice)}
                              >
                                <i className="fa fa-info-circle" aria-hidden="true"></i>
                                Detalhar notas
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger placement="top" overlay={profileMsg}>
                              <Button
                                simple
                                bsSize="xs"
                                type="button"
                                bsStyle="primary"
                                onClick={() => handleModalProfile(invoice)}
                              >
                                <i className="fa fa-user" aria-hidden="true"></i>
                                Visualizar perfil
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              }
            />

            <div className="text-center">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={totalItems}
                pageRangeDisplayed={5}
                onChange={(page) => setPage(page)}
              />
            </div>

            <InvoiceModal
              setModalClose={handleCloseInvoice}
              invoice={invoiceCtrl.invoice}
              showModal={invoiceCtrl.isInvoiceOpen}
            />

            <DetailInvoices
              setModalClose={handleCloseDetailsInvoice}
              list_invoice={detailsCtrl.details}
              showModal={detailsCtrl.isDetailsOpen}
            />

            <ProfileCandidate
              setModalClose={handleCloseProfile}
              profile={profileCtrl.profile}
              showModal={profileCtrl.isProfileOpen}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
};
