import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import React, { useEffect, useState } from "react";
import {
  Col, Modal,
  Row
} from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import api from "services/api.jsx";


const Update = ({ onClose = () => { }, candidates, application }) => {

  const [show, setShow] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [linkPlaystore, setLinkPlaystore] = useState(application.url_playstore);
  const [linkApple, setLinkApple] = useState(application.url_applestore);
  const [name, setName] = useState(application.name);
  const [year, setyear] = useState(application.year);
  const [slug, setSlug] = useState(application.slug ? application.slug : "");
  const [file, setFile] = useState();
  const [is_active, setIsActive] = useState(application.is_active);
  const [enableLeadership, setEnableLeadership] = useState(application.enable_leadership ? "1" : "0");
  const [enableOnboarding, setEnableOnboarding] = useState(application.onboarding ? "1" : "0");
  const [type_application_id, setTypeApplication] = useState(application.type_application);
  const [political_representative_id, setPoliticalRepresentativeId] = useState(application.coalition.political_representative_id);

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    const formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('name', name);
    formData.append('year', year);
    formData.append('is_active', is_active);
    formData.append('enable_leadership', Number(enableLeadership));
    formData.append('onboarding', Number(enableOnboarding));
    formData.append('type_application_id', type_application_id);
    formData.append("slug", slug);

    if (file) {
      formData.append("policy", file, file.name);
    }

    if (political_representative_id) {
      formData.append('political_representative_id', political_representative_id);
    }

    if (linkPlaystore) {
      formData.append('url_playstore', linkPlaystore);
    }

    if (linkApple) {
      formData.append('url_applestore', linkApple);
    }

    trackPromise(api.post(`/applications/${application.id}`, formData, config))
      .then(response => {
        onClose();
      })
      .catch((error) => {
        setIsAlertVisible(true);
      });

  };

  function slugfy(text) {
    return text
      .toString() // Cast to string (optional)
      .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string (optional)
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\_/g, "-") // Replace _ with -
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/\-$/g, ""); // Remove trailing -
  }

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edição de Aplicação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            {isAlertVisible && <div className="alert-error">Erro na atualização da aplicação!</div>}
            <FormInputs
              ncols={["col-md-8", "col-md-4"]}
              properties={[
                {
                  label: "Nome",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Nome Aplicação",
                  required: true,
                  defaultValue: name,
                  onChange: event => setName(event.target.value)
                },
                {
                  label: "Ano",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Ano",
                  required: true,
                  defaultValue: year,
                  onChange: event => setyear(event.target.value)
                },
              ]}
            />

            <Row>
              <Col md={12}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Slug",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "Slug da aplicação",
                      required: true,
                      value: slug,
                      onChange: (event) => { setSlug(slugfy(event.target.value)) },
                    }
                  ]}
                />

                <div className="form-group">
                  <label className="">Tipo Aplicação</label>
                  <select
                    name="type_application"
                    value={type_application_id}
                    onChange={event => setTypeApplication(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">-</option>
                    <option value="one">Aplicativo de um candidato</option>
                    <option value="many">Aplicativo de vários candidatos</option>
                  </select>
                </div>

                <div className="form-group">
                  <label className="">
                    <span>Política de privacidade</span>
                    {
                      application.link_policy &&
                      <sub>
                        <a href={application.link_policy} target="_blank" rel="noopener noreferrer">&nbsp;Veja a atual</a>
                      </sub>
                    }
                  </label>
                  <input
                    type="file"
                    accept=".pdf"
                    className="form-control"
                    name="political-privacy"
                    onChange={(event) => setFile(event.target.files[0])}
                  />
                </div>

                <div className="form-group">
                  <label className="">Político Representativo</label>
                  <select
                    name="political_representative_id"
                    value={political_representative_id ? political_representative_id : ''}
                    onChange={event => setPoliticalRepresentativeId(event.target.value)}
                    className="form-control"
                    required={false}
                  >
                    <option value="">Nenhum representante político</option>
                    {candidates.map(constant => (
                      <option key={constant.id} value={constant.id}>{constant.name}</option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label className="">Situação da Aplicação</label>
                  <select
                    name="isActive"
                    defaultValue={is_active}
                    onChange={event => setIsActive(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">-</option>
                    <option value="true">Ativa</option>
                    <option value="false">Inativa</option>
                  </select>
                </div>

                <div className="form-group">
                  <label className="">Gerenciamento de lideranças</label>
                  <select
                    name="enableLeadership"
                    defaultValue={enableLeadership}
                    onChange={event => setEnableLeadership(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">-</option>
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                  </select>
                </div>

                <div className="form-group">
                  <label className="">Gerenciamento do Onboarding</label>
                  <select
                    name="enableOnboarding"
                    defaultValue={enableOnboarding}
                    onChange={event => setEnableOnboarding(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">-</option>
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                  </select>
                </div>

                <FormInputs
                  ncols={["col-md-6", "col-md-6"]}
                  properties={[
                    {
                      label: "Link Android",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "Link na PlayStore",
                      defaultValue: linkPlaystore,
                      onChange: event => setLinkPlaystore(event.target.value)
                    },
                    {
                      label: "Link IOS",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "Link na AppleStore",
                      defaultValue: linkApple,
                      onChange: event => setLinkApple(event.target.value)
                    },
                  ]}
                />
              </Col>
            </Row>

            <hr />
            <Button bsStyle="danger" fill onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button bsStyle="info" pullRight fill type="submit">
              Salvar Alterações
            </Button>
            <div className="clearfix" />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Update;
