import Button from "components/CustomButton/CustomButton.jsx";
import DangerAlert from "components/DangerAlert/DangerAlert";
import React from "react";
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import api from "services/api";
import { participationStatus } from "./index";

const Store = ({ show, close, reload, areaId }) => {
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [error, setError] = React.useState("");
  const [initialValues, setInitialValues] = React.useState({});
  const appId = localStorage.getItem("idApp");

  function submitHandler(event) {
    event.preventDefault();
    const config = { headers: { "Content-Type": "application/json" } };
    const data = {
      name: event.target.name.value,
      area_id: areaId,
      initial_state: event.target.initialState.value,
      initial_message: event.target.initialMessage.value,
    };

    trackPromise(api.post(`/${appId}/participation_category`, data, config))
      .then((res) => {
        close();
        reload();
      })
      .catch((err) => {
        setError(err.message);
        setIsAlertVisible(true);
      });
  }

  React.useEffect(() => {
    trackPromise(
      api.get(`/${appId}/participation_category/last_data?area_id=${areaId}`)
    )
      .then((res) => setInitialValues(res.data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <Modal
      size="sm"
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Cadastro de categoria de participação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          {isAlertVisible && (
            <DangerAlert error={error} close={() => setIsAlertVisible(false)} />
          )}
          <Row>
            <Col md={8}>
              <FormGroup>
                <ControlLabel>Nome</ControlLabel>
                <FormControl
                  type="text"
                  placeholder="Nome da categoria"
                  name="name"
                  required
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <ControlLabel>Status inicial</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="Status inicial da categoria"
                  key={initialValues.initial_state}
                  defaultValue={initialValues.initial_state}
                  name="initialState"
                  required
                >
                  {Array.from(participationStatus.entries()).map(
                    (status, index) => (
                      <option key={index} value={status[0]}>
                        {status[1]}
                      </option>
                    )
                  )}
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <ControlLabel>Mensagem inicial</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  placeholder="Mensagem inicial (opcional)"
                  key={initialValues.initial_message}
                  defaultValue={initialValues.initial_message}
                  name="initialMessage"
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Button bsStyle="danger" fill onClick={close}>
            Cancelar
          </Button>
          <Button bsStyle="info" pullRight fill type="submit">
            Cadastrar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Store;
