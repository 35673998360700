import React, { useState, useEffect, useRef } from "react";
import { Modal, Table, Tooltip, OverlayTrigger, Alert } from "react-bootstrap";
import Select from "react-select";
import { trackPromise } from "react-promise-tracker";
import NotificationSystem from "react-notification-system";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import DangerAlert from "components/DangerAlert/DangerAlert";

import { style } from "variables/Variables";

import api from "services/api.jsx";

const Coordinators = ({ onClose = () => {}, application }) => {
  const [show, setShow] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [erro, setErro] = useState("");

  const [coordinators, setCoordinators] = useState([]);
  const [coordinatorsAll, setCoordinatorsAll] = useState([]);
  const [idCoordenador, setIdCoordenador] = useState(null);

  const [isForm, setIsForm] = useState(false);

  const notificationSystem = useRef();

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  const remove = <Tooltip id="remove_tooltip">Excluir</Tooltip>;

  useEffect(() => {
    async function loadData() {
      await Promise.all([loadCoordinators(), loadCoordinatorsAll()]);
    }

    loadData();
  }, []);

  const loadCoordinatorsAll = async () => {
    try {
      const response = await trackPromise(api.get(`/coordinators`));

      const data = response.data.data;

      const dataFormatted = data.map((item) => ({
        value: item.id,
        label: `${item.name} - ${item.email}`,
      }));

      setCoordinatorsAll(dataFormatted);
    } catch (err) {
      console.error(err);
    }
  };

  const loadCoordinators = async () => {
    try {
      const response = await trackPromise(
        api.get(`/coordinators/${application.id}`)
      );
      setCoordinators(response.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!idCoordenador) return;

    const data = {
      user_id: Number(idCoordenador),
      application_id: application.id,
    };

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    try {
      await trackPromise(
        api.post(`/coordinators/link_applications`, data, config)
      );

      handleNotification("Coordenador adicionado com sucesso", "success");

      setIsForm(false);

      await loadCoordinators();
    } catch (err) {
      setErro(
        "Erro na vinculaçao do coordenador! " + err.response.data.message
      );

      setIsAlertVisible(true);
    }
  };

  const deleteCoordinatorApplication = async (user_id) => {
    try {
      await trackPromise(
        api.delete(`/coordinators`, {
          data: {
            application_id: application.id,
            user_id,
          },
        })
      );

      await loadCoordinators();

      handleNotification("Coordenador excluído com sucesso", "success");
    } catch (error) {
      console.error(error);
      setErro("Erro na exclusão do coordenador! Tente novamente!");
      setIsAlertVisible(true);
    }
  };

  function handleNotification(message, status) {
    const notification = notificationSystem.current;

    notification.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: message,
      level: status,
      position: "tr",
      dismissible: true,
    });
  }

  function setViewForm() {
    setIsForm(!isForm);
  }

  return (
    <>
      <NotificationSystem ref={notificationSystem} style={style} />
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Coordenadores da aplicação: <strong>{application.name}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            title="cadastrar novo coordenador"
            bsStyle="success"
            fixMargin
            pullRight
            fill
            onClick={() => setViewForm()}
          >
            <i className={`fa fa-${!isForm ? "plus" : "list"}`}></i>{" "}
            {!isForm ? "Vincular Coordenador" : "Listar vinculados"}
          </Button>

          <Card
            title="Aplicação"
            category="Coordenadores vinculados"
            ctTableFullWidth
            ctTableResponsive
            content={
              <>
                {isAlertVisible && (
                  <div style={{ padding: "0 16px" }}>
                    <DangerAlert
                      error={erro}
                      close={() => setIsAlertVisible(false)}
                    />
                  </div>
                )}

                {isForm && (
                  <form
                    className="formCoordinator"
                    onSubmit={submitHandler}
                    encType="multipart/form-data"
                  >
                    <Select
                      name="coordinator"
                      options={coordinatorsAll}
                      placeholder="Busque por um coordenador"
                      noOptionsMessage={() => "Dados não encontrados"}
                      isSearchable
                      isClearable
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setIdCoordenador(selectedOption.value);
                        }
                      }}
                    />

                    <Button bsStyle="info" pullRight fill block type="submit">
                      Vincular
                    </Button>
                  </form>
                )}

                {!isForm && (
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coordinators.map((app) => (
                        <tr key={app.id}>
                          <td>{app.id}</td>
                          <td>{app.name}</td>
                          <td>{app.email}</td>
                          <td>
                            <OverlayTrigger placement="top" overlay={remove}>
                              <Button
                                bsStyle="danger"
                                simple
                                type="button"
                                bsSize="xs"
                                onClick={() =>
                                  deleteCoordinatorApplication(app.id)
                                }
                              >
                                <i className="fa fa-times"></i>
                                Excluir
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </>
            }
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Coordinators;
