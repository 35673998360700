import Card from "components/Card/Card.jsx";
import Confirm from "components/Confirm/Confirm.jsx";
import Button from 'components/CustomButton/CustomButton.jsx';
import React, { Component } from "react";
import { Col, Grid, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";
import { style } from "variables/Variables.jsx";
import api from "../../services/api.jsx";
import Store from "./store.jsx";
import Update from "./update.jsx";
import { TutorialVideoModal } from "components/TutorialModal/TutorialModal.jsx";

class Histories extends Component {
  typeApplication = localStorage.getItem("typeApplication");
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      histories: [],
      history: null,
      isModalVisibleEdit: false,
      isModalVisibleNew: false,
      isComunicationModalVisible: false,
      candidates: [],
      candidate: JSON.parse(localStorage.getItem('user')).candidate_id ? JSON.parse(localStorage.getItem('user')).candidate_id : 0,
      role: JSON.parse(localStorage.getItem('user')).role,
      app: localStorage.getItem('idApp'),
      callback: undefined,
      confirm: false,
      text: false,
    };
  }
  // componente de inicialização
  componentDidMount() {
    if (this.state.role === "candidate")
      this.loadHistorias(this.state.candidate);
    if (this.state.role === "coordinator" || this.state.role === "advisor")
      this.loadCandidates();
  }
  //edita historia
  editHistory(history) {
    this.setState({ history: history });
    this.setState({ isModalVisibleEdit: true });
  }
  //load historias
  loadHistorias = async (idCandidate) => {
    try {
      const response = await trackPromise(api.get(`/${this.state.app}/candidates/${idCandidate}/histories`));
      this.setState({ histories: response.data.data });
    } catch (e) {
      this.setState({ histories: [] });
      if (e.response && e.response.status != 400) this.handleNotificationClick(e.response.data.message, 'error');
    }
  };
  // seta no estate o id do candidato selecionado
  changeHandler = (candidateId, candidateName) => {
    this.setState({ [candidateName]: candidateId });
    candidateId ? this.loadHistorias(candidateId) : this.setState({ events: [], candidate: 0 });

  };
  // carrega os candidatos
  loadCandidates = async () => {
    const response = await trackPromise(api.get(`/${this.state.app}/candidates?onlyActives=1`));
    this.setState({ candidates: response.data.data });
    if (this.typeApplication === 'one' && response.data.data.length > 0) {
      this.setState({ candidate: response.data.data[0].id });
      this.changeHandler(response.data.data[0].id, response.data.data[0].name);
    }
  };
  // deleta historia
  deleteHistoria = () => {
    trackPromise(api.delete(`/${this.state.app}/candidates/${this.state.candidate}/histories`))
      .then(response => {
        this.setState({ histories: [] });
        this.loadHistorias(this.state.candidate);
        this.handleNotificationClick('Historia excluida com sucesso!', 'success');
      })
      .catch((error) => {
        console.log(error.message);
        this.handleNotificationClick('Erro na exclusão da história! Tente novamente!', 'error');
      });
  };
  // coponente de notificação
  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: (
        <div>
          {message}
        </div>
      ),
      level: color,
      position: 'tr',
      autoDismiss: 5,
      dismissible: true
    });
  };
  openComunicationModal = () => {
    this.setState({ isComunicationModalVisible: true });
  };

  //componente de rendereização
  render() {
    const { histories, isModalVisibleEdit, candidates, candidate, role, isModalVisibleNew, isComunicationModalVisible } = this.state;
    const remove = (<Tooltip id="remove_tooltip">Excluir</Tooltip>);
    const edit = (<Tooltip id="edit_tooltip">Editar</Tooltip>);

    return (
      <>
        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <Row>
              <Col md={12}>
                {(!histories || histories.length == 0) &&
                  <Button
                    disabled={(role === "coordinator" || role === "advisor") ? candidate ? false : true : false}
                    title="cadastrar nova história"
                    bsStyle="primary"
                    onClick={() => this.setState({ isModalVisibleNew: true })}
                    fixMargin pullRight fill
                    style={{ marginTop: "15px", marginRight: "15px" }}
                  >
                    <i className="fa fa-plus" />
                    Nova História
                  </Button>
                }
                <Card
                  title="Histórias"
                  extraTitleContent={
                    <i
                      className="fa fa-question-circle"
                      style={{ fontSize: "18px", cursor: "pointer" }}
                      title="Assista ao vídeo tutorial dessa área"
                      onClick={this.openComunicationModal}
                    />
                  }
                  category="Histórias cadastradas de um candidato"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <>
                      {(role === "coordinator" || role === "advisor") &&
                        <form >
                          <div className="form-group">
                            <label className="col-sm-2 col-form-label">
                              Selecione o candidato:
                            </label>
                            <div className="col-sm-10">
                              <select
                                name="candidate"
                                value={candidate}
                                onChange={(e) => this.changeHandler(e.target.value, e.target.name)}
                                className="form-control"
                                disabled={this.typeApplication === 'one'}
                              >
                                {this.typeApplication === 'one' ? null : <option value="">-</option>}
                                {candidates.map(candidate => (
                                  <option key={candidate.id} value={candidate.id}>{candidate.name}</option>
                                ))}
                              </select>
                            </div>


                          </div>
                        </form>
                      }
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Imagem</th>
                            {/* <th>Conteúdo</th> */}
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {histories.id &&
                            <tr >
                              <td>{histories.id}</td>
                              <td><img className="img-list" src={histories.link_image_header} alt={histories.link_image_header} /></td>
                              {/* <td>
                                <div dangerouslySetInnerHTML={{ __html: histories.content }} />
                              </td> */}
                              <td>
                                <OverlayTrigger placement="top" overlay={edit}>
                                  <Button
                                    bsStyle="info"
                                    simple
                                    type="button"
                                    bsSize="xs"
                                    onClick={() => this.editHistory(histories)}
                                  >
                                    <i className="fa fa-edit" />
                                    Editar
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger placement="top" overlay={remove}>
                                  <Button
                                    bsStyle="danger"
                                    simple
                                    type="button"
                                    bsSize="xs"
                                    onClick={() => this.setState({
                                      callback: () => this.deleteHistoria(),
                                      text: 'excluir a história',
                                      confirm: true
                                    })}
                                  >
                                    <i className="fa fa-times"></i>
                                    Excluir
                                  </Button>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          }

                        </tbody>
                      </Table>

                      {isModalVisibleEdit && <Update onClose={() => { this.setState({ isModalVisibleEdit: false }); this.loadHistorias(this.state.candidate); }} history={this.state.history} idCandidato={this.state.candidate} />}
                      {isModalVisibleNew && <Store onClose={() => { this.setState({ isModalVisibleNew: false }); this.loadHistorias(this.state.candidate); }} idCandidato={this.state.candidate} />}
                    </>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <TutorialVideoModal
          isOpen={isComunicationModalVisible}
          onClose={() => this.setState({ isComunicationModalVisible: false })}
          title={"Proposta e biografia"}
          linkTitle={"Proposta e biografia"}
          link={"https://www.youtube.com/embed/DM8PeX47zao?si=Nxl1Cl5tA-yz2dtX"}
        />
        <Confirm
          show={this.state.confirm}
          close={() => this.setState({
            confirm: false,
            callback: undefined,
            text: ''
          })}
          callback={this.state.callback}
          text={this.state.text}
        />
      </>
    );
  }
}

export default Histories;
