import Dashboard from "views/Dashboard.jsx";

// componentes nova versão
import Applications from "views/application/index.jsx";
import MenuApplication from "views/application_menu/menu.jsx";

import Coordinators from "views/coordinator/index.jsx";
import Administrators from "views/administrator/index.jsx";
import Users from "views/coordinator/users.jsx";
import Candidates from "views/candidate/index.jsx";
import Events from "views/event/index.jsx";
import Histories from "views/historie/index.jsx";
import Proposals from "views/proposal/index.jsx";
import ProfileCandidate from "views/candidate/profile.jsx";
import News from "views/new/index.jsx";

import PreviewMobile from "views/mobile/index.jsx";
import Voters from "views/voters/index.jsx";
import VotingIntentions from "views/voters/voting_intentions.jsx";
import LeadershipRequest from "views/leaders/leadership_request";
import { LeadersOfLeaders } from "views/leaders/list";
import Neighborhoods from "views/neighborhoods/index.jsx";
import Advisor from "views/advisor/index.jsx";

//modulo de tarefas

import Tasks from "views/tasks/index.jsx";
import CreateTask from "views/tasks/components/create.jsx";
import EditTask from "views/tasks/components/edit.jsx";
import TaskAnswer from "views/tasks/components/task-aswer.jsx";

//modulo de metas

import Goals from "views/goals/index.jsx";
import CreateGoal from "views/goals/components/create.jsx";
import EditGoal from "views/goals/components/edit.jsx";
import GoalAnswer from "views/goals/components/goal-answer.jsx";

// modulo de pesquisa
import Search from "views/search/index.jsx";
import Questions from "views/search/questions/index.jsx";
import Options from "views/search/questions/options/index.jsx";
import Answers from "views/search/answers/index.jsx";

//participation module
import ParticipationAreas from "views/participation/area/index";
import ParticipationCategories from "views/participation/category/index";
import Participations from "views/participation/index";
import Participation from "views/participation/show";

//modulo de notificação de liderança
import ListNotificationLeads from "views/notification-leads/list-notification-lead";
import CreateNotificationLeads from "views/notification-leads/create-notification-lead";
import UpdateNotificationLeads from "views/notification-leads/update-notification-lead";
import { Gallery } from "views/leaders/list/components/Gallery.jsx";

import { Infos } from "views/infos/index";
import Invoice from "views/invoice/index";
import { leads } from "views/leads";
import CoordinatorsFiltered from "views/coordinator/index-filtered";
import { ListFields } from "views/fields/list-fields/list-fields";
import { StartGuide } from "views/start-guide";
import Voter from "views/voter/create-voter";
import UpdateVoter from "views/voter/update-voters";

const dashboardRoutes = [
  {
    name: "Dashboard",
    component: Dashboard,
    path: "/dashboard",
    icon: "pe-7s-albums",
    layout: "/admin",
    role: ["admin"],
    visible: true,
    application_type: ["general"],
  },
  {
    name: "Aplicações",
    component: Applications,
    path: "/apps",
    icon: "pe-7s-monitor",
    layout: "/admin",
    role: ["admin"],
    visible: true,
    application_type: ["general"],
  },
  {
    name: "Localidades",
    component: Neighborhoods,
    path: "/neighborhoods",
    icon: "pe-7s-map-2",
    layout: "/admin",
    role: ["admin", "advisor"],
    visible: true,
    application_type: ["general"],
  },
  {
    name: "Coordenadores",
    component: Coordinators,
    path: "/organizers",
    icon: "pe-7s-users",
    layout: "/admin",
    role: ["admin"],
    visible: true,
    application_type: ["general"],
  },
  {
    name: "Administradores",
    component: Administrators,
    path: "/administrators",
    icon: "pe-7s-note2",
    layout: "/admin",
    role: ["admin"],
    visible: true,
    application_type: ["general"],
  },
  {
    name: "Emitir nota fiscal",
    component: Invoice,
    path: "/emitir_nota_fiscal",
    icon: "pe-7s-file",
    layout: "/admin",
    role: ["admin"],
    visible: true,
    application_type: ["general"],
  },
  {
    name: "Usuários do sistema",
    component: Users,
    path: "/users",
    icon: "pe-7s-lock",
    layout: "/admin",
    role: ["admin"],
    visible: true,
    application_type: ["general"],
  },
  {
    name: "Leads",
    component: leads,
    path: "/leads",
    icon: "pe-7s-users",
    layout: "/admin",
    role: ["admin"],
    visible: true,
    application_type: ["general"],
  },
  {
    name: "Dashboard",
    group: "Banco de Dados",
    component: VotingIntentions,
    path: "/voting_intentions",
    icon: "pe-7s-way",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Notícias",
    group: "Comunicação",
    component: News,
    path: "/news",
    icon: "pe-7s-mail-open-file",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Eventos",
    group: "Comunicação",
    component: Events,
    path: "/events",
    icon: "pe-7s-note2",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Pesquisas",
    group: "Comunicação",
    component: Search,
    path: "/researches",
    icon: "pe-7s-wallet",
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: true,
    application_type: ["general", "one"],
  },
  {
    name: "Comunicação para líderes",
    group: "Comunicação",
    component: ListNotificationLeads,
    path: "/lead-notifications",
    icon: "pe-7s-bell",
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: true,
    application_type: ["general", "one"],
  },
  {
    name: "Criação de notificação de liderança",
    group: "Comunicação",
    component: CreateNotificationLeads,
    path: "/create-notification-leader",
    icon: "pe-7s-bell",
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: false,
    application_type: ["general", "one"],
  },
  {
    name: "Edição de notificação de liderança",
    group: "Comunicação",
    component: UpdateNotificationLeads,
    path: "/update-notification-leader/:id",
    icon: "pe-7s-bell",
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: false,
    application_type: ["general", "one"],
  },
  {
    name: "Propostas",
    group: "Página do Candidato",
    component: Proposals,
    path: "/proposals",
    icon: "pe-7s-display2",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Biografia",
    group: "Página do Candidato",
    component: Histories,
    path: "/histories",
    icon: "pe-7s-news-paper",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Tarefas",
    group: "Gamificação de liderança",
    path: "/tasks",
    icon: "pe-7s-graph1",
    component: Tasks,
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: true,
    application_type: ["one", "many"],
  },
  {
    name: "Metas",
    group: "Gamificação de liderança",
    path: "/goals",
    icon: "pe-7s-target",
    component: Goals,
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: true,
    application_type: ["one", "many"],
  },
  {
    name: "Metas",
    group: "Gamificação de liderança",
    path: "/create-goal",
    icon: "pe-7s-graph1",
    component: CreateGoal,
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: false,
    application_type: ["one", "many"],
  },
  {
    name: "Metas",
    group: "Gamificação de liderança",
    path: "/edit-goal/:goalId",
    icon: "pe-7s-graph1",
    component: EditGoal,
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: false,
    application_type: ["one", "many"],
  },
  {
    name: "Metas",
    group: "Gamificação de liderança",
    path: "/goal-answer/:goalId",
    icon: "pe-7s-graph1",
    component: GoalAnswer,
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: false,
    application_type: ["one", "many"],
  },
  {
    name: "Tarefas",
    group: "Gamificação de liderança",
    path: "/create-task",
    icon: "pe-7s-graph1",
    component: CreateTask,
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: false,
    application_type: ["one", "many"],
  },
  {
    name: "Tarefas",
    group: "Gamificação de liderança",
    path: "/edit-task/:taskId",
    icon: "pe-7s-graph1",
    component: EditTask,
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: false,
    application_type: ["one", "many"],
  },
  {
    name: "Tarefas",
    group: "Gamificação de liderança",
    path: "/task-answer/:taskId",
    icon: "pe-7s-graph1",
    component: TaskAnswer,
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: false,
    application_type: ["one", "many"],
  },
  {
    name: "Listagem de Participações",
    group: "Página de Participações",
    component: Participations,
    path: "/participations",
    icon: "pe-7s-note",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "one", "many"],
    exact: true,
  },
  {
    name: "Informações",
    group: "Página do Candidato",
    component: Infos,
    path: "/infos",
    icon: "pe-7s-info",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "one", "many"],
    exact: true,
  },
  {
    name: "Lideranças",
    group: "Banco de Dados",
    component: LeadersOfLeaders,
    path: "/leaders",
    icon: "pe-7s-users",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Galeria",
    group: "Gamificação de liderança",
    component: Gallery,
    path: "/gallery",
    icon: "pe-7s-photo",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Solicitações de liderança",
    component: LeadershipRequest,
    path: "/leadership_request",
    icon: "pe-7s-users",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: false,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Gerenciar Assesores",
    group: "Configurações Gerais",
    component: Advisor,
    path: "/advisor",
    icon: "pe-7s-graph2",
    layout: "/admin",
    role: ["coordinator"],
    visible: false,
    application_type: ["general"],
  },
  {
    name: "Eleitores",
    group: "Banco de Dados",
    component: Voters,
    path: "/voters",
    icon: "pe-7s-user",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Eleitores",
    group: "Banco de Dados",
    component: Voter,
    path: "/create-voter",
    icon: "pe-7s-user",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: false,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Eleitores",
    group: "Banco de Dados",
    component: UpdateVoter,
    path: "/update-voter/:id",
    icon: "pe-7s-user",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: false,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Gerenciar Candidatos",
    group: "Configurações Gerais",
    component: Candidates,
    path: "/candidates",
    icon: "pe-7s-users",
    layout: "/admin",
    role: ["coordinator", "advisor"],
    visible: true,
    application_type: ["general"],
  },
  {
    name: "Coordenadores",
    group: "Configurações Gerais",
    component: CoordinatorsFiltered,
    path: "/organizers_filltered",
    icon: "pe-7s-users",
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: true,
    application_type: ["general", "one"],
  },
  {
    name: "Redes Sociais e Imagens",
    group: "Página do Candidato",
    component: ProfileCandidate,
    path: "/perfil/candidate",
    icon: "pe-7s-user",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "many", "one"],
  },
  {
    name: "Configurar Aplicação",
    group: "Configurações Gerais",
    component: PreviewMobile,
    path: "/mobile",
    icon: "pe-7s-phone",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Configurar Menu",
    group: "Configurações Gerais",
    component: MenuApplication,
    path: "/menu/manage",
    icon: "pe-7s-menu",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Configurar Participações",
    group: "Página de Participações",
    component: ParticipationAreas,
    path: "/participation/areas",
    icon: "pe-7s-albums",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: true,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Questão",
    component: Questions,
    path: "/researche/:researche_id/questions",
    icon: "pe-7s-wallet",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: false,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Opções",
    component: Options,
    path: "/options/research/:researche_id/question/:question_id",
    icon: "pe-7s-wallet",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: false,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Resultado Pesquisa",
    component: Answers,
    path: "/answers/researche/:researche_id",
    icon: "pe-7s-wallet",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: false,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Categorias de Participação",
    component: ParticipationCategories,
    path: "/participation/categories/:areaId",
    icon: "pe-7s-flag",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: false,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Listagem de Participação",
    group: "Página de Participações",
    component: Participation,
    path: "/participations/:id",
    icon: "pe-7s-note",
    layout: "/admin",
    role: ["coordinator", "advisor", "candidate"],
    visible: false,
    application_type: ["general", "one", "many"],
  },
  {
    name: "Campos do Perfil",
    group: "Configurações Gerais",
    component: ListFields,
    path: "/campos_extras",
    icon: "pe-7s-file",
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: true,
    application_type: ["general", "one"],
  },
  {
    name: "Iniciando a aplicação",
    group: "Ajuda",
    component: StartGuide,
    path: "/start_guide",
    icon: "pe-7s-settings",
    layout: "/admin",
    role: ["coordinator", "candidate"],
    visible: true,
    application_type: ["general", "one"],
  },
];

export default dashboardRoutes;
