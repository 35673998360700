import Card from "components/Card/Card.jsx";
import Confirm from "components/Confirm/Confirm.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { TutorialVideoModal } from "components/TutorialModal/TutorialModal.jsx";
import React, { Component } from "react";
import {
  Col,
  Grid,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";
import { style } from "variables/Variables.jsx";
import api from "../../services/api.jsx";
import Store from "./store.jsx";
import Update from "./update.jsx";

class Proposals extends Component {
  typeApplication = localStorage.getItem("typeApplication");
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      proposals: [],
      isModalVisible: false,
      isModalVisibleNew: false,
      isComunicationModalVisible: false,
      proposta: null,
      candidates: [],
      candidate: JSON.parse(localStorage.getItem("user")).candidate_id
        ? JSON.parse(localStorage.getItem("user")).candidate_id
        : 0,
      app: localStorage.getItem("idApp"),
      role: JSON.parse(localStorage.getItem("user")).role,
      callback: undefined,
      text: "",
      confirm: false,
    };
  }
  componentDidMount() {
    if (this.state.role === "candidate")
      this.loadProposals(this.state.candidate);
    if (this.state.role === "coordinator" || this.state.role === "advisor")
      this.loadCandidates();
  }
  editProposta(event) {
    this.setState({ proposta: event });
    this.setVisibleModal(true);
  }
  setVisibleModal(e) {
    this.setState({ isModalVisibleEdit: e });
  }
  // seta no estate o id do candidato selecionado
  changeHandler = (candidateId, candidateName) => {
    this.setState({ [candidateName]: candidateId });
    candidateId
      ? this.loadProposals(candidateId)
      : this.setState({ news: [], candidate: 0 });
  };

  openComunicationModal = () => {
    this.setState({ isComunicationModalVisible: true });
  };

  // carrega os candidatos
  loadCandidates = async () => {
    const response = await trackPromise(
      api.get(`/${this.state.app}/candidates?onlyActives=1`)
    );
    this.setState({ candidates: response.data.data });
    if (this.typeApplication === 'one' && response.data.data.length > 0) {
      this.setState({ candidate: response.data.data[0].id });
      this.changeHandler(response.data.data[0].id, response.data.data[0].name);
    }
  };
  loadProposals = async (idCandidate) => {
    try {
      const response = await trackPromise(
        api.get(`/${this.state.app}/candidates/${idCandidate}/proposals`)
      );
      this.setState({ proposals: response.data.data });
    } catch (e) {
      // console.error("Error index.jsx, proposals:", e)
      //this.handleNotificationClick(e.response.data.message, 'error');
    }
  };
  deleteProposal = (idProposals) => {
    trackPromise(
      api.delete(
        `/${this.state.app}/candidates/${this.state.candidate}/proposals/${idProposals}`
      )
    )
      .then((response) => {
        this.loadProposals(this.state.candidate);
        this.handleNotificationClick(
          "Proposta excluida com sucesso!",
          "success"
        );
      })
      .catch((error) => {
        console.log(error.message);
        this.handleNotificationClick(
          "Erro na exclusão da proposta! Tente novamente!",
          "error"
        );
      });
  };

  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: <div>{message}</div>,
      level: color,
      position: "tr",
      autoDismiss: 5,
      dismissible: true,
    });
  };

  render() {
    const {
      proposals,
      isModalVisibleEdit,
      candidate,
      candidates,
      role,
      isModalVisibleNew,
      isComunicationModalVisible
    } = this.state;
    const remove = <Tooltip id="remove_tooltip">Excluir</Tooltip>;
    const edit = <Tooltip id="edit_tooltip">Editar</Tooltip>;

    return (
      <>
        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Button
                  disabled={
                    role === "coordinator" || role === "advisor"
                      ? candidate
                        ? false
                        : true
                      : false
                  }
                  title="cadastrar nova proposta"
                  bsStyle="primary"
                  fixMargin
                  pullRight
                  fill
                  onClick={() => this.setState({ isModalVisibleNew: true })}
                >
                  <i className="fa fa-plus"></i>Nova Proposta
                </Button>
                <Card
                  title="Propostas"
                  extraTitleContent={
                    <i
                      className="fa fa-question-circle"
                      style={{ fontSize: "18px", cursor: "pointer" }}
                      title="Assista ao vídeo tutorial dessa área"
                      onClick={this.openComunicationModal}
                    />
                  }
                  category="Propostas cadastradas de um candidato"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <>
                      {(role === "coordinator" || role === "advisor") && (
                        <form>
                          <div className="form-group">
                            <label className="col-sm-2 col-form-label">
                              Selecione o candidato:
                            </label>
                            <div className="col-sm-10">
                              <select
                                name="candidate"
                                value={candidate}
                                onChange={(e) => this.changeHandler(e.target.value, e.target.name)}
                                className="form-control"
                                disabled={this.typeApplication === 'one'}
                              >
                                {this.typeApplication !== 'one' && <option value="">-</option>}
                                {candidates.map((candidate) => (
                                  <option
                                    key={candidate.id}
                                    value={candidate.id}
                                  >
                                    {candidate.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </form>
                      )}
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>Imagem</th>
                            <th>Título</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {proposals.map((proposal) => (
                            <tr key={proposal.id}>
                              <td>
                                <img
                                  className="img-list"
                                  src={proposal.link_image_header}
                                  alt={proposal.link_image_header}
                                />
                              </td>
                              <td>{proposal.title}</td>
                              <td>
                                <OverlayTrigger placement="top" overlay={edit}>
                                  <Button
                                    bsStyle="info"
                                    simple
                                    type="button"
                                    bsSize="xs"
                                    onClick={() => this.editProposta(proposal)}
                                  >
                                    <i className="fa fa-edit" />
                                    Editar
                                  </Button>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  overlay={remove}
                                >
                                  <Button
                                    bsStyle="danger"
                                    simple
                                    type="button"
                                    bsSize="xs"
                                    onClick={() =>
                                      this.setState({
                                        callback: () =>
                                          this.deleteProposal(proposal.id),
                                        text: `excluir a proposta ${proposal.title}`,
                                        confirm: true,
                                      })
                                    }
                                  >
                                    <i className="fa fa-times" />
                                    Excluir
                                  </Button>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      {isModalVisibleEdit && (
                        <Update
                          onClose={() => {
                            this.setVisibleModal(false);
                            this.loadProposals(this.state.candidate);
                          }}
                          proposta={this.state.proposta}
                          idCandidato={this.state.candidate}
                        />
                      )}
                      {isModalVisibleNew && (
                        <Store
                          onClose={() => {
                            this.setState({ isModalVisibleNew: false });
                            this.loadProposals(this.state.candidate);
                          }}
                          idCandidato={this.state.candidate}
                        />
                      )}
                    </>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <TutorialVideoModal
          isOpen={isComunicationModalVisible}
          onClose={() => this.setState({ isComunicationModalVisible: false })}
          title={"Proposta e biografia"}
          linkTitle={"Proposta e biografia"}
          link={"https://www.youtube.com/embed/DM8PeX47zao?si=Nxl1Cl5tA-yz2dtX"}
        />

        <Confirm
          show={this.state.confirm}
          close={() =>
            this.setState({
              confirm: false,
              callback: undefined,
              text: "",
            })
          }
          callback={this.state.callback}
          text={this.state.text}
        />
      </>
    );
  }
}

export default Proposals;
