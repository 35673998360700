import Card from "components/Card/Card.jsx";
import Confirm from "components/Confirm/Confirm.jsx";
//import Pagination from 'react-bootstrap/Pagination';
//import PageItem from 'react-bootstrap/PageItem';
import Button from 'components/CustomButton/CustomButton.jsx';
import React, { Component } from "react";
import { Fragment } from "react";
import { Col, Grid, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";
import { style } from "variables/Variables.jsx";
import api from "../../services/api.jsx";
import Store from "./create.jsx";

class Coordinators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      applications: [],
      coordinators: [],
      coordinator: [],
      configPagination: {
        activePage: null,
        itemsCountPerPage: null,
        totalItemsCount: 1,
        pageRangeDisplayed: 5
      },
      isModalVisibleEdit: false,
      isModalVisibleNew: false,
      confirm: false,
      callback: undefined,
      user: {},
      text: ''
    };
  }

  componentDidMount() {
    this.loadCoordinators();
    this.loadApplications();
  }

  loadApplications = async () => {
    try {
      const response = await api.get('/applications');
      this.setState({ applications: response.data.data });
    } catch (e) {
      this.handleNotificationClick(e.response.data.message, 'error');
    }
  };

  loadCoordinators = async () => {
    const response = await trackPromise(api.get(`/coordinators`));
    this.setState({ coordinators: response.data.data });
  };

  editCoordinator(value) {
    this.setState({ coordinator: value, isModalVisibleEdit: true });
  }

  deleteCoordinator = (id) => {
    trackPromise(api.delete(`/users/${id}`))
      .then(response => {
        this.loadCoordinators();
        this.handleNotificationClick('Coordenador excluido com sucesso!', 'success');
      })
      .catch((error) => {
        console.log(error.message);
        this.handleNotificationClick('Erro na exclusão do coordenador! Tente novamente!', 'error');
      });
  };
  resetPassword = id => {
    trackPromise(api.put(`/users/${id}/reset_password`))
      .then(response => {
        this.loadCoordinators();
        this.handleNotificationClick('Senha resetada com sucesso!', 'success');
      })
      .catch((error) => {
        console.log(error.message);
        if (error.response.data.errors.user) {
          this.handleNotificationClick(error.response.data.errors.user[0], 'error');
        }
      });
  };
  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: (
        <div>
          {message}
        </div>
      ),
      level: color,
      position: 'tr',
      autoDismiss: 5,
      dismissible: true
    });
  };

  render() {
    const { coordinators, isModalVisibleNew } = this.state;

    const remove = (<Tooltip id="remove_tooltip">Excluir</Tooltip>);
    const resetePassword = (<Tooltip id="password_tooltip">Resetar Senha</Tooltip>);

    return (
      <>
        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Button title="cadastrar novo coordenador" bsStyle="success" fixMargin pullRight fill onClick={() => this.setState({ isModalVisibleNew: true })}>
                  <i className="fa fa-plus"></i> novo cadastro</Button>
                <Card
                  title="Coordenadores"
                  category="Gerenciamento de coordenadores"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <>

                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th>Aplicação</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            coordinators.map(coord => (
                              <tr key={coord.id}>
                                <td>{coord.id}</td>
                                <td>{coord.name}</td>
                                <td>{coord.email}</td>
                                <td>

                                  {
                                    coord.coordinator_id.length === 0 ? 'nenhuma aplicação vinculada' :
                                      coord.coordinator_id.map((elm, index) => (
                                        <Fragment key={index}>
                                          <span >{elm.application.name}</span><br />
                                        </Fragment>
                                      ))
                                  }

                                </td>
                                <td>
                                  <OverlayTrigger placement="top" overlay={resetePassword}>
                                    <Button
                                      bsStyle="warning"
                                      simple
                                      type="button"
                                      bsSize="xs"
                                      onClick={() => this.setState({
                                        confirm: true,
                                        user: coord,
                                        text: `resetar a senha de ${coord.name}`,
                                        callback: () => this.resetPassword(coord.id)
                                      })}
                                    >
                                      <i className="fa fa-unlock-alt" />
                                      Resetar senha
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" overlay={remove}>
                                    <Button
                                      bsStyle="danger"
                                      simple
                                      type="button"
                                      bsSize="xs"
                                      onClick={() => this.setState({
                                        confirm: true,
                                        user: coord,
                                        text: `excluir ${coord.name}`,
                                        callback: () => this.deleteCoordinator(coord.id)
                                      })}
                                    >
                                      <i className="fa fa-times" />
                                      Excluir
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                      {isModalVisibleNew &&
                        <Store
                          onClose={() => {
                            this.setState({
                              isModalVisibleNew: false
                            });
                            this.loadCoordinators();
                          }}
                          applications={this.state.applications}
                        />
                      }
                    </>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <Confirm
          show={this.state.confirm}
          close={() => this.setState({
            confirm: false,
            callback: undefined,
            user: {},
            text: ''
          })}
          callback={this.state.callback}
          text={this.state.text}
        />
      </>
    );
  }
}

export default Coordinators;
