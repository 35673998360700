import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";

import api from "services/api";
import Button from "components/CustomButton/CustomButton.jsx";
import CustomButton from "components/CustomButton/CustomButton";

export default function DetailInvoices({
  setModalClose,
  list_invoice,
  showModal,
}) {
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleClose = () => {
    setModalClose();
  };

  const resendInvoice = async (id) => {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    try {
      await trackPromise(
        api.post(
          `/applications_payment/${list_invoice.id}/invoice/${id}/resend`,
          config
        )
      );
    } catch (error) {
      console.error(error);
      setIsAlertVisible(true);
    }
  };

  return (
    <Modal
      size="small"
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Listagem das notas de {list_invoice?.user_full_name}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isAlertVisible && (
          <div className="alert alert-danger">
            Error ao reenviar a nota fiscal
          </div>
        )}

        <Table striped hover>
          <thead>
            <tr>
              <th>Título</th>
              <th style={{ textAlign: "center" }}>Nota Fiscal</th>
            </tr>
          </thead>
          <tbody>
            {list_invoice?.invoice.map((invoice) => (
              <tr key={invoice.id}>
                <td>{invoice?.title ? invoice.title : "Não informado"}</td>
                <td style={{ textAlign: "center" }}>
                  <Button
                    simple
                    bsSize="xs"
                    type="button"
                    target="_blank"
                    bsStyle="primary"
                    href={invoice.invoice}
                  >
                    <i className="fa fa-download" aria-hidden="true"></i>
                    Visualizar nota
                  </Button>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Button
                    simple
                    bsSize="xs"
                    type="button"
                    bsStyle="success"
                    onClick={() => resendInvoice(invoice.id)}
                  >
                    <i className="fa fa-share-square" aria-hidden="true"></i>
                    Reenviar email com nota
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <hr />

        <CustomButton bsStyle="danger" fill onClick={() => handleClose()}>
          Cancelar
        </CustomButton>
      </Modal.Body>
    </Modal>
  );
}
