import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { CSVLink } from "react-csv";
import Select from "react-select";
import { Breadcrumb } from "react-bootstrap";

import { TableLeaders } from "./components/Table.jsx";
import { useDebounce } from "hooks/useDebounce.js";
import api from "services/api";

import "./list.css";

const headers = [
  { label: "ID", key: "id" },
  { label: "NOME", key: "name" },
  { label: "E-MAIL", key: "email" },
  { label: "CANDIDATO", key: "candidate" },
  { label: "META", key: "goal" },
  { label: "DIRETOS", key: "votersDirect" },
  { label: "INDIRETOS", key: "votersIndirect" },
  { label: "LÍDERES", key: "leadersDirect" },
  { label: "LÍDERES INDIRETOS", key: "leadersIndirect" },
  { label: "REDE TOTAL", key: "total" },
];

export function LeadersOfLeaders() {
  const [leaders, setLeaders] = useState([]);
  const [leadersFiltered, setLeadersFiltered] = useState([]);
  const [leadersDetails, setLeadersDetails] = useState([]);
  const [candidates, setCandidates] = useState([]);

  const [selectedCandidate, setSelectedCandidate] = useState();
  const [search, setSearch] = useState("");
  const searchDeffered = useDebounce(search, 700);

  const appId = localStorage.getItem("idApp");

  useEffect(() => {
    getDataLeadersOfLeaders();
  }, [searchDeffered, selectedCandidate]);

  useEffect(() => {
    getCandidates();
  }, []);

  async function getDataLeadersOfLeaders() {
    try {
      const response = await trackPromise(
        api.get(`${appId}/leaders/leaders_of_leaders`, {
          params: {
            ...(search ? { name: searchDeffered } : {}),
            ...(selectedCandidate ? { candidate_id: selectedCandidate } : {}),
          },
        })
      );

      const data = response.data;

      const leadersFormatted = mapDataLeader(data);

      setLeaders(leadersFormatted);
      setLeadersFiltered(leadersFormatted);

      return leadersFormatted;
    } catch (error) {
      console.error(error);
    }
  }

  async function getCandidates() {
    try {
      const response = await api.get(`${appId}/candidates/get_by_roles`);

      const candidates = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      setCandidates(candidates);

      return candidates;
    } catch (error) {
      console.error(error);
    }
  }

  function handleSelectLeader(leader) {
    const leadersFormatted = mapDataLeader(leader.leadersChilds);

    setLeadersDetails((state) => [...state, leader]);

    setLeadersFiltered(leadersFormatted);
  }

  function handleRemoveSelectLeader({ start, leader }) {
    if (start) {
      setLeadersDetails([]);
      setLeadersFiltered(leaders);
      return;
    }

    const indexLeader = leadersDetails.findIndex(
      (item) => item.id === leader.id
    );

    if (indexLeader !== -1) {
      if (indexLeader === leadersDetails.length - 1) {
        return;
      }

      const leadersFormatted = mapDataLeader(leader.leadersChilds);

      setLeadersDetails((state) => state.slice(0, indexLeader + 1));

      setLeadersFiltered(leadersFormatted);
    }
  }

  function mapDataLeader(leaders) {
    return leaders.map((item) => ({
      id: item.id,
      name: item.name,
      email: item.email,
      goal: item.leader_data ? item.leader_data.goal : 0,
      leadersChilds: item.children,
      candidate: item.my_voting_intentions.candidate
        ? item.my_voting_intentions.candidate
        : undefined,
      votersDirect: item.direct_voting_intentions,
      votersIndirect: item.indirect_voting_itentions,
      leadersDirect: item.children.length,
      leadersIndirect: item.indirect_leaders,
      total:
        item.direct_voting_intentions +
        item.indirect_voting_itentions +
        item.children.length +
        item.indirect_leaders,
    }));
  }

  const csvData = useMemo(
    () =>
      leadersFiltered.map((item) => ({
        ...item,
        candidate: item.candidate?.name ? item.candidate.name : "Sem candidato",
      })),
    [leadersFiltered]
  );

  return (
    <div className="container-leaders">
      <div className="content-list">
        <header>
          <div className="top-header">
            <div>
              <h3>Filtros</h3>

              <div className="buttons-leaders">
                <CSVLink
                  data={csvData}
                  headers={headers}
                  filename={`Líderes-APP:${appId}-${new Date().getTime()}`}
                  className="btn-action outline"
                >
                  Gerar CSV
                </CSVLink>

                <Link to={`/admin/leadership_request`}>
                  <button className="btn-action ">
                    Solicitações de lideranças
                  </button>
                </Link>
              </div>
            </div>

            <div className="inputs-leaders">
              <div className="form-input">
                <label>Nome</label>
                <input
                  className="form-control"
                  name="voterName"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  placeholder="Buscar líder por nome"
                  style={{
                    height: "38px",
                    borderColor: "#CCCCCC",
                  }}
                />
              </div>

              <div className="form-input">
                <label>Selecione o candidato</label>
                <Select
                  name="candidate"
                  options={candidates}
                  placeholder="Busque o candidato"
                  noOptionsMessage={() => "Dados não encontrados"}
                  isSearchable
                  isClearable
                  isDisabled={candidates.length < 2}
                  onChange={(option) =>
                    setSelectedCandidate(option ? option.value : undefined)
                  }
                />
              </div>
            </div>
          </div>

          <Breadcrumb style={{ marginBottom: 0 }}>
            <Breadcrumb.Item
              onClick={() => handleRemoveSelectLeader({ start: true })}
              active={!leadersDetails.length}
            >
              Líderes majoritários
            </Breadcrumb.Item>

            {leadersDetails.map((item) => (
              <Breadcrumb.Item
                key={item.id}
                onClick={() => handleRemoveSelectLeader({ leader: item })}
                active={
                  leadersDetails[leadersDetails.length - 1].id === item.id
                }
              >
                {item.name}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </header>

        <TableLeaders
          leaders={leadersFiltered}
          handleSelectLeader={handleSelectLeader}
          updateLeaders={getDataLeadersOfLeaders}
        />
      </div>
    </div>
  );
}
