import React, { useState } from "react";
import NotificationSystem from "react-notification-system";
import {
  Col,
  Grid,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import EditButton from "components/EditButton/EditButton";
import RemoveButton from "components/RemoveButton/RemoveButton";
import CandidateTag from "components/CandidateTag/CandidateTag";
import DangerAlert from "components/DangerAlert/DangerAlert";
import { TutorialVideoModal } from "components/TutorialModal/TutorialModal";

import Store from "./store";
import Update from "./update";
import api from "services/api";

import defaultAvatar from "assets/img/default-avatar.png";
import { style as styleVariables } from "variables/Variables.jsx";
import style from "../style.module.css";

const Areas = () => {
  const appId = localStorage.getItem("idApp");
  const appType = localStorage.getItem("typeApplication");
  const [areas, setAreas] = React.useState([]);
  const [showStore, setShowStore] = React.useState(false);
  const [showUpdate, setShowUpdate] = React.useState(false);
  const [areaUpdate, setAreaUpdate] = React.useState();
  const [isOpenModal, SetIsOpenModal] = React.useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  function handleCloseModal() {
    SetIsOpenModal(false)
  }

  function handleOpenModal() {
    SetIsOpenModal(true)
  }

  function getAreas() {
    trackPromise(api.get(`/${appId}/participation_area`))
      .then((res) => setAreas(res.data.data))
      .catch((err) => console.error(err));
  }

  React.useEffect(() => {
    getAreas();
  }, []);

  function deleteArea(id) {
    trackPromise(api.delete(`/${appId}/participation_area/${id}`))
      .then(() => getAreas())
      .catch((err) => {
        console.error(err);
        setErrorMessage("Falha ao excluir área.");
      });
  }

  return (
    <>
      <div className="content">
        {errorMessage !== "" && (
          <DangerAlert error={errorMessage} close={() => setErrorMessage("")} />
        )}

        <NotificationSystem style={styleVariables} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Button
                title="cadastrar nova área de participação"
                bsStyle="primary"
                fixMargin
                pullRight
                fill
                onClick={() => setShowStore(true)}
              >
                <i className="pe-7s-albums" />
                &nbsp; Nova área de participação
              </Button>
              <Card
                title="Áreas"
                extraTitleContent={
                  <i
                    className="fa fa-question-circle"
                    style={{ fontSize: "18px", cursor: "pointer" }}
                    title="Assista ao vídeo tutorial dessa área"
                    onClick={handleOpenModal}
                  />
                }
                ctTableFullWidth
                ctTableResponsive
                content={
                  <>
                    <Table striped hover>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Nome</th>
                          {appType === "many" && <th>Candidato</th>}
                          <th>Status</th>
                          <th>Opções</th>
                        </tr>
                      </thead>
                      <tbody>
                        {areas.map((area) => (
                          <tr key={area.id}>
                            <td className="text-center">
                              <img
                                src={area.icon_link || defaultAvatar}
                                alt="foto"
                                className="mini-img"
                              />
                            </td>
                            <td>{area.name}</td>
                            {appType === "many" && (
                              <td>
                                {!!area.candidate_id ? (
                                  <CandidateTag candidate={area.candidate} />
                                ) : (
                                  <div>Não definido</div>
                                )}
                              </td>
                            )}
                            <td>
                              <div className={style.containerBadge}>
                                <span>
                                  {area.enable ? "Habilitada" : "Desabilitada"}
                                </span>
                                {area.categories_count === 0 && (
                                  <small className={style.badge}>
                                    Sem categorias
                                  </small>
                                )}
                              </div>
                            </td>
                            <td>
                              <EditButton
                                callback={() => {
                                  setAreaUpdate(area);
                                  setShowUpdate(true);
                                }}
                              />
                              <RemoveButton
                                callback={() => deleteArea(area.id)}
                              />
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="remove">
                                    Lista de categorias
                                  </Tooltip>
                                }
                              >
                                <Button
                                  bsStyle="danger"
                                  simple
                                  type="button"
                                  bsSize="xs"
                                >
                                  <NavLink
                                    to={`/admin/participation/categories/${area.id}`}
                                    className="nav-link"
                                    activeClassName="active"
                                  >
                                    <i className="pe-7s-flag" />
                                    Categorias
                                  </NavLink>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
      <TutorialVideoModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        link={"https://www.youtube.com/embed/ayMRs7JRo5E?si=oRkqGKqePJMdo2vy"}
        linkTitle={"Configurar participações"}
        title={"Configurar participações"} />
      {showStore && (
        <Store
          show={showStore}
          close={() => setShowStore(false)}
          reload={getAreas}
        />
      )}
      {showUpdate && areaUpdate && (
        <Update
          show={showUpdate}
          close={() => {
            setShowUpdate(false);
            setAreaUpdate();
          }}
          area={areaUpdate}
          reload={getAreas}
        />
      )}
    </>
  );
};

export default Areas;
