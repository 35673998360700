import Card from "components/Card/Card.jsx";
import Confirm from "components/Confirm/Confirm.jsx";
//import Pagination from 'react-bootstrap/Pagination';
//import PageItem from 'react-bootstrap/PageItem';
import Button from 'components/CustomButton/CustomButton.jsx';
import React, { Component } from "react";
import { Col, Grid, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";
import { style } from "variables/Variables.jsx";
import api from "../../services/api.jsx";
import Store from "./store.jsx";

class Administrators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      administrators: [],
      administrator: [],
      isModalVisibleEdit: false,
      isModalVisibleNew: false,
      confirm: false,
      callback: undefined,
      text: ''
    };
  }

  componentDidMount() {
    this.loadUsers();
  }

  loadUsers = async () => {
    try {
      const response = await trackPromise(api.get('/users?role=admin'));
      this.setState({ administrators: response.data.data });
    } catch (e) {
      this.handleNotificationClick(e.response.data.message, 'error');
    }
  };

  editAdministrator(value) {
    this.setState({ administrator: value });
    this.setState({ isModalVisibleEdit: true });
  }

  loadApplications = async () => {
    try {
      const response = await api.get('/applications');
      this.setState({ applications: response.data.data });
    } catch (e) {
      this.handleNotificationClick(e.response.data.message, 'error');
    }
  };

  deleteAdministrator = (id) => {
    trackPromise(api.delete(`/users/${id}`))
      .then(response => {
        this.loadUsers();
        this.handleNotificationClick('Administrador excluido com sucesso!', 'success');
      })
      .catch((error) => {
        console.log(error.message);
        this.handleNotificationClick('Erro na exclusão do administrador! ' + error.response.data.message, 'error');
      });
  };

  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: (
        <div>
          {message}
        </div>
      ),
      level: color,
      position: 'tr',
      autoDismiss: 5,
      dismissible: true
    });
  };

  resetPasswordCandidate = id => {
    api.put(`/users/${id}/reset_password`)
      .then(response => {
        this.loadUsers();
        this.handleNotificationClick('Senha resetada com sucesso!', 'success');
      })
      .catch((error) => {
        console.log(error.message)
        if (error.response.data.errors.user) {
          this.handleNotificationClick(error.response.data.errors.user[0], 'error');
        }
      });
  }

  render() {
    const { administrators, isModalVisibleNew } = this.state;

    const remove = (<Tooltip id="remove_tooltip">Excluir</Tooltip>);
    const resetePassword = (<Tooltip id="password_tooltip">Resetar Senha</Tooltip>);

    return (
      <>
        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Button title="cadastrar novo administrador" bsStyle="success" size="xs" fixMargin pullRight fill onClick={() => this.setState({ isModalVisibleNew: true })}>
                  <i className="fa fa-plus"></i> novo cadastro</Button>
                <Card
                  title="Administradores"
                  category="Administradores cadastrados"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <>
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            administrators.map(admin => (
                              <tr key={admin.id}>
                                <td>{admin.id}</td>
                                <td>{admin.name}</td>
                                <td>{admin.email}</td>
                                <td>
                                  <OverlayTrigger placement="top" overlay={resetePassword}>
                                    <Button
                                      bsStyle="warning"
                                      simple
                                      type="button"
                                      bsSize="xs"
                                      onClick={() => this.setState({
                                        text: `resetar a senha de ${admin.name}`,
                                        confirm: true,
                                        callback: () => this.resetPasswordCandidate(admin.id),
                                      })
                                      }
                                    >
                                      <i className="fa fa-unlock-alt" />
                                      Resetar senha
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" overlay={remove}>
                                    <Button
                                      bsStyle="danger"
                                      simple
                                      type="button"
                                      bsSize="xs"
                                      onClick={() => this.setState({
                                        text: `excluir ${admin.name}`,
                                        confirm: true,
                                        callback: () => this.deleteAdministrator(admin.id)
                                      })}
                                    >
                                      <i className="fa fa-times" />
                                      Excluir
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                      {isModalVisibleNew &&
                        <Store onClose={() => {
                          this.setState({ isModalVisibleNew: false });
                          this.loadUsers();
                        }}
                        />
                      }
                    </>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <Confirm
          show={this.state.confirm}
          close={() => this.setState({
            confirm: false,
            callback: undefined,
            text: ''
          })}
          callback={this.state.callback}
          text={this.state.text}
        />
      </>
    );
  }
}

export default Administrators;
