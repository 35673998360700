import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import {
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  Form,
  InputGroup,
  HelpBlock,
} from "react-bootstrap";
import { Info, X } from "@phosphor-icons/react";

import Button from "components/CustomButton/CustomButton.jsx";
import { SelectIcon } from "../../components/SelectIcon";

import { useQueryParams } from "hooks/useQueryParams";
import { useGroup } from "./hooks/useGroup";
import api from "services/api";

import "./styles.css";

export function UpdateGroup({ isOpen, onClose }) {
  const appId = localStorage.getItem("idApp");

  const queryParams = useQueryParams();

  const [tagsRemoved, setTagsRemoved] = useState([]);

  const { group } = useGroup({
    appId,
    id: queryParams.get("group_edit_id")
      ? Number(queryParams.get("group_edit_id"))
      : undefined,
  });

  const {
    control,
    register,
    handleSubmit,
    unregister,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      typeImage: "",
      previewImageUrl: "",
      icon: "",
      tags: [],
    },
  });

  useEffect(() => {
    if (group) {
      reset({
        name: group.name,
        typeImage: group.image ? group.image.type : "",
        previewImageUrl: group.image?.type === "image" ? group.image.link : "",
        icon: group.image?.type === "icon" ? group.image.link : "",
        tags: group.tags,
      });
    }
  }, [group]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tags",
  });

  const typeImage = useWatch({
    control,
    name: "typeImage",
  });

  async function handleUpdateGroup({
    name,
    typeImage,
    image,
    previewImageUrl,
    icon,
    tags,
  }) {
    try {
      const formData = new FormData();

      formData.append("_method", "PUT");
      formData.append("title", name);

      if (typeImage === "image") {
        if (image.length && image[0]) {
          formData.append("imagem", image[0], "imagem.png");
        } else if (previewImageUrl && !image.length) {
          formData.append("image", previewImageUrl);
        } else {
          formData.append("image", "");
        }
      } else if (icon) {
        formData.append("image", icon);
      }

      const { tagsUpdated, tagsAdded } = tags.reduce(
        (data, item) => {
          item.tagId ? data.tagsUpdated.push(item) : data.tagsAdded.push(item);
          return data;
        },
        {
          tagsUpdated: [],
          tagsAdded: [],
        }
      );

      tagsRemoved.forEach((item) => {
        formData.append("tags_removed[]", item);
      });

      tagsUpdated.forEach((item) => {
        formData.append("tags_updated[]", JSON.stringify(item));
      });

      tagsAdded.forEach((item) => {
        formData.append("tags[]", item.name);
      });

      await trackPromise(
        api.post(
          `${appId}/group_information/${queryParams.get("group_edit_id")}`,
          formData
        )
      );

      handleOnClose(true);
    } catch (error) {
      console.error(error);
    }
  }

  function handleOnChangeTypeImage(field) {
    field === "image" ? unregister("icon") : unregister("image");
  }

  function handleOnClose(success) {
    reset({
      name: "",
      typeImage: "",
      previewImageUrl: "",
      icon: "",
      tags: [],
    });

    onClose(success);
  }

  function handleOnChangeImage(files) {
    if (files.length) {
      setValue("previewImageUrl", URL.createObjectURL(files[0]));
    }
  }

  function handleRemoveImage() {
    setValue("previewImageUrl", "");
    setValue("image", "");
  }

  const previewImageUrl = useWatch({
    control,
    name: "previewImageUrl",
  });

  return (
    <Modal show={isOpen} onHide={() => handleOnClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Atualizar grupo</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          id="form-update-group"
          className="form-update-group"
          onSubmit={handleSubmit(handleUpdateGroup)}
        >
          <FormGroup>
            <ControlLabel>Nome</ControlLabel>

            <Controller
              control={control}
              name="name"
              rules={{ required: "Informe o nome do grupo" }}
              render={({ field: { value, onChange } }) => (
                <FormControl
                  type="text"
                  value={value}
                  placeholder="Nome do grupo"
                  onChange={onChange}
                />
              )}
            />

            {errors.name ? <HelpBlock>{errors.name.message}</HelpBlock> : null}
          </FormGroup>

          <div>
            <ControlLabel>Imagem</ControlLabel>

            <div className="row">
              <div className="col-md-6">
                <label htmlFor="icon" className="container-radio">
                  <input
                    id="image"
                    type="radio"
                    value="image"
                    {...register("typeImage", {
                      onChange: (event) =>
                        handleOnChangeTypeImage(event.target.value),
                    })}
                  />
                  Usar imagem
                </label>

                <FormGroup>
                  <input
                    type="file"
                    disabled={typeImage === "icon"}
                    {...register("image", {
                      onChange: (event) =>
                        handleOnChangeImage(event.target.files),
                      ...(typeImage === "image" && !previewImageUrl
                        ? { required: "Informe a imagem" }
                        : {}),
                    })}
                  />

                  <div className="help-text">
                    <Info size={16} color="#6b7280" />
                    <span>Imagem preferencialmente com resolução quadrada</span>
                  </div>

                  {errors.image && typeImage === "image" ? (
                    <HelpBlock>{errors.image.message}</HelpBlock>
                  ) : null}
                </FormGroup>

                {typeImage === "image" && previewImageUrl ? (
                  <div className="preview_image">
                    <img src={previewImageUrl} alt="" />
                    <button type="button" onClick={handleRemoveImage}>
                      <X weight="bold" />
                    </button>
                  </div>
                ) : null}
              </div>

              <div className="col-md-6">
                <label htmlFor="icon" className="container-radio">
                  <input
                    id="icon"
                    type="radio"
                    value="icon"
                    {...register("typeImage")}
                  />
                  Usar ícone
                </label>

                <Controller
                  control={control}
                  name="icon"
                  rules={{
                    ...(typeImage === "icon"
                      ? { required: "Informe o ícone" }
                      : {}),
                  }}
                  render={({ field: { value, onChange } }) => (
                    <SelectIcon
                      selected={value}
                      onChange={onChange}
                      disabled={typeImage === "image"}
                      {...(typeImage === "icon" && errors.icon
                        ? { error: errors.icon.message }
                        : {})}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="container-tags">
            <ControlLabel>Tags</ControlLabel>

            <div className="content-tags">
              {fields.length ? (
                <FormGroup className="tags">
                  {fields.map((item, index) => (
                    <InputGroup key={item.id}>
                      <Controller
                        control={control}
                        name={`tags.${index}.name`}
                        render={({ field: { value, onChange } }) => (
                          <FormControl
                            type="text"
                            value={value}
                            placeholder="Nome da tag"
                            onChange={onChange}
                            required
                          />
                        )}
                      />

                      <InputGroup.Button
                        onClick={() => {
                          remove(index);
                          setTagsRemoved((prevState) => [
                            ...prevState,
                            item.tagId,
                          ]);
                        }}
                      >
                        <Button bsStyle="danger" type="button">
                          <i className="fa fa-trash" />
                        </Button>
                      </InputGroup.Button>
                    </InputGroup>
                  ))}
                </FormGroup>
              ) : null}

              <button type="button" onClick={() => append({ name: "" })}>
                <i className="fa fa-plus" />
                Adicionar nova tag
              </button>
            </div>
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button fill onClick={() => handleOnClose()}>
          Cancelar
        </Button>

        <Button
          bsStyle="success"
          pullRight
          fill
          form="form-update-group"
          type="submit"
        >
          Adicionar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
