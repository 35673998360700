import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import NotificationSystem from "react-notification-system";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import { Onboarding } from "components/Onboarding/Onboarding";

import { style } from "variables/Variables.jsx";

import routes from "routes.js";

import image from "assets/img/background/fd2.png";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      image: image,
      color: "black",
      hasImage: true,
      fixedClasses: "dropdown",
      user: JSON.parse(localStorage.getItem("user")),
      typeRole: localStorage.getItem("typeRole"),
    };
  }

  // const user = JSON.parse(localStorage.getItem("user"));
  // const typeApplication = localStorage.getItem("typeApplication");

  getRoutes = (routes) => {
    return routes
      .filter((route) => {
        return route.role.indexOf(this.state.typeRole) != -1;
      })
      .map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              render={(props) => <prop.component {...props} />}
              key={key}
              exact={!!prop.exact}
            />
          );
        } else {
          return null;
        }
      });
  };
  getBrandText = (path) => {
    let routesMaps = routes.filter((route) => {
      return route.role === this.state.typeRole;
    });
    for (let i = 0; i < routesMaps.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routesMaps[i].layout + routesMaps[i].path
        ) !== -1
      ) {
        return routesMaps[i].name;
      }
    }

    return "";
  };
  handleImageClick = (image) => {
    this.setState({ image: image });
  };
  handleColorClick = (color) => {
    this.setState({ color: color });
  };
  handleHasImage = (hasImage) => {
    this.setState({ hasImage: hasImage });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  render() {
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />

        <Sidebar
          {...this.props}
          routes={routes}
          image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
        />

        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />

          <Onboarding />

          <Switch>{this.getRoutes(routes)}</Switch>

          <Footer />
        </div>
      </div>
    );
  }
}

export default Admin;
