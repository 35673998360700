import { useSetAtom } from "jotai";
import { OnboardingOpenAtom } from "components/Onboarding/atoms/OnboardingOpenAtom";

import "./styles.css";

export function TutorialStep() {
  const setOnboardingOpen = useSetAtom(OnboardingOpenAtom);

  function handleOnClose() {
    setOnboardingOpen(false);
    localStorage.removeItem("onboarding");
  }

  return (
    <div className="content tutorial-step">
      <div className="heading">
        <strong>Pronto para dar o primeiro passo à vitória?</strong>
        <span>Confira o vídeo e começe a usar seu Mobby Candidato</span>
      </div>

      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/58bH-b6YpHE?si=cL-fTjC5cYPFQzQ5"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />

      <button onClick={handleOnClose}>Concluir</button>
    </div>
  );
}
