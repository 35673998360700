import React from "react";
import { FormControl } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css'
import "./leads-filter.css";

const LeadsFilter = ({ callback, reset }) => {
	const [phone, setPhone] = React.useState('');
	const [name, setName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [dateBegin, setDateBegin] = React.useState('');
	const [dateEnd, setDateEnd] = React.useState('');

	function handleSubmit(event) {
		event.preventDefault();
		const filters = [];

		if (dateBegin) {
			filters.push(`first_date=${dateBegin}`)
		}

		if (dateEnd) {
			filters.push(`last_date=${dateEnd}`)
		}

		if (name) {
			filters.push(`name=${name}`)
		}

		if (email) {
			filters.push(`email=${email}`)
		}

		if (phone) {
			filters.push(`user_phone=${phone}`)
		}

		const url = filters.length ? `${filters.join("&")}&` : "";

		callback(url);
	}

	function removeNonNumericCharacters(value) {
		return value.replace(/[^0-9]/g, "");
	}

	const handleReset = () => {
		setPhone('');
		setName('');
		setEmail('');
		setDateBegin('');
		setDateEnd('');
		reset();
	};

	const blue = '#613BCE';
	const red = '#FF4A55';

	function handleCallback(start, end) {
		setDateBegin(start.toDate().toISOString())
		setDateEnd(end.toDate().toISOString())
	};

	return (
		<div className="form-container leads-page">
			<form onSubmit={handleSubmit}>
				<div className="form-group">
					<div className="label-group">
						<label htmlFor="name">Nome</label>
						<FormControl
							placeholder="Nome"
							name="name"
							id="name"
							className="form-field"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>

					<div className="label-group">
						<label htmlFor="email">Email</label>
						<FormControl
							type="email"
							placeholder="Email"
							name="email"
							id="email"
							className="form-field"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>

					<div className="label-group">
						<label htmlFor="user_phone">Telefone</label>
						<FormControl
							type="tel"
							placeholder="Telefone"
							name="user_phone"
							id="user_phone"
							value={phone}
							onChange={(e) => setPhone(removeNonNumericCharacters(e.target.value))}
						/>
					</div>
					
					<div className="form-date">
						<label htmlFor="created_at">Data</label>
						<DateRangePicker
							initialSettings={{ startDate: '1/1/2024', endDate: '1/12/2024' }}
							onCallback={handleCallback}
						>
							<input
								type="text"
								className="form-control"
							/>
						</DateRangePicker>
					</div>
					
					<div className="form-actions">
						<button type="submit" className="form-button" style={{ background: blue }}>
							Buscar
						</button>
						<button
							type="button"
							style={{ background: red }}
							className="form-button"
							onClick={handleReset}
						>
							Limpar
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default LeadsFilter;
