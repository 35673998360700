import Card from "components/Card/Card.jsx";
import Confirm from "components/Confirm/Confirm.jsx";
import Button from 'components/CustomButton/CustomButton.jsx';
import React, { Component } from "react";
import { Col, Grid, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { TutorialVideoModal } from "components/TutorialModal/TutorialModal.jsx";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";
import { style, thArray } from "variables/Variables.jsx";
import api from "../../services/api.jsx";
import Store from "./store.jsx";
import Update from "./update.jsx";



class Candidates extends Component {
  typeApplication = localStorage.getItem("typeApplication")
  state = {
    _notificationSystem: null,
    role: localStorage.getItem('typeRole'),
    app: localStorage.getItem('idApp'),
    candidates: [],
    elective_positions: [],
    political_parties: [],
    candidate: null,
    isModalVisibleEdit: false,
    isModalVisibleNew: false,
    isModalVisible: false,
    callback: undefined,
    text: {},
    confirm: false
  };

  openComunicationModal = () => {
    this.setState({ isModalVisible: true });
  };

  componentDidMount() {
    this.loadCandidates();
    this.loadElectivePositions();
    this.loadPoliticalParties();
  }

  loadPoliticalParties = async () => {
    try {
      const response = await api.get('/political_parties');

      this.setState({ political_parties: response.data.data });
    } catch (e) { }
  };

  loadElectivePositions = async () => {
    try {
      const response = await api.get('/elective_positions');
      this.setState({ elective_positions: response.data.data });
    } catch (e) { }
  };

  setVisibleModal(e) {
    this.setState({ isModalVisibleEdit: e });
  }

  editCandidate(event) {
    this.setState({ candidate: event });
    this.setVisibleModal(true);
  }

  loadCandidates = async () => {
    const response = await trackPromise(api.get(`/${this.state.app}/candidates`));
    this.setState({ candidates: response.data.data });
  };

  deleteCandidate = (id) => {
    trackPromise(api.delete(`/${this.state.app}/candidates/${id}`))
      .then(response => {
        this.loadCandidates();
        this.handleNotificationClick('Candidato excluido com sucesso!', 'success');
      })
      .catch((error) => {
        console.log(error.message);
        this.handleNotificationClick('Erro na exclusão do candidato! Tente novamente!', 'error');
      });
  };

  resetPasswordCandidate = id => {
    trackPromise(api.put(`/users/${id}/reset_password`))
      .then(response => {
        this.loadCandidates();
        this.handleNotificationClick('Senha resetada com sucesso!', 'success');
      })
      .catch((error) => {
        console.log(error.message);
        if (error.response.data.errors && error.response.data.errors.user) {
          this.handleNotificationClick(error.response.data.errors.user[0], 'error');
        } else {
          this.handleNotificationClick('Erro ao resetar senha do candidato. ' + error.response.data.message, 'error');
        }
      });
  };

  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: (
        <div>
          {message}
        </div>
      ),
      level: color,
      position: 'tr',
      autoDismiss: 5,
      dismissible: true
    });
  };

  render() {
    const { candidates, isModalVisibleEdit, isModalVisibleNew, elective_positions, political_parties, isModalVisible } = this.state;

    const remove = (<Tooltip id="remove_tooltip">Excluir</Tooltip>);
    const edit = (<Tooltip id="edit_tooltip">Editar</Tooltip>);
    const resetePassword = (<Tooltip id="password_tooltip">Resetar Senha</Tooltip>);


    return (
      <>
        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <Row>
              <Col md={12}>
                {
                  !(this.typeApplication == 'one' && candidates.length > 0)
                    ? <Button
                      title="cadastrar novo candidato"
                      bsStyle="primary"
                      fixMargin pullRight fill
                      onClick={() => this.setState({ isModalVisibleNew: true })}
                    >
                      <i className="fa fa-user-plus" />
                      Novo Candidato
                    </Button>
                    : null
                }
                <Card
                  title="Candidatos"
                  category="Candidatos cadastrados"
                  extraTitleContent={
                    <i
                      className="fa fa-question-circle"
                      style={{ fontSize: "18px", cursor: "pointer" }}
                      title="Assista ao vídeo tutorial dessa área"
                      onClick={this.openComunicationModal}
                    />
                  } 
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <>
                      <Table striped hover>
                        <thead>
                          <tr>
                            {thArray.filter(elem => elem !== "Email").map((prop, key) => {
                              return <th key={key}>{prop}</th>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {candidates.map(candidate => (
                            <tr key={candidate.id}>
                              <td>{candidate.name}</td>
                              <td>{candidate.elective_position}</td>
                              <td>{candidate.number}</td>
                              <td>
                                <span className={`badge badge-${candidate.is_active ? 'success' : 'danger'}`}>
                                  {candidate.is_active ? 'Ativo' : 'Inativo'}
                                </span>
                              </td>
                              <td>
                                <OverlayTrigger placement="top" overlay={edit}>
                                  <Button
                                    bsStyle="info"
                                    simple
                                    type="button"
                                    bsSize="xs"
                                    onClick={() => this.editCandidate(candidate)}
                                  >
                                    <i className="fa fa-edit" />
                                    Editar
                                  </Button>
                                </OverlayTrigger>
                                {/* {this.state.role !== "advisor" ? <OverlayTrigger placement="top" overlay={remove}> */}
                                {/* <Button
                                    bsStyle="danger"
                                    simple
                                    type="button"
                                    bsSize="xs"
                                    onClick={() => this.setState({
                                      callback: () => this.deleteCandidate(candidate.id),
                                      text: `excluir o candidato ${candidate.name}`,
                                      confirm: true
                                    })}
                                  >
                                    <i className="fa fa-times" />
                                    Excluir
                                  </Button> */}
                                {/* </OverlayTrigger> : null} */}
                                <OverlayTrigger placement="top" overlay={resetePassword}>
                                  <Button
                                    bsStyle="warning"
                                    simple
                                    type="button"
                                    bsSize="xs"
                                    onClick={() => this.setState({
                                      callback: () => this.resetPasswordCandidate(candidate.user.id),
                                      text: `resetar a senha do candidato ${candidate.name}`,
                                      confirm: true
                                    })}
                                  >
                                    <i className="fa fa-unlock-alt" />
                                    Resetar senha
                                  </Button>
                                </OverlayTrigger>

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {isModalVisibleEdit && <Update onClose={() => { this.setState({ isModalVisibleEdit: false }); this.loadCandidates(); }} eletivePositions={elective_positions} politicalParties={political_parties} candidato={this.state.candidate} />}
                      {isModalVisibleNew && <Store onClose={() => { this.setState({ isModalVisibleNew: false }); this.loadCandidates(); }} eletivePositions={elective_positions} politicalParties={political_parties} />}
                    </>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <TutorialVideoModal
          isOpen={isModalVisible}
          onClose={() => this.setState({ isModalVisible: false })}
          link={"https://www.youtube.com/embed/NhiVpOE73LE?si=RdH7cd5W1RkieZGz"}
          linkTitle={"Configurações gerais"}
          title={"Configurações gerais"} />

        <Confirm
          show={this.state.confirm}
          close={() => this.setState({
            confirm: false,
            callback: undefined,
            text: ''
          })}
          callback={this.state.callback}
          text={this.state.text}
        />
      </>
    );
  }
}

export default Candidates;
