import { useEffect, useState } from "react";

import api from "services/api";

export function useAssignedTags({ appId, id, type }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getAssignedTags() {
      try {
        const response = await api.get(
          `${appId}/tags/assigned/${id}?type=${type}`
        );

        if (!response.data) {
          return;
        }

        const tags = response.data.map((item) => ({
          id: item.id,
          name: item.title,
        }));

        setData(tags);
      } catch (error) {
        console.error(error);
      }
    }

    if (id && type) {
      getAssignedTags();
    }
  }, [id, type]);

  return { assignedTags: data };
}
