import React from "react";
import {
  Map as LeafletMap,
  Marker as MarkerLeaflet,
  TileLayer,
} from "react-leaflet";

import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";

function Marker({ position }) {
  const positionMarker = [
    position?.lat ? position.lat : 0,
    position?.lng ? position.lng : 0,
  ];

  return <MarkerLeaflet interactive={false} position={positionMarker} />;
}

export function MapCreate({
  interactive = true,
  position,
  handleSetPosition,
  ...props
}) {
  const positionCenter = [
    position?.lat ? position.lat : -8.910475,
    position?.lng ? position.lng : -54.3025137,
  ];

  return (
    <LeafletMap
      center={positionCenter}
      zoom={15}
      zoomControl={interactive}
      scrollWheelZoom={interactive}
      doubleClickZoom={interactive}
      touchZoom={interactive}
      boxZoom={interactive}
      style={{ height: "100%" }}
      {...(interactive ? { onClick: (e) => handleSetPosition(e.latlng) } : {})}
      {...props}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={position} />
    </LeafletMap>
  );
}
