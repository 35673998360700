import { Table } from "react-bootstrap";
import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";

import api from "services/api";
import Swal from "sweetalert2";
import Card from "components/Card/Card";
import Button from "components/CustomButton/CustomButton";
import CustomButton from "components/CustomButton/CustomButton";
import DeleteModal from "./components/delete-notification-leader";
import { TutorialVideoModal } from "components/TutorialModal/TutorialModal";
import { EmptyNotificationLeaders } from "./components/empty-notification";

const ListNotificationLeads = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedCommunication, setSelectedCommunication] = useState(null);
  const [listCommunicationLeader, setListCommunicationLeader] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const history = useHistory();
  const appId = localStorage.getItem('idApp');

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOnCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    getCommunicationLeader();
  }, [appId]);

  const getCommunicationLeader = async () => {
    try {
      const response = await trackPromise(api.get(`${appId}/leadership_news`));

      setListCommunicationLeader(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const createNewComunicationLeaders = () => {
    history.push({ pathname: `/admin/create-notification-leader` });
    Lideranças
    Eleitores
    Comunicação

    Notícias

  };

  const handleEditCommunicationLeaders = (id) => {
    history.push({ pathname: `/admin/update-notification-leader/${id}` });
  }

  const handleDeleteCommunicationLeaders = (id) => {
    setSelectedCommunication(id);
    setShowModalDelete(true);
  }

  const confirmDeleteCommunication = async () => {
    try {
      await trackPromise(api.delete(`${appId}/leadership_news/${selectedCommunication}`));

      Toast.fire({
        icon: "success",
        title: "Comunicação excluída com sucesso!"
      });

      setShowModalDelete(false);
      getCommunicationLeader();
    } catch (error) {
      console.error(error);

      Toast.fire({
        icon: "error",
        title: "Erro ao excluir a comunicação."
      });
    }
  }

  const handleCloseConfirmationModal = () => {
    setShowModalDelete(false);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  return (
    <div className="content">
      <CustomButton
        style={{ display: 'flex', gap: "3px", alignItems: "center", marginTop: "15px", marginRight: "15px" }}
        title="Cadastrar comunicação para liderança"
        bsStyle="primary"
        fixMargin pullRight fill
        onClick={createNewComunicationLeaders}
      >
        <i className="fa fa-user-plus" />
        Criar comunicação
      </CustomButton>
      <Card
        title="Comunicações para as lideranças"
        category="Gerenciamento das comunicações para as lideranças do candidato"
        ctTableFullWidth
        extraTitleContent={
          <i
            className="fa fa-question-circle"
            style={{ fontSize: "18px", cursor: "pointer" }}
            title="Assista ao vídeo tutorial dessa área"
            onClick={handleOpenModal}
          />
        }
        ctTableResponsive
        content={
          listCommunicationLeader?.length === 0 ? (
            <EmptyNotificationLeaders />

          ) : (
            <Table striped hover>
              <thead>
                <tr>
                  <th>Título</th>
                  <th>Descrição</th>
                  <th>Candidato</th>
                </tr>
              </thead>
              <tbody>
                {listCommunicationLeader?.map((communication) => (
                  <tr key={communication.id}>
                    <td>{communication.title}</td>
                    <td>{communication.description}</td>
                    <td>{communication.candidate?.name || "Não foi selecionado"}</td>

                    <td>
                      <Button
                        bsStyle="info"
                        simple
                        type="button"
                        bsSize="xs"
                        onClick={() => handleEditCommunicationLeaders(communication.id)}
                      >
                        <i className="fa fa-edit"></i>
                        Editar
                      </Button>

                      <Button
                        bsStyle="danger"
                        simple
                        type="button"
                        bsSize="xs"
                        onClick={() => handleDeleteCommunicationLeaders(communication.id)}
                      >
                        <i className="fa fa-times" />
                        Excluir
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )
        }
      />

      <TutorialVideoModal isOpen={openModal}
        onClose={handleOnCloseModal}
        link={"https://www.youtube.com/embed/lV-IKFdVjzI?si=huI0YXaVDx973TxL"}
        title={"Notícias, eventos e pesquisas"}
        linkTitle={"Área de comunicação"} />

      <DeleteModal
        show={showModalDelete}
        onClose={handleCloseConfirmationModal}
        onConfirm={confirmDeleteCommunication}
      />
    </div>
  )
}

export default ListNotificationLeads;