import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import api from "services/api.jsx";

const Store = ({ onClose = () => { } }) => {
  const [show, setShow] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [file, setFile] = useState();
  const [name, setName] = useState(null);
  const [year, setyear] = useState(null);
  const [slug, setSlug] = useState("");
  const [type_application_id, setTypeApplication] = useState(null);

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    const formData = new FormData();

    formData.append("name", name);
    formData.append("type_application_id", type_application_id);
    formData.append("year", year);
    formData.append("slug", slug);

    if(file && file.name) formData.append("policy", file, file.name);

    trackPromise(api.post(`/applications`, formData, config))
      .then((response) => onClose())
      .catch((error) => setIsAlertVisible(true));
  };

  function slugfy(text) {
    return text
      .toString() // Cast to string (optional)
      .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string (optional)
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\_/g, "-") // Replace _ with -
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/\-$/g, ""); // Remove trailing -
  }

  return (
    <>
      <Modal size="sm" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de Aplicação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            {isAlertVisible && <div className="alert-error">Erro no cadastro da aplicação!</div>}

            <FormInputs
              ncols={["col-md-8", "col-md-4"]}
              properties={[
                {
                  label: "Nome",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Nome Aplicação",
                  required: true,
                  defaultValue: name,
                  onChange: (event) => setName(event.target.value),
                },
                {
                  label: "Ano",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Ano",
                  required: true,
                  defaultValue: year,
                  onChange: (event) => setyear(event.target.value),
                },
              ]}
            />

            <FormInputs
              ncols={["col-md-12"]}
              properties={[
                {
                  label: "Slug",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Slug da aplicação",
                  required: true,
                  value: slug,
                  onChange: (event) => {setSlug(slugfy(event.target.value))},
                }
              ]}
            />

            <Row>
              <Col md={12}>
                <div className="form-group">
                  <label className="">Tipo aplicação</label>
                  <select
                    name="type_application"
                    defaultValue={type_application_id}
                    onChange={(event) => setTypeApplication(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">Selecione o tipo de aplicação</option>
                    <option value="one">Aplicativo de um candidato</option>
                    <option value="many">Aplicativo de vários candidatos</option>
                  </select>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="form-group">
                  <label className="">Política de privacidade</label>
                  <input
                    type="file"
                    accept=".pdf"
                    className="form-control"
                    name="political-privacy"
                    onChange={event => setFile(event.target.files[0])}
                  />
                </div>
              </Col>
            </Row>

            <hr />

            <Button bsStyle="danger" fill onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button bsStyle="info" pullRight fill type="submit">
              Cadastrar
            </Button>
            <div className="clearfix" />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Store;
