import Card from "components/Card/Card.jsx";
import Confirm from "components/Confirm/Confirm.jsx";
import Button from 'components/CustomButton/CustomButton.jsx';
import React, { Component } from "react";
import { Col, Grid, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";
import { style } from "variables/Variables.jsx";
import api from "../../services/api.jsx";
import Store from "./store.jsx";
import Update from "./update.jsx";
import newsStyles from "./styles.module.css";
import { TutorialVideoModal } from "components/TutorialModal/TutorialModal.jsx";

class News extends Component {
  typeApplication = localStorage.getItem("typeApplication");
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      news: [],
      isModalVisibleEdit: false,
      isModalVisibleNew: false,
      new: null,
      candidates: [],
      isComunicationModalVisible: false,
      candidateName: 'Notícias do App',
      candidate: JSON.parse(localStorage.getItem('user')).candidate_id ? JSON.parse(localStorage.getItem('user')).candidate_id : 0,
      app: localStorage.getItem('idApp'),
      role: JSON.parse(localStorage.getItem('user')).role,
      confirm: false,
      text: '',
      callback: undefined,
      onlyLeaders: 0
    };
  }
  // state de cilco de vida de inicialização do componente
  componentDidMount() {
    if (this.state.role === "candidate")
      this.loadNews(this.state.candidate);
    if (this.state.role === "coordinator" || this.state.role === "advisor")
      this.loadCandidates();
    this.loadNews(0, 0);
  }
  // edita uma noticia
  editNew(event) {
    this.setState({ new: event });
    this.setState({ isModalVisibleEdit: true });
  }
  //carrega as noticias de um candidato
  loadNews = async (idCandidate, onlyLeaders) => {
    try {
      const response = await trackPromise(api.get(`/${this.state.app}/candidates/${idCandidate}/news?onlyLeaders=${onlyLeaders ? onlyLeaders : this.state.onlyLeaders}`));
      this.setState({ news: response.data.data });
    } catch (e) {
      //this.handleNotificationClick(e.response.data.message, 'error');
    }
  };
  // seta no estate o id do candidato selecionado
  changeCandidate = (candidateId) => {

    this.setState({ "candidate": candidateId });

    if (candidateId == "0") {
      this.setState({ candidateName: "Notícia do App" });
    } else {
      this.state.candidates.forEach(candidate => {
        if (candidate.id == candidateId) {
          this.setState({ candidateName: candidate.name });
        }
      });
    }

    this.loadNews(candidateId, this.state.onlyLeaders);
  };

  changeOnlyLeaders = (e) => {
    this.setState({ "onlyLeaders": e.target.value });
    this.loadNews(this.state.candidate, e.target.value);
  };
  // carrega os candidatos
  loadCandidates = async () => {
    const response = await api.get(`/${this.state.app}/candidates?onlyActives=1`);
    this.setState({ candidates: response.data.data });
    if (this.typeApplication === 'one' && response.data.data.length > 0) {
      this.changeCandidate(response.data.data[0].id);
    }
  };
  //deleta uma noticia
  deleteNew = (idNew) => {
    trackPromise(api.delete(`/${this.state.app}/candidates/${this.state.candidate}/news/${idNew}`))
      .then(response => {
        this.loadNews(this.state.candidate);
        this.handleNotificationClick('Noticia excluida com sucesso!', 'success');
      })
      .catch((error) => {
        console.log(error.message);
        this.handleNotificationClick('Erro na exclusão da noticia! Tente novamente!', 'error');
      });
  };
  // componente de notificação
  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: (
        <div>
          {message}
        </div>
      ),
      level: color,
      position: 'tr',
      autoDismiss: 5,
      dismissible: true
    });
  };
  openComunicationModal = () => {
    this.setState({ isComunicationModalVisible: true });
  };
  // renderzação do componente
  render() {
    const { news, isModalVisibleEdit, candidates, candidate, role, isModalVisibleNew, onlyLeaders, candidateName, isComunicationModalVisible } = this.state;

    const remove = (<Tooltip id="remove_tooltip">Excluir</Tooltip>);
    const edit = (<Tooltip id="edit_tooltip">Editar</Tooltip>);

    return (
      <>
        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <Row>
              <Col md={12}>
                {
                  (role === "advisor" && parseInt(candidate) === 0) ? null : <Button
                    title="cadastrar nova noticia"
                    bsStyle="primary" fixMargin pullRight fill
                    style={{ marginTop: "15px", marginRight: "15px" }}
                    disabled={(this.typeApplication === 'one' && !candidate)}
                    onClick={() => this.setState({ isModalVisibleNew: true })}>
                    <i className="fa fa-plus"></i> Nova Notícia</Button>
                }
                <Card
                  title="Notícias"
                  extraTitleContent={
                    <i
                      className="fa fa-question-circle"
                      style={{ fontSize: "18px", cursor: "pointer" }}
                      title="Assista ao vídeo tutorial dessa área"
                      onClick={this.openComunicationModal}
                    />
                  }
                  category="Notícias cadastradas de um candidato"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <>
                      {(role === "coordinator" || role === "advisor") &&
                        <form >
                          <div className={`${newsStyles.alignCenter} form-group`}>
                            <label className="col-sm-2 col-form-label">
                              Selecione o candidato:
                            </label>
                            <div className="col-sm-5">
                              <select
                                name="candidate"
                                value={candidate}
                                onChange={(e) => this.changeCandidate(e.target.value)}
                                disabled={this.typeApplication === 'one'}
                                className="form-control"
                              >
                                {this.typeApplication === 'one' ? null : <option value={0}>Notícias do App</option>}
                                {candidates.map(candidate => (
                                  <option key={candidate.id} value={candidate.id}>{candidate.name}</option>
                                ))}
                              </select>
                            </div>
                            <label className="col-sm-2 col-form-label text-right">
                              Tipo de notícias:
                            </label>
                            <div className="col-sm-3">
                              <select
                                name="onlyLeaders"
                                defaultValue={onlyLeaders}
                                onChange={this.changeOnlyLeaders}
                                className="form-control"
                              >
                                <option value={0}>Notícias gerais</option>
                                <option value={1}>Notícias apenas de líderes</option>
                              </select>
                            </div>
                          </div>
                        </form>
                      }
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>Imagem</th>
                            <th>Título</th>
                            <th>Visibilidade</th>
                            {/* <th>Destaque</th> */}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            news && news.map(event => (
                              <tr key={event.id}>
                                <td><img className="img-list" src={event.link_image_header} alt={event.link_image_header} /></td>
                                <td>{event.title}</td>
                                <td>
                                  <span className={`badge badge-${event.is_active ? 'success' : 'danger'}`}>
                                    {event.is_active ? 'Vísivel' : 'Oculta'}
                                  </span>
                                </td>
                                {/* <td>
                                  <span className={`badge badge-${event.highlighted ? 'success' : 'danger'}`}>
                                    {event.highlighted ? 'Sim' : 'Não'}
                                  </span>
                                </td> */}
                                <td>
                                  <OverlayTrigger placement="top" overlay={edit}>
                                    <Button
                                      bsStyle="info"
                                      simple
                                      type="button"
                                      bsSize="xs"
                                      onClick={() => this.editNew(event)}
                                    >
                                      <i className="fa fa-edit" />
                                      Editar
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" overlay={remove}>
                                    <Button
                                      bsStyle="danger"
                                      simple
                                      type="button"
                                      bsSize="xs"
                                      onClick={() => this.setState({
                                        callback: () => this.deleteNew(event.id),
                                        text: `excluir a noticia ${event.title}`,
                                        confirm: true
                                      })}
                                    >
                                      <i className="fa fa-times" />
                                      Excluir
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            ))
                          }

                        </tbody>
                      </Table>

                      {isModalVisibleEdit &&
                        <Update
                          onClose={() => {
                            this.setState({ isModalVisibleEdit: false });
                            this.loadNews(this.state.candidate, this.state.onlyLeaders);
                          }}
                          event={this.state.new}
                          idCandidato={this.state.candidate}
                          nomeCandidato={candidateName}
                        />}
                      {isModalVisibleNew &&
                        <Store
                          onClose={() => {
                            this.setState({ isModalVisibleNew: false });
                            this.loadNews(this.state.candidate, this.state.onlyLeaders);
                          }}
                          idCandidato={this.state.candidate}
                          nomeCandidato={candidateName}
                        />}
                    </>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <TutorialVideoModal isOpen={isComunicationModalVisible}
          onClose={() => this.setState({ isComunicationModalVisible: false })}
          link={"https://www.youtube.com/embed/lV-IKFdVjzI?si=huI0YXaVDx973TxL"}
          title={"Notícias, eventos e pesquisas"}
          linkTitle={"Área de comunicação"} />
        <Confirm
          show={this.state.confirm}
          close={() => this.setState({
            confirm: false,
            callback: undefined,
            text: ''
          })}
          callback={this.state.callback}
          text={this.state.text}
        />
      </>
    );
  }
}

export default News;
