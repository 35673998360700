import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import React, { useState } from "react";
import {
  Alert, Col, Modal,
  Row
} from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import api from "services/api.jsx";


const Store = ({ onClose = () => { }, eletivePositions, politicalParties }) => {

  const [show, setShow] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [erro, setErro] = useState('');

  const [elective_positions] = useState(eletivePositions);
  const [political_parties] = useState(politicalParties);

  const [name, setName] = useState(null);
  const [number, setNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [elective_position_id, setElectivePositionId] = useState(null);
  const [political_party_id, setPoliticalPartyId] = useState(null);
  const [operational_area_type, setOperationalAreaType] = useState('');
  const [operational_area_id, setOperationalAreaId] = useState(null);
  const [change_password, setChangePassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [slug, setSlug] = useState(null);

  const handleClose = () => {
    onClose();
    setShow(false);
  };


  const handleInputChange = (newValue) => {
    setOperationalAreaId(newValue.id);
  };

  const animatedComponents = makeAnimated();

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      if (inputValue) {

        let query;

        if (operational_area_type === "counties") {
          query = `/candidates/operational_areas/counties?q=${inputValue.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); })}`;
        }
        else if (operational_area_type === "federal_units") {
          query = `/candidates/operational_areas/federal_units?q=${inputValue.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); })}`;
        }
        else {
          throw new Error("Área de operação inválida");
        }

        api.get(query)
          .then(response => {

            if (response.data.data) {
              const result = response.data.data.map(function (elm) {
                return {
                  id: elm.id,
                  value: elm.id,
                  label: elm.name
                };
              });
              const filters = result.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
              );
              resolve(filters);
            }
            resolve([]);
          })
          .catch(err => {
            resolve([]);
          });
      }
    });

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('name', name);
    formData.append('number', number);
    if (email) formData.append('email', email);
    formData.append('elective_position_id', elective_position_id);
    formData.append('political_party_id', political_party_id);
    formData.append('operational_area_type', operational_area_type);
    formData.append('operational_area_id', operational_area_id);
    // formData.append('password', password);
    // formData.append('change_password', Boolean(change_password) ? 1 : 0);
    formData.append('slug', slug);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      }
    };

    trackPromise(api.post(`${localStorage.getItem('idApp')}/candidates`, formData, config))
      .then(response => {
        onClose();
      })
      .catch((error) => {
        console.log(error.response.data.errors);
        if (error.response.data.errors?.name) {
          setErro(error.response.data.errors?.name[0]);
        } else if (error.response.data.errors?.email) {
          setErro(error.response.data.errors?.email[0]);
        } else if (error.response.data.errors?.number) {
          setErro(error.response.data.errors?.number[0]);
        } else {
          setErro("Erro ao criar candidato");
        }
        setIsAlertVisible(true);
      });

  };

  const setNameSlug = (text) => {
    setName(text);
    setSlug(text
      .toString() // Cast to string (optional)
      .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string (optional)
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\_/g, "-") // Replace _ with -
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/\-$/g, "")); // Remove trailing -
  }

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de Candidato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            {isAlertVisible && <Alert bsStyle="danger">
              <button type="button" aria-hidden="true" className="close">×</button>
              <span><b> Atenção - </b> {erro}</span>
            </Alert>}
            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              properties={[
                {
                  label: "Nome",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Nome candidato",
                  required: true,
                  defaultValue: name,
                  onChange: event => setNameSlug(event.target.value)
                },
                {
                  label: "Slug",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Slug do candidato",
                  required: true,
                  value: slug,
                  defaultValue: slug,
                  onChange: event => setSlug(event.target.value)
                },
              ]}
            />

            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              properties={[
                {
                  label: "Email",
                  type: "email",
                  bsClass: "form-control",
                  placeholder: "seu@email.com",
                  required: false,
                  defaultValue: email,
                  onChange: event => setEmail(event.target.value)
                },
                {
                  label: "Número",
                  type: "number",
                  bsClass: "form-control",
                  placeholder: "10444",
                  required: false,
                  defaultValue: number,
                  onChange: event => setNumber(event.target.value)
                },
              ]}
            />

            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="">Partido Político</label>
                  <select
                    name="political_party_id"
                    defaultValue={political_party_id}
                    onChange={event => setPoliticalPartyId(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">-</option>
                    {political_parties.map(constant => (
                      <option key={constant.id} value={constant.id}>{constant.abbreviation}</option>
                    ))}
                  </select>

                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="">Cargo Eletivo</label>
                  <select
                    name="elective_position_id"
                    defaultValue={elective_position_id}
                    onChange={event => setElectivePositionId(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">-</option>
                    {Object.entries(elective_positions).map(([key, value]) => (
                      <option key={key} value={key}>{value}</option>
                    ))}
                  </select>

                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="">Tipo de Área Operacional</label>
                  <select
                    name="operational_area_type"
                    defaultValue={operational_area_type}
                    onChange={event => setOperationalAreaType(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">-</option>
                    <option value="counties">Cidade</option>
                    <option value="federal_units">Unidade Federativa</option>
                  </select>

                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label className="">{operational_area_type === 'counties' ? 'Cidade/UF' : 'Cidade/UF'}</label>
                  <AsyncSelect
                    placeholder={`Digite o nome do ${operational_area_type === 'counties' ? 'Cidade/UF' : 'Cidade/UF'}`}
                    isDisabled={operational_area_type === ''}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    onChange={handleInputChange}
                    cacheOptions
                    loadOptions={promiseOptions}
                  />
                </div>
              </Col>
            </Row>

            {/* <Row>
              <Col md={8}>
                <div className="form-group">
                  <label className="">Forçar Alteração de Senha no Primero Acesso</label>
                  <select
                    name="change_password"
                    defaultValue={change_password}
                    onChange={event => setChangePassword(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">-</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </div>
              </Col>
              <Col md={4}>
                <div className="form-group">
                  <label className="password">Senha</label>
                  <input type="password" className="form-control" name="password" minLength="8" maxLength="32" id="password" defaultValue={password} placeholder="******" onChange={event => setPassword(event.target.value)} required />
                  <span className="muted">Sua senha deverá conter entre 8 e 32 caracteres e não poderá conter espaços. </span>
                </div>
              </Col>
            </Row> */}
            <hr />

            <Button bsStyle="danger" fill onClick={() => onClose()}>Cancelar</Button>
            <Button bsStyle="info" pullRight fill type="submit">Cadastrar</Button>

            <div className="clearfix" />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Store;
