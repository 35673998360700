import Button from "components/CustomButton/CustomButton.jsx";
import React, { useState } from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import 'react-quill/dist/quill.snow.css';
import api from "services/api.jsx";


const Update = ({ onClose = () => { }, idAdvisor, advisor, idApp }) => {
    const [show, setShow] = useState(true);
    const [payload, setPayload] = useState();

    const [erro, setErro] = useState();
    const [isAlertVisible, setIsAlertVisible] = useState(false);

    const handleClose = () => setShow(false);

    const submitHandler = async (e) => {
        e.preventDefault();
        api
            .put(`/users/${idAdvisor}`, payload)
            .then((res) => onClose())
            .catch((error) => {
                setErro(error.response.data.message);
                setIsAlertVisible(true);
            });
    };

    return (
        <>
            <Modal
                size="sm"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edição de Assessor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submitHandler} encType="multipart/form-data">
                        {isAlertVisible &&
                            <Alert bsStyle="danger">
                                <button type="button" aria-hidden="true" className="close">×</button>
                                <span><b> Atenção - </b> {erro}</span>
                            </Alert>
                        }
                        <Row>
                            <Col md={6}>
                                <div className="form-group">
                                    <label>Nome</label>
                                    <input
                                        className="form-control"
                                        defaultValue={advisor.name}
                                        onChange={(e) => setPayload({ ...payload, name: e.target.value })}
                                        required
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-group">
                                    <label className="">Email</label>
                                    <input
                                        className="form-control"
                                        defaultValue={advisor.email}
                                        onChange={(e) => setPayload({ ...payload, email: e.target.value })}
                                        required
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className="form-group">
                                    <label for="candidates">Candidatos Associados</label>
                                    <div id="candidates">
                                        {advisor.candidates.map((el)=>
                                            <>
                                                <span>{el.candidate.name}</span>
                                                <br />
                                            </>
                                            )
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div style={{ marginTop: "1rem" }}>
                            <Button bsStyle="danger" fill onClick={() => onClose()}>
                                Cancelar
                            </Button>
                            <Button
                                bsStyle="info"
                                type="submit"
                                disabled={!payload}
                                pullRight
                                fill
                            >
                                Salvar Alterações
                            </Button>
                        </div>
                        <div className="clearfix" />
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Update;
