import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";

import api from "services/api";

export function useGroup({ appId, id }) {
  const [data, setData] = useState();

  useEffect(() => {
    async function getGroup() {
      const response = await trackPromise(
        api.get(`${appId}/group_information/${id}`)
      );

      const data = response.data;

      let image = null;

      if (data.image) {
        image = {
          type: isLink(data.image) ? "image" : "icon",
          link: data.image,
        };
      }

      const tags = data.tags.map((item) => ({
        tagId: item.id,
        name: item.title,
      }));

      const group = {
        name: response.data.title,
        image,
        tags,
      };

      setData(group);
    }

    if (id) {
      getGroup();
    }
  }, [id]);

  function isLink(value) {
    return value.startsWith("http");
  }

  return { group: data };
}
