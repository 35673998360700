import React, { useState } from "react";
import { Modal, FormControl } from "react-bootstrap";

import Button from 'components/CustomButton/CustomButton.jsx';


export default function InvalidateModal({ show, onClose, onConfirm }) {
    const [invalidateText, setInvalidateText] = useState("");

    const handleConfirm = () => {
        onConfirm(invalidateText);
        setInvalidateText("");
    };

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Deseja invalidar a resposta?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label>Motivo da invalidação (opcional)</label>
                <FormControl
                    componentClass="textarea"
                    placeholder="Motivo da invalidação (opcional)"
                    value={invalidateText}
                    onChange={(e) => setInvalidateText(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    bsStyle="warning"
                    fixMargin pullRight fill
                    onClick={onClose}>
                    Voltar
                </Button>
                <Button b
                    bsStyle="info"
                    fixMargin pullRight fill
                    onClick={handleConfirm}>
                    Confirmar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
