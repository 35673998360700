import Select from "react-select";
import ReactQuill from "react-quill";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useHistory, useParams } from "react-router";
import { Col, ControlLabel, FormGroup, Row } from "react-bootstrap";

import api from "services/api";
import Swal from "sweetalert2";
import FormInputs from "components/FormInputs/FormInputs";
import Button from "components/CustomButton/CustomButton";

const UpdateNotificationLeads = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [description, setDescription] = useState('');
  const [candidateId, setCandidateId] = useState("");
  const [selectedCandidate, setSelectedCandidate] = useState('');

  const history = useHistory();
  const appId = localStorage.getItem("idApp");

  useEffect(() => {
    loadCommunicationLeader();
    getCandidates();
  }, [appId, id]);

  const loadCommunicationLeader = async () => {
    try {
      const response = await trackPromise(api.get(`${appId}/leadership_news/${id}`));

      setTitle(response.data.title);
      setContent(response.data.content);
      setDescription(response.data.description);
      setCandidateId(response.data.candidate_id);
    } catch (error) {
      console.error(error);
    }
  }

  async function getCandidates() {
    try {
      const response = await api.get(`${appId}/candidates/get_by_roles`);

      const candidates = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      setCandidates(candidates);

      return candidates;
    } catch (error) {
      console.error(error);
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append("title", title);
    formData.append("_method", "PUT");
    formData.append("content", content);
    formData.append("description", description);
    formData.append("candidate_id", candidateId);
    
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    
    try {
      await trackPromise(
        api.post(
          `${appId}/leadership_news/${id}`,
          formData,
          config
        )
      );

      Toast.fire({
        icon: "success",
        title: "Comunicação atualizada com sucesso."
      });

      history.push({ pathname: `/admin/lead-notifications` });
    } catch (error) {
      console.error(error);

      Toast.fire({
        icon: "error",
        title: "Erro ao atualizar a comunicação."
      });
    }
  }

  const Toast = Swal.mixin({
    toast: true,
    timer: 3000,
    position: "top-end",
    timerProgressBar: true,
    showConfirmButton: false,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  return (
    <div className="content">
      <Row>
        <Col md={12}>
        <p>Criar Notificação</p>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            <Row>
              <Col md={6}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      type: "text",
                      value: title,
                      required: true,
                      label: "Título",
                      bsClass: "form-control",
                      placeholder: "Digite o título da notificação",
                      onChange: (event) => setTitle(event.target.value),
                    }
                  ]}
                />
              </Col>

              <Col md={6}>
                <label>Selecione candidato</label>
                <Select
                  isClearable
                  isSearchable
                  name="candidate"
                  options={candidates}
                  placeholder="Busque o candidato"
                  isDisabled={candidates.length < 2}
                  noOptionsMessage={() => "Dados não encontrados"}
                  value={candidateId !== null ? candidates?.find(option => option.value === candidateId) : ""}
                  onChange={(option) =>
                    setCandidateId(option ? option.value : candidateId)
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      type: "text",
                      required: true,
                      label: "Descrição",
                      value: description,
                      bsClass: "form-control",
                      placeholder: "Digite a Descrição da notificação",
                      onChange: (event) => setDescription(event.target.value),
                    },
                  ]}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup controlId="formControlsTextarea">
                  <ControlLabel>Conteúdo</ControlLabel>
                  <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={(value) => setContent(value)}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        ["link", "image"],
                        ["clean"],
                      ],
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <div className="clearfix" />
            <Button bsStyle="danger" fill onClick={() => window.history.back()}>
              Voltar
            </Button>
            <Button bsStyle="info" pullRight fill type="submit">
              Salvar
            </Button>
          </form>
        </Col>
      </Row>
    </div>
  )
}

export default UpdateNotificationLeads;