import React, { Component } from "react";
import { Grid, Row, Col, Table, Tooltip, OverlayTrigger } from "react-bootstrap";

import { Link } from "react-router-dom";

import Button from 'components/CustomButton/CustomButton.jsx';
import Card from "components/Card/Card.jsx";

import api from "../../../services/api.jsx";

import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";

import Update from "./update.jsx";
import Store from "./store.jsx";
import { trackPromise } from "react-promise-tracker";
import Confirm from "components/Confirm/Confirm.jsx";

class Questions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callback:undefined,
            confirmText:"",
            confirmCondition:false,
            _notificationSystem: null,
            questions: [],
            isModalVisibleEdit: false,
            isModalVisibleNew: false,
            question: null,
            app: localStorage.getItem('idApp'),
            role: JSON.parse(localStorage.getItem('user')).role,
        }
    }
    // state de cilco de vida de inicialização do componente
    componentDidMount() {
        this.loadQuestions();
    }
    // edita uma pesquisa
    editQuestion(event) {
        this.setState({ question: event });
        this.setState({ isModalVisibleEdit: true })
    }
    //carrega as pesquisas de um app
    loadQuestions = async () => {
        try {
            const response = await trackPromise(api.get(`/${this.state.app}/researches/${this.props.match.params.researche_id}/questions`));
            this.setState({ questions: response.data.data });
        } catch (e) {
            this.handleNotificationClick(e.response.data.message, 'error');
        }
    }

    //deleta uma pesquisa
    deleteQuestion = (id) => {

            api.delete(`/${this.state.app}/researches/${this.props.match.params.researche_id}/questions/${id}`)
            .then(response => {
                this.loadQuestions();
                this.handleNotificationClick('Pergunta excluida com sucesso!', 'success');
            })
            .catch((error) => {
                this.handleNotificationClick('Erro na exclusão da pergunta! Tente novamente!', 'error');
            });
    }
    // componente de notificação
    handleNotificationClick = (message, color) => {
        this.refs.notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-info" />,
            message: (
                <div>
                    {message}
                </div>
            ),
            level: color,
            position: 'tr',
            autoDismiss: 5,
            dismissible: true
        });
    };
    // renderzação do componente
    render() {
        const { isModalVisibleEdit, questions, isModalVisibleNew } = this.state;

        const remove = (<Tooltip id="remove_tooltip">Excluir</Tooltip>);
        const edit = (<Tooltip id="edit_tooltip">Editar</Tooltip>);

        return (
            <>
            <div className="content">
                <NotificationSystem ref="notificationSystem" style={style} />
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Button title="cadastrar nova pergunta" bsStyle="primary" fixMargin pullRight fill onClick={() => this.setState({ isModalVisibleNew: true })}>  <i className="fa fa-plus"></i> Nova Pergunta</Button>

                            <Button title="Voltar" bsStyle="warning" fixMargin pullRight fill onClick={this.props.history.goBack}> <i className="fa fa-backward"></i></Button>



                            <Card
                                title="Perguntas"
                                category="Perguntas cadastradas"
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <>
                                        <Table striped hover>
                                            <thead>
                                                <tr>
                                                    <th>Título</th>
                                                    <th>Descrição</th>
                                                    <th>Tipo</th>
                                                    <th>Opções</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    questions.map(event => (
                                                        <tr key={event.id}>
                                                            <td>{event.title}</td>
                                                            <td>{event.description}</td>
                                                            <td>

                                                              {event.type === "string" && "Resposta descritiva"}
                                                              {event.type === "int" && "Valor inteiro"}
                                                              {event.type === "float" && "Valor Real (3,25)"}
                                                              {event.type === "checkbox" && "Múltipla escolha"}

                                                            </td>

                                                            <td>
                                                                {event.type === 'checkbox' &&
                                                                    <Link to={`../../options/research/${this.props.match.params.researche_id}/question/${event.id}`}>
                                                                        <Button bsStyle="warning" fill ><i className="fa fa-eye"></i></Button>
                                                                    </Link>
                                                                }
                                                            </td>
                                                            <td>
                                                                <OverlayTrigger placement="top" overlay={edit}>
                                                                    <Button
                                                                        bsStyle="info"
                                                                        simple
                                                                        type="button"
                                                                        bsSize="xs"
                                                                        onClick={() => this.editQuestion(event)}
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                        Editar
                                                                    </Button>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={remove}>
                                                                    <Button
                                                                        bsStyle="danger"
                                                                        simple
                                                                        type="button"
                                                                        bsSize="xs"
                                                                        onClick={() => this.setState({
                                                                            callback:()=> this.deleteQuestion(event.id),
                                                                            confirmText:`excluir a pergunta "${event.title.replace("?","")}"`,
                                                                            confirmCondition:true
                                                                        })}
                                                                    >
                                                                        <i className="fa fa-times"></i>
                                                                        Excluir
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </Table>

                                        {isModalVisibleEdit && <Update onClose={() => { this.setState({ isModalVisibleEdit: false }); this.loadQuestions() }} question={this.state.question} id_research={this.props.match.params.researche_id} />}
                                        {isModalVisibleNew && <Store onClose={() => { this.setState({ isModalVisibleNew: false }); this.loadQuestions() }} id_research={this.props.match.params.researche_id} />}

                                    </>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
            <Confirm  
            callback={this.state.callback} 
            text={this.state.confirmText} 
            show={this.state.confirmCondition}
            close={()=>this.setState({
              callback:undefined,
              confirmText:"",
              confirmCondition:false,
            })}
            />
            </>
        );
    }
}

export default Questions;
