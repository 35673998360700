import React from 'react';

import './progress-bar-styles.css';

const CircularProgressBar = ({ segments }) => {
  const totalPercentage = segments.reduce((acc, segment) => acc + (segment.show ? segment.percentage : 0), 0);

  let cumulativePercentage = 0;

  return (
    <div className="circular-progress-bar">
      <svg className="circular-progress" viewBox="0 0 36 36">
        <path
          className="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        {segments.map((segment, index) => {
          if (!segment.show) return null;

          cumulativePercentage += segment.percentage;
          const strokeDasharray = `${cumulativePercentage}, 100`;

          return (
            <path
              key={index}
              className="circle"
              strokeDasharray={strokeDasharray}
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
              style={{ stroke: segment.color }}
            />
          );
        })}
        <text x="18" y="18" className="percentage">
          {totalPercentage === 99.99 ? 100 : totalPercentage}%
        </text>
      </svg>
    </div>
  );
};

export default CircularProgressBar;

