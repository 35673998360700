import { useState } from "react";
import { useAtom, useSetAtom } from "jotai";
import { User, ArrowRight } from "@phosphor-icons/react";

import { FormDataAtom } from "components/Onboarding/atoms/FormDataAtom";
import { CurrentStepAtom } from "components/Onboarding/atoms/CurrentStepAtom";

import "./styles.css";

export function PersonalStep() {
  const [formData, setFormData] = useAtom(FormDataAtom);
  const setCurrentStep = useSetAtom(CurrentStepAtom);

  const [name, setName] = useState(formData.name ?? "");

  function handleNextStep() {
    setFormData((prev) => ({
      ...prev,
      name,
    }));

    setCurrentStep((prev) => prev + 1);
  }

  return (
    <div className="content personal-step">
      <div className="heading">
        <strong>Nome do(a) candidato(a) majoritário(a)</strong>
        <span>Escreva abaixo o nome do(a) candidato(a) majoritário(a) da campanha</span>
      </div>

      <div className="field-text">
        <User size={24} color="#9747ff" />
        <input
          name="name"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Insira o nome do(a) candidato(a) aqui"
        />
      </div>

      <button disabled={!name} onClick={handleNextStep}>
        Avançar
        <ArrowRight size={20} weight="bold" />
      </button>
    </div>
  );
}
