import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Dropdown,
  MenuItem,
  Badge,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import Toggle from "react-toggle";

import Button from "components/CustomButton/CustomButton";
import { Icon } from "components/Icon";
import { CustomToggle } from "components/CustomToggle";
import { SortableList } from "../SortableList";
import { useReorderInfos } from "views/infos/hooks/useReorderInfos";
import { useRemoveGroup } from "views/infos/hooks/useRemoveGroup";
import { useRemoveInfo } from "views/infos/hooks/useRemoveInfo";
import { useQueryParams } from "hooks/useQueryParams";
import api from "services/api";

import { style } from "variables/Variables.jsx";
import "./styles.css";

const types = {
  "Group Information": <Badge bsStyle="primary">Grupo</Badge>,
  Information: <Badge bsStyle="primary">Informação</Badge>,
};

export function ListInfos({ infos, updateInfos, refetchInfos, hasTags }) {
  const appId = localStorage.getItem("idApp");

  const history = useHistory();
  const queryParams = useQueryParams();

  const notificiationSystemRef = useRef(null);
  const switchRef = useRef(null);

  const [newInfos, setNewInfos] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState({
    isOpen: false,
    id: null,
    message: "",
  });

  const { reorderInfos } = useReorderInfos({ appId });
  const { removeGroup } = useRemoveGroup({ appId });
  const { removeInfo } = useRemoveInfo({ appId });

  useEffect(() => setNewInfos(infos), [infos]);

  async function handleOnDragEnd(list) {
    const currentInfos = infos;

    try {
      setNewInfos(list);

      await reorderInfos(list);
    } catch (error) {
      console.error(error);

      handleNotificationClick("Erro ao atualizar ordem da listagem", "error");

      setNewInfos(currentInfos);
    }
  }

  async function handleRemoveItem(id, type) {
    try {
      if (type === "Group Information") {
        await removeGroup(id);
      } else {
        await removeInfo(id);
      }

      handleOnCloseModalDelete();

      handleNotificationClick("Item removido com sucesso!", "success");

      refetchInfos();
    } catch (error) {
      console.error(error);
    }
  }

  function handleEditItem(type, id) {
    if (type === "Group Information") {
      queryParams.append("open_update_group", "open");
      queryParams.append("group_edit_id", String(id));

      history.push({
        pathname: "/admin/infos",
        search: queryParams.toString(),
      });
    } else {
      queryParams.append("open_update_info", "open");
      queryParams.append("info_edit_id", String(id));

      history.push({
        pathname: "/admin/infos",
        search: queryParams.toString(),
      });
    }
  }

  function handleOpenItem({ id, type, name }) {
    if (type === "Group Information") {
      queryParams.delete("group_id");
      queryParams.delete("group_name");

      queryParams.append("group_id", String(id));
      queryParams.append("group_name", name);

      history.push({
        pathname: "/admin/infos",
        search: queryParams.toString(),
      });
    }
  }

  function handleOpenAssociateTags({ id, type, name }) {
    queryParams.append("open_associate_tags", "open");
    queryParams.append("item_id", String(id));
    queryParams.append("item_name", name);
    queryParams.append(
      "item_type",
      type === "Group Information" ? "group" : "info"
    );

    history.push({
      pathname: "/admin/infos",
      search: queryParams.toString(),
    });
  }

  function handleOnCloseModalDelete() {
    setOpenModalDelete({ isOpen: false, id: null, type: "", message: "" });
  }

  function copyLink(id, type) {
    const url = `/infos${
      type === "Group Information" ? `?group_id=${id}` : `/${id}`
    }`;

    navigator.clipboard.writeText(url);

    handleNotificationClick(
      "Link da aplicação copiado com sucesso!",
      "success"
    );
  }

  async function updateVisibility({ appId, visibility, type, id }) {
    let currentInfos = [];
    try {
      const url =
        type === "Group Information"
          ? `${appId}/group_information/${id}`
          : `${appId}/information/${id}`;

      updateInfos((prevState) => {
        currentInfos = [...prevState];

        return prevState.map((item) => ({
          ...item,
          visibility: item.id === id ? visibility : item.visibility,
        }));
      });

      await api.put(url, {
        visibility: visibility,
      });
    } catch (error) {
      updateInfos(currentInfos);
      handleNotificationClick("Erro ao alterar a visibilidade!", "error");
    }
  }

  async function handleChangeToggle(visibility, type, id) {
    updateVisibility({ appId, visibility, type, id });
  }

  function handleNotificationClick(message, color) {
    notificiationSystemRef.current?.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: <div>{message}</div>,
      level: color,
      position: "tr",
      dismissible: true,
    });
  }

  function copyLink(id, type) {
    const url = `/infos${
      type === "Group Information" ? `?group_id=${id}` : `/${id}`
    }`;

    navigator.clipboard.writeText(url);

    handleNotificationClick(
      "Link da aplicação copiado com sucesso!",
      "success"
    );
  }

  async function updateVisibility({ appId, visibility, type, id }) {
    let currentInfos = [];
    try {
      const url =
        type === "Group Information"
          ? `${appId}/group_information/${id}`
          : `${appId}/information/${id}`;

      updateInfos((prevState) => {
        currentInfos = [...prevState];

        return prevState.map((item) => ({
          ...item,
          visibility: item.id === id ? visibility : item.visibility,
        }));
      });

      await api.put(url, {
        visibility: visibility,
      });
    } catch (error) {
      updateInfos(currentInfos);
      handleNotificationClick("Erro ao alterar a visibilidade!", "error");
    }
  }

  async function handleChangeToggle(visibility, type, id) {
    updateVisibility({ appId, visibility, type, id });
  }

  return (
    <>
      <SortableList
        items={newInfos}
        onChange={handleOnDragEnd}
        renderItem={(item) => (
          <SortableList.Item
            id={item.id}
            className={item.type === "Group Information" ? "group" : ""}
            {...(item.type === "Group Information"
              ? {
                  onClick: () =>
                    handleOpenItem({
                      type: item.type,
                      id: item.id,
                      name: item.title,
                    }),
                }
              : {})}
          >
            <SortableList.DragHandle />

            <div className="container-info">
              <div className="content-info">
                {item.image ? <ImageIcon data={item.image} /> : null}

                <div className="content-info-data">
                  <div>
                    <strong>{item.title}</strong>
                    {types[item.type]}
                  </div>

                  {item.tags.length ? <Tags tags={item.tags} /> : null}
                </div>
              </div>

              <div className="toggle-menu">
                <OverlayTrigger
                  key={"visibility"}
                  placement="top"
                  overlay={
                    <Tooltip id={"visibility"}>
                      {`${item.visibility ? `Visível` : `Invisível`}`}
                    </Tooltip>
                  }
                >
                  <div
                    style={{ display: "flex" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChangeToggle(!item.visibility, item.type, item.id);
                    }}
                  >
                    <Toggle
                      checked={item.visibility}
                      name="visibility"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                </OverlayTrigger>

                <Dropdown id="dropdown-custom-1" bsSize="small" pullRight>
                  <CustomToggle bsRole="toggle" />

                  <Dropdown.Menu>
                    {item.type === "Group Information" ? (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenItem({
                            type: item.type,
                            id: item.id,
                            name: item.title,
                          });
                        }}
                      >
                        <Button
                          bsStyle="info"
                          style={{ width: "100%" }}
                          simple
                          type="button"
                          bsSize="xs"
                        >
                          <i className="fa fa-info"></i>
                          {item.type === "Group Information"
                            ? "Acessar informações"
                            : "Acessar informação"}
                        </Button>
                      </MenuItem>
                    ) : null}

                    <MenuItem
                      disabled={!queryParams.get("group_id") || !hasTags}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenAssociateTags({
                          id: item.id,
                          type: item.type,
                          name: item.title,
                        });
                      }}
                      >
                      <Button
                        bsStyle="success"
                        style={{ width: "100%" }}
                        simple
                        type="button"
                        bsSize="xs"
                        disabled={!queryParams.get("group_id") || !hasTags}
                      >
                        <i className="fa fa-plus"></i>
                        Associar tags
                      </Button>
                    </MenuItem>

                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        copyLink(item.id, item.type);
                      }}
                    >
                      <Button
                        bsStyle="success"
                        style={{ width: "100%" }}
                        simple
                        type="button"
                        bsSize="xs"
                      >
                        <i class="fa fa-link" aria-hidden="true"></i>
                        Copiar link
                      </Button>
                    </MenuItem>

                    <MenuItem divider />

                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditItem(item.type, item.id);
                      }}
                    >
                      <Button
                        bsStyle="warning"
                        style={{ width: "100%" }}
                        simple
                        type="button"
                        bsSize="xs"
                      >
                        <i className="fa fa-pencil"></i>
                        Editar
                      </Button>
                    </MenuItem>

                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenModalDelete({
                          isOpen: true,
                          id: item.id,
                          type: item.type,
                          message: `Deseja excluir ${
                            item.type === "Group Information"
                              ? "o grupo"
                              : "a informação"
                          } ${item.title}?`,
                        });
                      }}
                    >
                      <Button
                        bsStyle="danger"
                        style={{ width: "100%" }}
                        simple
                        type="button"
                        bsSize="xs"
                      >
                        <i className="fa fa-trash"></i>
                        Remover
                      </Button>
                    </MenuItem>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </SortableList.Item>
        )}
      />

      <ModalConfirmationDelete
        isOpen={openModalDelete.isOpen}
        message={openModalDelete.message}
        onClose={() => handleOnCloseModalDelete()}
        handleConfirmation={() =>
          handleRemoveItem(openModalDelete.id, openModalDelete.type)
        }
      />

      <NotificationSystem ref={notificiationSystemRef} style={style} />
    </>
  );
}

function ImageIcon({ data: { type, link } }) {
  return (
    <div className="content-info-image">
      {type === "image" ? <img src={link} height={48} width={48} /> : null}
      {type === "icon" ? <Icon icon={link} size={32} color="#0f172a" /> : null}
    </div>
  );
}

function Tags({ tags }) {
  return (
    <div className="infos-badges">
      {tags.map((item, index) => (
        <Badge key={index} className="custom-badge">
          {item.title}
        </Badge>
      ))}
    </div>
  );
}

function ModalConfirmationDelete({
  isOpen,
  onClose,
  handleConfirmation,
  message,
}) {
  return (
    <Modal size="sm" show={isOpen} onHide={onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Excluir item</Modal.Title>
      </Modal.Header>

      <Modal.Body>{message}</Modal.Body>

      <Modal.Footer>
        <Button bsStyle="danger" fill onClick={onClose}>
          Cancelar
        </Button>
        <Button bsStyle="info" fill onClick={() => handleConfirmation()}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
