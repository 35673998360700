import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import React, { useState } from "react";
import { Col, ControlLabel, FormGroup, Modal, Row } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import api from "services/api.jsx";
import ReactQuill from "react-quill";
import styles from "./styles.module.css";

const Update = ({ onClose = () => { }, event, idCandidato }) => {
  const [show, setShow] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isAlertVisibleUrl, setIsAlertVisibleUrl] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [video, setVideo] = useState(event.video);
  const [title, setTitle] = useState(event.title);
  const [content, setContent] = useState(event.content);
  const [image_header, setImageHeader] = useState(null);
  const [is_active, setIsActive] = useState(event.is_active ? "true" : "false");
  const [highlighted, setHighlighted] = useState(event.highlighted ? "true" : "false");
  const [role] = useState(JSON.parse(localStorage.getItem("user")).role);

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("title", title);
    formData.append("content", content);
    formData.append("is_active", is_active === "true" ? 1 : 0);
    formData.append("highlighted", highlighted === "true" ? 1 : 0);
    formData.append("application", localStorage.getItem("idApp"));
    formData.append("news", event.id);
    formData.append("video", video ? video : "");

    if (image_header) {
      formData.append("image_header", image_header, image_header.name);
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const url = event.candidate_id
      ? `/${localStorage.getItem("idApp")}/candidates/${idCandidato}/news/${event.id}`
      : `${localStorage.getItem("idApp")}/news/0/${event.id}`;

    api
      .post(url, formData, config)
      .then(() => onClose())
      .catch((error) => {
        const errors = error.response.data.errors.candidate
          ? error.response.data.errors.candidate[0]
          : "Houve um erro";
        setErrorText(errors);
        setIsAlertVisible(true);
      });
  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edição de Notícia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            {isAlertVisible && (
              <div className="alert alert-danger">
                Error no cadastro do evento!
                <br />
                ERROR: {errorText}
              </div>
            )}
            <FormInputs
              ncols={["col-md-12"]}
              properties={[
                {
                  label: "Titulo",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Titulo",
                  required: true,
                  defaultValue: title,
                  maxLength: 100,
                  name: "title",
                  onChange: (event) => setTitle(event.target.value),
                },
              ]}
            />

            <Row className={styles.alignCenter}>
              <Col md={12}>
                <div className="form-group">
                  <label>Link video</label>
                  <input
                    type="text"
                    className="form-control"
                    name="video"
                    placeholder="Link video (opcional)"
                    value={video ? video : ""}
                    onChange={(event) => setVideo(event.target.value)}
                  />
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={6}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Imagem",
                      type: "file",
                      bsClass: "form-control",
                      defaultValue: image_header,
                      name: "image_header",
                      onChange: (event) => setImageHeader(event.target.files[0]),
                    },
                  ]}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup controlId="formControlsTextarea">
                  <ControlLabel>Conteúdo</ControlLabel>;
                  <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={(value) => setContent(value)}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        ["link", "image"],
                        ["clean"],
                      ],
                    }}
                  ></ReactQuill>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="form-group">
                  <label className="">Visibilidade</label>
                  <select
                    name="isActive"
                    value={is_active}
                    onChange={(event) => setIsActive(event.target.value)}
                    className="form-control"
                    required
                  >
                    <option value="">-</option>
                    <option value="true">Visível</option>
                    <option value="false">Oculta</option>
                  </select>
                </div>
              </Col>
              {/* <Col md={6}>
                {(role === "coordinator" || role === "advisor") && (
                  <div className="form-group">
                    <label className="">Destaque</label>
                    <select
                      name="highlighted"
                      value={highlighted}
                      onChange={(event) => setHighlighted(event.target.value)}
                      className="form-control"
                      required
                    >
                      <option value="">-</option>
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </select>
                  </div>
                )}
              </Col> */}
            </Row>
            <Button bsStyle="danger" fill onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button bsStyle="info" pullRight fill type="submit">
              Salvar Alterações
            </Button>
            <div className="clearfix" />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Update;
