export const politicalParties = [
  {
      "id": "70",
      "name": "AVANTE",
      "abbreviation": "AVANTE"
  },
  {
      "id": "23",
      "name": "CIDADANIA",
      "abbreviation": "CIDADANIA"
  },
  {
      "id": "27",
      "name": "DEMOCRACIA CRISTÃ",
      "abbreviation": "DC"
  },
  {
      "id": "25",
      "name": "DEMOCRATAS",
      "abbreviation": "DEM"
  },
  {
      "id": "15",
      "name": "MOVIMENTO DEMOCRÁTICO BRASILEIRO",
      "abbreviation": "MDB"
  },
  {
      "id": "43",
      "name": "PARTIDO VERDE",
      "abbreviation": "PV"
  },
  {
      "id": "21",
      "name": "PARTIDO COMUNISTA BRASILEIRO",
      "abbreviation": "PCB"
  },
  {
      "id": "65",
      "name": "PARTIDO COMUNISTA DO BRASIL",
      "abbreviation": "PCdoB"
  },
  {
      "id": "29",
      "name": "PARTIDO DA CAUSA OPERÁRIA",
      "abbreviation": "PCO"
  },
  {
      "id": "33",
      "name": "PARTIDO DA MOBILIZAÇÃO NACIONAL",
      "abbreviation": "PMN"
  },
  {
      "id": "35",
      "name": "PARTIDO DA MULHER BRASILEIRA",
      "abbreviation": "PMB"
  },
  {
      "id": "45",
      "name": "PARTIDO DA SOCIAL DEMOCRACIA BRASILEIRA",
      "abbreviation": "PSDB"
  },
  {
      "id": "12",
      "name": "PARTIDO DEMOCRÁTICO TRABALHISTA",
      "abbreviation": "PDT"
  },
  {
      "id": "13",
      "name": "PARTIDO DOS TRABALHADORES",
      "abbreviation": "PT"
  },
  {
      "id": "22",
      "name": "PARTIDO LIBERAL",
      "abbreviation": "PL"
  },
  {
      "id": "30",
      "name": "PARTIDO NOVO",
      "abbreviation": "NOVO"
  },
  {
      "id": "28",
      "name": "PARTIDO RENOVADOR TRABALHISTA BRASILEIRO",
      "abbreviation": "PRTB"
  },
  {
      "id": "90",
      "name": "PARTIDO REPUBLICANO DA ORDEM SOCIAL",
      "abbreviation": "PROS"
  },
  {
      "id": "20",
      "name": "PARTIDO SOCIAL CRISTÃO",
      "abbreviation": "PSC"
  },
  {
      "id": "55",
      "name": "PARTIDO SOCIAL DEMOCRÁTICO",
      "abbreviation": "PSD"
  },
  {
      "id": "50",
      "name": "PARTIDO SOCIALISMO E LIBERDADE",
      "abbreviation": "PSOL"
  },
  {
      "id": "40",
      "name": "PARTIDO SOCIALISTA BRASILEIRO",
      "abbreviation": "PSB"
  },
  {
      "id": "16",
      "name": "PARTIDO SOCIALISTA DOS TRABALHADORES UNIFICADO",
      "abbreviation": "PSTU"
  },
  {
      "id": "17",
      "name": "PARTIDO SOCIAL LIBERAL",
      "abbreviation": "PSL"
  },
  {
      "id": "14",
      "name": "PARTIDO TRABALHISTA BRASILEIRO",
      "abbreviation": "PTB"
  },
  {
      "id": "36",
      "name": "PARTIDO TRABALHISTA CRISTÃO",
      "abbreviation": "PTC"
  },
  {
      "id": "51",
      "name": "PATRIOTA",
      "abbreviation": "PATRIOTA"
  },
  {
      "id": "19",
      "name": "PODEMOS",
      "abbreviation": "PODE"
  },
  {
      "id": "11",
      "name": "PROGRESSISTAS",
      "abbreviation": "PP"
  },
  {
      "id": "18",
      "name": "REDE SUSTENTABILIDADE",
      "abbreviation": "REDE"
  },
  {
      "id": "10",
      "name": "REPUBLICANOS",
      "abbreviation": "REPUBLICANOS"
  },
  {
      "id": "77",
      "name": "SOLIDARIEDADE",
      "abbreviation": "SOLIDARIEDADE"
  },
  {
      "id": "80",
      "name": "UNIDADE POPULAR",
      "abbreviation": "UP"
  }
];
