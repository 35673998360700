import React, { useState, useEffect } from "react";
import {
    Modal,
} from "react-bootstrap";

import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import api from "services/api.jsx";

//import AlertCustom from "components/CustomAlert/Alert.jsx";

const Store = ({ onClose = () => { }, id_research, question_id }) => {

    const [show, setShow] = useState(true);
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [errorText, setErrorText] = useState('');

    const [title, setTitle] = useState();

    const handleClose = () => {
        onClose();
        setShow(false);
    };

    //const handleShow = () => setShow(true);

    useEffect(() => { }, [])

    const submitHandler = (e) => {
        e.preventDefault();

        const formData = {
            title,
        };

        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        api.post(`/${localStorage.getItem('idApp')}/researches/${id_research}/questions/${question_id}/options`, formData, config)
            .then(response => {
                onClose();
            })
            .catch((error) => {
                console.log(error.response.data.message)
                setErrorText(error.response.data.message)
                setIsAlertVisible(true);

            });

    };

    return (
        <>
            <Modal
                size="sm"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Cadastro de opção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submitHandler} encType="multipart/form-data">
                        {isAlertVisible && <div className="alert alert-danger">Error no cadastro da opção!<br />ERROR: {errorText}</div>}
                        <FormInputs
                            ncols={["col-md-12"]}
                            properties={[
                                {
                                    label: "Titulo",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Titulo",
                                    required: true,
                                    defaultValue: title,
                                    name: 'title',
                                    onChange: event => setTitle(event.target.value)
                                }
                            ]}
                        />
                        <Button bsStyle="danger" fill onClick={() => onClose()}>
                            Cancelar
                        </Button>
                        <Button bsStyle="success" pullRight fill type="submit">
                            Salvar
                        </Button>
                        <div className="clearfix" />
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Store;
