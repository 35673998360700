import React, { useRef, useState } from "react";
import {
  Overlay,
  FormControl,
  OverlayTrigger,
  Tooltip,
  HelpBlock,
} from "react-bootstrap";
import * as PhosphorIcon from "@phosphor-icons/react";

import { icons } from "../../data/icons";

import "./styles.css";

export function SelectIcon({
  selected,
  onChange,
  disabled = false,
  error = "",
}) {
  const [isOpen, setIsOpen] = useState(false);

  const triggerRef = useRef(null);
  const containerRef = useRef(null);

  function handleToggle() {
    setIsOpen((prevState) => !prevState);
  }

  return (
    <div ref={containerRef}>
      <button
        ref={triggerRef}
        onClick={handleToggle}
        type="button"
        className="trigger-button"
        disabled={disabled}
      >
        <div>
          {selected ? (
            <div className="selected-icon">
              <Icon
                icon={selected}
                size={20}
                color={disabled ? "#9ca3af" : "#565656"}
              />
              <span>{selected}</span>
            </div>
          ) : (
            <span>Selecione o ícone</span>
          )}
        </div>

        <PhosphorIcon.ArrowRight
          size={20}
          color={disabled ? "#9ca3af" : "#333"}
        />
      </button>

      {error ? <HelpBlock>{error}</HelpBlock> : null}

      <Overlay
        show={isOpen}
        onHide={() => setIsOpen(false)}
        target={triggerRef.current}
        container={containerRef.current}
        rootClose
      >
        <DropdownIcons
          selected={selected}
          onChange={onChange}
          onClose={() => setIsOpen(false)}
        />
      </Overlay>
    </div>
  );
}

function DropdownIcons({ className, selected, onChange, onClose }) {
  const [filterIcons, setFiltersIcon] = useState(icons);
  const [search, setSearch] = useState("");

  function handleOnChange(value) {
    if (value) {
      setFiltersIcon(() => icons.filter((item) => item.name.includes(value)));
    } else {
      setFiltersIcon(icons);
    }

    setSearch(value);
  }

  function handleSelect(value) {
    onChange(value);
    onClose();
  }

  return (
    <div className={`container-icons ${className}`}>
      <FormControl
        type="text"
        value={search}
        onChange={(event) => handleOnChange(event.target.value)}
        placeholder="Busque um ícone"
      />

      {filterIcons.length ? (
        <div className="grid-icons">
          {filterIcons.map(({ pascal_name, Icon }) => (
            <OverlayTrigger
              key={pascal_name}
              placement="top"
              overlay={<Tooltip id={pascal_name}>{pascal_name}</Tooltip>}
            >
              <button
                type="button"
                onClick={() => handleSelect(pascal_name)}
                className={`trigger-icon ${
                  selected === pascal_name ? "selected" : ""
                }`}
              >
                <Icon
                  size={24}
                  color={selected === pascal_name ? "#fff" : "#334155"}
                />
              </button>
            </OverlayTrigger>
          ))}
        </div>
      ) : (
        <div className="empty-state">
          <span>Não foi possível identificar ícones</span>
        </div>
      )}
    </div>
  );
}

export function Icon({ icon, ...rest }) {
  return React.createElement(PhosphorIcon[icon], rest);
}
