import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useQueryParams } from "hooks/useQueryParams";
import "./styles.css";
import { TutorialVideoModal } from "components/TutorialModal/TutorialModal";

export function HeaderFields() {
  const [isOpenModal, setIsOpenModal] = useState(false)

  function handleCloseModal() {
    setIsOpenModal(false)
  }

  function handleOpenModal() {
    setIsOpenModal(true)
  }

  const history = useHistory();

  const queryParams = useQueryParams();

  function handleCreateField() {
    queryParams.set("open_create_field", "open");
    history.push({ pathname: "/admin/campos_extras", search: queryParams.toString() });
  }

  return (
    <header className="header-fields">
      <div style={{ display: "flex", justifyContent: "flex-start", gap: "5px" }}>
        <h3>Campos do perfil</h3>
        <i
          className="fa fa-question-circle"
          style={{ fontSize: "18px", cursor: "pointer" }}
          title="Assista ao vídeo tutorial dessa área"
          onClick={handleOpenModal}
        />
      </div>


      <button className="info-btn-action" onClick={handleCreateField}>
        Adicionar campo
      </button>

      <TutorialVideoModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        link={"https://www.youtube.com/embed/AN4arbE11w4?si=dyplAVxFEyRI226T"}
        linkTitle={"Campos extras"}
        title={"Campos extras"}
      />

    </header>
  );
}
