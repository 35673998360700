import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";

import Button from "components/CustomButton/CustomButton.jsx";

import api from "services/api.jsx";

export default ({ onClose = () => {}, voterId, votingIntention }) => {
  const [show, setShow] = useState(true);
  const [leaders, setLeaders] = useState([]);
  const [leader, setLeader] = useState();

  const [isDisabledSelect, setIsDisabledSelect] = useState(false);
  const [isVisibledSelect, setIsVisibleSelect] = useState(true);

  const [majority, setMajority] = useState(
    votingIntention.leader_id ? "0" : "1"
  );
  const [goal, setGoal] = useState(0);
  const appId = localStorage.getItem("idApp");
  const appType = localStorage.getItem("typeApplication");
  const typeRole = localStorage.getItem("typeRole");
  const user = JSON.parse(localStorage.getItem("user"));

  const isDisabledMajority = getDisabledMajority();

  useEffect(() => {
    async function loadData() {
      await loadLeaders();
    }

    loadData();
  }, []);

  useEffect(() => {
    if (leaders.length !== 0) {
      if (votingIntention.leader_id) {
        if (leaders.some((item) => item.id === votingIntention.leader_id)) {
          setIsDisabledSelect(true);
          setLeader(votingIntention.leader_id);
        } else if (typeRole === "candidate") {
          setIsVisibleSelect(false);
        } else {
          setIsDisabledSelect(false);
        }
      }
    }
  }, [leaders]);

  function handleClose(status) {
    onClose(status);
    setShow(false);
  }

  function getDisabledMajority() {
    if (
      appType !== "one" &&
      typeRole !== "coordinator" &&
      votingIntention.leader_id
    ) {
      return true;
    }

    return false;
  }

  async function loadLeaders() {
    let candidateIds = "";

    if (appType === "many" && votingIntention.candidate_id) {
      candidateIds = `?candiate_ids=${votingIntention.candidate_id}`;
    } else if (appType === "many" && typeRole === "candidate") {
      candidateIds = `?candiate_ids=${user.candidate_id}`;
    }

    trackPromise(
      api.get(`/${appId}/leaders/get_leaders_by_candidate${candidateIds}`)
    )
      .then((res) => setLeaders(res.data.data))
      .catch((err) => console.error(err.message));
  }

  const changeHandlerLeaders = (e) => {
    setLeader(e.target.value);
  };

  const changeHandlerMajority = (e) => {
    setMajority(e.target.value);
  };

  function store() {
    const data = {
      user_id: voterId,
      goal
    };

    if (!parseInt(majority)) data.leader_id = leader;

    trackPromise(api.post(`${appId}/leaders/assign_leader_of_leader`, data))
      .then(() => handleClose("att"))
      .catch((err) => console.error(err.message));
  }

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> Seleção de líderes </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row style={{ marginBottom: 16 }}>
            <Col md={6}>
              <label htmlFor='goal'>Meta&nbsp;</label>
              <input
                name="goal"
                type="number"
                defaultValue={0}
                className="form-control"
                onChange={e => setGoal(e.target.value)}
              />
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label>Majoritário?</label>
                <div>
                  <label style={{ fontWeight: 400, marginTop: 4 }}>
                    <input
                      type="radio"
                      name="onlyLeaders"
                      value="1"
                      onChange={changeHandlerMajority}
                      defaultChecked={!votingIntention.leader_id}
                      disabled={isDisabledMajority}
                    />
                    &nbsp;
                    <span>Sim</span>
                  </label>
                  <label
                    className="m-l"
                    style={{ fontWeight: 400, marginTop: 4 }}
                  >
                    <input
                      type="radio"
                      name="onlyLeaders"
                      value="0"
                      onChange={changeHandlerMajority}
                      defaultChecked={!!votingIntention.leader_id}
                      disabled={isDisabledMajority}
                    />
                    &nbsp;
                    <span>Não</span>
                  </label>
                </div>
              </div>
            </Col>
            {majority === "0" && (
              <>
                {votingIntention.candidate_id && (
                  <Col md={12} style={{ marginBottom: 16 }}>
                    <label>Candidato:</label>
                    <span> {votingIntention.candidate_name}</span>
                  </Col>
                )}

                {leaders.length > 0 && (
                  <Col md={6} style={{ marginBottom: "8px" }}>
                    {isVisibledSelect ? (
                      <div className="form-group">
                        <label>Selecione o líder:</label>
                        <select
                          name="leader"
                          className="form-control"
                          onChange={changeHandlerLeaders}
                          value={leader}
                          disabled={isDisabledSelect}
                        >
                          {leaders.map((leader) => (
                            <option key={leader.id} value={leader.id}>
                              {leader.name} - {leader.id}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <div style={{ marginBottom: "8px" }}>
                        <strong style={{ color: "red" }}>
                          Eleitor com liderança de outro candidato, você não
                          possui permissão de alterar.
                        </strong>
                      </div>
                    )}
                  </Col>
                )}
              </>
            )}
          </Row>
          <Button bsStyle="danger" fill onClick={() => onClose()}>
            Cancelar
          </Button>
          <Button
            bsStyle="info"
            pullRight
            fill
            onClick={store}
            disabled={!isVisibledSelect && isDisabledMajority}
          >
            Salvar
          </Button>
          <div className="clearfix" />
        </Modal.Body>
      </Modal>
    </>
  );
};
