import React from "react";
import { Modal } from "react-bootstrap";

import Button from 'components/CustomButton/CustomButton.jsx';


export default function ValidateModal({ show, onClose, onConfirm }) {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Validar resposta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Deseja validar a resposta?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="warning"
          fixMargin pullRight fill
          onClick={onClose}>
          Voltar
        </Button>
        <Button
          bsStyle="info"
          fixMargin pullRight fill
          onClick={onConfirm}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

