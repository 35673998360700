import React from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import { Alert, Modal } from "react-bootstrap";
import style from "./style.module.css";
import { trackPromise } from "react-promise-tracker";
import api from "services/api";

const Message = ({ show, close, reload, participationId }) => {
  const appId = localStorage.getItem('idApp');
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);
  const [error, setError] = React.useState();

  function handleSubmit(event) {
    event.preventDefault();

    const data = {
      content: event.target.message.value
    };

    trackPromise(api.post(`/${appId}/participation/${participationId}/message`, data, { headers: { "content-type": "application/json" } }))
      .then(res => {
        close();
        reload();
      })
      .catch(err => console.error(err));


  }
  return (
    <Modal size="sm" show={show} onHide={close} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Adicionar mensagem</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {isAlertVisible && (
            <Alert bsStyle="danger">
              <button type="button" aria-hidden="true" className="close">
                ×
              </button>
              <span>
                <b> Atenção - </b> {error}
              </span>
            </Alert>
          )}
          <div className="form-group">
            <textarea
              placeholder="Adicione aqui sua mensagem"
              name="message"
              cols="90"
              rows="10"
              className={style.msgBox}
              required
            />
          </div>
          <hr />
          <Button bsStyle="danger" fill onClick={() => close()}>
            Cancelar
          </Button>
          <Button bsStyle="info" pullRight fill type="submit">
            Cadastrar
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default Message;
