import React, { useState, useEffect } from "react";
import { Map, Marker, TileLayer, Circle } from "react-leaflet";
import { useParams, useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";

import Swal from "sweetalert2";
import api from "services/api";
import FormInputs from "components/FormInputs/FormInputs";
import Button from "components/CustomButton/CustomButton.jsx";
import "./styles.css";

const Toast = Swal.mixin({
  toast: true,
  width: 400,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});

export default function UpdateTask() {
  const { taskId } = useParams();
  const history = useHistory();
  
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [donePoints, setDonePoints] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [neededPictures, setNeededPictures] = useState(null);
  const [locationChecking, setLocationChecking] = useState({});
  const [radius, setRadius] = useState(null);
  const [includeLocation, setIncludeLocation] = useState(false);

  const appId = localStorage.getItem("idApp");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });

    const loadTask = async () => {
      try {
        const response = await api.get(`/${appId}/tasks/${taskId}`);
        const task = response.data;
        setTitle(task.title);
        setDescription(task.description);
        setDonePoints(task.done_points);
        setMaxDate(task.max_date);
        setNeededPictures(task.needed_pictures);
        setLocationChecking({
          lat: task.latitude_checking,
          lng: task.longitude_checking,
        });
        setRadius(task.radius);
        setIncludeLocation(task.latitude_checking && task.longitude_checking);
      } catch (error) {
        console.error(error);
        Toast.fire({
          icon: "error",
          title: "Erro ao carregar a tarefa"
        });
        setIsAlertVisible(true);
      }
    };

    loadTask();
  }, [appId, taskId]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if ((!locationChecking || !locationChecking.lat || !locationChecking.lng) && !neededPictures) {
      Toast.fire({
        icon: "error",
        title: "Por favor, preencha um dos requisitos para completar a tarefa (localização ou quantidade de fotos necessárias)"
      });
      return;
    }

    if (locationChecking.lat && locationChecking.lng && !radius) {
      Toast.fire({
        icon: "error",
        title: "Por favor, preencha o raio quando a localização estiver definida."
      });
      return;
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('done_points', donePoints);
    formData.append('max_date', maxDate);
    if (neededPictures) {
      formData.append('needed_pictures', neededPictures);
    }

    if (includeLocation && locationChecking.lat && locationChecking.lng) {
      formData.append('latitude_checking', locationChecking.lat);
      formData.append('longitude_checking', locationChecking.lng);
      formData.append('radius', radius);
    } else {
      formData.append('latitude_checking', '');
      formData.append('longitude_checking', '');
      formData.append('radius', '');
    }

    formData.append('_method', 'PUT');

    trackPromise(api.post(`/${appId}/tasks/${taskId}`, formData, config))
      .then(response => {
        Toast.fire({
          icon: "success",
          title: "Tarefa atualizada com sucesso!"
        });
        history.push("/admin/tasks");
      })
      .catch((error) => {
        console.error(error);
        Toast.fire({
          icon: "error",
          title: "Houve um problema ao atualizar sua tarefa!"
        });
      });
  };

  function onMapClick(e) {
    var lat = e.latlng.lat;
    var lng = e.latlng.lng;

    setLocationChecking({ lat, lng });
    setCurrentLocation({ lat, lng });
  }

  function handleCheckboxChange(e) {
    setIncludeLocation(e.target.checked);
    if (!e.target.checked) {
      setLocationChecking({ lat: 0, lng: 0 });
      setRadius(null);
    }
  }

  return (
    <div className="content task">
      <Row>
        <Col md={12}>
          <p>Atualizar Tarefa</p>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            <Row>
              <Col md={4}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Título",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "Digite o Título da tarefa",
                      required: true,
                      value: title,
                      onChange: (event) => setTitle(event.target.value),
                    }
                  ]}
                />
              </Col>

              <Col md={4}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Descrição",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "Digite a Descrição da tarefa",
                      required: false,
                      value: description,
                      onChange: (event) => setDescription(event.target.value),
                    },
                  ]}
                />
              </Col>

              <Col md={4}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Fotos Necessárias",
                      type: "number",
                      bsClass: "form-control",
                      placeholder: "Digite a quantidade de fotos necessárias",
                      required: false,
                      value: neededPictures,
                      onChange: (event) => setNeededPictures(event.target.value),
                    },
                  ]}
                />
              </Col>
            </Row>

            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col md={4}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Pontuação",
                      type: "number",
                      bsClass: "form-control",
                      placeholder: "Digite a pontuação da tarefa",
                      required: true,
                      value: donePoints,
                      onChange: (event) => setDonePoints(event.target.value),
                    }
                  ]}
                />
              </Col>

              <Col md={4}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Data Limite",
                      type: "datetime-local",
                      bsClass: "form-control",
                      placeholder: "Digite a data limite da tarefa",
                      required: true,
                      value: maxDate,
                      onChange: (event) => setMaxDate(event.target.value),
                    },
                  ]}
                />
              </Col>

              <Col md={4} style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
                <label className="task-label">Incluir Localização</label>
                <input
                  type="checkbox"
                  checked={includeLocation}
                  onChange={handleCheckboxChange}
                />
              </Col>
            </Row>

            {includeLocation && (
              <>
                <Row >
                  <Col md={12}>
                    <p>Escolha a localização da tarefa</p>
                    <Map style={{ marginLeft: "15px", marginTop: "15px", marginBottom: "15px" }} center={currentLocation} onClick={onMapClick} zoom={15}>
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      />
                      <Marker position={currentLocation} />
                      <Circle center={currentLocation} fillColor="blue" radius={radius} />
                    </Map>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "Raio (em metros)",
                          type: "number",
                          bsClass: "form-control",
                          placeholder: "Adicione o raio em metros",
                          required: false,
                          value: radius,
                          onChange: (event) => setRadius(event.target.value),
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </>
            )}

            <div className="clearfix" />
            <Button bsStyle="danger" fill onClick={() => history.goBack()}>
              Voltar
            </Button>
            <Button bsStyle="info" pullRight fill type="submit">
              Salvar
            </Button>
          </form>
        </Col>
      </Row>
    </div>
  );
}
