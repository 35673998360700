import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Grid,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import CandidateTag from "components/CandidateTag/CandidateTag";
import { TutorialVideoModal } from "components/TutorialModal/TutorialModal";
import { Filters } from "./components/ParticipationFilters";
import { ModalReport } from "./components/ModalReport";
import { participationStatus } from "./category";

import api from "services/api";

import { datetimeFormatter } from "services/formatters";
import { style } from "variables/Variables.jsx";

const nameReportByType = {
  summarized: "SIMPLES",
  detailed: "DETALHADO",
};

const Participations = () => {
  const appId = localStorage.getItem("idApp");
  const appType = localStorage.getItem("typeApplication");

  const [participations, setParticipations] = useState([]);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);

  const [filters, setFilters] = useState({});
  const [areas, setAreas] = useState([]);
  const [federalUnits, setFederalUnits] = useState([]);
  const [candidates, setCandidates] = useState([]);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenComunicationModal, setIsOpenComunicationModal] = useState(false)

  const filtersRef = useRef();
  const notificationSystem = useRef();

  function getParticipations(url) {
    trackPromise(api.get(url))
      .then((res) => {
        setParticipations(res.data.data);
        setCurrentPage(res.data.meta.current_page);
        setItemsPerPage(res.data.meta.per_page);
        setTotalItems(res.data.meta.total);
      })
      .catch((err) => console.error(err));
  }

  async function getAreas() {
    return trackPromise(api.get(`/${appId}/participation_area`))
      .then((res) => {
        setAreas(
          res.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      })
      .catch((err) => console.error(err));
  }

  async function getFederalUnitys() {
    return trackPromise(api.get(`/federal_units`))
      .then((res) => {
        setFederalUnits(
          res.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      })
      .catch((err) => console.error(err));
  }

  async function getCandidates() {
    return trackPromise(api.get(`/${appId}/candidates/get_by_roles`))
      .then((res) => {
        setCandidates(
          res.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      })
      .catch((err) => console.error(err));
  }

  async function loadDataFilter() {
    const promises = [getAreas(), getFederalUnitys(), getCandidates()];
    await Promise.all(promises);
  }

  function generateReport(type) {
    const filtersFormtted = Object.values(filters)
      .filter((item) => item !== "")
      .join("&");

    trackPromise(
      api.get(
        `/${appId}/participation/get_report/index?${filtersFormtted}&type=${type}`,
        { responseType: "blob" }
      )
    )
      .then((res) => {
        handleNotification(
          "Relatório gerado, veja no seu gerenciador de documentos.",
          "success"
        );

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute(
          "download",
          `RELATÓRIO ${nameReportByType[type]
          } PARTICIPAÇÕES - ${new Date().getTime()}.pdf`
        );
        document.body.appendChild(link);

        link.click();
      })
      .catch((err) => {
        console.error(err);
        handleNotification(
          "Erro ao gerar relatório, tente novamente.",
          "error"
        );
      });
  }

  function handleNotification(message, status) {
    const notification = notificationSystem.current;

    notification.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: message,
      level: status,
      position: "tr",
      dismissible: true,
    });
  }

  function handleCloseModal() {
    setIsOpenModal(false);
  }

  function handleCloseComunicationModal() {
    setIsOpenComunicationModal(false);
  }

  function handleOpenComunicationModal() {
    setIsOpenComunicationModal(true);
  }

  useEffect(() => {
    loadDataFilter();
  }, []);

  useEffect(() => {
    const filtersFormtted = Object.values(filters)
      .filter((item) => item !== "")
      .join("&");

    getParticipations(
      `/${appId}/participation?${filtersFormtted}&paginate=1&page=${page}`
    );
  }, [filters, page]);

  return (
    <>
      <div className="content">
        <NotificationSystem ref={notificationSystem} style={style} />

        <Grid fluid>
          <Row>
            <Col md={12}>
              <Button
                title="Gerar relótorio"
                bsStyle="primary"
                fixMargin
                pullRight
                fill
                onClick={() => setIsOpenModal(true)}
              >
                Gerar relatório
              </Button>
              <Button
                title="Resetar filtros"
                bsStyle="default"
                fixMargin
                pullRight
                style={{
                  marginRight: "1rem",
                }}
                onClick={() => filtersRef.current.handleReset()}
              >
                Resetar filtros
              </Button>

              <Card
                title="Participações"
                extraTitleContent={
                  <i
                    className="fa fa-question-circle"
                    style={{ fontSize: "18px", cursor: "pointer" }}
                    title="Assista ao vídeo tutorial dessa área"
                    onClick={handleOpenComunicationModal}
                  />
                }
                category="Listagem de participações da aplicação"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <>
                    <Filters
                      ref={filtersRef}
                      areas={areas}
                      federalUnits={federalUnits}
                      candidates={candidates}
                      handleChangeFilter={setFilters}
                      handleChangePage={setPage}
                    />

                    <Table striped hover>
                      <thead>
                        <tr>
                          <th>Área</th>
                          <th>Categoria</th>
                          <th>Status</th>
                          <th>Eleitor</th>
                          {appType === "many" && <th>Candidato</th>}
                          <th>Criado em</th>
                          <th>Opções</th>
                        </tr>
                      </thead>
                      <tbody>
                        {participations.map((participation) => (
                          <tr key={participation.id}>
                            <td>{participation.area.name}</td>
                            <td>{participation.category.name}</td>
                            <td>
                              {participationStatus.get(participation.status)}
                            </td>
                            <td>{participation.user.name}</td>
                            {appType === "many" && (
                              <td>
                                {participation.area.candidate_id ? (
                                  <CandidateTag
                                    candidate={participation.area.candidate}
                                  />
                                ) : (
                                  <div>Sem candidato específico</div>
                                )}
                              </td>
                            )}
                            <td>{datetimeFormatter(participation.created)}</td>
                            <td>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="remove">Ver detalhes</Tooltip>
                                }
                              >
                                <Button
                                  bsStyle="primary"
                                  simple
                                  type="button"
                                  bsSize="xs"
                                >
                                  <NavLink
                                    to={`/admin/participations/${participation.id}`}
                                    className="nav-link"
                                    activeClassName="active"
                                  >
                                    <i className="pe-7s-search" />
                                    &nbsp;
                                    <span>Detalhes</span>
                                  </NavLink>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                }
              />
            </Col>
          </Row>
        </Grid>
        <div className="text-center">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalItems}
            pageRangeDisplayed={5}
            onChange={(page) => setPage(page)}
          />
        </div>
      </div>

      <TutorialVideoModal
        isOpen={isOpenComunicationModal}
        onClose={handleCloseComunicationModal}
        link={"https://www.youtube.com/embed/asRC_51EWOQ?si=VLB9HsXAlpeVNuw-"}
        linkTitle={"Listagem de participações"}
        title={"Listagem de participações"}
      />

      <ModalReport
        show={isOpenModal}
        onHide={handleCloseModal}
        handleAction={generateReport}
      />
    </>
  );
};

export default Participations;
