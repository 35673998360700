import React, { useState, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useParams, useHistory } from "react-router-dom";
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

import api from 'services/api.jsx';
import Card from 'components/Card/Card.jsx';
import Button from 'components/CustomButton/CustomButton.jsx';
import MapModal from './map';
import ImagesModal from './images-modal';
import ValidateModal from './validate';
import InvalidateModal from './invalidate';
import { EmptyAnswer } from './empty-answer'
import Pagination from 'react-js-pagination';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});

const TaskAnswer = () => {
  const appId = localStorage.getItem('idApp');
  const { taskId } = useParams();
  const history = useHistory();

  const [voterTasks, setVoterTasks] = useState([]);
  const [showMapModal, setShowMapModal] = useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [showValidateModal, setShowValidateModal] = useState(false);
  const [showInvalidateModal, setShowInvalidateModal] = useState(false);
  const [selectedTaskLocation, setSelectedTaskLocation] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [taskToValidate, setTaskToValidate] = useState(null);
  const [isValidation, setIsValidation] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    loadVoterTasks(currentPage);
  }, [appId, taskId, currentPage]);

  function loadVoterTasks(page) {
    trackPromise(
      api.get(`/${appId}/tasks/${taskId}/tasks_validation?page=${page}`)
        .then((response) => {
          const tasksData = response.data.data;
          const { current_page, per_page, total } = response.data;

          const tasks = tasksData.map(item => ({
            ...item.voter_task.task,
            images: item.images_task,
            voterTask: item.voter_task,
            voter: item.voter_task.voter,
          }));

          setVoterTasks(tasks);
          setCurrentPage(current_page);
          setItemsPerPage(per_page);
          setTotalItems(total);
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  const handleShowMap = (latitude, longitude) => {
    setSelectedTaskLocation({ latitude, longitude });
    setShowMapModal(true);
  };

  const handleShowImages = (images) => {
    setSelectedImages(images);
    setShowImagesModal(true);
  };

  const handleTaskStatusChange = (voterTaskId, isValid, invalidateText = null) => {
    const payload = { validated: isValid, invalidate_text: invalidateText };
    trackPromise(
      api.put(`${appId}/voter_task/${voterTaskId}/validate`, payload)
        .then((response) => {
          loadVoterTasks(currentPage);
          if (isValid) {
            toastValidate();
          } else {
            toastInvalidate();
          }
        })
        .catch((e) => {
          console.log(e);
          if (isValid) {
            Toast.fire({
              icon: "error",
              title: "Erro ao validar a tarefa!"
            });
          } else {
            Toast.fire({
              icon: "error",
              title: "Erro ao invalidar a tarefa!"
            });
          }
        })
    );
  };

  const toastValidate = () => {
    Toast.fire({
      icon: "success",
      title: "Tarefa validada com sucesso!"
    });
  }

  const toastInvalidate = () => {
    Toast.fire({
      icon: "success",
      title: "Tarefa invalidada com sucesso!"
    });
  }

  const handleShowValidate = (voterTaskId) => {
    setTaskToValidate(voterTaskId);
    setIsValidation(true);
    setShowValidateModal(true);
  };

  const handleCloseValidate = () => {
    setShowValidateModal(false);
    setTaskToValidate(null);
    setIsValidation(null);
  };

  const handleShowInvalidate = (voterTaskId) => {
    setTaskToValidate(voterTaskId);
    setIsValidation(false);
    setShowInvalidateModal(true);
  };

  const handleCloseInvalidate = () => {
    setShowInvalidateModal(false);
    setTaskToValidate(null);
    setIsValidation(null);
  };

  const handleConfirmValidate = () => {
    if (taskToValidate !== null && isValidation !== null) {
      handleTaskStatusChange(taskToValidate, isValidation);
    }
    handleCloseValidate();
  };

  const handleConfirmInvalidate = (invalidateText) => {
    if (taskToValidate !== null && isValidation !== null) {
      handleTaskStatusChange(taskToValidate, isValidation, invalidateText);
    }
    handleCloseInvalidate();
  };

  return (
    <div className="content">
      <Button
        style={{ display: 'flex', gap: "3px", alignItems: "center", marginTop: "15px", marginRight: "15px" }}
        title="Voltar"
        bsStyle="warning"
        fixMargin pullRight fill
        onClick={() => history.goBack()}
      >
        <i className="fa fa-arrow-left" />
        Voltar
      </Button>
      <Card
        title="Tarefas do Eleitor"
        category="Listagem de Tarefas do Eleitor"
        ctTableFullWidth
        ctTableResponsive
        content={
          voterTasks.length === 0 ? (
            <EmptyAnswer />
          ) : (
            <Table striped hover style={{ marginBottom: "0px" }}>
              <thead>
                <tr>
                  <th>Título da tarefa</th>
                  <th>Nome do eleitor</th>
                  <th>Email do eleitor</th>
                  <th>Data de envio</th>
                  <th>Id da tarefa</th>
                  <th>Id do eleitor</th>
                  <th>Localização</th>
                  <th>Imagens</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {voterTasks.map(({ voterTask, images, voter }) => (
                  <tr key={voterTask.id}>
                    <td>{voterTask.task.title}</td>
                    <td>{voter.user.name}</td>
                    <td>{voter.user.email}</td>
                    <td>{dayjs(voterTask.done_date).format('DD/MM/YYYY')}</td>
                    <td>{voterTask.task_id}</td>
                    <td>{voterTask.voter_id}</td>
                    {voterTask.latitude_checkin && voterTask.longitude_checkin ? (
                      <td>
                        <Button
                          bsStyle="info"
                          simple
                          type="button"
                          bsSize="xs"
                          onClick={() => handleShowMap(voterTask.latitude_checkin, voterTask.longitude_checkin)}
                        >
                          <i className="fa fa-map"></i>
                          Localização
                        </Button>
                      </td>
                    ) : (
                      <td>Sem localização</td>
                    )}
                    <td>
                      {images.length > 0 ? (
                        <Button
                          bsStyle="info"
                          simple
                          type="button"
                          bsSize="xs"
                          onClick={() => handleShowImages(images)}
                        >
                          <i className="fa fa-image"></i>
                          Ver Imagens
                        </Button>
                      ) : (
                        'Sem imagens'
                      )}
                    </td>
                    {voterTask.validated === null
                      ? <td style={{ color: "#1DC7EA" }}>Aguardando</td>
                      : voterTask.validated === true
                        ? <td style={{ color: "green" }}>Validada</td>
                        : <td style={{ color: "red" }}>Indeferida</td>}
                    {
                      voterTask.validated === null ?
                        <td>
                          <Button
                            bsStyle="info"
                            bsSize="xs"
                            fixMargin fill
                            onClick={() => handleShowValidate(voterTask.id)}
                          >
                            Validar
                          </Button>
                          <Button
                            bsStyle="warning"
                            bsSize="xs"
                            fixMargin fill
                            onClick={() => handleShowInvalidate(voterTask.id)}
                          >
                            Invalidar
                          </Button>
                        </td> : <td>Tarefa avaliada</td>
                    }
                  </tr>
                ))}
              </tbody>
            </Table>
          )
        }
      />
      <MapModal
        setModalClose={() => setShowMapModal(false)}
        showModal={showMapModal}
        latitude={selectedTaskLocation.latitude}
        longitude={selectedTaskLocation.longitude}
        radius={selectedTaskLocation.radius}
      />
      <ImagesModal
        setModalClose={() => setShowImagesModal(false)}
        showModal={showImagesModal}
        images={selectedImages}
      />
      <ValidateModal show={showValidateModal} onClose={handleCloseValidate} onConfirm={handleConfirmValidate} />
      <InvalidateModal show={showInvalidateModal} onClose={handleCloseInvalidate} onConfirm={handleConfirmInvalidate} />
      <div style={{ textAlign: 'center' }}>
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalItems}
          pageRangeDisplayed={5}
          onChange={(page) => setCurrentPage(page)} />
      </div>
    </div>
  );
}

export default TaskAnswer;
