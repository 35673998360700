import React from "react";
import "./styles.css";

export function EmptyTask() {
    return (
        <div className="fields-empty-state">
            <strong>Não há tarefas cadastradas</strong>

            <p>
                Parece que não há tarefas disponíveis no momento. Por favor,
                verifique novamente mais tarde ou realize a criação de uma nova tarefa.
            </p>
        </div>
    );
}
