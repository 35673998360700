import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : process.env.REACT_APP_ENVSTATE !== "prod"
  ? "https://candidatos.api.grt8.com.br:4021/api"
  : "http://localhost:8000/api";

const api = axios.create({
  baseURL: apiUrl,
});

// interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const requestConfig = error.config;
      return axios(requestConfig);
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("access_token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
