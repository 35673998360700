import React, { useState, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

import api from 'services/api.jsx';
import Card from 'components/Card/Card.jsx';
import Button from 'components/CustomButton/CustomButton.jsx';
import ValidateModal from './validate';
import InvalidateModal from './invalidate';
import ImagesModal from './images-modal';
import { EmptyAnswer } from './empty-answer';
import Pagination from 'react-js-pagination';

const Toast = Swal.mixin({
	toast: true,
	width: 400,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	}
});

export default function GoalAnswer() {
	const [voterGoals, setVoterGoals] = useState([]);
	const [filteredVoterGoals, setFilteredVoterGoals] = useState([]);
	const [showValidateModal, setShowValidateModal] = useState(false);
	const [showInvalidateModal, setShowInvalidateModal] = useState(false);
	const [showImagesModal, setShowImagesModal] = useState(false);
	const [selectedVoter, setSelectedVoter] = useState(null);
	const [isValidation, setIsValidation] = useState(null);
	const [goalValidate, setGoalValidate] = useState(null);
	const [stepNumberFilter, setStepNumberFilter] = useState("");
	const [currentPage, setCurrentPage] = React.useState(1);
	const [itemsPerPage, setItemsPerPage] = React.useState(15);
	const [totalItems, setTotalItems] = React.useState(0);

	const history = useHistory();
	const appId = localStorage.getItem('idApp');
	const { goalId } = useParams();

	useEffect(() => {
		loadVoterGoals(currentPage);
	}, [appId, goalId, currentPage]);

	useEffect(() => {
		filterVoterGoals(stepNumberFilter);
	}, [voterGoals, stepNumberFilter]);

	function loadVoterGoals(page) {
		trackPromise(
			api.get(`/${appId}/goals/${goalId}/goals_validation?page=${page}`)
				.then((response) => {
					const goalsData = response.data.data;
					const { current_page, per_page, total } = response.data;


					const goals = goalsData.map(item => ({
						...item.voter_goal.goal,
						images: item.images_goal,
						voterGoal: item.voter_goal,
						voter: item.voter_goal.voter,
					}))
					setVoterGoals(goals)
					setCurrentPage(current_page);
					setItemsPerPage(per_page);
					setTotalItems(total);
				})
				.catch((e) => {
					console.log(e);
				})
		);
	}

	const handleGoalValidate = (voterGoalId, isValid, validateText = "") => {
		const payload = { validated: isValid, validate_text: validateText, _method: 'PUT' };
		trackPromise(
			api.post(`${appId}/voter_goal/${voterGoalId}/validate`, payload)
				.then((response) => {
					loadVoterGoals(`/${appId}/goals/${goalId}/goals_validation`);
					if (isValid) {
						toastValidate();
					} else {
						toastInvalidate();
					}
				})
				.catch((e) => {
					console.log(e);
					if (isValid) {
						Toast.fire({
							icon: "error",
							title: "Erro ao validar a meta!"
						});
					} else {
						Toast.fire({
							icon: "error",
							title: "Erro ao invalidar a meta!"
						});
					}
				})
		);
	};

	const toastValidate = () => {
		Toast.fire({
			icon: "success",
			title: "Meta validada com sucesso!"
		});
	}

	const toastInvalidate = () => {
		Toast.fire({
			icon: "success",
			title: "Meta invalidada com sucesso!"
		});
	}

	const handleShowValidate = (voterGoalId) => {
		setGoalValidate(voterGoalId);
		setIsValidation(true);
		setShowValidateModal(true);
	};

	const handleCloseValidate = () => {
		setShowValidateModal(false);
		setGoalValidate(null);
		setIsValidation(null);
	};

	const handleShowInvalidate = (voterGoalId) => {
		setGoalValidate(voterGoalId);
		setIsValidation(false);
		setShowInvalidateModal(true);
	};

	const handleCloseInvalidate = () => {
		setShowInvalidateModal(false);
		setGoalValidate(null);
		setIsValidation(null);
	};

	const handleConfirmValidate = () => {
		if (goalValidate !== null && isValidation !== null) {
			handleGoalValidate(goalValidate, isValidation);
		}
		handleCloseValidate();
	};

	const handleConfirmInvalidate = (validateText) => {
		if (goalValidate !== null && isValidation !== null) {
			handleGoalValidate(goalValidate, isValidation, validateText);
		}
		handleCloseInvalidate();
	};

	const handleShowImages = (voter) => {
		setSelectedVoter(voter);
		setShowImagesModal(true);
	};

	const filterVoterGoals = (stepNumber) => {
		if (stepNumber === "") {
			setFilteredVoterGoals(voterGoals);
		} else {
			const filteredGoals = voterGoals.filter(voter => voter.voterGoal?.step_number.toString() === stepNumber);
			setFilteredVoterGoals(filteredGoals);
		}
	};

	const getMaxStepNumber = () => {
		if (!voterGoals.length) {
			return 0
		}
		const stepNumbers = voterGoals.map((item) => item.voterGoal?.step_number)
		return Math.max(...stepNumbers);
	};

	return (
		<div className="content">
			<Button
				style={{ display: 'flex', gap: "3px", alignItems: "center", marginTop: "15px", marginRight: "15px" }}
				title="Voltar"
				bsStyle="warning"
				fixMargin pullRight fill
				onClick={() => history.goBack()}
			>
				<i className="fa fa-arrow-left" />
				Voltar
			</Button>
			<Card
				title="Metas do Eleitor"
				category="Listagem de Metas do Eleitor"
				ctTableFullWidth
				ctTableResponsive
				content={
					<div>
						<div style={{ display: "flex", gap: "10px", marginLeft: "25px", alignItems: "center" }}>
							{Array.from({ length: getMaxStepNumber() }, (_, i) => (
								<Button
									key={i + 1}
									bsStyle={stepNumberFilter === (i + 1).toString() ? "info" : "default"}
									onClick={() => setStepNumberFilter((i + 1).toString())}
								>
									Passo {i + 1}
								</Button>
							))}
							<Button
								bsStyle={stepNumberFilter === "" ? "info" : "default"}
								onClick={() => setStepNumberFilter("")}
							>
								Todos
							</Button>
						</div>
						{filteredVoterGoals.length === 0 ? <EmptyAnswer /> : (<Table striped hover style={{ marginBottom: "0px" }}>
							<thead>
								<tr>
									<th>Título da Meta</th>
									<th>Nome do eleitor</th>
									<th>Email do eleitor</th>
									<th>Número do passo</th>
									<th>Data de Entrega</th>
									<th>Descrição</th>
									<th>Status</th>
									<th>Imagem</th>
									<th>Ações</th>
								</tr>
							</thead>

							<tbody>
								{filteredVoterGoals.map((voter) => (
									<tr key={voter.voterGoal.id}>
										<td>{voter.title}</td>
										<td>{voter.voterGoal.voter.user.name}</td>
										<td>{voter.voterGoal.voter.user.email}</td>
										<td>{voter.voterGoal.step_number}</td>
										<td>{dayjs(voter.voterGoal.max_date).format('DD/MM/YYYY')}</td>
										<td>{voter.voterGoal.description ? voter.voterGoal.description : "Sem descrição"}</td>
										{voter.voterGoal.validated === null
											? <td style={{ color: "#1DC7EA" }}>Aguardando</td>
											: voter.voterGoal.validated === true
												? <td style={{ color: "green" }}>Validada</td>
												: <td style={{ color: "red" }}>Indeferida</td>}
										<td>
											{voter.images && voter.images.length > 0 ? (
												<Button
													bsStyle="info"
													simple
													type="button"
													bsSize="xs"
													onClick={() => handleShowImages(voter)}
												>
													<i className="fa fa-image"></i>
													Ver Imagens
												</Button>
											) : (
												'Sem imagens'
											)}
										</td>
										{
											voter.voterGoal.validated === null ?
												<td>
													<Button
														bsStyle="info"
														bsSize="xs"
														fixMargin fill
														onClick={() => handleShowValidate(voter.voterGoal.id)}
													>
														Validar
													</Button>
													<Button
														bsStyle="warning"
														bsSize="xs"
														fixMargin fill
														onClick={() => handleShowInvalidate(voter.voterGoal.id)}
													>
														Invalidar
													</Button>
												</td> : <td>Meta avaliada</td>
										}
									</tr>
								))}
							</tbody>
						</Table>)}
					</div>
				} />
			<ValidateModal show={showValidateModal} onClose={handleCloseValidate} onConfirm={handleConfirmValidate} />
			<InvalidateModal show={showInvalidateModal} onClose={handleCloseInvalidate} onConfirm={handleConfirmInvalidate} />
			<ImagesModal setModalClose={() => setShowImagesModal(false)} showModal={showImagesModal} voter={selectedVoter} />
			<div style={{ textAlign: 'center' }}>
				<Pagination
					activePage={currentPage}
					itemsCountPerPage={itemsPerPage}
					totalItemsCount={totalItems}
					pageRangeDisplayed={5}
					onChange={(page) => setCurrentPage(page)} />
			</div>
		</div>
	)
}
