import Card from 'components/Card/Card.jsx';
import Confirm from 'components/Confirm/Confirm.jsx';
import Button from 'components/CustomButton/CustomButton.jsx';
import React, { Component, createRef } from 'react';
import {
	Col,
	Grid,
	Row,
	Table,
	Tooltip,
	MenuItem,
	Dropdown,
} from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import NotificationSystem from 'react-notification-system';
import { trackPromise } from 'react-promise-tracker';
import Select from 'react-select';
import { FiInfo } from 'react-icons/fi';

import { style } from 'variables/Variables.jsx';
import api from '../../services/api.jsx';
import Details from './details.jsx';
import Notification from './notification';
import Leaders from './leaders';
import Store from './store.jsx';
import CandidateTag from 'components/CandidateTag/CandidateTag.jsx';
import { CustomToggle } from 'components/CustomToggle';

import './styles.css';
import { CSVLink } from 'react-csv';
import DeleteVoter from 'views/voter/delete-voter-modal.jsx';
require('bootstrap/less/bootstrap.less');

class Voters extends Component {
	types = [
		{ value: 1, label: 'Todos' },
		{ value: 2, label: 'Usuários' },
		{ value: 3, label: 'Líderes' },
	];

	headers = [
		{ label: "ID", key: "id" },
		{ label: "NOME", key: "name" },
		{ label: "E-MAIL", key: "email" },
		{ label: "CANDIDATO", key: "candidate" },
		{ label: "CIDADE", key: "county" },
		{ label: "ESTADO", key: "federal_unit_initials" },
		{ label: "BAIRRO", key: "neighborhood" },
		{ label: "DATA DE NASCIMENTO", key: "birth" },
		{ label: "GÊNERO", key: "gender" },
		{ label: "TELEFONE", key: "telephone" },
	];

	typeApplication = localStorage.getItem('typeApplication');


	constructor(props) {
		super(props);

		this.state = {
			_notificationSystem: null,
			users: [],
			role: JSON.parse(localStorage.getItem('user')).role,
			applications: [],
			isModalVisibleNew: false,
			idApplication: localStorage.getItem('idApp'),
			appUser: JSON.parse(localStorage.getItem('user')),
			isAdmin: localStorage.getItem('typeRole') === 'admin' ? true : false,
			configPagination: {
				activePage: null,
				itemsCountPerPage: null,
				totalItemsCount: 1,
				pageRangeDisplayed: 5,
			},
			showDetails: false,
			showDeleteModal: false,
			idUserDelete: 0,
			currentUserDetails: null,
			currentVotingIntentionDetails: {},
			voterName: '',
			voterIsleader: false,
			showNotification: false,
			confirm: false,
			text: '',
			callback: undefined,
			showLeaders: false,
			candidates: [],
			federalUnits: [],
			citys: [],
			neighborhoods: [],
			voters: [],
			filters: {
				type: 'type=0',
			},
			nameSearch: '',
			email: '',
			isLoading: false,
		};
	}

	nameRef = React.createRef();
	emailRef = React.createRef();
	typeRef = React.createRef();
	stateRef = React.createRef();
	cityRef = React.createRef();
	neighborhoodRef = React.createRef();
	refCSV = React.createRef()


	async componentDidMount() {
		const promises = [this.loadUsers(), this.getFederalUnitys(), this.loadFields()];

		if (this.state.isAdmin) {
			promises.push(this.loadApplications());
		}

		await Promise.all(promises);
	}

	exportVoters = () => {
		trackPromise(api.get(`${this.state.idApplication}/voters/export`)).then((response) => {
			const data = response.data;

			const voters = data.map((voter) => {
				const fields = voter.voter.answers.reduce((acc, item) => {
					acc[item.voter_field_id.toString()] = item.answer;
					return acc;
				}, {})

				return {
					id: voter.id,
					name: voter.name,
					email: voter.email,
					candidate: voter.voter.voting_intention?.candidate ? voter.voter.voting_intention?.candidate?.name : '-',
					county: voter.voter.neighborhood_id ? voter.voter.neighborhood.county.name : '-',
					federal_unit_initials: voter.voter.neighborhood_id ? voter.voter.neighborhood.county.federal_unit.name_formated : '-',
					neighborhood: voter.voter.neighborhood_id ? voter.voter.neighborhood.name : '-',
					birth: voter.voter.birth,
					gender: voter.voter.gender,
					telephone: voter.voter.telephone,
					...fields
				}
			});

			this.setState({ voters: voters });

			this.refCSV.current.link.click();
		}).catch(error => {
			console.error(error);
		});
	};

	handlePageChange(pageNumber) {
		this.loadUsers(pageNumber);
	}

	loadFields = async () => {
		try {
			const response = await api.get(`${this.state.idApplication}/voters/fields`);
			response.data.map((field) => {
				this.headers.push({
					key: field.id.toString(),
					label: field.name
				})
			});
		} catch (error) {
			console.error(error);
		}
	}

	loadApplications = async () => {
		try {
			const response = await api.get('/applications');
			this.setState({ applications: response.data.data });
		} catch (e) {
			this.handleNotificationClick(e.response.data.message, 'error');
		}
	};

	resetPasswordCandidate = (id) => {
		trackPromise(api.put(`/users/${id}/reset_password`))
			.then((response) => {
				this.loadUsers();
				this.handleNotificationClick('Senha resetada com sucesso!', 'success');
			})
			.catch((error) => {
				console.log(error.message);
				if (error.response.data.errors) {
					if (error.response.data.errors.user) {
						this.handleNotificationClick(
							error.response.data.errors.user[0],
							'error'
						);
					}
				} else {
					this.handleNotificationClick(error.response.data.message, 'error');
				}
			});
	};

	async loadUsers(page = 1) {
		try {
			this.setState({
				isLoading: true,
			});

			const filtersFormtted = Object.values(this.state.filters)
				.filter((item) => item !== '')
				.join('&');

			const url = this.state.isAdmin
				? '/users'
				: `/${this.state.idApplication}/voters/search?type_application_id=one&by_candidate=1&page=${page}&${filtersFormtted}`;

			const response = await trackPromise(api.get(url));

			this.setState({
				users: this.state.isAdmin
					? response.data.data.filter((elm) => {
						return elm.role === 'voter';
					})
					: response.data.data,
			});

			if (page) {
				this.setState({
					configPagination: {
						activePage: response.data.meta.current_page,
						itemsCountPerPage: response.data.meta.per_page,
						totalItemsCount: response.data.meta.total,
						pageRangeDisplayed: 5,
					},
				});
			}
		} catch (e) {
			this.handleNotificationClick(e.response.data.message, 'error');
		} finally {
			this.setState({
				isLoading: false,
			});
		}
	}

	setLiderPartidario = (user_id, leader) => {
		const idApp = localStorage.getItem('idApp');
		trackPromise(
			api({
				method: 'post',
				url: `${idApp}/leaders/assign`,
				data: { user_id },
			})
		)
			.then((response) => {
				this.loadUsers();
				window.location.reload();
			})
			.catch((error) => {
				this.handleNotificationClick(
					'Erro tente novamente ' + error.response.data.message,
					'error'
				);
			});
	};

	filterVoters = async () => {
		await this.loadUsers();
	};

	resetFilter = () => {
		this.setState(
			{
				filters: {},
			},
			async () => {
				this.nameRef.current.value = '';
				this.emailRef.current.value = '';
				this.typeRef.current.select.clearValue();
				this.stateRef.current.select.clearValue();
				this.cityRef.current.select.clearValue();
				this.neighborhoodRef.current.select.clearValue();

				await this.loadUsers();
			}
		);
	};

	async getFederalUnitys() {
		return api
			.get(`/federal_units`)
			.then((res) => {
				this.setState({
					federalUnits: res.data.data.map((item) => ({
						value: item.id,
						label: item.name,
					})),
				});
			})
			.catch((err) => console.error(err));
	}

	async getCitys(stateId) {
		if (stateId === '') {
			return this.setState({
				citys: [],
			});
		}

		await api
			.get(`counties?id=${stateId}`)
			.then((res) => {
				this.setState({
					citys: res.data.data.map((item) => ({
						value: item.id,
						label: item.name,
					})),
				});
			})
			.catch((err) => console.error(err));
	}

	async getNeighborhoods(cityId) {
		if (cityId === '') {
			return this.setState({
				neighborhoods: [],
			});
		}

		await api
			.get(`counties/${cityId}/neighborhoods`)
			.then((res) => {
				this.setState({
					neighborhoods: res.data.data.map((item) => ({
						value: item.id,
						label: item.name,
					})),
				});
			})
			.catch((err) => console.error(err));
	}

	handleChangeName(name) {
		this.setState({
			filters: {
				...this.state.filters,
				name: name ? `name=${name}` : '',
			},
		});
	}

	handleChangeEmail(email) {
		this.setState({
			filters: {
				...this.state.filters,
				email: email ? `email=${email}` : '',
			},
		});
	}

	handleChangeType(type) {
		this.setState({
			filters: {
				...this.state.filters,
				type: type ? `type=${type}` : '',
			},
		});
	}

	async handleChangeState(state) {
		this.setState(
			{
				filters: {
					...this.state.filters,
					state: state ? `federal_unit=${state}` : '',
				},
			},
			async () => {
				if (!state) {
					this.cityRef.current.select.clearValue();
					this.setState({
						citys: [],
					});
				}

				if (state !== '') {
					await this.getCitys(state);
				}
			}
		);
	}

	async handleChangeCity(city) {
		this.setState(
			{
				filters: {
					...this.state.filters,
					city: city ? `county=${city}` : '',
				},
			},
			async () => {
				if (!city) {
					this.neighborhoodRef.current.select.clearValue();
					this.setState({
						neighborhoods: [],
					});
				}

				if (city !== '') {
					await this.getNeighborhoods(city);
				}
			}
		);
	}

	handleChangeNeighborhood(neighborhood) {
		this.setState({
			filters: {
				...this.state.filters,
				neighborhood: neighborhood ? `neighborhood=${neighborhood}` : '',
			},
		});
	}

	deleteVoter = (id) => {
		const appId = localStorage.getItem('idApp');
		trackPromise(api.delete(`${appId}/voters/${id}`))
			.then((response) => {
				this.loadUsers();
				this.handleNotificationClick('Eleitor excluído com sucesso!', 'success');
			})
			.catch((error) => {
				console.log(error.message);
				this.handleNotificationClick('Erro na exclusão do eleitor! ' + error.response.data.message, 'error');
			});
	};

	handleCreateVoter = () => {
		this.props.history.push(`/admin/create-voter`)
	}

	handleEditVoter = (id) => {
		this.props.history.push(`/admin/update-voter/${id}`)
	}

	handleNotificationClick = (message, color) => {
		this.refs.notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-info" />,
			message: <div>{message}</div>,
			level: color,
			position: 'tr',
			autoDismiss: 5,
			dismissible: true,
		});
	};

	filter() {
		return (
			<>
				<Row style={{ margin: '0px 0px 16px' }}>
					<Col md={4}>
						<label>Nome</label>
						<input
							ref={this.nameRef}
							type="text"
							name="name"
							id="name"
							placeholder="Busque pelo nome"
							value={this.state.nameSearch}
							onChange={(event) =>
								this.setState({ nameSearch: event.target.value })
							}
							onBlur={(event) => this.handleChangeName(event.target.value)}
							className="form-control"
							style={{
								height: '38px',
								borderColor: '#CCCCCC',
							}}
						/>
					</Col>
					<Col md={4}>
						<label>E-mail</label>
						<input
							ref={this.emailRef}
							type="text"
							name="email"
							id="email"
							placeholder="Busque pelo e-mail"
							value={this.state.email}
							onChange={(event) => this.setState({ email: event.target.value })}
							onBlur={(event) => this.handleChangeEmail(event.target.value)}
							className="form-control"
							style={{
								height: '38px',
								borderColor: '#CCCCCC',
							}}
						/>
					</Col>
					<Col md={4}>
						<label>Tipo</label>
						<Select
							ref={this.typeRef}
							name="state"
							options={this.types}
							defaultValue={this.types[0]}
							placeholder="Digite a busca"
							noOptionsMessage={() => 'Dados não encontrados'}
							isSearchable
							onChange={(option) => {
								if (option) this.handleChangeType(option.value);
							}}
						/>
					</Col>
				</Row>
				<Row style={{ margin: '16px 0px 24px' }}>
					<Col md={4}>
						<label>Estado</label>
						<Select
							ref={this.stateRef}
							name="state"
							options={this.state.federalUnits}
							placeholder="Digite a busca"
							noOptionsMessage={() => 'Dados não encontrados'}
							isSearchable
							isClearable
							onChange={(option, triggeredAction) => {
								if (triggeredAction.action === 'clear')
									return this.handleChangeState('');
								if (option) this.handleChangeState(option.value);
							}}
						/>
					</Col>
					<Col md={4}>
						<label>Cidade</label>
						<Select
							ref={this.cityRef}
							name="city"
							options={this.state.citys}
							placeholder="Digite a busca"
							noOptionsMessage={() => 'Dados não encontrados'}
							isSearchable
							isClearable
							isDisabled={this.state.citys.length === 0}
							onChange={(option, triggeredAction) => {
								if (triggeredAction.action === 'clear')
									return this.handleChangeCity('');
								if (option) this.handleChangeCity(option.value);
							}}
						/>
					</Col>
					<Col md={4}>
						<label>Bairro</label>
						<Select
							ref={this.neighborhoodRef}
							name="neighborhood"
							options={this.state.neighborhoods}
							placeholder="Digite a busca"
							noOptionsMessage={() => 'Dados não encontrados'}
							isSearchable
							isClearable
							isDisabled={this.state.neighborhoods.length === 0}
							onChange={(option, triggeredAction) => {
								if (triggeredAction.action === 'clear')
									return this.handleChangeNeighborhood('');
								if (option) this.handleChangeNeighborhood(option.value);
							}}
						/>
					</Col>
				</Row>
			</>
		);
	}

	render() {
		const {
			users,
			isLoading,
			isModalVisibleNew,
			showDetails,
			showDeleteModal,
			idUserDelete,
			isAdmin,
			configPagination,
			showNotification,
			showLeaders,
		} = this.state;

		const resetePassword = (
			<Tooltip id="password_tooltip">Resetar Senha</Tooltip>
		);

		const remove = <Tooltip id="remove_tooltip">Excluir</Tooltip>;

		const leaders = (
			<Tooltip id="password_tooltip">
				Deixar usuário como lider partidário
			</Tooltip>
		);

		return (
			<>
				<div className="content">
					<NotificationSystem ref="notificationSystem" style={style} />
					<Grid fluid>
						<Row>
							<Col md={12}>
								{isAdmin && (
									<Button
										title="cadastrar novo eleitor"
										bsStyle="success"
										fixMargin
										pullRight
										fill
										onClick={() => this.setState({ isModalVisibleNew: true })}
									>
										<i className="fa fa-plus"></i> novo cadastro
									</Button>
								)}
								<Card
									title="Eleitores"
									category="Eleitores cadastrados"
									ctTableFullWidth
									ctTableResponsive
									content={
										<>
											<div className="buttons">
												<CSVLink
													data={this.state.voters}
													headers={this.headers}
													ref={this.refCSV}
													filename={`Eleitores-APP:${this.state.idApplication}-${new Date().getTime()}`}
												/>
												<button
													className="btn-action outline"
													onClick={this.exportVoters}
												>
													Gerar CSV
												</button>
												<button
													className="btn-create"
													onClick={() => this.handleCreateVoter()}
												>
													Novo Eleitor
												</button>
											</div>

											{this.filter()}


											<div className="buttons-filter">
												<button
													className="btn-reset"
													onClick={this.resetFilter}
												>
													Resetar filtro
												</button>
												<button
													className="btn-search"
													onClick={this.filterVoters}
												>
													Buscar
												</button>
											</div>

											{users.length !== 0 && (
												<Table striped hover>
													<thead>
														<tr>
															<th>Identificador</th>
															<th>Cidade</th>
															<th>Bairro</th>
															{this.typeApplication !== 'one' && (
																<th>Candidato</th>
															)}
														</tr>
													</thead>
													<tbody>
														{users.map((app) => (
															<tr key={app.id}>
																<td>
																	<div className="profile">
																		<strong>
																			{app.name}
																			{app.leader && (
																				<span className="badge badge-custom-success">
																					Liderança
																				</span>
																			)}
																		</strong>
																		<span className="email">{app.email}</span>
																	</div>
																</td>
																<td>
																	{app.voter.neighborhood ? `${app.voter.neighborhood?.county} /
																		${app.voter.neighborhood?.federal_unit}` : '-'}

																</td>
																<td>{app.voter.neighborhood ? app.voter.neighborhood?.name : '-'}</td>
																{this.typeApplication !== 'one' && (
																	<td>
																		{app.voter.voting_intention.candidate ? (
																			<CandidateTag
																				candidate={
																					app.voter.voting_intention.candidate
																				}
																			/>
																		) : (
																			<div>Sem candidato específico</div>
																		)}
																	</td>
																)}
																{this.state.role !== 'advisor' && (
																	<td className="text-center">
																		<Dropdown
																			id="dropdown-custom-1"
																			bsSize="small"
																			pullRight
																		>
																			<CustomToggle bsRole="toggle" />
																			<Dropdown.Menu>
																				<MenuItem
																					onClick={() => {
																						this.setState({
																							showDetails: true,
																							currentUserDetails: app.id,
																						});
																					}}
																				>
																					<Button
																						bsStyle="primary"
																						style={{ width: '100%' }}
																						simple
																						type="button"
																						bsSize="xs"
																					>
																						<i className="fa fa-search-plus"></i>
																						Visualizar detalhes
																					</Button>
																				</MenuItem>
																				{/* <MenuItem
																					onClick={(event) => {
																						event.stopPropagation();
																						this.setState({
																							text: `resetar a senha de ${app.name}`,
																							callback: () =>
																								this.resetPasswordCandidate(
																									app.id
																								),
																							confirm: true,
																						});
																					}}
																				>
																					<Button
																						bsStyle="link"
																						style={{ width: '100%' }}
																						simple
																						type="button"
																						bsSize="xs"
																					>
																						<i className="fa fa-unlock-alt"></i>
																						Resetar senha
																					</Button>
																				</MenuItem> */}
																				<MenuItem
																					onClick={(event) => {
																						event.stopPropagation();
																						this.setState({
																							showNotification: true,
																							currentUserDetails: app.id,
																						});
																					}}
																				>
																					<Button
																						bsStyle="success"
																						style={{ width: '100%' }}
																						simple
																						type="button"
																						bsSize="xs"
																					>
																						<i className="fa fa-bell"></i>
																						Notificação
																					</Button>
																				</MenuItem>
																				{!app.leader &&
																					app.voter.voting_intention && (
																						<MenuItem
																							onClick={(event) => {
																								event.stopPropagation();
																								this.setState({
																									showLeaders: true,
																									currentUserDetails: app.id,
																									currentVotingIntentionDetails:
																									{
																										candidate_id: app.voter
																											.voting_intention
																											.candidate
																											? app.voter
																												.voting_intention
																												.candidate.id
																											: null,
																										candidate_name: app.voter
																											.voting_intention
																											.candidate
																											? app.voter
																												.voting_intention
																												.candidate.name
																											: null,
																										leader_id: app.voter
																											.voting_intention.leader
																											? app.voter
																												.voting_intention
																												.leader
																											: null,
																									},
																								});
																							}}
																						>
																							<Button
																								bsStyle="info"
																								style={{ width: '100%' }}
																								simple
																								type="button"
																								bsSize="xs"
																							>
																								<i className="fa fa-user"></i>
																								Tornar líder partidário
																							</Button>
																						</MenuItem>
																					)}
																				{isAdmin && (
																					<MenuItem>
																						<Button
																							bsStyle="info"
																							style={{ width: '100%' }}
																							simple
																							type="button"
																							bsSize="xs"
																							onClick={() =>
																								this.deleteVoter(app.id)
																							}
																						>
																							<i className="fa fa-times"></i>
																							Excluir
																						</Button>
																					</MenuItem>
																				)}
																				<MenuItem
																					onClick={() => this.handleEditVoter(app.id)}
																				>
																					<Button
																						bsStyle="info"
																						style={{ width: '100%' }}
																						simple
																						type="button"
																						bsSize="xs"
																					>
																						<i className="fa fa-search-plus"></i>
																						Editar Eleitor
																					</Button>
																				</MenuItem>
																				<MenuItem
																					onClick={() => {
																						this.setState({
																							showDeleteModal: true,
																							idUserDelete: app.id
																						});
																					}}
																				>
																					<Button
																						bsStyle="danger"
																						style={{ width: '100%' }}
																						simple
																						type="button"
																						bsSize="xs"
																					>
																						<i className="fa fa-search-plus"></i>
																						Excluir Eleitor
																					</Button>
																				</MenuItem>
																			</Dropdown.Menu>
																		</Dropdown>
																	</td>
																)}
															</tr>
														))}
													</tbody>
												</Table>
											)}

											{users.length === 0 && !isLoading && (
												<div className="not-found">
													<FiInfo size={20} />
													<span>Sem dados encontrados</span>
												</div>
											)}


											<div style={{ textAlign: 'center' }}>
												<Pagination
													activePage={configPagination.activePage}
													itemsCountPerPage={configPagination.itemsCountPerPage}
													totalItemsCount={configPagination.totalItemsCount}
													pageRangeDisplayed={
														configPagination.pageRangeDisplayed
													}
													onChange={this.handlePageChange.bind(this)}
												/>
											</div>

											{isModalVisibleNew && (
												<Store
													onClose={() => {
														this.setState({ isModalVisibleNew: false });
														this.loadUsers();
													}}
													applications={this.state.applications}
												/>
											)}

											{showDetails && (
												<Details
													voterId={this.state.currentUserDetails}
													onClose={() => {
														this.setState({ showDetails: false });
													}}
												/>
											)}

											{showDeleteModal && (
												<DeleteVoter
													show={showDeleteModal}
													onDelete={() => this.deleteVoter(idUserDelete)}
													onClose={() => {
														this.setState({ showDeleteModal: false })
													}} />
											)}

											{showNotification && (
												<Notification
													voterId={this.state.currentUserDetails}
													onClose={() => {
														this.setState({ showNotification: false });
													}}
												/>
											)}

											{showLeaders && (
												<Leaders
													voterId={this.state.currentUserDetails}
													votingIntention={
														this.state.currentVotingIntentionDetails
													}
													onClose={async (status) => {
														this.setState({ showLeaders: false });

														if (status === 'att') {
															await this.loadUsers();
														}
													}}
												/>
											)}
										</>
									}
								/>
							</Col>
						</Row>
					</Grid>
				</div>
				<Confirm
					show={this.state.confirm}
					close={() =>
						this.setState({
							confirm: false,
							callback: undefined,
							text: '',
						})
					}
					callback={this.state.callback}
					text={this.state.text}
				/>
			</>
		);
	}
}

export default Voters;
