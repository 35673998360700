import React from "react";
import { FiMoreVertical } from "react-icons/fi";

import "./styles.css";

export class CustomToggle extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick(e);
  }

  render() {
    return (
      <button onClick={this.handleClick} className="btn-drop">
        <FiMoreVertical size={20} color="#475569" />
        {this.props.children}
      </button>
    )
  }
}