import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import dayjs from 'dayjs';

import api from "services/api.jsx";

function Loading() {
  return (
    <div style={{ "padding": "30px" }}>
      <p> Carregando conteúdo... </p>
    </div>
  )
}

export default ({ onClose = () => { }, voterId }) => {

  const [show, setShow] = useState(true);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true)
  const appId = localStorage.getItem('idApp');

  const handleClose = () => {
    onClose()
    setShow(false);
  };

  useEffect(() => {
    api
      .get(`${appId}/voters/${voterId}`)
      .then(res => {
        setLoading(false)
        setContent(res.data.data)
      })
  }, [loading, appId, voterId])

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>

        {loading && <Loading />}

        {(!loading && content) &&
          <>
            <Modal.Header closeButton>
              <Modal.Title> Dados Cadastrais </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p> <b> Nome: </b> {content.name} </p>
              <p> <b> Lider Partidário: </b> {content.leader ? "Sim" : "Não"} </p>
              <p> <b> Email: </b> {content.email} </p>
              <p> <b> Telefone: </b> {content.voter ? content.voter.telephone || "-" : "-"} </p>

              <p>
                <b> Bairro: </b>
                {
                  content.voter ?
                    content.voter.neighborhood
                      ? `${content.voter.neighborhood.name} - ${content.voter.neighborhood.county}/${content.voter.neighborhood.federal_unit}`
                      : "-"
                    : "-"
                }
              </p>

              <p>
                <b>Intenção de voto: </b>
                {
                  content.voter && content.voter.voting_intention && content.voter.voting_intention.candidate ?
                    `
                        ${content.voter.voting_intention.candidate.elective_position} -
                        ${content.voter.voting_intention.candidate.name}
                        (${content.voter.voting_intention.candidate.number})
                        | ${content.voter.voting_intention.candidate.political_party.abbreviation}
                        `
                    : "-"
                }
              </p>

              <p>
                {
                  content.voter.answers ?
                    content.voter.answers.map((item) => (
                      item.field.type === "date" ? <p><b>{item.field.name}: </b>{dayjs(item.answer).format("DD/MM/YYYY")}</p>
                        : <p><b>{item.field.name}: </b>{item.answer}</p>
                    )) : "-"
                }
              </p>

            </Modal.Body>
          </>
        }

      </Modal>
    </>
  );
}
