import { useState } from "react";
import { useAtom, useSetAtom } from "jotai";
import { ArrowRight, CircleNotch } from "@phosphor-icons/react";

import { FormDataAtom } from "components/Onboarding/atoms/FormDataAtom";
import { CurrentStepAtom } from "components/Onboarding/atoms/CurrentStepAtom";
import api from "services/api";

import "./styles.css";

export function ColorsStep() {
  const [formData, setFormData] = useAtom(FormDataAtom);
  const setCurrentStep = useSetAtom(CurrentStepAtom);

  const [loading, setLoading] = useState(false);

  const [colors, setColors] = useState({
    buttons: formData.colorPrimary ?? "",
    bg: formData.colorBg ?? "",
    colorText: formData.colorText ?? "",
  });

  function handleNextStep() {
    setFormData((prev) => ({
      ...prev,
      colorPrimary: colors.buttons,
      colorBg: colors.bg,
      colorText: colors.colorText,
    }));

    handleSendData({
      name: formData.name,
      politicalParty: formData.politicalParty,
      profile: formData.profile,
      banner: formData.banner,
      colors,
    });
  }

  async function handleSendData({
    name,
    politicalParty,
    profile,
    banner,
    colors,
  }) {
    try {
      setLoading(true);

      const idApp = localStorage.getItem("idApp");

      const formData = new FormData();

      formData.append("name", name);
      formData.append("political_party", politicalParty.id);
      formData.append("image_perfil", profile);
      formData.append("image_cover", banner);
      formData.append("primary_color", colors.buttons);
      formData.append("secondary_color", colors.bg);
      formData.append("text_color", colors.colorText);

      await api.post(`${idApp}/onboarding`, formData);

      setCurrentStep((prev) => prev + 1);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="content colors-step">
      <div className="heading">
        <strong>Configure as cores de acordo com sua campanha</strong>
        <span>
          Você poderá alterar as cores após essa primeira configuração
        </span>
      </div>

      <div className="steps">
        <div>
          <span>1</span>

          <div className="input-color">
            <strong>Cor dos botões</strong>

            <label htmlFor="button">
              <div style={{ backgroundColor: colors.buttons }} />
            </label>

            <input
              id="button"
              name="button"
              type="color"
              onChange={(e) => {
                const value = e.target.value ?? "";
                setColors((prev) => ({
                  ...prev,
                  buttons: value,
                }));
              }}
            />
          </div>
        </div>

        <hr />

        <div className={!colors.buttons ? "disabled" : ""}>
          <span>2</span>

          <div className="input-color">
            <strong>Cor do fundo</strong>
            <label htmlFor="bg">
              <div style={{ backgroundColor: colors.bg }} />
            </label>

            <input
              id="bg"
              name="bg"
              type="color"
              disabled={!colors.buttons}
              onChange={(e) => {
                const value = e.target.value ?? "";
                setColors((prev) => ({
                  ...prev,
                  bg: value,
                }));
              }}
            />
          </div>
        </div>

        <hr />

        <div className={!colors.buttons || !colors.bg ? "disabled" : ""}>
          <span>3</span>

          <div className="input-color">
            <strong>Cor do texto</strong>
            <label htmlFor="text">
              <div style={{ backgroundColor: colors.colorText }} />
            </label>

            <input
              id="text"
              name="text"
              type="color"
              disabled={!colors.buttons || !colors.bg}
              onChange={(e) => {
                const value = e.target.value ?? "";
                setColors((prev) => ({
                  ...prev,
                  colorText: value,
                }));
              }}
            />
          </div>
        </div>
      </div>

      <button
        disabled={!colors.bg || !colors.buttons || !colors.colorText}
        onClick={handleNextStep}
      >
        Salvar informações
        {loading ? (
          <CircleNotch size={20} weight="bold" className="animate-spin" />
        ) : (
          <ArrowRight size={20} weight="bold" />
        )}
      </button>
    </div>
  );
}
