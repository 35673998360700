import api from "services/api";

export function useReorderInfos({ appId }) {
  async function reorderInfos(infos) {
    try {
      await api.put(`${appId}/infos/home/reorder`, {
        newOrder: infos.map((item) => item.id),
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  return { reorderInfos };
}
