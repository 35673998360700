import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./profile.css";

import CustomButton from "components/CustomButton/CustomButton";

export default function ProfileCandidate({
  setModalClose,
  showModal,
  profile,
}) {
  const handleClose = () => {
    setModalClose();
  };

  const formatCPF = (cpf) => {
    cpf = cpf?.replace(/\D/g, "");
    return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const formatPhone = (phone) => {
    phone = phone?.replace(/\D/g, "");

    if (phone?.length === 11) {
      return phone?.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (phone?.length === 12) {
      return phone?.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2$3-$4");
    } else {
      return phone;
    }
  };

  return (
    <Modal
      size="small"
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Perfil do candidato - {profile?.user_full_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="profile-section">
            <h2>Dados pessoais</h2>
            <div className="infos-grid">
              <div className="infos-item">
                <span className="infos-label">Nome:</span>
                <span className="infos-value">{profile?.user_full_name}</span>
              </div>
              <div className="infos-item">
                <span className="infos-label">CPF:</span>
                <span className="infos-value">
                  {formatCPF(profile?.user_cpf)}
                </span>
              </div>
              <div className="infos-item">
                <span className="infos-label">Email:</span>
                <span className="infos-value">{profile?.user_mail}</span>
              </div>
              <div className="infos-item">
                <span className="infos-label">Local:</span>
                <span className="infos-value">{`${profile?.user_city}/${profile?.state}`}</span>
              </div>
              <div className="infos-item">
                <span className="infos-label">Telefone:</span>
                <span className="infos-value">
                  {formatPhone(profile?.user_phone)}
                </span>
              </div>
            </div>
          </div>

          <div className="profile-section">
            <h2>Dados da aplicação</h2>
            <div className="infos-grid">
              <div className="infos-item">
                <span className="infos-label">Data da compra:</span>
                <span className="infos-value">
                  {profile?.payment_date
                    ? new Date(profile?.payment_date).toLocaleDateString(
                        "pt-BR",
                        { day: "2-digit", month: "2-digit", year: "numeric" }
                      )
                    : "Não informado"}
                </span>
              </div>
              <div className="infos-item">
                <span className="infos-label">Valor:</span>
                <span className="infos-value">
                  {profile?.signature_type === "onetime"
                    ? `R$ ${profile?.value_onetime}`
                    : profile?.signature_type === "monthly" || profile?.signature_type === "mensal"
                    ? `R$ ${profile?.value_monthly}`
                    : profile?.signature_type === "installments"
                    ? `R$ ${profile?.value_installments}`
                    : "Não informado"}
                </span>
              </div>
              <div className="infos-item">
                <span className="infos-label">Tipo de assinatura:</span>
                <span className="infos-value">
                  {profile?.signature_type === "onetime"
                    ? "À vista"
                    : profile?.signature_type === "monthly" || profile?.signature_type === "mensal"
                    ? "Mensal"
                    : profile?.signature_type === "installments"
                    ? "Parcelado"
                    : "Não informado"}
                </span>
              </div>
              <div className="infos-item">
                <span className="infos-label">Tipo de aplicação:</span>
                <span
                  className="infos-value"
                  style={{ textTransform: "capitalize" }}
                >
                  {profile?.application_type}
                </span>
              </div>
              <div className="infos-item">
                <span className="infos-label">Plano:</span>
                <span className="infos-value">
                  {profile?.plan === "advanced" ? "Avançado" : "Básico"}
                </span>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <CustomButton bsStyle="danger" fill onClick={() => handleClose()}>
          Fechar
        </CustomButton>
      </Modal.Body>
    </Modal>
  );
}
