import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { trackPromise } from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

import api from "../../services/api.jsx";
import Button from 'components/CustomButton/CustomButton.jsx';
import Card from "components/Card/Card.jsx";
import DeleteModal from './components/delete.jsx';
import { EmptyGoal } from './components/empty-goal.jsx';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});

const Goals = () => {
  const [goals, setGoals] = useState([]);
  const appId = localStorage.getItem('idApp');
  const [selectedGoalId, setSelectedGoalId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    loadGoals(`/${appId}/goals`);
  }, [appId]);

  const handleNewGoal = () => {
    history.push({ pathname: "/admin/create-goal" });
  };

  const handleEditGoal = (goalId) => {
    history.push({ pathname: `/admin/edit-goal/${goalId}` });
  };

  const handleAnswer = (goalId) => {
    history.push({ pathname: `/admin/goal-answer/${goalId}` });
  };


  function loadGoals(url) {
    trackPromise(
      api.get(url)
        .then((response) => {
          const formattedGoals = response.data.map((goal) => {
            return {
              ...goal
            };
          });
          setGoals(formattedGoals);
        })
        .catch((e) => {
          console.log(e);
        })
    );
  }

  const handleDeleteGoal = (goalId) => {
    setSelectedGoalId(goalId);
    setShowDeleteModal(true);
  };

  const confirmDeleteGoal = () => {
    if (selectedGoalId) {
      trackPromise(
        api.delete(`/${appId}/goals/${selectedGoalId}`)
          .then(() => {
            loadGoals(`/${appId}/goals`);
            Toast.fire({
              icon: "success",
              title: "Meta cancelada com sucesso!"
            });
          })
          .catch((e) => {
            Toast.fire({
              icon: "error",
              title: "Erro ao cancelar a meta."
            });
            console.log(e);
          })
      );
    }
    setShowDeleteModal(false);
  };

  const handleCloseConfirmationModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <div className="content">
      <Button
        style={{ display: 'flex', gap: "3px", alignItems: "center", marginTop: "15px", marginRight: "15px" }}
        title="Cadastrar Nova Meta"
        bsStyle="primary"
        fixMargin pullRight fill
        onClick={handleNewGoal}
      >
        <i className="fa fa-user-plus" />
        Nova Meta
      </Button>
      <Card
        title="Metas"
        category="Gerenciamento de Metas"
        ctTableFullWidth
        ctTableResponsive
        content={
          goals.length === 0 ? (
            <EmptyGoal />
          ) : (
            <Table striped hover>
              <thead>
                <tr>
                  <th>Titulo</th>
                  <th>Descrição</th>
                  <th>Pontuação</th>
                  <th>Data limite</th>
                  <th>Número de etapas</th>
                  <th>Fotos necessárias</th>
                  <th>Editar</th>
                  <th>Excluir</th>
                  <th>Resposta</th>
                </tr>
              </thead>
              <tbody>
                {goals.map((goal) => (
                  <tr key={goal.id}>
                    <td>{goal.title}</td>
                    <td>{goal.description}</td>
                    <td>{goal.done_points}</td>
                    <td>{dayjs(goal.max_date).format('DD/MM/YYYY')}</td>
                    <td>{goal.steps_number}</td>
                    {goal.need_pictures_by_step ? (
                      <td>{goal.need_pictures_by_step}</td>
                    ) : (
                      <td>Sem fotos</td>
                    )}
                    <td>
                      <Button
                        bsStyle="info"
                        simple
                        type="button"
                        bsSize="xs"
                        onClick={() => handleEditGoal(goal.id)}
                      >
                        Editar
                      </Button>
                    </td>
                    <td>
                      <Button
                        bsStyle="danger"
                        simple
                        type="button"
                        bsSize="xs"
                        onClick={() => handleDeleteGoal(goal.id)}
                      >
                        <i className="fa fa-times" />
                        Excluir
                      </Button>
                    </td>
                    <td>
                      <Button
                        bsStyle="info"
                        simple
                        type="button"
                        bsSize="xs"
                        onClick={() => handleAnswer(goal.id)}
                      >
                        Resposta
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )
        } />
      <DeleteModal
        show={showDeleteModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={confirmDeleteGoal}
      />
    </div>
  );
}

export default Goals;
