import { useEffect } from "react";
import { useAtom } from "jotai";
import { Modal } from "react-bootstrap";
import { ArrowLeft } from "@phosphor-icons/react";

import { PersonalStep } from "./steps/Personal/Personal";
import { PoliticalPartyStep } from "./steps/PoliticalParty/PoliticalParty";
import { ProfileImageStep } from "./steps/ProfileImage/ProfileImage";
import { BannerImageStep } from "./steps/BannerImage/BannerImage";
import { ColorsStep } from "./steps/Colors/Colors";
import { TutorialStep } from "./steps/Tutorial/Tutorial";
import { CurrentStepAtom } from "./atoms/CurrentStepAtom";
import { OnboardingOpenAtom } from "./atoms/OnboardingOpenAtom";

const mapSteps = {
  1: <PersonalStep />,
  2: <PoliticalPartyStep />,
  3: <ProfileImageStep />,
  4: <BannerImageStep />,
  5: <ColorsStep />,
  6: <TutorialStep />,
};

export function Onboarding() {
  const [currentStep, setCurrentStep] = useAtom(CurrentStepAtom);
  const [onboardingOpen, setOnboardingOpen] = useAtom(OnboardingOpenAtom);

  const visibleOnboarding = localStorage.getItem("onboarding");

  useEffect(() => {
    if (!!visibleOnboarding) {
      setOnboardingOpen(true);
    }
  }, [visibleOnboarding]);

  function handleOnClose() {
    setOnboardingOpen(false);
    localStorage.removeItem("onboarding");
  }

  return (
    <Modal
      dialogClassName="modal-container"
      show={onboardingOpen}
      onHide={handleOnClose}
    >
      <div className="start-guide-content">
        <header>
          <button
            disabled={currentStep === 1 || currentStep === 6}
            onClick={() => setCurrentStep((prev) => prev - 1)}
          >
            <ArrowLeft size={18} weight="bold" />
            Voltar
          </button>

          <strong>Guia de início</strong>
        </header>

        {mapSteps[currentStep]}

        <footer>
          {Array.from({ length: 6 }).map((_, index) => (
            <div key={index} className={currentStep > index ? "filled" : ""} />
          ))}
        </footer>
      </div>
    </Modal>
  );
}
