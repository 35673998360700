import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import React, { useState } from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import AsyncSelect from "react-select/async";
import api from "services/api.jsx";

const Store = ({ onClose = () => {}, county_id }) => {
  const [show, setShow] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [erro, setErro] = useState("");
  const [name, setName] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [countyid, setCouintyid] = useState(county_id ? county_id : null);

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  const handleInputChange = (newValue) => {
    setCouintyid(newValue.value);
  };

  function filterCounties(data, inputValue) {
    return data.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  }

  async function promiseOptions(inputValue) {
    if (inputValue) {
      try {
        let query = `/candidates/operational_areas/counties?q=${inputValue
          .toLowerCase()
          .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())}`;

        const response = await api.get(query);
        const data = response.data.data;

        const counties = data.map((item) => ({
          value: item.id,
          label: `${item.name} - ${item.uf.abbreviation}`,
        }));

        const countiesFiltred = filterCounties(counties, inputValue);

        return countiesFiltred;
      } catch (error) {
        return [];
      }
    }
  }

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = {
      name,
      latitude,
      longitude,
    };

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    trackPromise(
      api.post(`/counties/${countyid}/neighborhoods`, formData, config)
    )
      .then((response) => {
        onClose();
      })
      .catch((error) => {
        console.log(error.response.data.errors);
        if (error.response.data.errors.name) {
          setErro(error.response.data.errors.name[0]);
        } else if (error.response.data.errors.latitude) {
          setErro(error.response.data.errors.latitude[0]);
        } else if (error.response.data.errors.logintude) {
          setErro(error.response.data.errors.logintude[0]);
        }
        setIsAlertVisible(true);
      });
  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de Bairro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            {isAlertVisible && (
              <Alert bsStyle="danger">
                <button type="button" aria-hidden="true" className="close">
                  ×
                </button>
                <span>
                  <b> Atenção - </b> {erro}
                </span>
              </Alert>
            )}
            <FormInputs
              ncols={["col-md-4", "col-md-4", "col-md-4"]}
              properties={[
                {
                  label: "Nome *",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Nome do Bairro",
                  required: true,
                  defaultValue: name,
                  disabled: countyid ? false : true,
                  onChange: (event) => setName(event.target.value),
                },
                {
                  label: "Latitude",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Opcional",
                  defaultValue: latitude,
                  disabled: countyid ? false : true,
                  onChange: (event) => setLatitude(event.target.value),
                },
                {
                  label: "Longitude",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Opcional",
                  defaultValue: longitude,
                  disabled: countyid ? false : true,
                  onChange: (event) => setLongitude(event.target.value),
                },
              ]}
            />

            <hr />

            <Button bsStyle="danger" fill onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button
              disabled={!countyid}
              bsStyle="info"
              pullRight
              fill
              type="submit"
            >
              Cadastrar
            </Button>

            <div className="clearfix" />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Store;
