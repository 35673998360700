import React from "react";
import "./styles.css";

export function EmptyNotificationLeaders() {
    return (
        <div className="fields-empty-state">
            <strong>Não há comunicações para as lideranças</strong>

            <p>
                Parece que não há comunicações para as lideranças no momento. Por favor,
                verifique novamente mais tarde ou realize a criação de uma nova comunicação para as lideranças.
            </p>
        </div>
    );
}
