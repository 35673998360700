import React, { useEffect, useState } from "react";
import { FormControl, FormGroup, Col, ControlLabel, Row, Form } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { useHistory, useParams } from "react-router";
import Swal from "sweetalert2";

import Button from "components/CustomButton/CustomButton.jsx";
import api from "services/api";
import Field from "./field";
import { useFields } from "./hooks/use-fields";
import dayjs from "dayjs";

const Toast = Swal.mixin({
  toast: true,
  width: 400,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});

export default function UpdateVoter() {
  const history = useHistory();
  const { id } = useParams();
  const appId = localStorage.getItem('idApp');
  const [candidates, setCandidates] = useState([]);

  useEffect(() => {
    trackPromise(api.get(`/${appId}/candidates/get_by_roles`))
      .then((res) => setCandidates(res.data.data))
      .catch(err => console.error(err));
  }, [appId]);

  const { fields } = useFields({ appId });

  const form = useForm({
    defaultValues: {
      name: "",
      email: "",
      birthdate: "",
      gender: "",
      phone: "",
      candidate_id: "",
    },
  });

  const { control, handleSubmit, setValue } = form;

  useEffect(() => {
    trackPromise(api.get(`/${appId}/voters/${id}`))
      .then(response => {
        const voter = response.data.data;
        setValue("name", voter.name);
        setValue("email", voter.email);
        setValue("birthdate", dayjs(voter.voter.birth).format('YYYY-MM-DD'));
        setValue("gender", voter.voter.gender);
        setValue("phone", voter.voter.telephone);
        setValue("candidate_id", voter.candidate_id);

        voter.voter.answers.forEach((item) => setValue(String(item.voter_field_id), item.answer))
      })
      .catch(error => {
        console.log('Error:', error);
        Toast.fire({
          icon: "error",
          title: "Houve um problema ao carregar os dados do eleitor!"
        });
      });
  }, [id, appId, setValue]);

  const submitHandler = (data) => {
    const { name, email, birthdate, gender, phone, candidate_id, ...customField } = data;

    const fields = Object.entries(customField).map(([id, response]) => ({
      id: Number(id.replace("field_", "")),
      value: response,
    }));

    const payload = {
      name,
      email,
      application_id: appId,
      birth: birthdate,
      gender,
      telephone: phone,
      candidate_id,
      fields,
      _method: "PUT",
    };

    const config = {
      headers: {
        'content-type': 'application/json',
      },
    };

    trackPromise(api.post(`/users/${id}`, payload, config))
      .then(response => {
        Toast.fire({
          icon: "success",
          title: "Eleitor atualizado com sucesso!"
        });
        history.goBack();
      })
      .catch((error) => {
        console.log(error);
        Toast.fire({
          icon: "error",
          title: "Houve um problema ao atualizar o eleitor!"
        });
      });
  };

  return (
    <div className="content voter">
      <FormProvider {...form}>
        <p>Atualizar eleitor</p>
        <Form onSubmit={handleSubmit(submitHandler)} id="voterForm">
          <Row>
            <Col md={12}>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Nome do eleitor</ControlLabel>
                    <Controller
                      control={control}
                      name="name"
                      rules={{ required: "Informe o nome do eleitor" }}
                      render={({ field }) => (
                        <FormControl
                          type="text"
                          placeholder="Nome do eleitor"
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Email do eleitor</ControlLabel>
                    <Controller
                      control={control}
                      name="email"
                      rules={{ required: "Informe o email do eleitor" }}
                      render={({ field }) => (
                        <FormControl
                          type="email"
                          placeholder="Email do eleitor"
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Telefone</ControlLabel>
                    <Controller
                      control={control}
                      name="phone"
                      rules={{ required: "Informe o telefone do eleitor" }}
                      render={({ field }) => (
                        <FormControl
                          type="text"
                          placeholder="Telefone"
                          maxLength={11}
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Data de nascimento</ControlLabel>
                    <Controller
                      control={control}
                      name="birthdate"
                      rules={{ required: "Informe a data de nascimento" }}
                      render={({ field }) => (
                        <FormControl
                          type="date"
                          placeholder="Data de nascimento"
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Gênero</ControlLabel>
                    <Controller
                      control={control}
                      name="gender"
                      rules={{ required: "Informe o gênero" }}
                      render={({ field }) => (
                        <FormControl
                          componentClass="select"
                          {...field}
                        >
                          <option value="">Selecione o gênero</option>
                          <option value="male">Masculino</option>
                          <option value="female">Feminino</option>
                        </FormControl>
                      )}
                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <ControlLabel>Escolha o candidato</ControlLabel>
                    <Controller
                      control={control}
                      name="candidate_id"
                      rules={{ required: "Escolha o candidato" }}
                      render={({ field }) => (
                        <FormControl componentClass="select" {...field}>
                          <option value="">Selecione um candidato</option>
                          {candidates.map(candidate => (
                            <option key={candidate.id} value={candidate.id}>
                              {candidate.name}
                            </option>
                          ))}
                        </FormControl>
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                {fields?.map((field, index) => (
                  <Col md={4} key={field.id}>
                    <Field field={field} />
                  </Col>
                ))}
              </Row>

              <div className="clearfix">
                <Button bsStyle="danger" fill onClick={() => history.goBack()}>
                  Voltar
                </Button>

                <Button bsStyle="info" pullRight fill type="submit">Enviar</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </div>
  );
}
