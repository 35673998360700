import React from "react";
import { useHistory } from "react-router";
import { trackPromise } from "react-promise-tracker";
import { Table } from "react-bootstrap";

import EditButton from "components/EditButton/EditButton";
import RemoveButton from "components/RemoveButton/RemoveButton";
import { CreateFieldModal } from "../create-field/create-field";
import { UpdateFieldModal } from "../update-field/update-field";
import { HeaderFields } from "./components/header-fields/header-fields";
import { EmptyState } from "./components/empty-state/empty-state";
import { ModalConfirmationDelete } from "./components/modal-confirmation-delete/modal-confirmation-delete";
import { useQueryParams } from "hooks/useQueryParams";
import { useFields } from "./hooks/use-fields";
import { handleSendNotification } from "services/notification";
import api from "services/api";

import "./styles.css";

export function ListFields() {
  const history = useHistory();
  const queryParams = useQueryParams();
  const appId = localStorage.getItem("idApp");

  const { fields, refetch } = useFields({ appId });

  function handleRemoveField(id) {
    trackPromise(api.delete(`/${appId}/voters/fields/${id}`))
      .then(() => {
        handleSendNotification({
          type: "success",
          message: "Campo removido com sucesso!",
        });

        refetch();
      })
      .catch((err) => {
        console.error(err);

        handleSendNotification({
          type: "success",
          message: "Erro ao remover o campo, tente novamente!",
        });
      });
  }

  function handleUpdateItem(id) {
    queryParams.append("open_update_field", "open");
    queryParams.append("field_update_id", String(id));

    history.push({
      pathname: "/admin/campos_extras",
      search: queryParams.toString(),
    });
  }

  function handleDeleteField({ id, name }) {
    queryParams.append("open_delete_field", "open");
    queryParams.append("field_delete_id", String(id));
    queryParams.append("field_delete_name", encodeURIComponent(name));

    history.push({
      pathname: "/admin/campos_extras",
      search: queryParams.toString(),
    });
  }

  function handleCloseModalCreateField(success = false) {
    if (success) {
      refetch();
    }

    history.goBack();
  }

  function handleCloseModalUpdateField(success = false) {
    if (success) {
      refetch();
    }

    history.goBack();
  }

  function handleCloseModalDeleteField(success = false) {
    if (success) {
      handleRemoveField(queryParams.get("field_delete_id"));
    }

    history.goBack();
  }

  const mapType = {
    text: "Texto",
    text_long: "Texto longo",
    number: "Número",
    date: "Data",
    cpf: "CPF",
    select: "Opções",
  };

  return (
    <div className="container-fields">
      <div className="content-fields">
        <HeaderFields />

        <div className="fields">
          {fields.length ? (
            <div>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th>Tipo</th>
                    <th>Opções</th>
                    <th>Obrigatório</th>
                    <th>Ações</th>
                  </tr>
                </thead>

                <tbody>
                  {fields.map((field) => (
                    <tr key={field.id}>
                      <td>{field.id}</td>
                      <td>{field.name}</td>
                      <td className={!field.hint ? "not-content" : ""}>
                        {field.hint ?? "Nã há descrição"}
                      </td>
                      <td>
                        <span className="badge-field badge-field-info">
                          {mapType[field.type]}
                        </span>
                      </td>
                      <td className={!field.options ? "not-content" : ""}>
                        {field.options ?? "Não há opções"}
                      </td>
                      <td>
                        <span
                          className={`badge-field ${
                            field.required
                              ? "badge-field-success"
                              : "badge-field-danger"
                          }`}
                        >
                          {field.required ? "Sim" : "Não"}
                        </span>
                      </td>
                      <td>
                        <EditButton
                          callback={() => handleUpdateItem(field.id)}
                        />

                        <RemoveButton
                          callback={() =>
                            handleDeleteField({
                              id: field.id,
                              name: field.name,
                            })
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <EmptyState />
          )}
        </div>
      </div>

      <CreateFieldModal
        isOpen={queryParams.get("open_create_field") === "open"}
        onClose={(success) => handleCloseModalCreateField(success)}
      />

      <UpdateFieldModal
        isOpen={queryParams.get("open_update_field") === "open"}
        onClose={(success) => handleCloseModalUpdateField(success)}
      />

      <ModalConfirmationDelete
        isOpen={queryParams.get("open_delete_field") === "open"}
        onClose={(success) => handleCloseModalDeleteField(success)}
        name={queryParams.get("field_edit_name")}
      />
    </div>
  );
}
