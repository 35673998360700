import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { trackPromise } from "react-promise-tracker";

import api from "services/api";

const status = [
  { value: "open", label: "Em aberto" },
  { value: "attendance", label: "Em apreciação" },
  { value: "closed", label: "Encerrado" },
];

export const Filters = forwardRef(
  (
    { areas, federalUnits, candidates, handleChangeFilter, handleChangePage },
    ref
  ) => {
    const appId = localStorage.getItem("idApp");

    const [categories, setCategories] = useState([]);
    const [citys, setCitys] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [leaders, setLeaders] = useState([]);

    const areaRef = useRef();
    const categoryRef = useRef();
    const statusRef = useRef();
    const stateRef = useRef();
    const cityRef = useRef();
    const neighborhoodRef = useRef();
    const dateStartRef = useRef();
    const dateEndRef = useRef();
    const candidateRef = useRef();
    const leaderRef = useRef();

    function handleChangeArea(area) {
      if (area === "") {
        categoryRef.current.select.clearValue();
        setCategories([]);
      }

      handleChangeFilter((oldFilter) => ({
        ...oldFilter,
        area: area ? `id_area=${area}` : "",
      }));

      getCategories(area);

      handleChangePage(1);
    }

    function handleChangeCategory(category) {
      handleChangeFilter((oldFilter) => ({
        ...oldFilter,
        category: category ? `id_category=${category}` : "",
      }));

      handleChangePage(1);
    }

    function handleChangeStatus(status) {
      handleChangeFilter((oldFilter) => ({
        ...oldFilter,
        status: status ? `status=${status}` : "",
      }));

      handleChangePage(1);
    }

    function handleChangeState(state) {
      if (state === "") {
        cityRef.current.select.clearValue();
        setCitys([]);
      }

      handleChangeFilter((oldFilter) => ({
        ...oldFilter,
        state: state ? `federal_unit_id=${state}` : "",
      }));

      getCitys(state);

      handleChangePage(1);
    }

    function handleChangeCity(city) {
      if (city === "") {
        neighborhoodRef.current.select.clearValue();
        setNeighborhoods([]);
      }

      handleChangeFilter((oldFilter) => ({
        ...oldFilter,
        city: city ? `county_id=${city}` : "",
      }));

      getNeighborhoods(city);

      handleChangePage(1);
    }

    function handleChangeNeighborhood(neighborhood) {
      handleChangeFilter((oldFilter) => ({
        ...oldFilter,
        neighborhood: neighborhood ? `neighborhood_id=${neighborhood}` : "",
      }));

      handleChangePage(1);
    }

    function handleChangeDateStart(dateStart) {
      handleChangeFilter((oldFilter) => ({
        ...oldFilter,
        dateStart: dateStart ? `created_at_initial=${dateStart}` : "",
      }));

      handleChangePage(1);
    }

    function handleChangeDateEnd(dateEnd) {
      handleChangeFilter((oldFilter) => ({
        ...oldFilter,
        dateEnd: dateEnd ? `created_at_final=${dateEnd}` : "",
      }));

      handleChangePage(1);
    }

    function handleChangeCandidate(candidate) {
      if (candidate === "") {
        leaderRef.current.select.clearValue();
        setLeaders([]);
      }

      handleChangeFilter((oldFilter) => ({
        ...oldFilter,
        candidate: candidate ? `candidate_id=${candidate}` : "",
      }));

      getLeaders(candidate);

      handleChangePage(1);
    }

    function handleChangeLeader(leader) {
      handleChangeFilter((oldFilter) => ({
        ...oldFilter,
        leader: leader ? `leader_id=${leader}` : "",
      }));

      handleChangePage(1);
    }

    function getCategories(areaId) {
      if (areaId === "") {
        return setCategories([]);
      }

      trackPromise(
        api.get(`/${appId}/participation_category?area_id=${areaId}`)
      )
        .then((res) => {
          setCategories(
            res.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            }))
          );
        })
        .catch((err) => console.error(err));
    }

    function getCitys(stateId) {
      if (stateId === "") {
        return setCitys([]);
      }

      trackPromise(api.get(`counties?id=${stateId}`))
        .then((res) => {
          setCitys(
            res.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            }))
          );
        })
        .catch((err) => console.error(err));
    }

    function getNeighborhoods(cityId) {
      if (cityId === "") {
        return setNeighborhoods([]);
      }

      trackPromise(api.get(`counties/${cityId}/neighborhoods`))
        .then((res) => {
          setNeighborhoods(
            res.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            }))
          );
        })
        .catch((err) => console.error(err));
    }

    function getLeaders(candidate) {
      if (candidate === "") {
        return setLeaders([]);
      }

      trackPromise(
        api.get(
          `${appId}/leaders/get_leaders_by_candidate?candidate_id=${candidate}`
        )
      )
        .then((res) => {
          setLeaders(
            res.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            }))
          );
        })
        .catch((err) => console.error(err));
    }

    useImperativeHandle(ref, () => ({
      handleReset() {
        handleChangeFilter({});
        areaRef.current.select.clearValue();
        categoryRef.current.select.clearValue();
        statusRef.current.select.clearValue();
        stateRef.current.select.clearValue();
        cityRef.current.select.clearValue();
        neighborhoodRef.current.select.clearValue();
        dateStartRef.current.value = "";
        dateEndRef.current.value = "";
        candidateRef.current.select.clearValue();
        leaderRef.current.select.clearValue();
      },
    }));

    return (
      <div
        style={{
          width: "100%",
          marginTop: "1rem",
          marginBottom: "2rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
        }}
      >
        <Row
          style={{
            marginLeft: 0,
            marginRight: 0,
          }}
        >
          <Col md={4}>
            <label>Filtre por área</label>
            <Select
              ref={areaRef}
              name="area"
              options={areas}
              placeholder="Digite a busca"
              noOptionsMessage={() => "Dados não encontrados"}
              isSearchable
              isClearable
              onChange={(option, triggeredAction) => {
                if (triggeredAction.action === "clear")
                  return handleChangeArea("");
                if (option) handleChangeArea(option.value);
              }}
            />
          </Col>
          <Col md={4}>
            <label>Filtre por categoria</label>
            <Select
              ref={categoryRef}
              name="category"
              options={categories}
              placeholder="Digite a busca"
              noOptionsMessage={() => "Dados não encontrados"}
              isSearchable
              isClearable
              isDisabled={categories.length === 0}
              onChange={(option, triggeredAction) => {
                if (triggeredAction.action === "clear")
                  return handleChangeCategory("");
                if (option) handleChangeCategory(option.value);
              }}
            />
          </Col>
          <Col md={4}>
            <label>Filtre por status</label>
            <Select
              ref={statusRef}
              name="status"
              options={status}
              placeholder="Digite a busca"
              noOptionsMessage={() => "Dados não encontrados"}
              isSearchable
              isClearable
              onChange={(option, triggeredAction) => {
                if (triggeredAction.action === "clear")
                  return handleChangeStatus("");
                if (option) handleChangeStatus(option.value);
              }}
            />
          </Col>
        </Row>
        <Row
          style={{
            marginLeft: 0,
            marginRight: 0,
          }}
        >
          <Col md={4}>
            <label>Filtre por estado</label>
            <Select
              ref={stateRef}
              name="state"
              options={federalUnits}
              placeholder="Digite a busca"
              noOptionsMessage={() => "Dados não encontrados"}
              isSearchable
              isClearable
              onChange={(option, triggeredAction) => {
                if (triggeredAction.action === "clear")
                  return handleChangeState("");
                if (option) handleChangeState(option.value);
              }}
            />
          </Col>
          <Col md={4}>
            <label>Filtre por cidade</label>
            <Select
              ref={cityRef}
              name="city"
              options={citys}
              placeholder="Digite a busca"
              noOptionsMessage={() => "Dados não encontrados"}
              isSearchable
              isClearable
              isDisabled={citys.length === 0}
              onChange={(option, triggeredAction) => {
                if (triggeredAction.action === "clear")
                  return handleChangeCity("");
                if (option) handleChangeCity(option.value);
              }}
            />
          </Col>
          <Col md={4}>
            <label>Filtre por bairro</label>
            <Select
              ref={neighborhoodRef}
              name="neighborhood"
              options={neighborhoods}
              placeholder="Digite a busca"
              noOptionsMessage={() => "Dados não encontrados"}
              isSearchable
              isClearable
              isDisabled={neighborhoods.length === 0}
              onChange={(option, triggeredAction) => {
                if (triggeredAction.action === "clear")
                  return handleChangeNeighborhood("");
                if (option) handleChangeNeighborhood(option.value);
              }}
            />
          </Col>
        </Row>
        <Row
          style={{
            marginLeft: 0,
            marginRight: 0,
          }}
        >
          <Col md={3}>
            <label>Filtre por Data de início</label>
            <input
              ref={dateStartRef}
              type="date"
              style={{
                appearance: "none",
                width: "100%",
                color: "hsl(0,0%,20%)",
                border: "1px solid hsl(0,0%,80%)",
                borderRadius: "4px",
                background: "hsl(0,0%,100%)",
                padding: "7px 8px",
                display: "inline-block !important",
                visibility: "visible !important",
              }}
              onChange={(e) => {
                if (!e.target.value) return handleChangeDateStart("");
                handleChangeDateStart(e.target.value);
              }}
            />
          </Col>
          <Col md={3}>
            <label>Filtre por Data de término</label>
            <input
              ref={dateEndRef}
              type="date"
              style={{
                appearance: "none",
                width: "100%",
                color: "hsl(0,0%,20%)",
                border: "1px solid hsl(0,0%,80%)",
                borderRadius: "4px",
                background: "hsl(0,0%,100%)",
                padding: "7px 8px",
                display: "inline-block !important",
                visibility: "visible !important",
              }}
              onChange={(e) => {
                if (!e.target.value) return handleChangeDateEnd("");
                handleChangeDateEnd(e.target.value);
              }}
            />
          </Col>
          <Col md={3}>
            <label>Filtre por candidato</label>
            <Select
              ref={candidateRef}
              name="candidate"
              options={candidates}
              placeholder="Digite a busca"
              noOptionsMessage={() => "Dados não encontrados"}
              isSearchable
              isClearable
              onChange={(option, triggeredAction) => {
                if (triggeredAction.action === "clear")
                  return handleChangeCandidate("");
                if (option) handleChangeCandidate(option.value);
              }}
            />
          </Col>
          <Col md={3}>
            <label>Filtre por liderança</label>
            <Select
              ref={leaderRef}
              name="leader"
              options={leaders}
              placeholder="Digite a busca"
              noOptionsMessage={() => "Dados não encontrados"}
              isSearchable
              isClearable
              isDisabled={leaders.length === 0}
              onChange={(option, triggeredAction) => {
                if (triggeredAction.action === "clear")
                  return handleChangeLeader("");
                if (option) handleChangeLeader(option.value);
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
);
