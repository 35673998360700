import React from "react";
import { Modal } from "react-bootstrap";

import Maps from "components/Leaflet/Leaflet.jsx";

export function Atuation({ show, onClose, name, voters }) {
  return (
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Atuação do líder partidário: {name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Maps markes={voters} />
      </Modal.Body>
    </Modal>
  );
}
