import React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { style as styleNotification } from "variables/Variables.jsx";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import DangerAlert from "components/DangerAlert/DangerAlert";

import MessagesList from "./components/messages-list";
import Message from "./message";
import { Map } from "components/Map";
import { ModalStatus } from "./components/ModalStatus";

import api from "services/api";
import style from "./style.module.css";

const nameByStatus = {
  open: "Em aberto",
  attendance: "Em apreciação",
  closed: "Encerrado",
};

const colorsByStatus = {
  open: "statusOpen",
  attendance: "statusAttendance",
  closed: "statusClosed",
};

const Show = ({ match }) => {
  const participationId = match.params.id;
  const appId = localStorage.getItem("idApp");

  const [participation, setParticipation] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isOpenModal, setIsOpenModal] = React.useState("");

  const statusRef = React.useRef();

  React.useEffect(() => {
    getParticipation();
  }, []);

  function getParticipation() {
    trackPromise(api.get(`/${appId}/participation/${participationId}`))
      .then((res) => setParticipation(res.data.data))
      .catch((err) => console.error(err));
  }

  function updateStatus(status) {
    trackPromise(
      api.post(
        `/${appId}/participation/${participationId}`,
        { status },
        { headers: { "content-type": "application/json" } }
      )
    )
      .then((res) => setParticipation(res.data.data))
      .catch((err) => {
        console.error(err);

        setErrorMessage("Erro ao atualizar status, tente novamente.");

        statusRef.current.value = participation.status;
      });
  }

  function generateReport() {
    trackPromise(
      api.get(`${appId}/participation/get_report/${participation.id}`, {
        responseType: "blob",
      })
    )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `PARTICIPAÇÃO - ${participation.id}.pdf`);
        document.body.appendChild(link);

        link.click();
      })
      .catch((err) => {
        console.error(err);
        setErrorMessage("Erro ao gerar relatório, tente novamente.");
      });
  }

  function handleCloseModal() {
    setIsOpenModal("");
  }

  return (
    <div className="content">
      <NotificationSystem style={styleNotification} />

      {errorMessage !== "" && (
        <DangerAlert error={errorMessage} close={() => setErrorMessage("")} />
      )}

      <Grid fluid>
        {participation && (
          <>
            <Row>
              <Col md={12}>
                <Button
                  title="Resetar filtros"
                  bsStyle="primary"
                  fixMargin
                  pullRight
                  fill
                  onClick={() => setIsOpenModal("status")}
                >
                  Atualizar status
                </Button>
                <Button
                  title="Gerar relótorio"
                  bsStyle="default"
                  fixMargin
                  pullRight
                  style={{
                    marginRight: "1rem",
                  }}
                  onClick={generateReport}
                >
                  Gerar relatório
                </Button>

                <Card
                  title={`Participação #${participation.id}`}
                  category="Detalhes da participação"
                  content={
                    <>
                      <Row
                        style={{
                          margin: "9px -15px 16px",
                        }}
                      >
                        <Col md={4}>
                          <div className={style.contentInfo}>
                            <header className="text-primary">
                              <span className="pe-7s-note"></span>
                              <div>
                                <strong>Dados gerais</strong>
                                <span
                                  className={
                                    style[colorsByStatus[participation.status]]
                                  }
                                >
                                  {nameByStatus[participation.status]}
                                </span>
                              </div>
                            </header>

                            <div>
                              <span>
                                <strong>Área:</strong> {participation.area.name}
                              </span>
                              <span>
                                <strong>Categoria:</strong>{" "}
                                {participation.category.name}
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className={style.contentInfo}>
                            <header className="text-primary">
                              <span className="pe-7s-user"></span>
                              <strong>Eleitor</strong>
                            </header>

                            <div>
                              <span>
                                <strong>Nome:</strong> {participation.user.name}
                              </span>
                              <span>
                                <strong>E-mail:</strong>{" "}
                                {participation.user.email}
                              </span>
                            </div>
                          </div>
                        </Col>
                        {(participation.area.candidate_id ||
                          participation.leader) && (
                          <Col md={4}>
                            <div className={style.contentInfo}>
                              <header className="text-primary">
                                <span className="pe-7s-users"></span>
                                <strong>Influência</strong>
                              </header>

                              <div>
                                {participation.area.candidate.name && (
                                  <span>
                                    <strong>Candidato:</strong>{" "}
                                    {participation.area.candidate.name}
                                  </span>
                                )}
                                {participation.leader && (
                                  <span>
                                    <strong>Liderança:</strong>{" "}
                                    {participation.leader.name}
                                  </span>
                                )}
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </>
                  }
                />
              </Col>
            </Row>
            <Row
              style={{
                marginBottom: "24px",
              }}
            >
              <Col md={4}>
                <Card
                  title="Dados avançados"
                  content={
                    <div className={style.advancedData}>
                      {participation.latitude || participation.url_file ? (
                        <>
                          {participation.latitude && (
                            <div>
                              <span>Localização</span>
                              <div className={style.mapContainer}>
                                <Map
                                  center={[
                                    participation.latitude,
                                    participation.longitude,
                                  ]}
                                  zoom={20}
                                  style={{ width: "100%", height: "100%" }}
                                  position={{
                                    latitude: participation.latitude,
                                    longitude: participation.longitude,
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {participation.url_file && (
                            <div>
                              <span>Arquivo</span>
                              <a
                                href={participation.url_file}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Ver anexo
                              </a>
                            </div>
                          )}
                        </>
                      ) : (
                        <div>
                          <span>Sem dados avançados!</span>
                        </div>
                      )}
                    </div>
                  }
                />
              </Col>
              <Col md={8}>
                <Card
                  title="Mensagens"
                  content={
                    <MessagesList participation={participation} />
                  }
                />
                <div className="text-right">
                  <Button
                    bsStyle="primary"
                    disabled={participation.status === "closed"}
                    onClick={() => setIsOpenModal("message")}
                  >
                    Adicionar Mensagem
                  </Button>
                </div>
              </Col>
            </Row>

            {isOpenModal === "status" && (
              <ModalStatus
                show={isOpenModal === "status"}
                onHide={handleCloseModal}
                handleAction={updateStatus}
                status={participation.status}
              />
            )}

            {isOpenModal === "message" && (
              <Message
                show={isOpenModal === "message"}
                close={handleCloseModal}
                reload={getParticipation}
                participationId={participationId}
              />
            )}
          </>
        )}
      </Grid>
    </div>
  );
};

export default Show;
