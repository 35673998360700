import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from 'components/CustomButton/CustomButton.jsx';

const EditButton = ({ callback }) => {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id="edit">Editar</Tooltip>}>
      <Button
        bsStyle="info"
        bsSize="xs"
        type="button"
        onClick={callback}
        simple
      >
        <i className="fa fa-edit" />
        Editar
      </Button>
    </OverlayTrigger>
  );
};

export default EditButton;