import React from "react";
import { Col, FormControl, FormGroup, Row } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import api from "services/api";

const statusesList = [
  { value: "open", label: "Em aberto" },
  { value: "attendance", label: "Em atendimento" },
  { value: "closed", label: "Concluído" },
];

const UserFilter = ({ callback, reset }) => {
  function handleSubmit(event) {
    event.preventDefault();
    const filters = [];

    if (!!event.target.name.value) {
      filters.push(`name=${event.target.name.value}`);
    }

    if (!!event.target.email.value) {
      filters.push(`email=${event.target.email.value}`);
    }

    const url = !!filters.length
      ? `${filters.join("&")}&`
      : "";

    callback(url);
  }


  return (
    <Row>
      <Col md={12}>
        <form onSubmit={handleSubmit}>
          <Col md={5}>
            <label>Nome</label>
            <FormControl
              placeholder="Nome"
              name="name"
              id="name"
            />
          </Col>
          <Col md={3}>
            <label>Email</label>
            <FormControl
              type="email"
              placeholder="Email"
              name="email"
              id="email"
            />
          </Col>
          <Col md={2} className="div-buttons">
            <button type="submit" className="btn-fill btn-card btn btn-primary">
              Busca
            </button>
          </Col>
        </form>
        <Col md={2} className="align-right div-buttons">
          <button
            className="btn-fill btn-card btn btn-danger"
            onClick={() => {
              document.getElementById("name").value = "";
              document.getElementById("email").value = "";
              reset();
            }}
          >
            Resetar filtro
          </button>
        </Col>
      </Col>
    </Row>
  );
};

export default UserFilter;