import React, { useEffect, useState } from "react";
import {
  Col,
  Modal,
  Row,
  Alert
} from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import api from "services/api.jsx";


const Enrolleds = ({ onClose = () => { }, event }) => {

  const [show, setShow] = useState(true);
  const [app] = useState(localStorage.getItem('idApp'));
  const [enrolleds, setEnrolleds] = useState([]);

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  useEffect(() => {
    async function getEnrolleds() {
      const response = await trackPromise(api.get(`/${app}/events/${event.id}/enrollment`));
      setEnrolleds(response.data.data);
    };

    getEnrolleds();
  }, [app, event]);
  
  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Inscritos no evento: {event.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-enrolleds">
            {!enrolleds.length ? (
              <Alert bsStyle="danger">
                <strong>Nenhum inscrito cadastrado para esse evento.</strong>
              </Alert>
            ) : null}
            {enrolleds.length ? enrolleds.map((item, index) => (
              <Row key={index}>
                <Col md={12}>
                  <div className="card-enrolleds">
                    <div className="card-enrolleds-name">
                      {item.user.name}
                    </div>
                    <div className="card-enrolleds-email">
                      {item.user.email}
                    </div>
                  </div>
                </Col>
              </Row>
            )) : null}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Enrolleds;
