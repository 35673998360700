import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import React, { useEffect, useState } from "react";
import {
  Modal
} from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import api from "services/api.jsx";



//import AlertCustom from "components/CustomAlert/Alert.jsx";

const Store = ({ onClose = () => { }, idCandidato }) => {
  const [show, setShow] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  // eslint-disable-next-line
  const [errorText, setErrorText] = useState('');
  const [title, setTitle] = useState();
  const [is_active, setIsActive] = useState();
  const [description, setDescription] = useState();
  const [begin_period_at, setBeginPeriodAt] = useState(new Date());
  const [end_period_at, setEndPeriodAt] = useState(new Date());

  const handleClose = () => {
    onClose();
    setShow(false);
  };

  //const handleShow = () => setShow(true);

  useEffect(() => { });

  const toISOFormat = (dateTimeString) => {
    const [date] = dateTimeString.split(' ');
    const [YYYY, MM, DD] = date.split('-');
    return `${DD}/${MM}/${YYYY} 00:00`;
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = {
      title,
      description,
      is_active,
      begin_period_at: toISOFormat(begin_period_at),
      end_period_at: toISOFormat(end_period_at)
    };

    const config = {
      headers: {
        'content-type': 'application/json'
      }
    };

    trackPromise(api.post(
      `/${localStorage.getItem('idApp')}/researches`,
      formData,
      config
    ))
      .then(response => {
        onClose();
      })
      .catch((error) => {
        console.log(error);
        setIsAlertVisible(true);

      });

  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de Pesquisa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            {isAlertVisible && <div className="alert alert-danger">Error no cadastro da pesquisa!<br />ERROR: {errorText}</div>}
            <FormInputs
              ncols={["col-md-12"]}
              properties={[
                {
                  label: "Título",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Título",
                  required: true,
                  defaultValue: title,
                  name: 'title',
                  onChange: event => setTitle(event.target.value)
                }
              ]}
            />
            <FormInputs
              ncols={["col-md-6", "col-md-6"]}
              properties={[
                {
                  label: "Data Início",
                  type: "date",
                  bsClass: "form-control",
                  placeholder: "Data Inicio",
                  required: true,
                  defaultValue: begin_period_at,
                  name: 'begin_period_at',
                  onChange: event => setBeginPeriodAt(event.target.value)
                },
                {
                  label: "Data Fim",
                  type: "date",
                  bsClass: "form-control",
                  placeholder: "Data encerramento",
                  required: true,
                  defaultValue: end_period_at,
                  name: 'end_period_at',
                  onChange: event => setEndPeriodAt(event.target.value)
                }
              ]}
            />

            <div className="form-group">
              <label>Descrição</label>
              <textarea className="form-control" required rows="5" name="description" placeholder="Descrição da pesquisa" onChange={event => setDescription(event.target.value)}></textarea>
            </div>

            <div className="form-group">
              <label className="">Situação da pesquisa</label>
              <select
                name="isActive"
                defaultValue={is_active}
                onChange={event => setIsActive(event.target.value)}
                className="form-control"
                required
              >
                <option value="">-</option>
                <option value="1">Ativa</option>
                <option value="0">Inativa</option>
              </select>
            </div>

            <Button bsStyle="danger" fill onClick={() => onClose()}>
              Cancelar
            </Button>
            <Button bsStyle="success" pullRight fill type="submit">
              Salvar
            </Button>
            <div className="clearfix" />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Store;
