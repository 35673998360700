import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { HiOutlineDocumentReport, HiDocumentReport } from "react-icons/hi";

import Button from "components/CustomButton/CustomButton";
import style from "../style.module.css";

export function ModalReport(props) {
  const [reportSelected, setReportSelected] = useState("");

  function handleConfirm() {
    props.onHide();
    setReportSelected("");
    props.handleAction(reportSelected);
  }

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Gerar relatório
        </Modal.Title>
        <span>
          Escolha abaixo o tipo de relatório desejado a partir das suas
          descrições.
        </span>
      </Modal.Header>
      <Modal.Body>
        <Row
          style={{
            padding: "16px",
          }}
        >
          <Col
            md={6}
            style={{
              paddingLeft: "0",
              paddingRight: "8px",
            }}
          >
            <Card
              title="Simples"
              description="Relatório contento dados simples das participações, dados básicos do usuário, área, categoria, status e primeira mensagem da participação."
              icon="summarized"
              active={reportSelected === "summarized"}
              onClick={() => setReportSelected("summarized")}
            />
          </Col>
          <Col
            md={6}
            style={{
              paddingLeft: "8px",
              paddingRight: "0",
            }}
          >
            <Card
              title="Avançado"
              description="Relatório avançado, contendo além dos dados do simples, adicionais como detalhes do usuário, informações adicionais e dados das mensagens."
              icon="detailed"
              active={reportSelected === "detailed"}
              onClick={() => setReportSelected("detailed")}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Fechar</Button>
        <Button bsStyle="primary" fill onClick={handleConfirm}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const iconsByStatus = {
  summarized: <HiOutlineDocumentReport size={24} color="#4f46e5" />,
  detailed: <HiDocumentReport size={24} color="#4f46e5" />,
};

function Card(props) {
  return (
    <div
      className={`${style.cardContent} ${
        props.active ? style.cardContentActive : ""
      }`}
      onClick={props.onClick}
    >
      <div>
        <div className={`${style.icon} ${style["indigo"]}`}>
          {iconsByStatus[props.icon]}
        </div>
        <strong>{props.title}</strong>
      </div>

      <div className={style.cardDescription}>
        <p>{props.description}</p>
      </div>
    </div>
  );
}
