import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";

import api from "services/api";
import FormInputs from "components/FormInputs/FormInputs";
import Button from "components/CustomButton/CustomButton.jsx";

const Toast = Swal.mixin({
  toast: true,
  width: 400,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});

export default function EditGoal() {
  const appId = localStorage.getItem("idApp");
  const { goalId } = useParams();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [donePoints, setDonePoints] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [picturesByStep, setPicturesByStep] = useState(null);
  const [steps, setSteps] = useState(null);
  const history = useHistory();

  useEffect(() => {
    loadGoalData();
  }, [goalId]);

  const loadGoalData = async () => {
    try {
      const response = await api.get(`/${appId}/goals/${goalId}`);
      const goal = response.data;
      setTitle(goal.title);
      setDescription(goal.description);
      setDonePoints(goal.done_points);
      setMaxDate(goal.max_date);
      setPicturesByStep(goal.need_pictures_by_step);
      setSteps(goal.steps_number);
    } catch (error) {
      console.error(error);
      Toast.fire({
        icon: "error",
        title: "Erro ao carregar os dados da meta!"
      });
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('done_points', donePoints);
    formData.append('max_date', maxDate);
    formData.append('need_pictures_by_step', picturesByStep);
    formData.append('steps_number', steps);

    formData.append('_method', 'PUT');

    try {
      await trackPromise(api.post(`/${appId}/goals/${goalId}`, formData, config));
      Toast.fire({
        icon: "success",
        title: "Meta atualizada com sucesso!"
      });
      history.goBack();
    } catch (error) {
      console.error(error);
      Toast.fire({
        icon: "error",
        title: "Houve um problema ao atualizar sua meta!"
      });
    }
  };

  return (
    <div className="content task">
      <Row>
        <Col md={12}>
          <p>Editar Meta</p>
          <form onSubmit={submitHandler} encType="multipart/form-data">
            <Row>
              <Col md={4}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Título",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "Digite o Título da meta",
                      required: true,
                      defaultValue: title,
                      onChange: (event) => setTitle(event.target.value),
                    }
                  ]}
                />
              </Col>

              <Col md={4}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Data limite",
                      type: "datetime-local",
                      bsClass: "form-control",
                      placeholder: "Digite a data limite da meta",
                      required: true,
                      defaultValue: maxDate,
                      onChange: (event) => setMaxDate(event.target.value),
                    }
                  ]}
                />
              </Col>

              <Col md={4}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Pontuação",
                      type: "number",
                      bsClass: "form-control",
                      placeholder: "Digite a pontuação de cada etapa",
                      required: false,
                      defaultValue: donePoints,
                      onChange: (event) => setDonePoints(event.target.value),
                    },
                  ]}
                />
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Etapas para meta",
                      type: "number",
                      bsClass: "form-control",
                      placeholder: "Digite a quantidade de etapas para concluir a meta",
                      required: false,
                      defaultValue: steps,
                      onChange: (event) => setSteps(event.target.value),
                    },
                  ]}
                />
              </Col>

              <Col md={4}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Fotos necessárias por etapa",
                      type: "number",
                      bsClass: "form-control",
                      placeholder: "Digite a quantidade de fotos necessárias em cada etapa",
                      required: false,
                      defaultValue: picturesByStep,
                      onChange: (event) => setPicturesByStep(event.target.value),
                    },
                  ]}
                />
              </Col>

              <Col md={4}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Descrição do que deve ser feito por etapa",
                      bsClass: "form-control",
                      placeholder: "Descreva o que é necessário para concluir as etapas",
                      required: true,
                      defaultValue: description,
                      onChange: (event) => setDescription(event.target.value),
                    }
                  ]} />
              </Col>
            </Row>
            <div className="clearfix" />
            <Button bsStyle="danger" fill onClick={() => history.goBack()}>
              Voltar
            </Button>
            <Button bsStyle="info" pullRight fill type="submit">
              Salvar
            </Button>
          </form>
        </Col>
      </Row>
    </div>
  );
}
