import React, { Component } from "react";
import { Grid, Row, Col, Table, Tooltip, OverlayTrigger } from "react-bootstrap";

import Button from 'components/CustomButton/CustomButton.jsx';
import Card from "components/Card/Card.jsx";

import api from "services/api.jsx";

import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";

import Update from "./update.jsx";
import Store from "./store.jsx";

class Options extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _notificationSystem: null,
            options: [],
            isModalVisibleEdit: false,
            isModalVisibleNew: false,
            opition: null,
            app: localStorage.getItem('idApp'),
            role: JSON.parse(localStorage.getItem('user')).role,
        }
    }
    // state de cilco de vida de inicialização do componente
    componentDidMount() {
        this.loadOptions();
    }
    // edita uma pesquisa
    editOption(event) {
        this.setState({ option: event });
        this.setState({ isModalVisibleEdit: true })
    }
    //carrega as pesquisas de um app
    loadOptions = async () => {
        try {
            const response = await api.get(`/${this.state.app}/researches/${this.props.match.params.researche_id}/questions/${this.props.match.params.question_id}/options`);
            this.setState({ options: response.data.data });
        } catch (e) {
            this.handleNotificationClick(e.response.data.message, 'error');
        }
    }

    //deleta uma pesquisa
    deleteOption = (id) => {
        api.delete(`/${this.state.app}/researches/${this.props.match.params.researche_id}/questions/${this.props.match.params.question_id}/options/${id}`)
            .then(response => {
                this.loadOptions();
                this.handleNotificationClick('Opção excluida com sucesso!', 'success');
            })
            .catch((error) => {
                this.handleNotificationClick('Erro na exclusão da opção! Tente novamente!', 'error');
            });
    }
    // componente de notificação
    handleNotificationClick = (message, color) => {
        this.refs.notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-info" />,
            message: (
                <div>
                    {message}
                </div>
            ),
            level: color,
            position: 'tr',
            autoDismiss: 5,
            dismissible: true
        });
    };
    // renderzação do componente
    render() {
        const { isModalVisibleEdit, options, isModalVisibleNew } = this.state;

        const remove = (<Tooltip id="remove_tooltip">Excluir</Tooltip>);
        const edit = (<Tooltip id="edit_tooltip">Editar</Tooltip>);

        return (
            <div className="content">
                <NotificationSystem ref="notificationSystem" style={style} />
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Button title="cadastrar nova opção" bsStyle="primary" fixMargin pullRight fill onClick={() => this.setState({ isModalVisibleNew: true })}>  <i className="fa fa-plus"></i> Nova Opção</Button>
                            <Button onClick={this.props.history.goBack} title="Voltar" bsStyle="warning" fixMargin pullRight fill> <i className="fa fa-backward"></i></Button>


                            <Card
                                title="Opções"
                                category="Opções cadastradas"
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <>
                                        <Table striped hover>
                                            <thead>
                                                <tr>
                                                    <th>Titulo</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    options.map(event => (
                                                        <tr key={event.id}>
                                                            <td>{event.title}</td>
                                                            <td className="m-center">
                                                                <OverlayTrigger placement="top" overlay={edit}>
                                                                    <Button
                                                                        bsStyle="info"
                                                                        simple
                                                                        type="button"
                                                                        bsSize="xs"
                                                                        onClick={() => this.editOption(event)}
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                        Editar
                                                                    </Button>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={remove}>
                                                                    <Button
                                                                        bsStyle="danger"
                                                                        simple
                                                                        type="button"
                                                                        bsSize="xs"
                                                                        onClick={() => this.deleteOption(event.id)}
                                                                    >
                                                                        <i className="fa fa-times"></i>
                                                                        Excluir
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }

                                            </tbody>
                                        </Table>

                                        {isModalVisibleEdit && <Update onClose={() => { this.setState({ isModalVisibleEdit: false }); this.loadOptions() }} option={this.state.option} id_research={this.props.match.params.researche_id} question_id={this.props.match.params.question_id} />}
                                        {isModalVisibleNew && <Store onClose={() => { this.setState({ isModalVisibleNew: false }); this.loadOptions() }} id_research={this.props.match.params.researche_id} question_id={this.props.match.params.question_id} />}

                                    </>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Options;
