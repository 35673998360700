import React from "react";
import { Map as LeafletMap, Marker, TileLayer } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";

export function Map({ position, ...rest }) {
  return (
    <LeafletMap
      dragging={true}
      touchZoom={true}
      zoomControl={true}
      scrollWheelZoom={true}
      doubleClickZoom={true}
      {...rest}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker
        position={[position.latitude, position.longitude]}
        interactive={false}
      />
    </LeafletMap>
  );
}
