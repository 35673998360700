import React, { useState } from "react";
import {
    Modal,
    Table,
} from "react-bootstrap";

//import AlertCustom from "components/CustomAlert/Alert.jsx";

const ModalResult = ({ onClose = () => { }, result, researche }) => {

    const [show, setShow] = useState(true);
    const answers = result.answers;

    const handleClose = () => {
        onClose();
        setShow(false);
    };

    //const handleShow = () => setShow(true);

    return (
        <>
            <Modal
                size="sm"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <strong>Usuário:</strong> {result.name} - <strong>E-mail:</strong> {result.email}
                        </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="info-box-header">
                        <p>
                            <span>{researche.title}</span><br />
                            <span>{researche.description}</span>
                        </p>
                    </div>
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Pergunta</th>
                                <th>Opção Selecionada</th>
                                <th>Texto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                answers.map(app => (
                                    <tr key={app.id}>
                                        <td>{app.question ? app.question.title : app.option.question.title}</td>
                                        <td>{app.question ? '-' : app.option.title}</td>
                                        <td>{app.question ? app.answer : '-'}</td>
                                    </tr>
                                ))
                            }
                        </tbody>

                    </Table>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalResult;