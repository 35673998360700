import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSetAtom } from "jotai";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";

import Loading from "components/Loading/Loading.jsx";
import { OnboardingOpenAtom } from "components/Onboarding/atoms/OnboardingOpenAtom";
import api from "services/api.jsx";
import { style } from "variables/Variables.jsx";
import logoMobbyCandidato from "assets/img/logo-mobby-candidato.png";

const PasswordReset = () => {
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [applicationId, setApplicationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [onboarding, setOnboarding] = useState(false);

  const setOnboardingOpen = useSetAtom(OnboardingOpenAtom);

  const notificationSystem = useRef(null);
  const history = useHistory();

  const coordinatorId = JSON.parse(
    localStorage.getItem("user")
  )?.coordinator_id;

  const getRoute = (role) => {
    let path = "/admin/";
    switch (role) {
      case "admin":
        path += "dashboard";
        break;
      case "coordinator":
        path += "profile";
        break;
      case "candidate":
        path += "profile/candidate";
        break;
      default:
        path += "dashboard";
    }
    return path;
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "application_id") {
      const app = coordinatorId.find(
        (elm) => elm.application.id === Number(value)
      );

      localStorage.setItem("nameApplication", app.application.name);
      localStorage.setItem("typeApplication", app.application.type);

      setOnboarding(!!app.application.onboarding);

      setApplicationId(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "password_confirmation") {
      setPasswordConfirmation(value);
    }
  };

  const submitHandlerApplication = async (e) => {
    e.preventDefault();

    setLoading(true);

    localStorage.setItem("idApp", applicationId);

    const { rede_cor, coordenador, existe_candidato, rede_cor_candidatos } =
      await loadStartGuide(applicationId);

    if (coordenador && existe_candidato && rede_cor && rede_cor_candidatos) {
      history.push("/admin/mobile");
    } else {
      history.push("/admin/start_guide");
    }

    if (onboarding) {
      localStorage.setItem("onboarding", onboarding);
    }

    setOnboardingOpen(onboarding);
  };

  const loadStartGuide = async (appId) => {
    try {
      const response = await api.get(`${appId}/start_guide`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    trackPromise(
      api.put("/users/password", {
        password_confirmation: passwordConfirmation,
        password,
      })
    )
      .then(() => {
        handleNotificationClick("Senha alterada com sucesso!", "success");
        if (
          localStorage.getItem("typeRole") === "coordinator" ||
          localStorage.getItem("typeRole") === "advisor"
        ) {
          setIsLogin(false);
        } else {
          history.push(getRoute(localStorage.getItem("typeRole"))); // Changed from navigate to history.push
        }
      })
      .catch((error) => {
        if (error.response.data.errors.password) {
          handleNotificationClick(
            error.response.data.errors.password[0],
            "error"
          );
        }
      });
  };

  const handleNotificationClick = (message, color) => {
    notificationSystem.current.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: <div>{message}</div>,
      level: color,
      position: "tr",
      autoDismiss: 5,
      dismissible: true,
    });
  };

  return (
    <div className="container-login">
      <Loading loading={loading} message="Validando dados..." />
      <NotificationSystem ref={notificationSystem} style={style} />

      <div className="content-login first-content-login">
        <div className="first-column-login">
          <h2 className="title title-primary-login ">
            Olá, Seja Bem Vindo ao Mobby Candidato!
          </h2>
          <p className="description description-primary-login ">
            Plataforma desenvolvida pela empresa GRT8.
          </p>
          <p className="description description-primary-login ">
            &copy; 2020 - Todos os Direitos Reservados
            <br /> <span>versão 1.0.3</span>
          </p>
        </div>

        {isLogin ? (
          <div className="second-column-login">
            <img src={logoMobbyCandidato} alt="mobby candidato" width="100" />
            <h2 className="title title-second-login ">MOBBY CANDIDATO</h2>
            <p className="description description-second-login ">
              Primeiro acesso
            </p>
            <form className="form-login" onSubmit={submitHandler}>
              <label className="label-input-login">
                <i className="fa fa-lock icon-modify-login"></i>
                <input
                  type="password"
                  placeholder="nova senha"
                  name="password"
                  className="login"
                  onChange={changeHandler}
                  minLength="8"
                  required
                />
              </label>
              <span className="muted">
                Sua senha deverá conter entre 8 e 32 caracteres e não poderá
                conter espaços.{" "}
              </span>
              <label className="label-input-login">
                <i className="fa fa-lock icon-modify-login"></i>
                <input
                  type="password"
                  placeholder="confirma senha"
                  name="password_confirmation"
                  className="login"
                  onChange={changeHandler}
                  minLength="8"
                  required
                />
              </label>
              <button className="btn-login btn-second-login" type="submit">
                Alterar
              </button>
            </form>
          </div>
        ) : (
          <div className="second-column-login">
            <img src={logoMobbyCandidato} alt="mobby candidato" width="100" />
            <h2 className="title title-second-login ">MOBBY CANDIDATO</h2>
            <p className="description description-second-login ">
              Selecione sua aplicação
            </p>
            <form className="form-login" onSubmit={submitHandlerApplication}>
              <label className="label-input-login">
                <i className="fa fa-user icon-modify-login"></i>
                <select name="application_id" onChange={changeHandler} required>
                  <option value="">Selecione sua aplicação</option>
                  {coordinatorId.map((app) => (
                    <option key={app.id} value={app.application.id}>
                      {app.application.name}
                    </option>
                  ))}
                </select>
              </label>
              <button className="btn-login btn-second-login" type="submit">
                Avançar
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
