import Card from "components/Card/Card.jsx";
import Confirm from "components/Confirm/Confirm.jsx";
//import Pagination from 'react-bootstrap/Pagination';
//import PageItem from 'react-bootstrap/PageItem';
import Button from 'components/CustomButton/CustomButton.jsx';
import React, { Component } from "react";
import { Col, Grid, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";
import { style } from "variables/Variables.jsx";
import api from "../../services/api.jsx";
import Coordinators from "./coordinators.jsx";
import Store from "./store.jsx";
import Update from "./update.jsx";

class Applications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      applications: [],
      application: {},
      candidates: [],
      isModalVisibleEdit: false,
      isModalVisibleNew: false,
      isModalVisibleCoordinator: false,
      applicationName: "",
      constants: [],
      confirm: false,
      callback: undefined,
      text: ''
    };
  }

  componentDidMount() {
    this.loadConstants();
    this.loadApplications();
  }

  loadCandidates = async (id) => {
    const response = await api.get(`/${id}/candidates`);
    this.setState({ candidates: response.data.data });
  };

  loadConstants = async () => {
    try {
      const response = await api.get('/types_application');
      this.setState({ constants: response.data.data });
    } catch (e) {
      this.handleNotificationClick(e.response.data.message, 'error');
    }
  };

  editApplication(value) {
    this.loadCandidates(value.id);
    this.setState({ application: value });
    this.setState({ isModalVisibleEdit: true });
  }

  loadApplications = async () => {
    try {
      const response = await trackPromise(api.get('/applications'));
      this.setState({ applications: response.data.data });
    } catch (e) {
      this.handleNotificationClick(e.response.data.message, 'error');
    }
  };

  deleteApplication = (id) => {
    trackPromise(api.delete(`/applications/${id}`))
      .then(response => {
        this.loadApplications();
        this.handleNotificationClick('Aplicação excluida com sucesso!', 'success');
      })
      .catch((error) => {
        console.log(error.message);
        this.handleNotificationClick('Erro na exclusão da aplicação! Tente novamente!', 'error');
      });
  };

  async viewCoordinators(app) {
    this.setState({ application: app });
    this.setState({ isModalVisibleCoordinator: true });
  }

  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: (
        <div>
          {message}
        </div>
      ),
      level: color,
      position: 'tr',
      autoDismiss: 5,
      dismissible: true
    });
  };

  changeNameHandle = (event) => {
    this.setState({
      applicationName: event.target.value
    });
  };

  copyLink(slug) {
    const url = `https://${slug}.app.mobbycandidato.com`;

    navigator.clipboard.writeText(url);

    this.handleNotificationClick('Link da aplicação copiado com sucesso!', 'success');
  }

  filter() {
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{
            display: "flex",
            padding: "20px"
          }}>
            <input value={this.state.applicationName} className="form-control" onChange={this.changeNameHandle} placeholder="Buscar aplicação" />
            <button
              style={{
                position: "relative",
                top: "-5px",
                left: "5px",
                height: 38
              }}
              className="btn-fill btn-card btn btn-primary" onClick={this.loadApplications}> Buscar </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { applications, isModalVisibleEdit, isModalVisibleNew, isModalVisibleCoordinator } = this.state;

    const remove = (<Tooltip id="remove_tooltip">Excluir</Tooltip>);
    const edit = (<Tooltip id="edit_tooltip">Editar</Tooltip>);
    const coordinators = (<Tooltip id="">Coordenadores</Tooltip>);
    const access = (<Tooltip id="">Link de acesso da aplicação</Tooltip>);
    const political = (<Tooltip id="">Política de privacidade</Tooltip>);

    return (
      <>
        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Button title="cadastrar nova applicação" bsStyle="success" fixMargin pullRight fill onClick={() => this.setState({ isModalVisibleNew: true })}>
                  <i className="fa fa-plus"></i> Nova aplicação</Button>
                <Card
                  title="Aplicações"
                  category="Gerenciamento de aplicações"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <>

                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Situação</th>
                            <th>Nome</th>
                            <th>Ano</th>
                            <th>Tipo</th>
                            <th>Política de privacidade</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            applications.map(app => (
                              <tr key={app.id}>
                                <td>{app.id}</td>
                                <td>
                                  <span className={`badge badge-${app.is_active ? 'success' : 'danger'}`}>
                                    {app.is_active ? 'Ativa' : 'Inativa'}
                                  </span>
                                </td>
                                <td>{app.name}</td>
                                <td>{app.year}</td>
                                <td>{app.type_application === 'one' ? 'Aplicativo de um candidato' : 'Aplicativo de vários candidatos'}</td>
                                <td className="text-center">
                                  {
                                    app.link_policy
                                      ? <OverlayTrigger placement="top" overlay={political}>
                                        <a
                                          href={app.link_policy}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="btn-simple btn btn-xs btn-social"
                                        >
                                          <i className="fa fa-file-pdf-o" />
                                        </a>
                                      </OverlayTrigger>
                                      : "-"
                                  }
                                </td>
                                <td>
                                  <OverlayTrigger placement="top" overlay={access}>
                                    <Button
                                      bsStyle="success"
                                      simple
                                      type="button"
                                      bsSize="xs"
                                      onClick={() => this.copyLink(`${app.slug}_${app.checker_key}`)}
                                    >
                                      <i className="fa fa-link" />
                                      Link de acesso
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" overlay={coordinators}>
                                    <Button
                                      bsStyle="warning"
                                      simple
                                      type="button"
                                      bsSize="xs"
                                      onClick={() => this.viewCoordinators(app)}
                                    >
                                      <i className="fa fa-users" />
                                      Coordenadores
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" overlay={edit}>
                                    <Button
                                      bsStyle="info"
                                      simple
                                      type="button"
                                      bsSize="xs"
                                      onClick={() => this.editApplication(app)}
                                    >
                                      <i className="fa fa-edit" />
                                      Editar
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" overlay={remove}>
                                    <Button
                                      bsStyle="danger"
                                      simple
                                      type="button"
                                      bsSize="xs"
                                      onClick={() => this.setState({
                                        text: `excluir a aplicação ${app.name}`,
                                        callback: () => this.deleteApplication(app.id),
                                        confirm: true
                                      })}
                                    >
                                      <i className="fa fa-times" />
                                      Excluir
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            ))
                          }

                        </tbody>
                      </Table>
                      {isModalVisibleEdit &&
                        <Update onClose={() => {
                          this.setState({ isModalVisibleEdit: false });
                          this.loadApplications();
                        }}
                          candidates={this.state.candidates}
                          application={this.state.application}
                        />}
                      {isModalVisibleNew &&
                        <Store onClose={() => {
                          this.setState({ isModalVisibleNew: false });
                          this.loadApplications();
                        }}
                        />
                      }
                      {isModalVisibleCoordinator &&
                        <Coordinators onClose={() => {
                          this.setState({ isModalVisibleCoordinator: false });
                          this.loadApplications();
                        }}
                          application={this.state.application}
                        />
                      }
                    </>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <Confirm
          show={this.state.confirm}
          close={() => this.setState({
            confirm: false,
            callback: undefined,
            text: '',
          })}
          callback={this.state.callback}
          text={this.state.text}
        />
      </>
    );
  }
}

export default Applications;
