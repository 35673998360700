
import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import ChartistGraph from "react-chartist";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";
import {
  legendPie, legendSales, optionsSales,
  responsiveSales, style
} from "variables/Variables.jsx";
import api from "../services/api.jsx";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      data: {},
      user: JSON.parse(localStorage.getItem('user')).candidate_id,
      dataSales: {},
      dataPie: {},
      totalVoters: 0,
      options: {
        donut: true,
        donutWidth: 60,
        donutSolid: true,
        startAngle: 270,
        showLabel: true,
        labelInterpolationFnc: {}
      }
    };
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData(idCandidate) {
    //'/candidates/' + idCandidate + '/data'
    try {
      const response = await trackPromise(api.get('/administrators/statistics/dashboard'));
      this.setState({ data: response.data.data });

      this.formatDataVoter(response.data.data.voters_by_month);
      this.formatTotalVoters(response.data.data.applications);
      this.fotmatDataPie(response.data.data);

    } catch (e) {
      this.handleNotificationClick(e.response.data.message, 'error');
    }
  }

  fotmatDataPie(data) {
    var labels = [];
    var series = [];

    series.push(data.total_applications);
    series.push(data.total_coordinators);
    series.push(this.state.totalVoters);

    const dataPie = { labels, series };
    this.setState({ dataPie: dataPie });
  }
  formatDataVoter(data) {
    const getNameMonth = value => {
      const year = value.substr(0, 4);
      const month = value.substr(5);
      let nameMonth = "";
      switch (month) {
        case "01": nameMonth = `Jan/${year}`; break;
        case "02": nameMonth = `Fev/${year}`; break;
        case "03": nameMonth = `Mar/${year}`; break;
        case "04": nameMonth = `Abr/${year}`; break;
        case "05": nameMonth = `Mai/${year}`; break;
        case "06": nameMonth = `Jun/${year}`; break;
        case "07": nameMonth = `Jul/${year}`; break;
        case "08": nameMonth = `Ago/${year}`; break;
        case "09": nameMonth = `Set/${year}`; break;
        case "10": nameMonth = `Out/${year}`; break;
        case "11": nameMonth = `Nov/${year}`; break;
        case "12": nameMonth = `Dez/${year}`; break;
        default:
          break;
      }
      return nameMonth;

    };
    var labels = [];
    var series = [];
    Object.keys(data).forEach(function (item) {
      labels.push(getNameMonth(item));
      series.push(data[item]);
    });

    const dataSales = { labels, series: [series] };
    this.setState({ dataSales: dataSales });
  }

  formatTotalVoters(data) {
    let total = 0;
    data.forEach(function (item) {
      total += item.total_voters;
    });
    this.setState({ totalVoters: total });
  }
  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: (
        <div>
          {message}
        </div>
      ),
      level: color,
      position: 'tr',
      autoDismiss: 5,
      dismissible: true
    });
  };

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  render() {
    const { total_applications, total_coordinators } = this.state.data;
    return (
      <div className="content">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Grid fluid>
          <Row>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-server text-warning" />}
                statsText="Aplicações"
                statsValue={total_applications}
                statsIcon={<i className="fa fa-refresh" />}

              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-wallet text-success" />}
                statsText="Coordenadores"
                statsValue={total_coordinators}
                statsIcon={<i className="fa fa-calendar-o" />}
   
              />
            </Col>
            <Col lg={4} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-users text-success" />}
                statsText="Eleitores"
                statsValue={this.state.totalVoters}
                statsIcon={<i className="fa fa-calendar-o" />}
     
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Card
                id="chartHours"
                title="Linha temporal dos cadastros dos eleitores"
                category=""  
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={this.state.dataSales}
                      type="Line"
                      options={optionsSales}
                      responsiveOptions={responsiveSales}
                    />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendSales)}</div>
                }
              />
            </Col>
            <Col md={4}>
              <Card
                title="Gráfico de total de aplicações, coordenadores e eleitores"
                category=""
                content={
                  <div
                    id="chartPreferences"
                    className="ct-chart ct-perfect-fourth"
                  >
                    <ChartistGraph data={this.state.dataPie} options={this.state.options} type="Pie" />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendPie)}</div>
                }
              />
            </Col>
          </Row>

        </Grid>
      </div>
    );
  }
}

export default Dashboard;
