
import React, { Component } from "react";
import { Grid, Row, Col, Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Chart } from "react-google-charts";
import { Card } from "components/Card/Card.jsx";
import Button from 'components/CustomButton/CustomButton.jsx';
import { style } from "variables/Variables.jsx";
import Pagination from "react-js-pagination";
import NotificationSystem from "react-notification-system";
import api from "services/api.jsx";
import ModalResult from "./view-result.jsx";
import "./styles.css";

class Answers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _notificationSystem: null,
            researche: {},
            results: [],
            result: {},
            options: [],
            countQuestions: 0,
            user: JSON.parse(localStorage.getItem('user')).candidate_id,
            totalVoters: 0,
            idApplication: localStorage.getItem('idApp'),
            dataBarChart: [],
            dataPieChart: [],
            isModalVisible: false,
            tabActive: 'panel',
            configPagination: {
                activePage: null,
                itemsCountPerPage: null,
                totalItemsCount: 1,
                pageRangeDisplayed: 5
            }
        }
    }

    componentDidMount() {
        this.loadData();
        this.loadResult();
    }

    componentWillUpdate() {

    }

    async loadData() {
        try {
            const response = await api.get(`${this.state.idApplication}/researches/${this.props.match.params.researche_id}/answers`);
            if (response.data) {
                let result = response.data.data.questions.filter(elm => {
                    return elm.type === 'checkbox'
                });

                //this.setDataBarChart(result)
                this.setState({ options: result, researche: response.data.data, countQuestions: response.data.data.questions.length });

            }
        } catch (e) {
            this.handleNotificationClick(e, 'error');
        }
    }

    async loadResult(pageNumber = 1) {
        try {
            const response = await api.get(`${this.state.idApplication}/researches/${this.props.match.params.researche_id}/dashboardAnswers?page=${pageNumber}`);
            this.setState({
                results: response.data.data,
                configPagination: {
                    activePage: response.data.meta.current_page,
                    itemsCountPerPage: response.data.meta.per_page,
                    totalItemsCount: response.data.meta.total,
                    pageRangeDisplayed: 5
                }
            })

        } catch (e) {
            // this.handleNotificationClick(e, 'error');
        }
    }

    handlePageChange(pageNumber) {
        this.loadUsers(pageNumber);
    }

    handleNotificationClick = (message, color) => {
        this.refs.notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-info" />,
            message: (
                <div>
                    {message}
                </div>
            ),
            level: color,
            position: 'tr',
            autoDismiss: 5,
            dismissible: true
        });
    };


    setDataBarChart(data) {
        let dataPieChart = [['Pergunta', 'Média']];
        for (let item of data) {
            if (item.answers) {
                dataPieChart.push([item.title, item.answers])
            }
        }
        return dataPieChart;

    }

    setNavTab(tab) {
        this.setState({ tabActive: tab })
    }

    viewResult(result) {
        this.setState({
            result,
            isModalVisible: true
        });
    }


    render() {

        const { isModalVisible, researche, countQuestions, tabActive, configPagination, results, options } = this.state;
        const info = (<Tooltip id="viewResult">Visualizar Resposta</Tooltip>);

        return (

            <div className="content">
                <NotificationSystem ref="notificationSystem" style={style} />
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Button title="Voltar" bsStyle="success" fixMargin fill onClick={this.props.history.goBack}> <i className="fa fa-backward"></i>Voltar</Button>
                            <hr />
                        </Col>
                        <Col md={12}>

                            <Card
                                statsIcon="fa fa-info"
                                title="Resultados"
                                stats="Resultado da pesquisa"
                                content={
                                    <>

                                        <Row>
                                            <Col md={12}>
                                                <ul className="nav nav-tabs nav-fills">
                                                    <li className="nav-item">
                                                        {
                                                            // eslint-disable-next-line
                                                        (<a className="nav-link" href="#" onClick={() => this.setNavTab('panel')}>Painel</a>)
                                }
                                                    </li>
                                                    <li className="nav-item">
                                                        {
                                                            // eslint-disable-next-line
                                                        (<a className="nav-link" href="#" onClick={() => this.setNavTab('result')}>Resultados</a>)
                                                        }
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={12}>
                                                <div className="info-box">
                                                    <div className="info-box-header">
                                                        <p>
                                                            <span>{researche.title}</span><br />
                                                            <span>{researche.description}</span>
                                                        </p>
                                                    </div>

                                                    <div className="info-box-descripion">
                                                        <p>
                                                            <strong><i className="fa fa-calendar"></i> Data Início:</strong>
                                                            {researche.begin_period_at}
                                                            <br />
                                                            <strong><i className="fa fa-calendar"></i> Data Encerramento:</strong>
                                                            {researche.end_period_at}
                                                            <br />
                                                            <strong><i className="fa fa-question-circle"></i>Quantidade de Perguntas:</strong>
                                                            {countQuestions}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row >
                                            {tabActive === 'panel' &&
                                                <Col md={12}>
                                                    <div className="result-box">
                                                        <Row>
                                                            {
                                                                options.map(app => (
                                                                    <Col md={4} key={app.id}>
                                                                        <Card
                                                                            statsIcon="fa fa-info"
                                                                            id="chartHours"
                                                                            title={app.title}
                                                                            stats={app.description}
                                                                            content={
                                                                                <>
                                                                                    <Chart
                                                                                        width={'100%'}
                                                                                        height={'300px'}
                                                                                        chartType="PieChart"
                                                                                        loader={<div>Loading Chart</div>}
                                                                                        data={this.setDataBarChart(app.options)}
                                                                                        options={{
                                                                                            title: app.title,
                                                                                            // Just add this option
                                                                                            is3D: true,
                                                                                        }}
                                                                                        rootProps={{ 'data-testid': '2' }}
                                                                                    />
                                                                                </>
                                                                            }
                                                                        />
                                                                    </Col>
                                                                ))}

                                                            {/* <Col md={12} >
                                                                <Card
                                                                    statsIcon="fa fa-info"
                                                                    id="chartHours"
                                                                    title="Mapa de geolocalização das respostas dos usuários"
                                                                    content={
                                                                        <>
                                                                         <Maps result={results}/>
                                                                        </>
                                                                    }
                                                                />
                                                            </Col> */}
                                                        </Row>
                                                    </div>
                                                </Col>
                                            }
                                            {tabActive === 'result' &&
                                                <Col md={12}>
                                                    <div className="result-box">
                                                        <Table striped hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th>Usuário</th>
                                                                    <th>Perguntas Respondidas</th>
                                                                    <th>Visualizar Resposta</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    results.map(app => (
                                                                        <tr key={app.id}>
                                                                            <td>{app.id}</td>
                                                                            <td>{app.name}</td>
                                                                            <td>{app.answers.length}</td>
                                                                            <td>
                                                                                <OverlayTrigger placement="top" overlay={info}>
                                                                                    <Button
                                                                                        bsStyle="success"
                                                                                        simple
                                                                                        type="button"
                                                                                        bsSize="xs"
                                                                                        onClick={() => this.viewResult(app)}
                                                                                    >
                                                                                        <i className="fa fa-eye"></i> Respostas

                                                                                    </Button>
                                                                                </OverlayTrigger>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>

                                                        </Table>
                                                        <div style={{ textAlign: "center" }}>
                                                            <Pagination
                                                                activePage={configPagination.activePage}
                                                                itemsCountPerPage={configPagination.itemsCountPerPage}
                                                                totalItemsCount={configPagination.totalItemsCount}
                                                                pageRangeDisplayed={configPagination.pageRangeDisplayed}
                                                                onChange={this.handlePageChange.bind(this)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                        {isModalVisible && <ModalResult onClose={() => { this.setState({ isModalVisible: false }); this.loadResult() }} result={this.state.result} researche={researche} />}
                                    </>

                                }
                            />
                        </Col>

                    </Row>

                </Grid>
            </div >
        );
    }
}

export default Answers;
