import api from "services/api";

export function useRemoveInfo({ appId }) {
  async function removeInfo(id) {
    try {
      await api.delete(`${appId}/information/${id}`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  return { removeInfo };
}
