import React from "react";

import { useFormContext } from "react-hook-form";
import { FormControl, FormGroup, ControlLabel } from "react-bootstrap";

export function NumberField({ value, onChange, field }) {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[field.id]?.message
    ? String(errors[field.id]?.message)
    : undefined;



  return (
    <FormGroup>
      <ControlLabel>{field.name}</ControlLabel>
      <FormControl
        value={value ? Number(value) : ""}
        onChange={(event) => onChange(Number(event.target.value))}
        type="number"
        label={field.name}
        placeholder={field.name}
        hint={field.hint}
        error={error}
      />
    </FormGroup>
  );
}
