import Card from "components/Card/Card.jsx";
import Confirm from "components/Confirm/Confirm.jsx";
import Button from 'components/CustomButton/CustomButton.jsx';
import React, { Component } from "react";
import { Col, Grid, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import { trackPromise } from "react-promise-tracker";
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import { style } from "variables/Variables.jsx";
import api from "../../services/api.jsx";
import Store from "./store.jsx";
import Update from "./update.jsx";

class Neighborhoods extends Component {
  state = {
    _notificationSystem: null,
    app: localStorage.getItem('idApp'),
    neighborhoods: [],
    neighborhood: null,
    couinty_id: null,
    isModalVisibleEdit: false,
    isModalVisibleNew: false,
    isAdmin: localStorage.getItem('typeRole') === "admin" ? true : false,
    confirm: false,
    callback: undefined,
    text: ''
  };

  componentDidMount() {
    //this.loadNeighborhoods();
  }

  loadNeighborhoods = async (couinties_id) => {
    if (couinties_id) {
      try {
        const response = await trackPromise(api.get(`/counties/${couinties_id}/neighborhoods`));
        this.setState({ neighborhoods: response.data.data });
      } catch (e) { }
    }
  };

  setVisibleModal(e) {
    this.setState({ isModalVisibleEdit: e });

  }

  editNeighborhood(event) {
    this.setState({ neighborhood: event });
    this.setVisibleModal(true);
  }

  handleInputChange = (newValue) => {
    this.setState({ couinty_id: newValue.id });
    this.loadNeighborhoods(newValue.id);
  };

  animatedComponents = makeAnimated();

  promiseOptions = inputValue =>
    new Promise(resolve => {
      if (inputValue) {
        let query;
        query = `/candidates/operational_areas/counties?q=${inputValue.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); })}`;
        api.get(query)
          .then(response => {

            if (response.data.data) {

              let result = response.data.data.map(elm => {
                return {
                  id: elm.id,
                  value: elm.id,
                  label: `${elm.name} - ${elm.uf.abbreviation}`
                };
              });
              const filters = result.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
              );
              resolve(filters);
            }
            resolve([]);
          })
          .catch(err => {
            resolve([]);
          });
      }
    });

  deleteNeighborhood = (id) => {
    trackPromise(api.delete(`/counties/${this.state.couinty_id}/neighborhoods/${id}`))
      .then(response => {
        this.loadNeighborhoods(this.state.couinty_id);
        this.handleNotificationClick('Bairro excluido com sucesso!', 'success');
      })
      .catch((error) => {
        console.log(error.message);
        this.handleNotificationClick('Erro na exclusão do bairro! Tente novamente!', 'error');
      });
  };


  handleNotificationClick = (message, color) => {
    this.refs.notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-info" />,
      message: (
        <div>
          {message}
        </div>
      ),
      level: color,
      position: 'tr',
      autoDismiss: 5,
      dismissible: true
    });
  };


  render() {
    const { neighborhoods, couinty_id, isModalVisibleEdit, isModalVisibleNew } = this.state;
    const remove = (<Tooltip id="remove_tooltip">Excluir</Tooltip>);
    const edit = (<Tooltip id="edit_tooltip">Editar</Tooltip>);

    return (
      <>
        <div className="content">
          <NotificationSystem ref="notificationSystem" style={style} />
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Button title="cadastrar novo bairro" bsStyle="primary" fixMargin pullRight fill onClick={() => this.setState({ isModalVisibleNew: true })}>  <i className="fa fa-plus"></i>Novo Bairro</Button>
                <Card
                  title="Bairros"
                  category="Bairros cadastrados "
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <>

                      <div className="form-group pd-40">
                        <label className="">Localize os bairros cadastrados por cidade</label>
                        <AsyncSelect
                          placeholder={`Digite o nome da cidade`}
                          isDisabled={false}
                          closeMenuOnSelect={false}
                          components={this.animatedComponents}
                          onChange={this.handleInputChange}
                          cacheOptions
                          loadOptions={this.promiseOptions}
                        />
                      </div>


                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Bairro</th>
                            <th>Cidade</th>
                            <th>UF</th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            neighborhoods.map(app => (
                              <tr key={app.id}>
                                <td>{app.id}</td>
                                <td>{app.name}</td>
                                <td>{app.county}</td>
                                <td>{app.federal_unit}</td>
                                <td>{app.latitude}</td>
                                <td>{app.longitude}</td>
                                <td>
                                  <OverlayTrigger placement="top" overlay={edit}>
                                    <Button
                                      bsStyle="info"
                                      simple
                                      type="button"
                                      bsSize="xs"
                                      onClick={() => this.editNeighborhood(app)}
                                    >
                                      <i className="fa fa-edit"></i>
                                      Editar
                                    </Button>
                                  </OverlayTrigger>
                                  {this.state.isAdmin &&
                                    <OverlayTrigger placement="top" overlay={remove}>
                                      <Button
                                        bsStyle="danger"
                                        simple
                                        type="button"
                                        bsSize="xs"
                                        onClick={() => this.deleteNeighborhood(app.id)}
                                      >
                                        <i className="fa fa-times"></i>
                                        Excluir
                                      </Button>
                                    </OverlayTrigger>
                                  }
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>

                      {isModalVisibleEdit && <Update onClose={() => { this.setState({ isModalVisibleEdit: false }); this.loadNeighborhoods(couinty_id); }} county_id={couinty_id} neighborhood={this.state.neighborhood} />}
                      {isModalVisibleNew && <Store onClose={() => { this.setState({ isModalVisibleNew: false }); this.loadNeighborhoods(couinty_id); }} county_id={couinty_id} />}
                    </>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <Confirm
          show={this.state.confirm}
          close={() => this.setState({
            confirm: false,
            callback: undefined,
            text: ''
          })}
          callback={this.state.callback}
          text={this.state.text}
        />
      </>
    );
  }
}

export default Neighborhoods;
