import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { FiCheck } from "react-icons/fi";
import { MdHourglassEmpty } from "react-icons/md";
import { RiUserVoiceLine } from "react-icons/ri";

import Button from "components/CustomButton/CustomButton";
import style from "../style.module.css";

export function ModalStatus(props) {
  const [statusActive, setStatusActive] = useState(props.status);

  function handleConfirm() {
    props.onHide();
    props.handleAction(statusActive);
  }

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Atualizar status
        </Modal.Title>
        <span>
          Veja as opções de status e suas descrições, abaixo, e selecione o novo
          status.
        </span>
      </Modal.Header>
      <Modal.Body>
        <Row
          style={{
            padding: "16px",
          }}
        >
          <Col
            md={4}
            style={{
              paddingLeft: "0",
              paddingRight: "8px",
            }}
          >
            <CardStatus
              status="Em aberto"
              description="A participação foi aberta por um eleitor, sem atendimento."
              icon="open"
              active={statusActive === "open"}
              onClick={() => setStatusActive("open")}
            />
          </Col>
          <Col
            md={4}
            style={{
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          >
            <CardStatus
              status="Em apreciação"
              description="Foi iniciado o processo de atendimento da participação."
              icon="attendance"
              active={statusActive === "attendance"}
              onClick={() => setStatusActive("attendance")}
            />
          </Col>
          <Col
            md={4}
            style={{
              paddingLeft: "8px",
              paddingRight: "0",
            }}
          >
            <CardStatus
              status="Encerrado"
              description="A participação foi concluída, não haverá mudanças."
              icon="closed"
              active={statusActive === "closed"}
              onClick={() => setStatusActive("closed")}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Fechar</Button>
        <Button bsStyle="primary" fill onClick={handleConfirm}>
          Atualizar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const iconsByStatus = {
  open: <MdHourglassEmpty size={24} color="#dc2626" />,
  attendance: <RiUserVoiceLine size={24} color="#4f46e5" />,
  closed: <FiCheck size={24} color="#16a34a" />,
};

const bgByStatus = {
  open: "red",
  attendance: "indigo",
  closed: "green",
};

function CardStatus(props) {
  return (
    <div
      className={`${style.cardContent} ${
        props.active ? style.cardContentActive : ""
      }`}
      onClick={props.onClick}
    >
      <div>
        <div className={`${style.icon} ${style[bgByStatus[props.icon]]}`}>
          {iconsByStatus[props.icon]}
        </div>
        <strong>{props.status}</strong>
      </div>

      <p>{props.description}</p>
    </div>
  );
}
